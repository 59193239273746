import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import FlexContainer from '../../elements/FlexContainer';
import { upperFirst } from 'lodash';
import Truncate from '../../utils/Truncate.tsx';
import LogIcon from '../../icons/LogIcon';
import { useNavigate } from 'react-router-dom';
import RemoveXIcon from '../../icons/RemoveXIcon';
import { setMealPlanFoodProperty } from '../../store/mealplan/selectedMealPlanSlice';
import { getImageURL } from '../../services/api/api';
import { trackEvent } from '../../integrations/analytics';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const FoodChipSmall = ({ food, instanceId, variant, handleRemove }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.selectedMealPlan);

  async function toggleEaten(event) {
    event.stopPropagation();
    trackEvent('Logged Food (Dashboard)', { food: food, eaten: !food.eaten });
    dispatch(
      setMealPlanFoodProperty({
        mealPlan,
        food,
        property: 'eaten',
        value: !food.eaten,
      }),
    );
  }

  const imgUrl = getImageURL(
    `${fileStorage}/Nutriology_Symbol_375x242.png`,
    'resizing_type:fill/height:32/width:32',
  );

  const foodName = food?.food?.verboseName ? food.food.verboseName : food?.name;

  const handleLink = () => {
    if (mealPlan) {
      trackEvent('Clicked Recipe or Food Name (Dashboard)', { food: food._id });
      navigate(`/app/mealplan/${mealPlan._id}/food/${food._id}`);
    }
  };

  return (
    <ChipContainer
      alignItems="center"
      deleted={variant === 'admin' && food?.food?.isDeleted}
      data-test="food-chip-small"
    >
      <Transparency alignItems="center">
        <Avatar alt="avatar" src={imgUrl} />
        <Label alt={foodName} onClick={handleLink}>
          <Truncate>{upperFirst(foodName)}</Truncate>
        </Label>
      </Transparency>
      {variant === 'dashboard' ? (
        <LogIcon
          hideLabel
          small
          logged={food.eaten}
          toggleSelect={toggleEaten}
        />
      ) : variant === 'admin' ? (
        <RemoveXIcon
          size="24px"
          data-name={instanceId || ''}
          data-type="food"
          data-test="meal-card-delete"
          onClick={handleRemove}
        />
      ) : null}
    </ChipContainer>
  );
};

const ChipContainer = styled(FlexContainer)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: ${(props) =>
    props.deleted ? '1px solid red' : `1px solid ${colors.primary300}`};
  border-radius: 20px;
  height: 38px;
  width: 100%;
  padding: 0px 4px;

  & .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
`;

const Transparency = styled(FlexContainer)`
  height: 100%;
  width: 100%;
`;

const Label = styled.div`
  color: ${colors.primary800};
  font-size: 15px;
  margin-left: 8px;
  text-align: left;
  width: 100%;

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

export default FoodChipSmall;
