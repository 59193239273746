import activeMealPlan from './mealplan/activeMealplanSlice';
import selectedMealPlan from './mealplan/selectedMealPlanSlice';
import activeFitnessPlan from './fitnessplan/activeFitnessPlanSlice';
import selectedFitnessPlan from './fitnessplan/selectedFitnessPlanSlice';
import shoppingList from './shoppinglist/shoppingListSlice';
import defaultPlaceholder from './mealplan/defaultPlaceholderSlice';
import track from './fitnessplan/trackSlice';
import currentUser from './general/currentUserSlice';
import errors from './general/errorSlice';
import selectedDate from './general/selectedDateSlice';
import selectedMealType from './mealplan/selectedMealTypeSlice';
import shouldAnimateSetRecord from './general/shouldAnimateSetSlice';
import ingredientMaps from './shoppinglist/ingredientMapsSlice';
import circleSlug from './general/circleSlugSlice';
// import { api } from './apiSlice'

const rootReducer = {
  // Add the generated RTK Query "API slice" caching reducer
  // [api.reducerPath]: api.reducer,
  currentUser,
  circleSlug,
  errors,
  ingredientMaps,
  activeMealPlan,
  selectedMealPlan,
  activeFitnessPlan,
  selectedFitnessPlan,
  selectedDate,
  selectedMealType,
  defaultPlaceholder,
  shoppingList,
  shouldAnimateSetRecord,
  track,
};

export default rootReducer;
