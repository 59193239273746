import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const CollapseIcon = ({ open, toggleSelect, small, ...props }) => {
  if (small) {
    return (
      <Icon
        viewBox="0 0 18 10"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        name="collapse"
        onClick={toggleSelect}
        data-open={open}
        {...props}
      >
        <g
          id="Style-Guide"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <g
            id="Style-Guide---Elements"
            transform="translate(-198.000000, -3941.000000)"
            stroke="#686868"
            strokeWidth="2"
          >
            <RotateGroup $open={open} id="Collapse-Arrow-Small">
              <Path
                d="M0.727272727,7.33333333 L8.03627318,0.633416253"
                id="Line-2"
              ></Path>
              <Path
                d="M8,7.33333333 L15.3090005,0.633416253"
                id="Line-2-Copy"
                transform="translate(11.636364, 4.000000) scale(-1, 1) translate(-11.636364, -4.000000) "
              ></Path>
            </RotateGroup>
          </g>
        </g>
      </Icon>
    );
  } else {
    return (
      <Container justify="center" alignItems="center">
        <Circle
          justify="center"
          alignItems="center"
          onClick={toggleSelect}
          name="collapse"
          data-open={open}
          {...props}
        >
          <Icon
            viewBox="0 0 18 10"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Style-Guide"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
            >
              <g
                id="Style-Guide---Elements"
                transform="translate(-198.000000, -3941.000000)"
                stroke="#686868"
                strokeWidth="2"
              >
                <RotateGroup $open={open} id="Collapse-Arrow-Small">
                  <Path
                    d="M0.727272727,7.33333333 L8.03627318,0.633416253"
                    id="Line-2"
                  ></Path>
                  <Path
                    d="M8,7.33333333 L15.3090005,0.633416253"
                    id="Line-2-Copy"
                    transform="translate(11.636364, 4.000000) scale(-1, 1) translate(-11.636364, -4.000000) "
                  ></Path>
                </RotateGroup>
              </g>
            </g>
          </Icon>
        </Circle>
      </Container>
    );
  }
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 32px;
  height: 32px;
`;

const Circle = styled(FlexContainer)`
  width: 32px;
  height: 32px;
  border-radius: 18px;
  position: absolute;
  border: 2px solid ${colors.primary700};

  &:hover {
    cursor: pointer;
    background-color: ${colors.primary050};
  }

  &:focus {
    background-color: ${colors.primary050};
  }

  &:active {
    background-color: ${colors.primary100};
  }

  &:disabled {
    border: 2px solid ${colors.primary300};
  }
`;

const RotateGroup = styled.g`
  transition: transform 0.3s;
  transform: ${(props) =>
    props.$open
      ? `translate(207px,3946px) translate(-207px,-3946px) translate(199px,3942px)`
      : 'translate(207px,3946px) rotate(-180deg) translate(-207px,-3946px) translate(199px,3942px)'};
`;

const Path = styled.path`
  fill: none;
  stroke: ${colors.primary700};
`;

// & #Line-2 {
//   transform: translate(207.000000, 3946.000000) rotate(-180.000000) translate(-207.000000, -3946.000000) translate(199.000000, 3942.000000);
//
// }
//
// & #Line-2-Copy {
//   transform: translate(11.636364, 4.000000) scale(-1, 1) translate(-11.636364, -4.000000);
// }

const Icon = styled.svg`
  width: ${(props) => props.width || 18}px;
  height: ${(props) => props.height || 10}px;

  &:hover {
    cursor: pointer;
  }

  &:disabled ${Path} {
    stroke: ${colors.primary300};
  }
`;

export default CollapseIcon;
