import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const CloseIcon = ({
  handleClose,
  size,
  color,
  showCircle,
  hideCircle,
  ...props
}) => (
  <Circle
    justify="center"
    alignItems="center"
    onClick={handleClose}
    $size={size}
    $color={color}
    $showCircle={showCircle}
    $hideCircle={hideCircle}
    data-type={props['data-type']}
    data-name={props['data-name']}
    data-test={props['data-test']}
    {...props}
  >
    <Icon
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      $size={size}
      $color={color}
      $showCircle={showCircle}
      $hideCircle={hideCircle}
      data-type={props['data-type']}
      data-name={props['data-name']}
    >
      <g id="Style-Guide" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="Style-Guide---Elements"
          transform="translate(-198.000000, -5239.000000)"
          strokeWidth="1.5"
        >
          <g
            id="Remove-X-icon-Default"
            transform="translate(199.000000, 5240.000000)"
          >
            <g
              id="Group"
              transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) translate(6.000000, 6.000000)"
              strokeLinecap="round"
            >
              <path
                d="M0,6 L12,6"
                id="Line-2"
                data-type={props['data-type']}
                data-name={props['data-name']}
              ></path>
              <path
                d="M6,0 L6,12"
                id="Line-2-Copy"
                data-type={props['data-type']}
                data-name={props['data-name']}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Circle>
);

const Circle = styled(FlexContainer)`
  width: ${(props) => props.$size || '32px'};
  height: ${(props) => props.$size || '32px'};
  border-radius: 16px;
  background-color: ${(props) =>
    props.$showCircle
      ? props.$color === 'pink'
        ? '#fffbfb'
        : colors.primary050
      : '#fff'};

  &:hover {
    background-color: ${(props) =>
      props.$showCircle
        ? props.$color === 'pink'
          ? '#fffbfb'
          : colors.primary050
        : colors.primary100};
  }

  &:active {
    background-color: ${(props) =>
      props.$color === 'pink'
        ? colors.hlitetwo100
        : props.$hideCircle
          ? '#fff'
          : colors.primary100};
  }
`;

const Icon = styled.svg`
  width: ${(props) => props.$size || '40px'};
  height: ${(props) => props.$size || '40px'};

  & path {
    stroke: ${(props) =>
      props.$color === 'pink' ? colors.hlitetwo400 : colors.primary600};
  }

  &:hover {
    cursor: pointer;
  }

  &:active path {
    stroke: ${(props) =>
      props.$color === 'pink' ? colors.hlitetwo300 : colors.primary600};
  }

  &:disabled path {
    fill: none;
    stroke: ${colors.primary300};
  }
`;

export default CloseIcon;
