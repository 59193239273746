import React, { useState, useMemo, useEffect } from 'react';
import {
  map,
  concat,
  uniqWith,
  isEqual,
  filter,
  debounce,
  capitalize,
} from 'lodash';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import Button from '../elements/Button';
import Chip from '../elements/Chip';
import DynamicSearchBar from '../elements/DynamicSearchBar';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { isMobile } from '../helpers/utils';
import MobileDialog from '../sharedModals/mobile/MobileDialog';
import FlexContainer from '../elements/FlexContainer';
import AlertStack from '../elements/AlertStack';
import { getHighlightedText } from '../helpers/utils';

const SearchModalReturnField = ({
  open,
  fetchService,
  fetchQueryFieldName,
  handleClose,
  value,
  setValue,
  ...props
}) => {
  const [modalValue, setModalValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [error, setError] = useState();

  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setModalValue(uniqWith(newValue, isEqual));
  };

  const handleSelectIngredient = (event, newValue) => {
    setInputValue('');
    if (options.length > 0) {
      setModalValue(uniqWith([...modalValue, newValue], isEqual));
    }
  };

  const fetch = useMemo(
    () =>
      debounce((params, callback) => {
        fetchService(params)
          .then(callback)
          .catch((error) => {
            console.error(error);
            if (error && error.message) {
              return setError('Error: ' + error.message);
            }
            if (typeof error === 'string') {
              return setError('Error: ' + error);
            }
            return setError('Error encountered');
          });
      }, 500),
    [fetchService],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (fetchService) {
      const params = {
        query: inputValue,
        limit: 200,
      };
      fetch(params, (results) => {
        if (active) {
          setOptions(map(results, fetchQueryFieldName) || []);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [inputValue, fetchQueryFieldName]);

  const handleDelete = (item) => {
    setModalValue(filter(modalValue, (v) => v !== item));
  };

  const handleAccept = () => {
    setValue(uniqWith(concat(value, modalValue), isEqual));
    setModalValue([]);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalValue([]);
    handleClose();
  };

  const optionsList = options.map((option, index) => {
    return (
      <OptionLabel
        key={index}
        onClick={(e) => handleSelectIngredient(e, option)}
        data-test="searchModal-foodOption"
      >
        {getHighlightedText(capitalize(option), inputValue)}
      </OptionLabel>
    );
  });

  if (isMobile()) {
    // Mobile view
    return (
      <MobileDialog
        open={open}
        handleClose={handleClose}
        height="fit-content"
        maxheight="85%"
        small="small"
        data-test="mobile-modal"
      >
        <DialogContainer flexDirection="column" alignItems="center">
          <Puller />
          <TopContainer flexDirection="column" alignItems="center">
            <MobileHeading>{props.heading}</MobileHeading>
            <MobileSubheading marginTop={'8px'}>
              {props.subheading}
            </MobileSubheading>
          </TopContainer>
          <SearchContainer flexDirection="column" alignSelf="flex-start">
            <MobileInputLabel>Search for food</MobileInputLabel>
            <DynamicSearchBar
              searchTerm={inputValue}
              handleChange={handleChangeInput}
              data-test="searchModal-ingSearch"
            />
          </SearchContainer>
          <MobileChipContainer wrap="wrap" alignItems="center">
            {modalValue.map((option, index) => (
              <ChipStyled
                key={index}
                label={props.getOptionLabel(option)}
                handleDelete={() => handleDelete(option)}
                bgcolor={props.chipBgColor}
                iconcolor="#FFFBFB"
                selected
                data-test="searchModal-ingChip"
              />
            ))}
          </MobileChipContainer>
          <OptionsContainer flexDirection="column">
            {optionsList}
          </OptionsContainer>
          {error && (
            <AlertStack
              messages={error}
              type="error"
              variant="filled"
              open={!!error}
              handleClose={() => setError(null)}
              autoHideDuration={10000}
            />
          )}

          <MobileAddButton
            alignItems="center"
            justify="center"
            backgroundcolor={props.chipBgColor}
            onClick={handleAccept}
            data-test="submit-items"
          >
            <MobileSubheading color="#fff">
              Add {props.heading}
            </MobileSubheading>
          </MobileAddButton>
          <div style={{ height: 8 }} />
          <MobileAddButton
            alignItems="center"
            justify="center"
            backgroundcolor="#fff"
            borderColor={colors.primary800}
            onClick={handleCloseModal}
          >
            <MobileSubheading color={colors.primary800}>
              Cancel
            </MobileSubheading>
          </MobileAddButton>
        </DialogContainer>
      </MobileDialog>
    );
  } else {
    // Web View
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {handleClose ? (
            <TitleButton aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </TitleButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Title>Search Foods</Title>
          <AutocompleteStyled
            multiple
            autoComplete
            freeSolo
            includeInputInList
            filterOptions={(x) => x}
            options={options}
            getOptionLabel={props.getOptionLabel || ((option) => option.name)}
            value={modalValue}
            onChange={handleChange}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={{
                  ...params.inputProps,
                  onChange(event) {
                    handleChangeInput(event);
                    return params.inputProps.onChange(event);
                  },
                }}
                placeholder={props.placeholder || ''}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                data-test="searchModal-ingSearch"
              />
            )}
          />
          {error && (
            <AlertStack
              messages={error}
              type="error"
              variant="filled"
              open={!!error}
              handleClose={() => setError(null)}
              autoHideDuration={10000}
            />
          )}
          {modalValue.map((option, index) => (
            <ChipStyled
              key={index}
              label={props.getOptionLabel(option)}
              handleDelete={() => handleDelete(option)}
              bgcolor={props.chipBgColor}
              hovercolor={props.hoverColor}
              iconcolor="#FFFBFB"
              data-test="searchModal-ingChip"
              selected
            />
          ))}
        </DialogContent>
        <DialogActions>
          <AddButton
            buttonText="ADD"
            onClick={handleAccept}
            backgroundcolor={props.chipBgColor}
            hovercolor={props.hoverColor}
            data-test="searchModal-add"
          />
        </DialogActions>
      </Dialog>
    );
  }
};

const TitleButton = styled(IconButton)`
  top: 4px;
  color: #9e9e9e;
  right: 8px;
  position: absolute;
`;

const AddButton = styled(Button)`
  margin: 16px;
  height: 40px;
  width: 89px;
  border-radius: 4px;
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  background-color: ${(props) => props.backgroundcolor};

  &:hover {
    background-color: ${(props) => props.hovercolor};
  }
`;

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ChipStyled = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : 'initial')};
  color: ${(props) => (props.bgcolor ? '#fff' : 'initial')};

  &:hover {
    background-color: ${(props) =>
      props.hovercolor ? props.hovercolor : 'initial'};
    color: ${(props) => (props.bgcolor ? '#fff' : 'initial')};
  }

  & .MuiChip-deleteIcon {
    color: ${(props) => (props.iconcolor ? props.iconcolor : 'initial')};
  }

  & .MuiChip-deleteIcon:hover {
    cursor: pointer;
    color: ${(props) => (props.iconcolor ? props.iconcolor : 'initial')};
  }
`;

const AutocompleteStyled = styled(Autocomplete)`
  width: ${(props) => props.width || '552px'};
  &.Mui-focused > div {
    border: ${(props) =>
      props.error
        ? `1px solid ${colors.error}`
        : `1px solid ${colors.secondary500}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  > div {
    box-sizing: border-box;
    height: 41px;
    width: 100%;
    border: ${(props) =>
      props.error
        ? `1px solid ${colors.error}`
        : `1px solid ${colors.primary400}`};
    background-color: #ffffff;
    margin: 20px 0;
    padding: 0 12px;
    font-size: ${(props) => (props.small ? '15px' : '17px')};
    line-height: ${(props) => (props.small ? '18px' : '20px')};
    border-radius: 2px;

    &input::placeholder {
      color: ${colors.primary400};
    }
  }
`;

/* mobile styles */

const DialogContainer = styled(FlexContainer)`
  height: 100%;
  background-color: #fbfbfb;
  background-image: url(${process.env.PUBLIC_URL}/Bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 24px;
  margin: 0 16px;
`;

const Puller = styled.div`
  width: 46px;
  height: 3px;
  background-color: ${colors.primary100};
  border-radius: 16px;
  position: absolute;
  top: 8px;
`;

const TopContainer = styled.div`
  padding: 36px 0 24px 0;
`;

const SearchContainer = styled(FlexContainer)`
  width: 100%;
`;

const MobileHeading = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${colors.primary800};
`;

const MobileSubheading = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.color || colors.primary800};
  margin-top: ${(props) => props.marginTop || 0};
`;

const MobileInputLabel = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary800};
  align-self: flex-start;
  margin-bottom: 8px;
`;
const MobileChipContainer = styled(FlexContainer)`
  width: 100%;
  padding: 8px 0 0 0;
  > * {
    margin-right: 8px;
  }
`;

const MobileAddButton = styled(FlexContainer)`
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.backgroundcolor};
  border-radius: 2px;
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
`;

const OptionsContainer = styled(FlexContainer)`
  overflow-y: scroll;
  height: 40vh;
  > * {
    border-bottom: 1px solid ${colors.primary300};
    padding: 14px 0;
  }
`;
const OptionLabel = styled.h4`
  color: ${colors.primary600};
`;

export default SearchModalReturnField;
