import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = (state) => ({
  isSubscribed: state.currentUser.user.isSubscribed,
});

const RequireSubscribe = () => {
  const { isSubscribed } = useSelector(mapState);
  return isSubscribed ? (
    <Outlet />
  ) : !isSubscribed ? (
    <Navigate to="/intake/payment" />
  ) : (
    <Navigate to="/login" />
  );
};

export default RequireSubscribe;
