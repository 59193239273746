import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import GraphLabel from '../../icons/GraphLabel';
import { PieChart } from 'react-minimal-pie-chart';
import { isMobile } from '../../helpers/utils';
import {
  formatCalcium,
  formatCholesterol,
  formatDietaryFiber,
  formatIron,
  formatProtein,
  formatSaturatedFat,
  formatSodium,
  formatTotalCarbohydrate,
  formatTotalFat,
  formatTotalSugars,
  formatTransFat,
  formatVitaminD,
  formatPotassium,
} from '../../helpers/nutrition';

const FoodNutritionContainer = ({ food, quantity, ...props }) => {
  const totalCalories = food.nutrientsPerUnit.calories * quantity;
  const data = [
    {
      title: 'Protein',
      value:
        (formatProtein(food.nutrientsPerUnit.proteins * quantity, false) * 4) /
        totalCalories,
      color: colors.pie1,
    },
    {
      title: 'Carbs',
      value:
        (formatTotalCarbohydrate(
          food.nutrientsPerUnit.carbohydrates * quantity,
          false,
        ) *
          4) /
        totalCalories,
      color: colors.pie2,
    },
    {
      title: 'Fat',
      value:
        (formatTotalFat(food.nutrientsPerUnit.fat * quantity, false) * 4) /
        totalCalories,
      color: colors.pie3,
    },
  ];

  return (
    <Container flexDirection="column" flexShrink="0">
      <FoodSectionHeader justify="space-between" alignItems="center">
        <FoodSectionName>Nutrition</FoodSectionName>
      </FoodSectionHeader>
      <InfoContainer
        alignItems="center"
        wrap="wrap"
        justify={isMobile() && 'center'}
      >
        <StatsContainer alignItems="center">
          <PieChart
            data={data.filter((item) => item.value > 0)}
            style={{
              height: isMobile() ? '164px' : '250px',
              width: isMobile() ? '164px' : '250px',
            }}
            labelPosition="60"
            label={({ x, y, dx, dy, dataEntry }) => {
              return (
                <GraphLabel
                  x={x}
                  y={y}
                  dx={dx}
                  dy={dy}
                  dominantBaseline="central"
                  textAnchor="middle"
                  text={`${Math.round(dataEntry.percentage)}%`}
                />
              );
            }}
          />
          <MacrosContainer flexDirection="column">
            <MacroItem>
              <MacroQuantity data-test="foodPage-protein">
                {formatProtein(food.nutrientsPerUnit.proteins * quantity)}
              </MacroQuantity>
              <Macro color={colors.pie1}>Protein</Macro>
            </MacroItem>
            <MacroItem>
              <MacroQuantity data-test="foodPage-carbs">
                {formatTotalCarbohydrate(
                  food.nutrientsPerUnit.carbohydrates * quantity,
                )}
              </MacroQuantity>
              <Macro color={colors.pie2}>Carbs</Macro>
            </MacroItem>
            <MacroItem>
              <MacroQuantity data-test="foodPage-fat">
                {formatTotalFat(food.nutrientsPerUnit.fat * quantity)}
              </MacroQuantity>
              <Macro color={colors.pie3}>Fat</Macro>
            </MacroItem>
          </MacrosContainer>
        </StatsContainer>
        <DailyValuesContainer flexDirection="column" wrap="wrap">
          <DailyValueItem>
            <DvQuantity data-test="foodPage-satFat">
              {formatSaturatedFat(
                food.nutrientsPerUnit.saturatedFat * quantity,
              )}
            </DvQuantity>
            <DailyValue>Saturated Fat</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-transFat">
              {formatTransFat(food.nutrientsPerUnit.transFat * quantity)}
            </DvQuantity>
            <DailyValue>Trans Fat</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-cholesterol">
              {formatCholesterol(
                food.nutrientsPerUnit.cholesterol_mg * quantity,
              )}
            </DvQuantity>
            <DailyValue>Cholesterol</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-sodium">
              {formatSodium(food.nutrientsPerUnit.sodium_mg * quantity)}
            </DvQuantity>
            <DailyValue>Sodium</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-fiber">
              {formatDietaryFiber(food.nutrientsPerUnit.fiber * quantity)}
            </DvQuantity>
            <DailyValue>Dietary Fibers</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-totalSugar">
              {formatTotalSugars(food.nutrientsPerUnit.totalSugar * quantity)}
            </DvQuantity>
            <DailyValue>Sugar</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-calcium">
              {formatCalcium(food.nutrientsPerUnit.calcium_mg * quantity)}
            </DvQuantity>
            <DailyValue>Calcium</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-vitD">
              {formatVitaminD(food.nutrientsPerUnit.vitaminD_mcg * quantity)}
            </DvQuantity>
            <DailyValue>Vitamin D</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-potassium">
              {formatPotassium(food.nutrientsPerUnit.potassium_mg * quantity)}
            </DvQuantity>
            <DailyValue>Potassium</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="foodPage-iron">
              {formatIron(food.nutrientsPerUnit.iron_mg * quantity)}
            </DvQuantity>
            <DailyValue>Iron</DailyValue>
          </DailyValueItem>
        </DailyValuesContainer>
      </InfoContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
`;

const FoodSectionHeader = styled(FlexContainer)`
  width: ${(props) => (isMobile() ? 'calc(100% - 48px)' : 'calc(100% - 16px)')};
  margin: ${(props) => (isMobile() ? '0 16px' : 'initial')};
  padding: 0 8px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const FoodSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

const InfoContainer = styled(FlexContainer)`
  width: 100%;
  padding-top: 24px;
  margin-bottom: 32px;
  gap: ${(props) => (isMobile() ? '24px' : '80px')};
`;

const StatsContainer = styled(FlexContainer)`
  gap: 32px;
`;

const DailyValuesContainer = styled(FlexContainer)`
  max-height: ${(props) => (isMobile() ? '185px' : '170px')};
  width: ${(props) => (isMobile() ? '90%' : '400px')};
  gap: 24px;
`;

const DailyValueItem = styled(FlexContainer)`
  width: ${(props) => (isMobile() ? '160px' : '200px')};
`;

const DailyValue = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;
const DvQuantity = styled(DailyValue)`
  width: ${(props) => (isMobile() ? '70px' : '78px')};
  font-weight: 700;
`;

const MacrosContainer = styled(FlexContainer)``;

const MacroItem = styled(FlexContainer)`
  color: ${colors.primary800};
  margin-bottom: 16px;
`;

const MacroQuantity = styled.h5`
  width: 55px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const Macro = styled.h5`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  margin-left: 14px;

  &:before {
    content: ' ';
    position: absolute;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: ${(props) => props.color};
    top: 30%;
    left: -14px;
  }
`;

export default FoodNutritionContainer;
