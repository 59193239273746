import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';

import WorkoutSection from './WorkoutSection';
import { DragDropContext } from 'react-beautiful-dnd';

const WorkoutSimplified = ({ exercises, reorderExercises, ...props }) => {
  const warmup = exercises.filter(
    (exercise) => exercise.workoutSequence === 'Warm Up',
  );
  const core = exercises.filter(
    (exercise) => exercise.workoutSequence === 'Core',
  );
  const strength = exercises.filter(
    (exercise) => exercise.workoutSequence === 'Strength',
  );
  const circuit = exercises.filter(
    (exercise) => exercise.workoutSequence === 'Circuit',
  );
  const conditioning = exercises.filter(
    (exercise) => exercise.workoutSequence === 'Conditioning',
  );
  const cooldown = exercises.filter(
    (exercise) => exercise.workoutSequence === 'Cool Down',
  );

  const onDragEndExercises = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    return reorderExercises(exercises, result.source, result.destination);
  };

  const workoutSections = (
    <>
      <WorkoutSection
        sectionId="warm-up"
        rest={3}
        exercises={warmup}
        {...props}
      />
      <WorkoutSection sectionId="core" rest={3} exercises={core} {...props} />
      <WorkoutSection
        sectionId="strength"
        rest={3}
        exercises={strength}
        {...props}
      />
      <WorkoutSection
        sectionId="circuit"
        rest={3}
        exercises={circuit}
        {...props}
      />
      <WorkoutSection
        sectionId="conditioning"
        rest={3}
        exercises={conditioning}
        {...props}
      />
      <WorkoutSection
        sectionId="cool-down"
        rest={3}
        exercises={cooldown}
        {...props}
      />
    </>
  );
  const workoutEditableSections = (
    <DragDropContext onDragEnd={onDragEndExercises}>
      {workoutSections}
    </DragDropContext>
  );

  return (
    <CardContainer flexDirection="column">
      {props.viewOnly ? <>{workoutSections}</> : <>{workoutEditableSections}</>}
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  background-color: #fff;
  min-height: 144px;
  min-width: 400px;
  width: 80%;
  position: relative;
`;

export default WorkoutSimplified;
