import React from 'react';
import Slider from '@mui/material/Slider';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const CustomSlider = ({
  unit,
  min,
  max,
  handleValueChanged,
  value,
  name,
  ...props
}) => {
  const handleChange = (event, newValue) => {
    handleValueChanged(name, newValue);
  };

  const valPct = value / max;
  const lowerbound = max - min > 20 ? 0.25 : 0.09;
  const upperbound = 0.93;

  return (
    <SliderContainer>
      <StyledSlider
        name={name}
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        valueLabelFormat={`${value} ${unit}`}
        valueLabelDisplay="on"
        data-test={props['data-test']}
        valpct={valPct}
        upperbound={upperbound}
        lowerbound={lowerbound}
      />
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  margin-top: 32px;
`;

const StyledSlider = styled(Slider)`
  color: #ff6868;
  padding: 24px 0;
  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    position: inherit;
  }
  .MuiSlider-track {
    height: 7px;
    border-radius: 27px;
  }
  .MuiSlider-rail {
    height: 7px;
    color: ${colors.primary100};
    border-radius: 27px;
  }

  .MuiSlider-valueLabel {
    z-index: auto;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #ff6868;
    margin-right: ${(props) => (props.valpct > props.upperbound ? '44px' : 0)};
    margin-left: ${(props) => (props.valpct < props.lowerbound ? '30px' : 0)};
    left: unset; /* override new material UI default */
    background: transparent;

    &::after {
      content: ${(props) => props.valueLabelFormat};
    }

    & * {
      background: transparent;
      color: #ff6868;
      width: 100px;
      text-align: center;
    }
  }
`;

export default CustomSlider;
