import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const TrashIcon = ({ selected, toggleSelect, ...props }) => (
  <Circle
    justify="center"
    alignItems="center"
    onClick={toggleSelect}
    width={props.width}
    height={props.height}
    data-name={props['data-name']}
  >
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      name="remove"
      onClick={toggleSelect}
      selected={selected}
      data-name={props['data-name']}
      {...props}
    >
      <path
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        fill="#fff"
        d="M9.9,10.5h12.1l0,0v12.8c0,1.4-1.2,2.6-2.6,2.6h-7c-1.4,0-2.6-1.2-2.6-2.6V10.5L9.9,10.5z"
        data-name={props['data-name']}
      />
      <line
        x1="8.4"
        y1="8.3"
        x2="23.6"
        y2="8.3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        data-name={props['data-name']}
      />
      <line
        x1="12.8"
        y1="6.1"
        x2="19.2"
        y2="6.1"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        data-name={props['data-name']}
      />
      <line
        x1="14"
        y1="13.5"
        x2="14"
        y2="22.9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        data-name={props['data-name']}
      />
      <line
        x1="18"
        y1="13.5"
        x2="18"
        y2="22.9"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        data-name={props['data-name']}
      />
    </Icon>
  </Circle>
);

const Circle = styled(FlexContainer)`
  width: ${(props) => props.width || '40px'};
  height: ${(props) => props.height || '40px'};
  border-radius: ${(props) => props.height / 2 || '20px'};

  /* &:hover {
    background-color: ${colors.primary100};
    cursor: pointer;
  } */

  &:active {
    background-color: ${colors.primary200};
  }
`;

const Icon = styled.svg`
  height: 32px;
  width: 32px;

  & path {
    fill: '#fff';
    stroke: ${colors.primary700};
  }

  & line {
    fill: '#fff';
    stroke: ${colors.primary700};
  }

  &:hover {
    cursor: pointer;
  }

  /* ${Circle}:hover & path {
    fill: ${colors.primary100};
  }

  ${Circle}:active & path {
    fill: ${colors.primary200};
  } */
`;

export default TrashIcon;
