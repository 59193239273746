import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import Dialog from '@mui/material/Dialog';
import { Drawer } from '@mui/material';
import CloseIcon from '../../icons/CloseIcon';
import CollapseIconSmall from '../../icons/CollapseIconSmall';
import { isMobile } from '../../helpers/utils';

const ExerciseModal = ({ open, handleClose, img, name, ...props }) => {
  if (!isMobile()) {
    // Web version
    return (
      <CustomDialog onClose={handleClose} open={open} maxWidth="sm">
        <CloseRight>
          <CloseIcon handleClose={handleClose} data-test="exercise-gif-close" />
        </CloseRight>
        <Content>
          <Img src={img} alt="Exercise instructions" data-test="exercise-gif" />
        </Content>
      </CustomDialog>
    );
  } else {
    // Mobile version
    return (
      <CustomDrawer onClose={handleClose} open={open} anchor="bottom">
        <CloseContainer>
          <CollapseIconSmall
            onClick={handleClose}
            data-test="exercise-gif-close"
          />
        </CloseContainer>
        <FlexContainer justify="center" alignItems="center">
          <MobileGif
            src={img}
            alt="Exercise instructions"
            data-test="exercise-gif"
          />
        </FlexContainer>
        <Title>{name}</Title>
      </CustomDrawer>
    );
  }
};

/**
 * Web
 */

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 50%;
  }
`;

const Content = styled(FlexContainer)`
  position: relative;
  background-color: white;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
`;

const CloseRight = styled(FlexContainer)`
  right: 12px;
  top: 12px;
  position: absolute;
`;

/**
 * Mobile
 */

const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    padding: 8px 30px;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid #d8d8d8;
  }
`;

const CloseContainer = styled.div`
  margin: 16px -16px 16px auto;
`;

const Title = styled.div`
  height: 32px;
  width: 135px;
  margin-top: 32px;
  color: ${colors.primary700};
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 32px;
`;

const MobileGif = styled.img`
  width: 100%;
`;

export default ExerciseModal;
