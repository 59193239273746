import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import RadioInput from '../../elements/RadioInput';
import { buildURI } from '../../helpers/generateCsv';

const UserExport = ({ users, ...props }) => {
  const defaultFileType = 'json';
  const fileNames = {
    json: 'users.json',
    csv: 'users.csv',
  };
  const [fileType, setFileType] = useState(defaultFileType);
  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const fileDownload = useRef(null);

  const changeFileType = (e) => {
    setFileType(e.target.value);
  };

  const handleExport = (e) => {
    e.preventDefault();
    // Prepare the file
    let output;
    let url;
    if (fileType === 'json') {
      output = JSON.stringify({ users: users }, null, 4);
      const blob = new Blob([output]);
      url = URL.createObjectURL(blob);
    } else if (fileType === 'csv') {
      // Prepare headers:
      let headers = [];
      // Create one user object that contains all relevant keys/headers
      const combinedUser = Object.assign({}, ...users);

      for (const [key, value] of Object.entries(combinedUser)) {
        if (typeof value === 'object') {
          Object.keys(value).forEach((subKey) => {
            headers.push({
              label: `${key}.${subKey}`,
              key: `${key}.${subKey}`,
            });
          });
        } else {
          headers.push({ label: key, key: key });
        }
      }
      url = buildURI(users, headers);
    }
    // Download it
    setFileDownloadUrl(url);
  };

  useEffect(() => {
    if (fileDownloadUrl && fileDownload) {
      fileDownload.current.click();
      URL.revokeObjectURL(fileDownloadUrl); // free up storage--no longer needed.
    }
  }, [fileDownloadUrl]);

  return (
    <Container flexDirection="column" alignItems="center">
      <RadioContainer flexDirection="row" justify="space-between">
        <RadioInput
          checked={fileType === 'json'}
          value="json"
          label="JSON"
          onChange={changeFileType}
          name="export"
          data-test="user-export-json"
        />
        <RadioInput
          checked={fileType === 'csv'}
          value="csv"
          label="CSV"
          onChange={changeFileType}
          name="export"
          data-test="user-export-csv"
        />
      </RadioContainer>
      <Button
        type="submit"
        buttonText="Export"
        buttonSize="small"
        width="120px"
        onClick={handleExport}
        data-test="user-export-submit"
      />
      <FileDownload
        download={fileNames[fileType]}
        href={fileDownloadUrl}
        ref={fileDownload}
      >
        Download
      </FileDownload>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 75%;

  > * {
    margin-bottom: 12px;
  }
`;

const RadioContainer = styled(FlexContainer)`
  width: 75%;
`;

const FileDownload = styled.a`
  visibility: hidden;
`;

export default UserExport;
