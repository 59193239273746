import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDate } from '../store/general/selectedDateSlice';
import { getUserFitnessPlanByDate } from '../store/fitnessplan/selectedFitnessPlanSlice';
import { getUserMealPlanByDate } from '../store/mealplan/selectedMealPlanSlice';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Avatar from '@mui/material/Avatar';
import MobileBackIcon from '../icons/MobileBackIcon';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import AppBar from '@mui/material/AppBar';
import MobileDialog from '../sharedModals/mobile/MobileDialog';
import MobileProfileDialog from './MobileProfileDialog';
import { DateTime } from 'luxon';
import { colors } from '../styleConstants';
import { useNavigate, useLocation } from 'react-router-dom';
import useModal from '../hooks/useModal';
import MobileDashboardInfo from '../dashboard/MobileDashboardInfo';

const MobileTopNav = ({
  dateValue,
  dateText,
  setDateValue,
  arrowsDisabled,
  noBoxShadow,
  userName,
  showGreeting,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser.user);
  const navigate = useNavigate();
  const location = useLocation();
  const { open, launchModal, closeModal } = useModal(false);
  const [showStats, setShowStats] = useState(false);
  const [hideDateControls, setHideDateControls] = useState(false);
  const [pageType, setPageType] = useState('');
  const [showProfileHeading, setShowProfileHeading] = useState(false);

  const showPrevButton =
    new Date().getTime() - dateValue?.getTime() < 7 * 24 * 60 * 60 * 1000;
  const showNextButton =
    dateValue?.getTime() - new Date().getTime() < 6 * 24 * 60 * 60 * 1000;

  const handleBack = () => {
    if (pageType === 'recipe' || pageType === 'food') {
      localStorage.setItem('mealplan_goBack', 'yes');
    } else if (pageType === 'exercise') {
      localStorage.setItem('fitnessplan_goBack', 'yes');
    }
    navigate(-1);
  };

  const handleDateChange = (value) => {
    const dt = new Date(dateValue);
    if (value === 'backward') dt.setDate(dateValue.getDate() - 1);
    else dt.setDate(dateValue.getDate() + 1);
    setDateValue(dt);
  };

  useEffect(() => {
    if (location.pathname.match(new RegExp(/^\/app\/profile/))) {
      setShowProfileHeading(true);
    } else {
      setShowProfileHeading(false);
    }

    if (
      location.pathname.match(
        new RegExp(/^\/app\/mealplan\/.+\/recipe\/.+$/),
      ) ||
      location.pathname.match(new RegExp(/^\/app\/mealplan\/.+\/food\/.+$/)) ||
      location.pathname.match(
        new RegExp(/^\/app\/fitnessplan\/.+\/exercise\/.+$/),
      ) ||
      location.pathname.match(new RegExp(/^\/app\/nutriology-transformation+$/))
    ) {
      setHideDateControls(true);
    } else {
      setHideDateControls(false);
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.match(new RegExp(/^\/app\/mealplan\/.+\/recipe\/.+$/))
    ) {
      setPageType('recipe');
    } else if (
      location.pathname.match(new RegExp(/^\/app\/mealplan\/.+\/food\/.+$/))
    ) {
      setPageType('food');
    } else if (
      location.pathname.match(
        new RegExp(/^\/app\/fitnessplan\/.+\/exercise\/.+$/),
      )
    ) {
      setPageType('exercise');
    }
  }, [location]);

  useEffect(() => {
    if (dateValue) {
      dispatch(setSelectedDate(dateValue));
      dispatch(getUserFitnessPlanByDate({ userId: user._id, date: dateValue }));
      dispatch(getUserMealPlanByDate({ userId: user._id, date: dateValue }));
    }
  }, [dateValue, user._id]);

  return (
    <NavContainer
      position="fixed"
      color="default"
      noboxshadow={noBoxShadow?.toString()}
      id="mobile-top-nav"
      data-test="mobile-topNav"
    >
      {!hideDateControls && !showGreeting && !showProfileHeading && (
        <StatsIconContainer>
          <StatsImg
            alt=""
            src={`${process.env.PUBLIC_URL}/icons/statsIcon.svg`}
            onClick={() => setShowStats(!showStats)}
            data-test="mobile-show-stats"
          />
        </StatsIconContainer>
      )}
      {hideDateControls ? (
        <DateHeaderContainer alignItems="center" justify="center">
          <BackIconContainer>
            <MobileBackIcon handleClick={handleBack} data-test="mobile-back" />
          </BackIconContainer>
          <Title>{pageType}</Title>
        </DateHeaderContainer>
      ) : (
        <DateHeaderContainer
          alignItems="center"
          justify="space-around"
          data-test="mobile-dateheader"
        >
          {!arrowsDisabled && showPrevButton && (
            <Arrow
              as={ArrowLeftIcon}
              disabled={arrowsDisabled}
              onClick={() => !arrowsDisabled && handleDateChange('backward')}
              data-test="mobile-dateheader-prevDay"
            />
          )}
          {showProfileHeading ? (
            <NavHeading data-test="mobile-nav-title">Settings</NavHeading>
          ) : showGreeting ? (
            <NavHeading data-test="mobile-greeting">
              Hello, {userName.split(' ')[0]}
            </NavHeading>
          ) : (
            <DateHeading data-test="mobile-dateheader-value">
              <strong>{dateText}</strong>
              <span>{DateTime.fromJSDate(dateValue).toFormat('MMM d')}</span>
            </DateHeading>
          )}
          {!arrowsDisabled && showNextButton && (
            <Arrow
              as={ArrowRightIcon}
              disabled={arrowsDisabled}
              onClick={() => !arrowsDisabled && handleDateChange('forward')}
              data-test="mobile-dateheader-nextDay"
            />
          )}
        </DateHeaderContainer>
      )}
      <AvatarContainer>
        <StyledAvatar
          src={user.avatarUrl}
          onClick={launchModal}
          data-test="mobile-nav-avatar"
        />
      </AvatarContainer>
      <MobileDialog
        open={open}
        handleClose={closeModal}
        height="fit-content"
        small="small"
        data-test="mobile-modal"
      >
        <MobileProfileDialog user={user} handleClose={closeModal} />
      </MobileDialog>
      <MobileDialog
        open={showStats}
        handleClose={() => setShowStats(!showStats)}
        height="fit-content"
        small="small"
        data-test="mobile-stats-modal"
      >
        <MobileDashboardInfo
          type={
            location.pathname.match(new RegExp(/^\/app\/mealplan/))
              ? 'meal'
              : 'workout'
          }
          handleClose={() => setShowStats(!showStats)}
        />
      </MobileDialog>
    </NavContainer>
  );
};
const NavContainer = styled(AppBar)`
  z-index: 1;
  top: 0;
  bottom: auto;
  background-color: #fff;
  box-shadow: ${(props) =>
    props.noboxshadow ? 'none' : '0 4px 6px 0 rgba(0,0,0,0.08)'};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const DateHeaderContainer = styled(FlexContainer)`
  height: 44px;
  padding: 0 12px;
  min-width: 55%;
  max-width: 75%;
  align-self: center;
`;
const DateHeading = styled.span`
  margin: auto;
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Arrow = styled.span`
  color: ${(props) => props.disabled && colors.primary300};
  font-size: 36px;
  cursor: pointer;
`;

const BackIconContainer = styled(FlexContainer)`
  position: absolute;
  left: 10px;
`;

const Title = styled.h4`
  color: ${colors.primary800};
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
`;

const StyledAvatar = styled(Avatar)`
  height: 30px;
  width: 30px;
  border: 2px solid ${colors.primary100};
`;

const StatsIconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 5px;
  height: 32px;
  width: 32px;
`;

const StatsImg = styled.img`
  height: 32px;
  width: 32px;
`;
const AvatarContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 5px;
`;

const NavHeading = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary800};
`;

export default MobileTopNav;
