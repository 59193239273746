import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import CardsRow from '../CardsRow';
import StaticSearchBar from '../../elements/StaticSearchBar';
import Filters from '../../sharedModals/web/Filters';

const SearchWeb = ({
  handleSearch,
  searchTerm,
  setSearchTerm,
  clearAllFilters,
  handleCollapseToggle,
  handleToggle,
  collapseToggleValues,
  toggleValues,
  searchQuery,
  searchType,
  userRecipes,
  setScreen,
  setRecipe,
  pageSizeRecipes,
  getUserRecipes,
  recipes,
  filteredRecipes,
  getRecipes,
  userFoods,
  getUserFoods,
  setFood,
  pageSizeFoods,
  getFoods,
  foods,
  showClearBtn,
  setSearchType,
  setSearchTypeSelected,
  hide,
  context,
  hasMoreRecipes,
  hasMoreFoods,
  hasMoreCustomRecipes,
  hasMoreCustomFoods,
  ...props
}) => {
  const handleSearchTypeChanged = (e) => {
    setSearchType(e.target.value);
    setSearchTypeSelected(e.target.value);
  };

  return (
    <Container flexDirection="column">
      <SearchArea flexDirection="column">
        <Title>Search Foods</Title>
        <StaticSearchBar
          handleSearch={handleSearch}
          placeholder="Try Sesame Chicken, etc"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data-test="meal-search"
        />
        <FilterContainer flexDirection="row" justify="space-around">
          {hide !== 'recipe' && (
            <RadioInput
              checked={searchType === 'recipe'}
              value="recipe"
              label="Recipe"
              onChange={handleSearchTypeChanged}
              name="searchType"
              data-test="meal-searchType-recipe"
            />
          )}
          {hide !== 'food' && (
            <RadioInput
              checked={searchType === 'food'}
              value="food"
              label="Food"
              onChange={handleSearchTypeChanged}
              name="searchType"
              data-test="meal-searchType-food"
            />
          )}
          {!hide && (
            <RadioInput
              checked={searchType === 'both'}
              value="both"
              label="Both"
              onChange={handleSearchTypeChanged}
              name="searchType"
              data-test="meal-searchType-both"
            />
          )}
        </FilterContainer>
      </SearchArea>

      <FlexContainer>
        {['recipe', 'both'].includes(searchType) && (
          <Filters
            itemType="recipe"
            toggleValues={toggleValues}
            handleToggle={handleToggle}
            clearAllFilters={clearAllFilters}
            handleCollapseToggle={handleCollapseToggle}
            collapseToggleValues={collapseToggleValues}
          />
        )}
        {(filteredRecipes.length > 0 ||
          foods.length > 0 ||
          userRecipes.length > 0 ||
          userFoods.length > 0) && (
          <RecommendedContainer flexDirection="column">
            <Header>Results</Header>
            <RowsContainer wrap="wrap">
              <MealRows flexDirection="column">
                {['recipe', 'both'].includes(searchType) && (
                  <>
                    <CardsRow
                      key={`user-recipes-${searchQuery}-${searchType}`}
                      title="Your Recipes"
                      items={userRecipes}
                      setScreen={setScreen}
                      setItem={setRecipe}
                      wrap="wrap"
                      type="Recipe"
                      pageSize={pageSizeRecipes}
                      loadMoreItems={getUserRecipes}
                      scrollParent={props.scrollParent}
                      hasMore={hasMoreCustomRecipes}
                      context={context}
                    />
                    <CardsRow
                      key={`recipes-${searchQuery}-${searchType}`}
                      title="Recipes"
                      items={filteredRecipes}
                      unfilteredLength={recipes?.length}
                      setScreen={setScreen}
                      setItem={setRecipe}
                      wrap="wrap"
                      type="Recipe"
                      pageSize={pageSizeRecipes}
                      loadMoreItems={getRecipes}
                      scrollParent={props.scrollParent}
                      hasMore={hasMoreRecipes}
                      context={context}
                    />
                  </>
                )}

                {['food', 'both'].includes(searchType) && (
                  <>
                    <CardsRow
                      key={`user-foods-${searchQuery}-${searchType}`}
                      title="Your Foods"
                      items={userFoods}
                      setScreen={setScreen}
                      setItem={setFood}
                      wrap="wrap"
                      type="Food"
                      pageSize={pageSizeFoods}
                      loadMoreItems={getUserFoods}
                      scrollParent={props.scrollParent}
                      hasMore={hasMoreCustomFoods}
                      context={context}
                    />
                    <CardsRow
                      key={`foods-${searchQuery}-${searchType}`}
                      title="Simple Foods"
                      items={foods}
                      setScreen={setScreen}
                      setItem={setFood}
                      wrap="wrap"
                      type="Food"
                      pageSize={pageSizeFoods}
                      loadMoreItems={getFoods}
                      scrollParent={props.scrollParent}
                      hasMore={hasMoreFoods}
                      context={context}
                    />
                  </>
                )}
              </MealRows>
            </RowsContainer>
          </RecommendedContainer>
        )}
      </FlexContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 16px;
`;

const FilterContainer = styled(FlexContainer)`
  margin-top: 15px;
  border-bottom: ${(props) =>
    props.isMobile ? `1.5px solid ${colors.primary300}` : undefined};
`;

const SearchArea = styled(FlexContainer)`
  width: fit-content;
  margin-bottom: 64px;
`;

const RecommendedContainer = styled(FlexContainer)`
  flex: 1;
`;

const RowsContainer = styled(FlexContainer)`
  margin-top: 24px;
`;

const MealRows = styled(FlexContainer)`
  margin-right: 64px;
  > * {
    margin-bottom: 24px;
  }
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

export default SearchWeb;
