import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { cloneDeep, isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import TrackForm from './TrackForm';
import { editTrack, getTrack } from '../../services/api/track';

const EditTrack = (props) => {
  const initialValues = {
    locationType: 'Home',
    gender: 'All',
    level: 'Beginner',
  };

  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const { open, launchModal, closeModal } = useModal(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [fitnessPlans, setFitnessPlans] = useState([]);
  const [track, setTrack] = useState([]);

  const [backTrack, setBackTrack] = useState([]);
  const [remainTrack, setRemainTrack] = useState([]);
  const [nextTrack, setNextTrack] = useState([]);
  const [nextLevel, setNextLevel] = useState([]);

  async function loadTrack(params) {
    try {
      const trackId = params.track;
      const result = await getTrack(trackId);
      if (result) {
        setTrack(result);
      } else {
        throw new Error('Track could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded fitness plan
  useEffect(() => {
    if (!isEmpty(track)) {
      setFitnessPlans([...track.fitnessPlans]);
      setBackTrack(track.backTrack);
      setRemainTrack(track.remainTrack);
      setNextTrack(track.nextTrack);
      setNextLevel(track.nextLevel);
      setValues({
        ...values,
        name: track.name,
        level: track.level,
        gender: track.gender,
        locationType: track.locationType,
        active: track.active,
      });
    }
  }, [track]);

  const getDeletedRecordNames = () => {
    if (fitnessPlans.some((fitnessPlan) => fitnessPlan.fitnessPlan.isDeleted)) {
      const deletedFitnessPlans = fitnessPlans
        .filter((fitnessPlan) => fitnessPlan.fitnessPlan.isDeleted)
        .map((fitnessPlan) => fitnessPlan.fitnessPlan.name);
      return deletedFitnessPlans.join(', ');
    }
    return false;
  };

  const submitData = () => {
    setLoading(true);

    // Prevent saving if any tracks have been deleted
    const deletedRecordNames = getDeletedRecordNames();
    if (deletedRecordNames) {
      setStatus('error');
      setMessage(
        `The following items are deleted and must be removed or replaced before saving: ${deletedRecordNames}`,
      );
      setLoading(false);
      return;
    }

    const trackId = track._id;
    let payload = cloneDeep(values);
    const fitnessPlanRefs = fitnessPlans.map((fitnessPlan, i) => {
      return {
        week: i + 1,
        fitnessPlan: fitnessPlan.fitnessPlan._id,
      };
    });
    payload.fitnessPlans = fitnessPlanRefs;

    editTrack(trackId, payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Track "${result.name}" was edited successfully!`);
        setValues(initialValues);
        setFitnessPlans([]);
        setBackTrack([]);
        setRemainTrack([]);
        setNextTrack([]);
        setNextLevel([]);
        setTrack([]);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Track"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="track-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'track',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addTrack={loadTrack}
        {...props}
      />
      <TrackForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        fitnessPlans={fitnessPlans}
        setFitnessPlans={setFitnessPlans}
        backTrack={backTrack}
        setBackTrack={setBackTrack}
        remainTrack={remainTrack}
        setRemainTrack={setRemainTrack}
        nextTrack={nextTrack}
        setNextTrack={setNextTrack}
        nextLevel={nextLevel}
        setNextLevel={setNextLevel}
        status={status}
        setStatus={setStatus}
        message={message}
        setMessage={setMessage}
        loading={loading}
        handleSubmit={handleSubmit}
        viewOnly={isEmpty(track)}
        buttonText="Edit Fitness Track"
        formType="edit"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default EditTrack;
