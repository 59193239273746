import { apiCall } from './api';

export async function addExercise(params) {
  try {
    if (params.image && !params.imageUrl) {
      const imageData = new FormData();
      imageData.append('gif', true); // allow gifs for exercise page image
      imageData.append('image', params.image);
      const imageResult = await apiCall('post', '/users/upload', imageData);

      params.imageUrl = imageResult.file;
    }
    if (params.thumbnail && !params.thumbnailUrl) {
      const thumbData = new FormData();
      thumbData.append('gif', false); // don't allow gifs for thumbnail image
      thumbData.append('image', params.thumbnail);
      const thumbResult = await apiCall('post', '/users/upload', thumbData);
      params.thumbnailUrl = thumbResult.file;
    }
    return apiCall('post', `/exercise`, params);
  } catch (err) {
    console.error(err);
  }
}

export function getExercise(id, params = {}) {
  return apiCall('get', `/exercise/${id}`, params);
}

export function getExercises(params) {
  return apiCall('get', `/exercise`, params);
}

export async function editExercise(id, params) {
  if (params.image) {
    const imageData = new FormData();
    imageData.append('gif', true); // allow gifs for exercise page image
    imageData.append('image', params.image);
    const imageResult = await apiCall('post', '/users/upload', imageData);

    params.imageUrl = imageResult.file;
  }
  if (params.thumbnail) {
    const thumbData = new FormData();
    thumbData.append('gif', false); // don't allow gifs for thumbnail image
    thumbData.append('image', params.thumbnail);
    const thumbResult = await apiCall('post', '/users/upload', thumbData);
    params.thumbnailUrl = thumbResult.file;
  }
  return apiCall('put', `/exercise/${id}`, params);
}

export function searchExercises(params) {
  return apiCall('get', `/exercise/search/${params.query}`, params);
}

export function getExerciseReferences(id, params = {}) {
  params.query = { 'exercises.exercise': id };
  return apiCall('get', `/workout`, params);
}

export function replaceExercisesOnWorkout(workout, oldExercise, newExercise) {
  return apiCall(
    'put',
    `/workout/${workout}/replace_exercise/${oldExercise}/${newExercise}`,
  );
}
