import React from 'react';
import styled from 'styled-components';
import { colors, screenSize } from '../styleConstants';
import FlexContainer from './FlexContainer';
// credit to @yenteho for the model for this button: https://codesandbox.io/s/ymwo5419yv

import { isMobile } from '../helpers/utils';
const RadioInput = ({
  checked,
  value,
  selected,
  onChange,
  name,
  hideValue,
  label,
  disabled,
  hideOnMobile,
  labelMargin,
  ...props
}) => {
  return (
    <Container
      alignItems="center"
      spacing={props.spacing}
      hideOnMobile={hideOnMobile}
    >
      <Item>
        <RadioButton
          type="radio"
          checked={checked || false}
          value={value}
          selected={selected}
          onChange={onChange}
          name={name}
          disabled={disabled}
          {...props}
        />
        <RadioButtonStyle checked={checked} />
      </Item>
      {hideValue ? null : (
        <RadioButtonLabel $labelMargin={labelMargin}>
          {label || value}
        </RadioButtonLabel>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  margin-bottom: ${(props) => props.spacing || '8px'};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  @media (max-width: ${screenSize.mobileL}) {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
  ${'' /* height: 48px; */}
  position: relative;
`;

const RadioButtonStyle = styled.label`
  position: absolute;
  top: 0%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  border: 1px solid
    ${() => (!isMobile() ? colors.primary500 : colors.secondary600)};
  background: ${(props) => isMobile() && props.checked && colors.secondary500};
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 4px;
    background: white;
  }
`;
const RadioButton = styled.input`
  position: absolute;
  top: 0%;
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
  &:hover ~ ${RadioButtonStyle} {
    background: ${colors.secondary500};
    opacity: 0.5;
    border: 1px solid ${colors.secondary600};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 4px;
      background: white;
    }
  }

  &:focus + ${RadioButtonStyle} {
    border: 1px solid ${colors.secondary600};
  }
  &:checked + ${RadioButtonStyle} {
    background: ${colors.secondary500};
    opacity: 1;
    border: 1px solid ${colors.secondary600};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 4px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
  &:disabled + ${RadioButtonStyle} {
    border: 1px solid ${colors.primary300};
    background: ${colors.primary050};
    &::after {
      box-shadow: none;
    }
  }
`;

const RadioButtonLabel = styled.div`
  color: ${colors.primary800};
  width: 100%;
  margin-left: ${(props) => (props.$labelMargin ? props.$labelMargin : '12px')};
  line-height: 19px;
`;

export default RadioInput;
