import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import { DateTime } from 'luxon';
import CardsRow from '../CardsRow';

const DuplicateWeb = ({ filteredRecipes, setScreen, setRecipe, context }) => {
  return (
    <Container flexDirection="column">
      <RecentContainer flexDirection="column">
        <Header>Recent Recipes</Header>
        <RowsContainer flexDirection="column">
          <MealRows flexDirection="column">
            <CardsRow
              title="Yesterday"
              items={filteredRecipes.yesterday}
              setScreen={setScreen}
              setItem={setRecipe}
              type="Recipe"
              slider={true}
              context={context}
            />
            <CardsRow
              title={DateTime.now().minus({ days: 2 }).toFormat('cccc')}
              items={filteredRecipes.twoDaysAgo}
              setScreen={setScreen}
              setItem={setRecipe}
              type="Recipe"
              slider={true}
              context={context}
            />
            <CardsRow
              title={DateTime.now().minus({ days: 3 }).toFormat('cccc')}
              items={filteredRecipes.threeDaysAgo}
              setScreen={setScreen}
              setItem={setRecipe}
              type="Recipe"
              slider={true}
              context={context}
            />
            <CardsRow
              title={DateTime.now().minus({ days: 4 }).toFormat('cccc')}
              items={filteredRecipes.fourDaysAgo}
              setScreen={setScreen}
              setItem={setRecipe}
              type="Recipe"
              slider={true}
              context={context}
            />
            <CardsRow
              title={DateTime.now().minus({ days: 5 }).toFormat('cccc')}
              items={filteredRecipes.fiveDaysAgo}
              setScreen={setScreen}
              setItem={setRecipe}
              type="Recipe"
              slider={true}
              context={context}
            />
          </MealRows>
        </RowsContainer>
      </RecentContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
`;
const RecentContainer = styled(FlexContainer)``;
const RowsContainer = styled(FlexContainer)`
  margin-top: 24px;
`;
const MealRows = styled(FlexContainer)`
  > * {
    margin-bottom: 24px;
  }
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

export default DuplicateWeb;
