import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StaticSearchBar from '../../elements/StaticSearchBar';
import CardsRow from '../CardsRow';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import NoFavoritesIcon from '../../icons/NoFavoritesIcon';
import MobileTabs from '../../universal/MobileTabs';

const FavoritesMobile = ({
  searchQuery,
  handleSearch,
  favoriteRecipes,
  customFavoriteRecipes,
  pageSizeFavoriteRecipes,
  getFavoriteRecipes,
  favoriteFoods,
  customFavoriteFoods,
  pageSizeFavoriteFoods,
  getFavoriteFoods,
  setScreen,
  setRecipe,
  setFood,
  searchTypeSelected,
  setSearchTypeSelected,
  context,
  hasMoreRecipes,
  hasMoreFoods,
  hasMoreCustomRecipes,
  hasMoreCustomFoods,
  ...props
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(2);

  const handleSearchTypeChangedBasedOnIndex = (name) => {
    setSearchTypeSelected(name);
  };
  const handleChange = (event, newValue) => {
    setSearchTypeSelected(
      event.currentTarget.name === 'recipes'
        ? 'recipe'
        : event.currentTarget.name,
    );
    setSelectedTabIndex(newValue);
  };

  const handleChangeIndex = (index) => {
    let name = '';
    switch (index) {
      case 0:
        name = 'recipe';
        break;
      case 1:
        name = 'food';
        break;
      case 2:
        name = 'both';
        break;
      default:
        name = 'both';
    }
    handleSearchTypeChangedBasedOnIndex(name);
    setSelectedTabIndex(index);
  };

  const renderCorrectFavTextBasedOnType = (type) =>
    type === 'Both'
      ? "You don't have any favorites yet."
      : `You don’t have any favorite ${type?.toLowerCase()} yet.`;

  const NoFavoritesComponent = ({
    type = 'Recipes',
    shouldNotRender = false,
  }) =>
    shouldNotRender ? null : (
      <NoFavoritesContainer
        flexDirection="column"
        justify="center"
        alignItems="center"
      >
        <NoFavoritesIcon />
        <NoFavoritesText>
          {renderCorrectFavTextBasedOnType(type)}
        </NoFavoritesText>
        {/* TO DO : link this to the screen where user can add recipes/foods to favorites */}
        {/* <AddToFavoritesText>Add {type} to Favorites</AddToFavoritesText> */}
      </NoFavoritesContainer>
    );
  const getTabData = (tabValue) => (
    <TabContainer>
      <TabHeading alignItems="center" justify="space-between">
        <Subheading>{tabValue}</Subheading>
      </TabHeading>

      <CardContainer>
        {tabValue === 'Recipes' && recipesTab()}
        {tabValue === 'Food' && foodsTab()}
        {tabValue === 'Both' && bothTab()}
      </CardContainer>
    </TabContainer>
  );

  const renderTitleIfRequired = (condition, title) =>
    condition || selectedTabIndex === 2 ? title : '';

  const recipesTab = (forBothTab) =>
    favoriteRecipes.length > 0 || customFavoriteRecipes.length > 0 ? (
      <>
        <CardsRow
          key={`custom-user-recipes-${searchQuery}`}
          numCards={9}
          title="Your Recipes"
          items={customFavoriteRecipes}
          setScreen={setScreen}
          setItem={setRecipe}
          wrap="wrap"
          type="Recipe"
          pageSize={pageSizeFavoriteRecipes}
          loadMoreItems={getFavoriteRecipes}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreCustomRecipes}
          isMobile
        />
        <CardsRow
          key={`user-recipes-${searchQuery}`}
          numCards={9}
          title={renderTitleIfRequired(
            customFavoriteRecipes.length > 0,
            'Recipes',
          )}
          items={favoriteRecipes}
          setScreen={setScreen}
          setItem={setRecipe}
          wrap="wrap"
          type="Recipe"
          pageSize={pageSizeFavoriteRecipes}
          loadMoreItems={getFavoriteRecipes}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreRecipes}
          isMobile
        />
      </>
    ) : (
      <NoFavoritesComponent type="Recipes" shouldNotRender={forBothTab} />
    );
  const foodsTab = (forBothTab) =>
    favoriteFoods.length > 0 || customFavoriteFoods.length > 0 ? (
      <>
        <CardsRow
          key={`custom-user-foods-${searchQuery}`}
          numCards={9}
          items={customFavoriteFoods}
          title="Your Foods"
          setScreen={setScreen}
          setItem={setFood}
          wrap="wrap"
          type="Food"
          pageSize={pageSizeFavoriteFoods}
          loadMoreItems={getFavoriteFoods}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreCustomFoods}
          isMobile
        />
        <CardsRow
          key={`user-foods-${searchQuery}`}
          numCards={9}
          title={renderTitleIfRequired(customFavoriteFoods.length > 0, 'Foods')}
          items={favoriteFoods}
          setScreen={setScreen}
          setItem={setFood}
          wrap="wrap"
          type="Food"
          pageSize={pageSizeFavoriteFoods}
          loadMoreItems={getFavoriteFoods}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreFoods}
          isMobile
        />
      </>
    ) : (
      <NoFavoritesComponent type="Foods" shouldNotRender={forBothTab} />
    );
  const bothTab = () =>
    !favoriteFoods.length &&
    !customFavoriteFoods.length &&
    !favoriteRecipes.length &&
    !customFavoriteRecipes.length ? (
      <NoFavoritesComponent type="Both" />
    ) : (
      <>
        {recipesTab(true)}
        {foodsTab(true)}
      </>
    );
  useEffect(() => {
    const indexAfterReturningBack = ['recipe', 'food', 'both'].indexOf(
      searchTypeSelected || 'both',
    );
    setSelectedTabIndex(indexAfterReturningBack);
  }, [searchTypeSelected]);
  return (
    <div>
      <SearchBarContainer>
        <StaticSearchBar
          handleSearch={handleSearch}
          placeholder="Try Sesame Chicken, etc"
          data-test="meal-search"
        />
      </SearchBarContainer>
      <MobileTabs
        value={selectedTabIndex}
        handleChange={handleChange}
        handleChangeIndex={handleChangeIndex}
        tabs={['Recipes', 'Food', 'Both']}
        children={[
          getTabData('Recipes'),
          getTabData('Food'),
          getTabData('Both'),
        ]}
      />
    </div>
  );
};
const SearchBarContainer = styled.div`
  padding: 20px 20px 25px 20px;
`;

const Subheading = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
`;

const TabHeading = styled(FlexContainer)`
  padding: 25px 20px;
`;
const TabContainer = styled.div`
  overflow: hidden;
`;
const CardContainer = styled.div`
  padding: 0 20px;
`;
const NoFavoritesContainer = styled(FlexContainer)`
  margin: 10px 0;
`;
const NoFavoritesText = styled.h6`
  color: ${colors.primary600};
  font-family: 'Work Sans';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 20px 0;
`;
// const AddToFavoritesText = styled.h4`
//   color: ${colors.secondary500};
//   font-family: 'Work Sans';
//   font-size: 17px;
//   letter-spacing: 0;
//   line-height: 20px;
//   margin: 5px 0;
// `;
export default FavoritesMobile;
