import React, { useState, useEffect } from 'react';
import { colors } from '../styleConstants';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import SearchIcon from '../icons/SearchIcon';
import Button from './Button';
import { isMobile } from '../helpers/utils';

import useFormValues from '../hooks/useFormValues';

const StaticSearchBar = ({
  handleSearch,
  placeholder,
  setSearchTerm,
  searchTerm,
  ...props
}) => {
  const [inputFocused, setInputFocused] = useState(false);

  const { values, handleChange } = useFormValues();

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(values.search);
    }
  };
  const handleSubmit = (e) => {
    handleSearch(values.search);
  };

  useEffect(() => {
    values.search = searchTerm;
  }, [searchTerm]);

  return (
    <Container alignItems="center" focused={inputFocused}>
      <SearchIcon />
      <Input
        id="search"
        name="search"
        type="search"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={values.search || ''}
        placeholder={placeholder || 'Type text here'}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        autoFocus={!isMobile()}
        {...props}
      />
      <StyledButton
        type="submit"
        handleClick={handleSubmit}
        buttonText="Search"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  height: 48px;
  width: ${(props) => (isMobile() ? 'auto' : '580px')};
  padding: ${(props) => (isMobile() ? '0px 0px 0px 16px' : '0 12px 0 16px')};
  border: ${(props) =>
    props.focused
      ? `1px solid ${colors.secondary500}`
      : `1px solid ${colors.primary400}`};
  border-radius: 24px;
`;

const Input = styled.input`
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) none repeat scroll 0px center;
  border: 0px none;
  opacity: 1;
  font-size: 17px;
  line-height: 20px;
  font-family: 'Work Sans';
  outline: currentcolor none 0px;
  padding: ${isMobile() ? '14px 14px 14px 0px' : '0px 14px 0 0'};
  color: inherit;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary300};
    opacity: 1; /* Firefox */
  }
`;

const StyledButton = styled(Button)`
  border-radius: ${(props) => (isMobile() ? '0 24px 24px 0' : '16px')};
  height: ${(props) => (isMobile() ? '48px' : '28px')};
`;

export default StaticSearchBar;
