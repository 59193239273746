import React, { useState, useEffect } from 'react';

import { omit, cloneDeep } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';

import FitnessPlanForm from './FitnessPlanForm';
import { addFitnessPlan } from '../../services/api/fitnessPlan';

const AddFitnessPlan = (props) => {
  const initialValues = {
    locationType: 'Home',
    gender: 'All',
    dayNum: {
      value: 1,
      label: `Monday`,
    },
    type: 'Weight Loss',
    level: 'Beginner',
    planLength: 7,
    planRef: 'B1-1',
  };

  const initialToggleValues = {
    active: false,
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [workouts, setWorkouts] = useState([]);
  const [activities, setActivities] = useState([]);

  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  useEffect(() => {
    const filteredWorkout = workouts.filter((workout) => {
      return workout.dayNum === values.dayNum.value;
    });
    const filteredActivity = activities.filter((activity) => {
      return activity.dayNum === values.dayNum.value;
    });
    setFilteredWorkouts(filteredWorkout);
    setFilteredActivities(filteredActivity);
  }, [workouts, activities, values.dayNum]);

  const submitData = () => {
    setLoading(true);

    let payload = cloneDeep(omit(values, ['dayNum']));
    const workoutRefs = workouts.map((workout) => {
      return {
        name: workout.workout.name,
        dayNum: workout.dayNum,
        workout: workout.workout._id,
        week: workout.week,
      };
    });
    const activityRefs = activities.map((activity) => {
      return {
        name: activity.activity.name,
        dayNum: activity.dayNum,
        activity: activity.activity._id,
      };
    });
    payload.workouts = workoutRefs;
    payload.activities = activityRefs;
    payload.active = toggleValues.active;

    addFitnessPlan(payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Fitness Plan "${result.name}" was created successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setWorkouts([]);
        setActivities([]);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <FitnessPlanForm
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      handleDateChanged={handleDateChanged}
      toggleValues={toggleValues}
      handleToggle={handleToggle}
      filteredWorkouts={filteredWorkouts}
      filteredActivities={filteredActivities}
      activities={activities}
      setActivities={setActivities}
      workouts={workouts}
      setWorkouts={setWorkouts}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      buttonText="Create Fitness Plan"
    />
  );
};

export default AddFitnessPlan;
