import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const TrackPyramid = ({ colorScheme, selected, level, disabled, ...props }) => {
  return (
    <Icon
      width="32px"
      height="27px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="PlanLevel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <Bottom
          d="M31.788463,25.0819651 L28.7216725,19.9594139 L3.24372071,19.9594139 L0.176930221,25.0819651 C-0.0589767402,25.486377 -0.0589767402,25.9918919 0.176930221,26.3963039 C0.412837182,26.8007158 0.850950109,27.0703238 1.32276403,27.0703238 L30.7100312,27.0703238 C31.1818451,27.0703238 31.619958,26.8007158 31.855865,26.3963039 C32.0580709,25.9918919 32.0580709,25.486377 31.788463,25.0819651 Z"
          id="bottom"
          fillRule="nonzero"
          $colorScheme={colorScheme}
          selected={selected}
          level={level}
          disabled={disabled}
        ></Bottom>
        <Middle
          id="middle"
          fillRule="nonzero"
          points="4.79396646 17.3307364 27.1714268 17.3307364 23.5654203 11.3319594 8.39997286 11.3319594"
          $colorScheme={colorScheme}
          selected={selected}
          level={level}
          disabled={disabled}
        ></Middle>
        <Top
          d="M21.9814736,8.7369828 L17.0948294,0.581342154 C16.6230155,-0.193780718 15.3423777,-0.193780718 14.8705638,0.581342154 L9.9839196,8.7369828 L21.9814736,8.7369828 Z"
          id="top"
          fillRule="nonzero"
          $colorScheme={colorScheme}
          selected={selected}
          level={level}
          disabled={disabled}
        ></Top>
      </g>
    </Icon>
  );
};

const Icon = styled.svg`
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '27px'};
`;

const Top = styled.path`
  fill: ${(props) =>
    props.disabled && props.level === 'Advanced'
      ? colors.primary400
      : props.disabled
        ? colors.primary100
        : props.$colorScheme === 'blue' && props.level === 'Advanced'
          ? colors.hliteone400
          : props.$colorScheme === 'blue'
            ? colors.hliteone200
            : props.selected && props.level === 'Advanced'
              ? colors.secondary500
              : props.selected
                ? colors.secondary200
                : props.level === 'Advanced'
                  ? colors.primary400
                  : colors.primary100};
`;

const Middle = styled.polygon`
  fill: ${(props) =>
    props.disabled &&
    (props.level === 'Advanced' || props.level === 'Intermediate')
      ? colors.primary400
      : props.disabled
        ? colors.primary100
        : props.$colorScheme === 'blue' &&
            (props.level === 'Advanced' || props.level === 'Intermediate')
          ? colors.hliteone400
          : props.$colorScheme === 'blue'
            ? colors.hliteone200
            : props.selected &&
                (props.level === 'Advanced' || props.level === 'Intermediate')
              ? colors.secondary500
              : props.selected
                ? colors.secondary200
                : props.level === 'Advanced' || props.level === 'Intermediate'
                  ? colors.primary400
                  : colors.primary100};
`;

const Bottom = styled.path`
  fill: ${(props) =>
    props.disabled
      ? colors.primary400
      : props.$colorScheme === 'blue'
        ? colors.hliteone400
        : props.selected
          ? colors.secondary500
          : colors.primary400};
`;

export default TrackPyramid;
