import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import Dropdown from '../elements/Dropdown';
import ExpertTip from '../universal/ExpertTip';
import useFormValues from '../hooks/useFormValues';
import { isMobile } from '../helpers/utils';
import MobileDropdown from '../elements/MobileDropdown';

const ExerciseModalView = ({
  exercise,
  context,
  handleClose,
  addExercise,
  ...props
}) => {
  const workout = context.workout || '';
  const defaultSequence = context.sequence || 'Circuit';

  const { values, handleValueChanged } = useFormValues({
    sequence: defaultSequence,
  });

  const sequenceOptions = [
    'Warm Up',
    'Core',
    'Strength',
    'Circuit',
    'Conditioning',
    'Cool Down',
  ];

  const handleSubmit = () => {
    let exerciseId;
    if (exercise.__t === 'ExerciseUser') {
      exerciseId = exercise.exerciseAdmin;
    } else {
      exerciseId = exercise._id;
    }

    const params =
      context.variant === 'admin'
        ? {
            workoutSequence: values.sequence,
            exercise: exerciseId,
          }
        : {
            workoutSequence: values.sequence,
            exercise: exerciseId,
            workout: workout,
            date: context.date,
          };

    addExercise(params);
    handleClose();
  };

  if (isMobile()) {
    // mobile version
    return (
      <FlexContainer justify="center">
        <MobileExerciseInfoContainer>
          <MobileExerciseImage justify="center" imageUrl={exercise.imageUrl} />

          <MobileExerciseName data-test="exerciseModal-name">
            {exercise.name}
          </MobileExerciseName>
          {context.variant !== 'swap' && (
            <MobileActionContainer>
              <MobileLabelDropdown>Add To Sequence:</MobileLabelDropdown>
              <MobileDropdown
                width="195px"
                options={sequenceOptions}
                value={{
                  value: values.sequence,
                  label: values.sequence,
                }}
                onChange={(value) =>
                  handleValueChanged('sequence', value.value, value.label)
                }
                data-test="exerciseModal-editSequence"
              />
            </MobileActionContainer>
          )}
        </MobileExerciseInfoContainer>
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="196px"
            buttonText="Add Exercise"
            pink="true"
            onClick={handleSubmit}
            data-test="exerciseModal-submit"
          />
        </AddButtonContainer>
      </FlexContainer>
    );
  } else {
    // web version
    return (
      <Container justify="space-between">
        <LeftColumn flexDirection="column">
          <ExerciseInfoContainer flexDirection="column">
            <ExerciseName data-test="exerciseModal-name">
              {exercise.name}
            </ExerciseName>
            {context.variant !== 'swap' && (
              <DropdownContainer flexDirection="column">
                <Dropdown
                  width="320px"
                  options={sequenceOptions}
                  label="Add To Sequence:"
                  value={{
                    value: values.sequence,
                    label: values.sequence,
                  }}
                  onChange={(value) =>
                    handleValueChanged('sequence', value.value, value.label)
                  }
                  data-test="exerciseModal-editSequence"
                />
              </DropdownContainer>
            )}
          </ExerciseInfoContainer>
          {exercise.expertTip && <ExpertTip tipText={exercise.expertTip} />}
        </LeftColumn>
        <RightColumn flexDirection="column" alignItems="flex-end">
          <ExerciseImage src={exercise.imageUrl} />
        </RightColumn>
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="196px"
            buttonText="Add Exercise"
            pink="true"
            onClick={handleSubmit}
            data-test="exerciseModal-submit"
          />
        </AddButtonContainer>
      </Container>
    );
  }
};

const Container = styled(FlexContainer)`
  width: 100%;
`;

const ExerciseImage = styled.img`
  max-width: 544px;
  margin-bottom: 64px;
  margin-left: 36px;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: ${isMobile() ? '64px' : '0%'};
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 50%;
`;

const RightColumn = styled(FlexContainer)`
  flex-basis: 50%;
`;

const DropdownContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
`;

const ExerciseInfoContainer = styled(FlexContainer)`
  padding-bottom: 48px;
`;

const ExerciseName = styled.h1`
  color: ${colors.primary800};
  margin: 16px 0 32px 0;
`;
// mobile
const MobileExerciseInfoContainer = styled.div`
  width: 100%;
  margin-bottom: 256px;
`;
const MobileExerciseImage = styled(FlexContainer)`
  height: 400px;
  width: 100%;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  text-align: center;
`;
const MobileExerciseName = styled.h1`
  margin: 24px 30px 0px 30px;
  color: ${colors.primary800};
  line-height: 40px;
`;
const MobileActionContainer = styled.div`
  margin: 34px 30px 32px 30px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  max-width: 315px;
  width: 100%;
`;

const MobileLabelDropdown = styled.h4`
  height: 20px;
  width: 45px;
  color: ${colors.primary600};
  line-height: 20px;
  margin-top: 6px;
`;

export default ExerciseModalView;
