import { createSlice } from '@reduxjs/toolkit';

const initialState = new Date();

const selectedDateSlice = createSlice({
  name: 'selectedDate',
  initialState,
  reducers: {
    setSelectedDate(state, action) {
      return action.payload;
    },
  },
});

export const { setSelectedDate } = selectedDateSlice.actions;
export default selectedDateSlice.reducer;
