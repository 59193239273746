import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import TextInput from '../elements/TextInput';
import useFormValues from '../hooks/useFormValues';
import useFormSubmit from '../hooks/useFormSubmit';
import Button from '../elements/Button';
import ProfilePhoto from '../elements/ProfilePhoto';
import PasswordDisplay from './PasswordDisplay';
import { colors } from '../styleConstants';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../store/general/currentUserSlice';
import useModal from '../hooks/useModal';
import { isMobile } from '../helpers/utils';
import CameraIcon from '../icons/CameraIcon';
import UserPhotoUpload from '../profileModals/UserPhotoUpload';
import CircularProgress from '@mui/material/CircularProgress';
import AlertStack from '../elements/AlertStack';

const ProfileForm = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser.user);

  const { values, setValues, handleChange } = useFormValues(
    cloneDeep({
      ...currentUser,
      firstName: currentUser.name.first,
      lastName: currentUser.name.last,
    }),
  );
  const { open, launchModal, closeModal } = useModal(false);

  useEffect(() => {
    setValues(
      cloneDeep({
        ...currentUser,
        firstName: currentUser.name.first,
        lastName: currentUser.name.last,
      }),
    );
  }, [currentUser, setValues]);

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const submitData = () => {
    setLoading(true);
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    } else if (!values.lastName) {
      errors.lastName = 'Last name is required';
    } else {
      const fullName = `${values.firstName} ${values.lastName}`;
      values.fullName = fullName;
      dispatch(updateUser(values))
        .then((result) => {
          setStatus('success');
          setMessage('Your profile has been successfully updated!');
        })
        .catch((err) => {
          setStatus('error');
          if (err.error.message) {
            setMessage(err.error.message);
          } else {
            setMessage('There was a problem updating your profile.');
            console.error(err);
          }
        });
    }
    setLoading(false);
  };

  const { errors, handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    values.password ? 'update+password' : 'update',
  );

  if (isMobile()) {
    // Mobile View
    return (
      <div>
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justify="center"
        >
          <ProfilePhoto
            src={currentUser.avatarUrl}
            onClick={launchModal}
            data-test="profile-avatar"
          />
          <ProfilePictureHelperTextContainer
            alignItems="center"
            justify="center"
          >
            <CameraIcon />
            <ProfilePictureHelperText>
              Click the image to edit/remove
            </ProfilePictureHelperText>
          </ProfilePictureHelperTextContainer>
        </FlexContainer>
        <TextInputContainerMobile>
          <TextInput
            label="First Name"
            id="firstName"
            name="firstName"
            onChange={handleChange}
            value={values?.firstName || ''}
            error={errors.firstName}
            data-test="profile-firstName"
            required
          />

          <TextInput
            label="Last Name"
            id="lastName"
            name="lastName"
            onChange={handleChange}
            value={values?.lastName || ''}
            error={errors.lastName}
            data-test="profile-lastName"
            required
          />

          <TextInput
            label="Email"
            id="email"
            name="email"
            onChange={handleChange}
            disabled={true}
            value={values.email || ''}
            error={errors.email}
            data-test="profile-email"
          />
          <BreakLine />
          <ChangePasswordText>Change Password</ChangePasswordText>
          <PasswordDisplay
            handleChange={handleChange}
            values={values}
            errors={errors}
          />
        </TextInputContainerMobile>
        <StyledButton
          type="submit"
          buttonText="Save Changes"
          data-test="profile-info-submit"
          onClick={handleSubmit}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        />

        {status === 'error' && (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'error'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="profile-info-error"
          />
        )}
        {status === 'success' && (
          <AlertStack
            messages={message}
            type="success"
            variant="filled"
            open={status === 'success'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="profile-info-success"
          />
        )}
        <UserPhotoUpload open={open} handleClose={closeModal} />
      </div>
    );
  }
  // Web View
  return (
    <>
      <FlexContainer flexDirection="column">
        <FormContainer wrap="wrap" justify="space-between">
          <input hidden type="file" name="avatar" />
          <AvatarFieldGroup name="left" flexDirection="column">
            <ProfilePhoto
              letters={values?.firstName.charAt(0)}
              src={currentUser.avatarUrl}
              onClick={launchModal}
              data-test="profile-avatar"
            />
            <FlexContainer
              alignItems="center"
              justify="center"
              style={{ marginTop: '16px' }}
            >
              <CameraIcon />
              <ProfilePictureHelperText>
                Click the image to edit/remove
              </ProfilePictureHelperText>
            </FlexContainer>
          </AvatarFieldGroup>
          <RightContainer flexDirection="column">
            <InfoGroup wrap="wrap">
              <TextInput
                label="First Name"
                id="firstName"
                name="firstName"
                onChange={handleChange}
                width="300px"
                value={values?.firstName || ''}
                error={errors?.firstName}
                data-test="profile-firstName"
              />
              <TextInput
                label="Last Name"
                id="lastName"
                name="lastName"
                onChange={handleChange}
                width="300px"
                value={values?.lastName || ''}
                error={errors?.lastName}
                data-test="profile-lastName"
              />
              <TextInput
                label="Email"
                id="email"
                name="email"
                width="300px"
                disabled={true}
                value={values.email || ''}
                error={errors.email}
                data-test="profile-email"
              />
            </InfoGroup>
            <ChangePasswordText>Change Password</ChangePasswordText>
            <PasswordDisplay
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
          </RightContainer>
        </FormContainer>
        <FlexContainer
          alignSelf="center"
          flexDirection="column"
          justify="center"
          alignItems="center"
        >
          <StyledButton
            type="submit"
            onClick={handleSubmit}
            buttonText="Save Changes"
            data-test="profile-form-submit"
            startIcon={
              loading && <CircularProgress size={24} color="inherit" />
            }
          />
          {status === 'error' && (
            <AlertStack
              messages={message}
              type="error"
              variant="filled"
              open={status === 'error'}
              handleClose={() => setStatus('hidden')}
              autoHideDuration={10000}
              data-test="profile-form-error"
            />
          )}
          {status === 'success' && (
            <AlertStack
              messages={message}
              type="success"
              variant="filled"
              open={status === 'success'}
              handleClose={() => setStatus('hidden')}
              autoHideDuration={10000}
              data-test="profile-form-success"
            />
          )}
        </FlexContainer>
      </FlexContainer>
      <UserPhotoUpload open={open} handleClose={closeModal} />
    </>
  );
};

const FormContainer = styled(FlexContainer)`
  flex-grow: 1;
`;

const AvatarFieldGroup = styled(FlexContainer)`
  margin-right: 48px;
`;

const RightContainer = styled(FlexContainer)`
  flex-basis: 400px;
  flex-grow: 1;
`;

const InfoGroup = styled(FlexContainer)`
  border-bottom: 1px solid ${colors.primary100};
  margin-bottom: 16px;

  > * {
    margin-right: 24px;
    margin-bottom: 16px;
  }
`;

/* Mobile styles */

const ProfilePictureHelperTextContainer = styled(FlexContainer)`
  padding: 16px;
`;

const ProfilePictureHelperText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${colors.primary600};
  width: 91px;
  padding: 0px 10px;
`;

const TextInputContainerMobile = styled.div`
  > * {
    margin-top: 16px;
  }
`;

const BreakLine = styled.div`
  margin: 24px 0;
  border: 1px solid ${colors.primary400};
`;

const ChangePasswordText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary800};
`;

const StyledButton = styled(Button)`
  width: ${() => (isMobile() ? '100%' : '230px')};
  height: 40px;
  background: ${colors.secondary600};
  border-radius: 2px;
  text-transform: none;
`;

export default ProfileForm;
