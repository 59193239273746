import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { useSelector } from 'react-redux';
import ProgressBadge from '../universal/ProgressBadge';
import MacroTable from './MacroTable';

const TodayStats = ({ plannedCalories, currentCalories, ...props }) => {
  const selectedDate = useSelector((state) => state.selectedDate);
  let over = false;
  let amountOver;
  if (plannedCalories > props.mealPlan.recommendedCalories) {
    over = true;
    amountOver = plannedCalories - props.mealPlan.recommendedCalories;
  }

  return (
    <Container flexDirection="column" alignItems="center">
      <TopContainer flexDirection="column" alignItems="center">
        <ProgressBadge
          color="blue"
          current={currentCalories}
          total={plannedCalories}
        />

        <PlannedRecommendedContainer flexDirection="column" alignItems="center">
          <PlannedRecommended>
            Planned:
            <PRSpan $over={over} data-test="todayStats-plannedCalories">
              {' '}
              {plannedCalories} calories {over && `(${amountOver} over)`}
            </PRSpan>
          </PlannedRecommended>
          <PlannedRecommended>
            Recommended:{' '}
            <PRSpan data-test="todayStats-recommendedCalories">
              {props.mealPlan.recommendedCalories} calories
            </PRSpan>
          </PlannedRecommended>
        </PlannedRecommendedContainer>
      </TopContainer>
      <BottomContainer name="bottom" flexDirection="column" alignItems="center">
        <MacroTable date={selectedDate} {...props} />
      </BottomContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 0.5px solid ${colors.primary400};
  width: 100%;
  padding: 16px 0 32px 0;
`;

const BottomContainer = styled(FlexContainer)`
  padding: 24px 0 16px 0;
  width: 100%;
`;

const PlannedRecommendedContainer = styled(FlexContainer)`
  margin-top: 24px;

  & :first-child {
    margin-bottom: 4px;
  }
`;

const PlannedRecommended = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary500};
`;

const PRSpan = styled.span`
  color: ${(props) => (props.$over ? colors.error : colors.primary800)};
`;

export default TodayStats;
