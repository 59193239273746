import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import StaticSearchBar from '../../elements/StaticSearchBar';
import CardsRow from '../CardsRow';
import MobileTabs from '../../universal/MobileTabs';
import FlexContainer from '../../elements/FlexContainer';
import {
  FiltersButton,
  FiltersDialog,
  AppliedFilters,
} from '../../sharedModals/mobile/MobileFilters';

const SearchMobile = ({
  handleSearch,
  searchTerm,
  setSearchTerm,
  clearAllFilters,
  handleCollapseToggle,
  handleToggle,
  collapseToggleValues,
  toggleValues,
  searchQuery,
  searchType,
  userRecipes,
  setScreen,
  setRecipe,
  pageSizeRecipes,
  getUserRecipes,
  recipes,
  filteredRecipes,
  getRecipes,
  userFoods,
  getUserFoods,
  setFood,
  pageSizeFoods,
  getFoods,
  foods,
  setSearchType,
  setSearchTypeSelected,
  context,
  hide,
  hasMoreRecipes,
  hasMoreFoods,
  hasMoreCustomRecipes,
  hasMoreCustomFoods,
  ...props
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const handleSearchTypeChanged = (e) => {
    setSearchType(
      e.currentTarget.name === 'recipes' ? 'recipe' : e.currentTarget.name,
    );
    setSearchTypeSelected(
      e.currentTarget.name === 'recipes' ? 'recipe' : e.currentTarget.name,
    );
  };

  const handleSearchTypeChangedBasedOnIndex = (name) => {
    setSearchType(name);
    setSearchTypeSelected(name);
  };
  const handleChange = (event, newValue) => {
    handleSearchTypeChanged(event);
    setSelectedTabIndex(newValue);
  };

  const handleChangeIndex = (index) => {
    // Designed to handle Cypress/SwipeableViews bug
    if (index > 2) {
      index = 2;
    }
    if (hide === 'recipe' || (hide === 'food' && index > 0)) {
      index = 0;
    }
    let name = '';
    switch (index) {
      case 0:
        name = 'recipe';
        break;
      case 1:
        name = 'food';
        break;
      case 2:
        name = 'both';
        break;
      default:
        name = 'both';
    }
    handleSearchTypeChangedBasedOnIndex(name);
    setSelectedTabIndex(index);
  };
  const getTabData = (tabValue) => (
    <TabContainer>
      <TabHeading alignItems="center" justify="space-between">
        <Subheading>{tabValue}</Subheading>
        {tabValue !== 'Food' && (
          <FiltersButton
            setFiltersDialogOpen={setFiltersDialogOpen}
            toggleValues={toggleValues}
            itemType="recipe"
          />
        )}
      </TabHeading>
      <CardContainer>
        <AppliedFilters
          toggleValues={toggleValues}
          handleToggle={handleToggle}
          itemType="recipe"
        />

        {tabValue === 'Recipes' && recipesTab()}
        {tabValue === 'Food' && foodsTab()}
        {tabValue === 'Both' && (
          <>
            {recipesTab()}
            {foodsTab()}
          </>
        )}
      </CardContainer>
    </TabContainer>
  );
  const recipesTab = () => (
    <>
      {['recipe', 'both'].includes(searchType) && (
        <>
          <CardsRow
            key={`user-recipes-${searchQuery}-${searchType}`}
            title="Your Recipes"
            items={userRecipes}
            setScreen={setScreen}
            setItem={setRecipe}
            wrap="wrap"
            type="Recipe"
            pageSize={pageSizeRecipes}
            loadMoreItems={getUserRecipes}
            scrollParent={props.scrollParent}
            context={context}
            hasMore={hasMoreCustomRecipes}
            isMobile
          />
          <CardsRow
            key={`recipes-${searchQuery}-${searchType}`}
            title={
              userRecipes.length > 0 || selectedTabIndex === 2 ? 'Recipes' : ''
            }
            items={filteredRecipes}
            unfilteredLength={recipes.length}
            setScreen={setScreen}
            setItem={setRecipe}
            wrap="wrap"
            type="Recipe"
            pageSize={pageSizeRecipes}
            loadMoreItems={getRecipes}
            scrollParent={props.scrollParent}
            context={context}
            hasMore={hasMoreRecipes}
            isMobile
          />
        </>
      )}
    </>
  );
  const foodsTab = () => (
    <>
      {['food', 'both'].includes(searchType) && (
        <>
          <CardsRow
            key={`user-foods-${searchQuery}-${searchType}`}
            title="Your Foods"
            items={userFoods}
            setScreen={setScreen}
            setItem={setFood}
            wrap="wrap"
            type="Food"
            pageSize={pageSizeFoods}
            loadMoreItems={getUserFoods}
            scrollParent={props.scrollParent}
            context={context}
            hasMore={hasMoreCustomFoods}
            isMobile
          />
          <CardsRow
            key={`foods-${searchQuery}-${searchType}`}
            title={
              userFoods.length > 0 || selectedTabIndex === 2
                ? 'Simple Foods'
                : ''
            }
            items={foods}
            setScreen={setScreen}
            setItem={setFood}
            wrap="wrap"
            type="Food"
            pageSize={pageSizeFoods}
            loadMoreItems={getFoods}
            scrollParent={props.scrollParent}
            context={context}
            hasMore={hasMoreFoods}
            isMobile
          />
        </>
      )}
    </>
  );
  useEffect(() => {
    if (selectedTabIndex === 0) {
      setFilteredResultCount(userRecipes.concat(filteredRecipes).length);
    } else if (selectedTabIndex === 1) {
      setFilteredResultCount(foods.concat(userFoods).length);
    } else {
      setFilteredResultCount(
        userRecipes.concat(filteredRecipes).concat(foods).concat(userFoods)
          .length,
      );
    }
  }, [filtersDialogOpen, selectedTabIndex, toggleValues]);

  useEffect(() => {
    const formatMap = { Recipes: 'recipe', Food: 'food', Both: 'both' };
    const indexAfterReturningBack = tabs
      .map((tab) => formatMap[tab])
      .indexOf(searchType);
    setSelectedTabIndex(indexAfterReturningBack);
  }, [searchType]);

  const tabs =
    hide === 'recipe'
      ? ['Food']
      : hide === 'food'
        ? ['Recipes']
        : ['Recipes', 'Food', 'Both'];
  const children =
    hide === 'recipe'
      ? [getTabData('Food')]
      : hide === 'food'
        ? [getTabData('Recipes')]
        : [getTabData('Recipes'), getTabData('Food'), getTabData('Both')];

  return (
    <div>
      <SearchBarContainer>
        <StaticSearchBar
          handleSearch={handleSearch}
          placeholder="Try Sesame Chicken, etc"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data-test="meal-search"
        />
      </SearchBarContainer>
      <MobileTabs
        value={selectedTabIndex}
        handleChange={handleChange}
        handleChangeIndex={handleChangeIndex}
        tabs={tabs}
        children={children}
      />
      <FiltersDialog
        itemType="recipe"
        open={filtersDialogOpen}
        setOpen={setFiltersDialogOpen}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        clearAllFilters={clearAllFilters}
        handleCollapseToggle={handleCollapseToggle}
        collapseToggleValues={collapseToggleValues}
        filteredResultCount={filteredResultCount}
      />
    </div>
  );
};
const SearchBarContainer = styled.div`
  padding: 20px 20px 25px 20px;
`;

const Subheading = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
`;

const TabHeading = styled(FlexContainer)`
  padding: 25px 20px;
`;

const TabContainer = styled.div`
  overflow: hidden;
`;

const CardContainer = styled.div`
  padding: 0 20px;
`;

export default SearchMobile;
