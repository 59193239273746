import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { DateTime } from 'luxon';
import AddIcon from '../icons/AddIcon';
import { colors } from '../styleConstants';
import AppBar from '@mui/material/AppBar';
import MobileDropdown from '../elements/MobileDropdown';
import { trackEvent } from '../integrations/analytics';
import { isMobile } from '../helpers/utils';
import { formatDate } from '../helpers/date';

const ShoppingListDateHeader = ({
  values,
  handleValueChanged,
  handleChange,
  setValues,
  launchModal,
  ...props
}) => {
  const dateFrom = formatDate(values.dateFrom);
  const dateTo = formatDate(values.dateTo);
  const handleDateChange = (e) => {
    trackEvent(
      `Adjust Date Range (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    const name = e.target.name;
    setValues({ ...values, [name]: formatDate(e.target.value) });
  };
  return (
    <DateHeaderWrapper
      position="fixed"
      color="default"
      id="mobile-top-nav"
      data-test="mobile-dateheader"
    >
      <TopContainer justify="space-between">
        <Header>Shopping List</Header>
        <AddIcon
          labelText="Add Food"
          orientation="row"
          pink="true"
          onClick={launchModal}
          data-hide="recipe"
          data-test="shoppingList-add"
        />
      </TopContainer>
      <DateContainer alignItems="center" justify="center">
        <MobileDropdown
          width="120px"
          height="30px"
          options={[]}
          disabled={true}
          isPlaceholder={true}
          data-test="dateFrom"
          value={{
            value: DateTime.fromISO(dateFrom).toFormat('MMM d'),
            label: DateTime.fromISO(dateFrom).toFormat('MMM d'),
          }}
          inputChild={
            <StyledInput
              type="date"
              name="dateFrom"
              onChange={handleDateChange}
              value={DateTime.fromISO(dateFrom).toFormat('yyyy-MM-dd')}
              data-test="dateFrom-input"
            />
          }
        />
        <To>to</To>
        <MobileDropdown
          width="120px"
          height="30px"
          options={[]}
          disabled={true}
          isPlaceholder={true}
          data-test="dateTo"
          value={{
            value: DateTime.fromISO(dateTo).toFormat('MMM d'),
            label: DateTime.fromISO(dateTo).toFormat('MMM d'),
          }}
          inputChild={
            <StyledInput
              type="date"
              name="dateTo"
              onChange={handleDateChange}
              value={DateTime.fromISO(dateTo).toFormat('yyyy-MM-dd')}
              data-test="dateTo-input"
            />
          }
        />
      </DateContainer>
    </DateHeaderWrapper>
  );
};

const DateHeaderWrapper = styled(AppBar)`
  z-index: 1;
  top: 0;
  bottom: auto;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
  padding: 32px 20px;
`;

const DateContainer = styled(FlexContainer)`
  padding-top: 16px;
`;

const TopContainer = styled(FlexContainer)``;

const Header = styled.h2`
  color: ${colors.primary700};
`;

const To = styled.p`
  font-size: 13px;
  color: ${colors.primary500};
  margin: 0 16px;
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
`;

export default ShoppingListDateHeader;
