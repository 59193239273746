import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import RecipeSample from '../samples/RecipeSample';
import WorkoutSample from '../samples/WorkoutSample';

const SamplesContainer = () => {
  return (
    <ContentContainer>
      <ScrollContainer data-test="scroll-container">
        <Routes>
          <Route path="/samples/recipe/:id" element={<RecipeSample />} />
          <Route path="/samples/workout/:id" element={<WorkoutSample />} />
        </Routes>
      </ScrollContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ScrollContainer = styled.div`
  height: 100%;
`;

export default SamplesContainer;
