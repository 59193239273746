import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';

const RecordIcon = ({
  selected,
  toggleSelect,
  shrinkIcon,
  hideLabel,
  ...props
}) => (
  <FlexContainer flexDirection="column" alignItems="center">
    <Icon
      onClick={toggleSelect}
      isMobile={isMobile()}
      name="record"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      {...props}
    >
      <circle
        cx="14.0233"
        cy="14.0233"
        r="13.0233"
        fill="white"
        stroke="#8976FF"
        strokeWidth="1.30233"
      />
      <path
        d="M20.4856 10.3748C20.7395 10.1209 20.7395 9.71065 20.4856 9.4567L18.9619 7.93298C18.8316 7.80275 18.6688 7.74414 18.4995 7.74414C18.3302 7.74414 18.1674 7.80926 18.0437 7.93298L17.3125 8.66416C17.0582 8.91846 17.0582 9.33075 17.3125 9.58505L18.8335 11.106C19.0878 11.3603 19.5001 11.3603 19.7544 11.106L20.4856 10.3748ZM9.14421 16.8325C9.0221 16.9546 8.95349 17.1202 8.95349 17.2929V18.8139C8.95349 19.1735 9.24503 19.4651 9.60465 19.4651H11.1256C11.2983 19.4651 11.464 19.3965 11.5861 19.2743L18.1368 12.7237C18.3911 12.4694 18.3911 12.0571 18.1368 11.8028L16.6158 10.2818C16.3615 10.0275 15.9492 10.0275 15.6949 10.2818L9.14421 16.8325ZM10.979 18.0387C10.8995 18.1181 10.7918 18.1627 10.6794 18.1627V18.1627C10.4455 18.1627 10.2558 17.9731 10.2558 17.7391V17.7391C10.2558 17.6268 10.3004 17.519 10.3799 17.4396L15.8558 11.9637C16.0212 11.7982 16.2895 11.7982 16.4549 11.9637V11.9637C16.6203 12.1291 16.6203 12.3973 16.4549 12.5627L10.979 18.0387Z"
        fill="#8976FF"
      />
    </Icon>
    {!hideLabel && <Label>Record</Label>}
  </FlexContainer>
);

const Icon = styled.svg`
  width: ${(props) => (props.width ? props.width : '32px')};
  height: ${(props) => (props.height ? props.height : '32px')};

  &:hover {
    cursor: pointer;
  }

  &:hover circle {
    fill: #8976ff;
  }

  &:hover path {
    fill: #fff;
  }

  &:focus circle {
    fill: #8976ff;
  }
  &:focus path {
    fill: #fff;
  }

  &:active circle {
    fill: #a79afb;
    stroke: #a79afb;
  }

  &:active path {
    fill: #fff;
  }

  &:disabled circle {
    fill: #fff;
    stroke: ${colors.primary300};
  }
  &:disabled path {
    fill: #fff;
    stroke: ${colors.primary300};
  }
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${colors.primary800};
  margin: 8px 0 0 0;

  ${Icon}:disabled & {
    color: ${colors.primary400};
  }
`;

export default RecordIcon;
