import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import Banner from '../../explore/Banner';

const ExploreBannerCard = ({
  exploreBanner,
  setScreen,
  handleClose,
  addExploreBanner,
  ...props
}) => {
  const handleClick = (e) => {
    const exploreBannerId = exploreBanner._id;
    const params = {
      exploreBanner: exploreBannerId,
    };

    addExploreBanner(params);
    handleClose();
  };

  return (
    <CardContainer
      flexDirection="column"
      onClick={handleClick}
      data-test="campaignModal-exploreBannerCard"
    >
      <TopContainer alignItems="center">
        <BannerName>{exploreBanner.name}</BannerName>
      </TopContainer>
      <Banner
        color={exploreBanner.color}
        title={exploreBanner.title}
        textBody={exploreBanner.textBody}
        imageUrl={exploreBanner.imageUrl}
      />
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  height: 224px;
  width: 382px;
  min-width: 192px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px 4px 16px 16px;
  position: relative;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`;

const TopContainer = styled(FlexContainer)`
  height: 72px;
  padding: 0 16px;
`;

const BannerName = styled.h4`
  color: ${colors.primary800};
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export default ExploreBannerCard;
