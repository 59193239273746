import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

import CloseIcon from '../icons/CloseIcon';
import BackIcon from '../icons/BackIcon';

const ModalNav = ({
  handleClose,
  handleBack,
  linksType,
  activePage,
  setScreen,
  context,
  ...props
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    setScreen(e.currentTarget.attributes.name.value);
  };

  const mealLinks = (
    <LinksRow justify="space-between" alignItems="center">
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="Meal Search"
      >
        <NavLink active={activePage} name="Meal Search" onClick={handleClick}>
          Search
        </NavLink>
      </LinkContainer>
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="Meal Duplicate"
      >
        <NavLink
          active={activePage}
          name="Meal Duplicate"
          onClick={handleClick}
        >
          Duplicate
        </NavLink>
      </LinkContainer>
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="Custom Food"
      >
        <NavLink active={activePage} name="Custom Food" onClick={handleClick}>
          Custom Food
        </NavLink>
      </LinkContainer>
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="Custom Recipe"
      >
        <NavLink active={activePage} name="Custom Recipe" onClick={handleClick}>
          Custom Recipe
        </NavLink>
      </LinkContainer>
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="My Recipe Favorites"
      >
        <NavLink
          active={activePage}
          name="My Recipe Favorites"
          onClick={handleClick}
        >
          My Favorites
        </NavLink>
      </LinkContainer>
    </LinksRow>
  );

  const fitnessLinks = (
    <LinksRow justify="space-between" alignItems="center">
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="Exercise Search"
      >
        <NavLink
          active={activePage}
          name="Exercise Search"
          onClick={handleClick}
        >
          Search
        </NavLink>
      </LinkContainer>
      {/* TODO: Post-Beta feature
      https://app.clickup.com/t/1mzumqk
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="Exercise Duplicate"
      >
        <NavLink
          active={activePage}
          name="Exercise Duplicate"
          onClick={handleClick}
        >
          Duplicate
        </NavLink>
      </LinkContainer>
      <LinkContainer
        alignItems="center"
        justify="center"
        active={activePage}
        name="My Exercise Favorites"
      >
        <NavLink
          active={activePage}
          name="My Exercise Favorites"
          onClick={handleClick}
        >
          My Favorites
        </NavLink>
      </LinkContainer> */}
    </LinksRow>
  );

  const backButton = (
    <BackHolder
      alignItems="center"
      justify="center"
      onClick={handleBack}
      data-test="modal-back"
    >
      <BackIcon handleClick={handleBack} hideCircle />
      <BackText>Search</BackText>
    </BackHolder>
  );

  return (
    <NavBar justify="space-between" alignItems="center">
      {
        linksType === 'exercise' && context.variant !== 'admin'
          ? fitnessLinks
          : linksType === 'meal' &&
              context.variant !== 'admin' &&
              context.type !== 'shoppinglist'
            ? mealLinks
            : linksType === 'hide'
              ? null
              : null //TODO: case 'back' => arrow back button to previous screen
      }

      {linksType === 'back' ? (
        backButton
      ) : (
        <CloseIcon handleClose={handleClose} data-test="modal-close" />
      )}
    </NavBar>
  );
};

const NavBar = styled(FlexContainer)`
  height: 58px;
  flex-shrink: 0;
  border-bottom: 1px solid ${colors.primary200};
  padding: 0 16px;
`;

const LinksRow = styled(FlexContainer)`
  flex-basis: 90%;
  ${'' /* flex-grow: 1; */}
  height: 100%;
`;

const BackHolder = styled(FlexContainer)`
  border-radius: 4px;
  gap: 8px;
  padding: 8px;
  background-color: ${colors.primary050};

  &:hover {
    background-color: ${colors.primary100};
    cursor: pointer;
  }
  &:active {
    background-color: ${colors.primary200};
  }
`;

const BackText = styled.h5`
  color: ${colors.primary800};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const LinkContainer = styled(FlexContainer)`
  flex-basis: 192px;
  box-sizing: border-box;
  height: 100%;

  transform: ${(props) =>
    props.active === props.name ? `translate(0, 3px)` : 'none'};

  border-bottom: ${(props) =>
    props.active === props.name ? `6px solid ${colors.secondary500}` : 'none'};
`;

const NavLink = styled.h4`
  color: ${(props) =>
    props.active === props.name ? colors.secondary600 : colors.primary600};

  &:hover {
    cursor: pointer;
    color: ${(props) =>
      props.active === props.name ? colors.secondary600 : colors.primary500};
  }
`;

export default ModalNav;
