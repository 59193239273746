import React from 'react';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import styled from 'styled-components';
import DashboardIcon from '../icons/DashboardIcon';
import PlannerIcon from '../icons/PlannerIcon';
import MealPlanIcon from '../icons/MealPlanIcon';
import FitnessPlanIcon from '../icons/FitnessPlanIcon';
import ShoppingListIcon from '../icons/ShoppingListIcon';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';

const mapState = (state) => ({
  isAdmin: state.currentUser.user.isAdmin,
});

const SideNav = ({ profile, ...props }) => {
  const { isAdmin } = useSelector(mapState);
  return (
    <NavContainer height="100%" flexDirection="column">
      <LogoArea alignItems="center">
        <Logo>Nutriology</Logo>
      </LogoArea>

      <NavContent>
        {profile && (
          <>
            <PersonalArea flexDirection="column">
              <Header color={colors.primary700}>Settings</Header>
              <StyledMenuItem disableGutters={true}>
                <StyledLink to="/app/profile" data-test="sideNav-profile">
                  <LinkContent alignItems="center">
                    <P $noMargin>My Profile</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/profile/preferences"
                  data-test="sideNav-preferences"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Preferences</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              {/* TODO: Post-Beta feature
              https://app.clickup.com/t/8etm8n
              <StyledMenuItem disableGutters={true}>
                <StyledLink to="/app/profile/notifications">
                  <LinkContent alignItems="center">
                    <P $noMargin>Notifications</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem> */}
              <StyledMenuItem disableGutters={true}>
                <StyledLink to="/app/profile/billing">
                  <LinkContent alignItems="center">
                    <P $noMargin>Billing & Subscription</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
            </PersonalArea>
            <HR />
          </>
        )}

        <AppArea flexDirection="column">
          <StyledMenuItem disableGutters={true}>
            <StyledLink to="/app/dashboard" data-test="sideNav-dashboard">
              <LinkContent alignItems="center">
                <DashboardIcon />
                <P>Dashboard</P>
              </LinkContent>
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem disableGutters={true}>
            <StyledLink to="/app/planner" data-test="sideNav-planner">
              <LinkContent alignItems="center">
                <PlannerIcon />
                <P>Planner</P>
              </LinkContent>
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem disableGutters={true}>
            <StyledLink to="/app/mealplan" data-test="sideNav-mealPlan">
              <LinkContent alignItems="center">
                <MealPlanIcon />
                <P>Meal Plan</P>
              </LinkContent>
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem disableGutters={true}>
            <StyledLink to="/app/fitnessplan" data-test="sideNav-fitnessPlan">
              <LinkContent alignItems="center">
                <FitnessPlanIcon />
                <P>Fitness Plan</P>
              </LinkContent>
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem disableGutters={true}>
            <StyledLink to="/app/shoppinglist" data-test="sideNav-shoppingList">
              <LinkContent alignItems="center">
                <ShoppingListIcon />
                <P>Shopping List</P>
              </LinkContent>
            </StyledLink>
          </StyledMenuItem>
        </AppArea>
        {(isAdmin || false) && (
          <>
            <AdminArea flexDirection="column">
              <HR />
              <Header>Admin</Header>
              <StyledMenuItem disableGutters={true}>
                <StyledLink to="/app/admin/user" data-test="sideNav-admin-user">
                  <LinkContent alignItems="center">
                    <P $noMargin>Users</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/recipe"
                  data-test="sideNav-admin-recipe"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Recipes</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink to="/app/admin/food" data-test="sideNav-admin-food">
                  <LinkContent alignItems="center">
                    <P $noMargin>Foods</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/mealplan"
                  data-test="sideNav-admin-mealPlan"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Meal Plans</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/exercise"
                  data-test="sideNav-admin-exercise"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Exercises</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/workout"
                  data-test="sideNav-admin-workout"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Workouts</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/activity"
                  data-test="sideNav-admin-activity"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Activities</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/fitnessPlan"
                  data-test="sideNav-admin-fitnessPlan"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Fitness Plans</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/track"
                  data-test="sideNav-admin-track"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Tracks</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
              <StyledMenuItem disableGutters={true}>
                <StyledLink
                  to="/app/admin/campaigns"
                  data-test="sideNav-admin-campaigns"
                >
                  <LinkContent alignItems="center">
                    <P $noMargin>Campaigns</P>
                  </LinkContent>
                </StyledLink>
              </StyledMenuItem>
            </AdminArea>
          </>
        )}
      </NavContent>
    </NavContainer>
  );
};

const NavContainer = styled(FlexContainer)`
  width: 225px;
  float: left;
  height: ${(props) => props.height};
  position: sticky;
  top: 0;
  background-color: ${colors.primary050};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  z-index: 9;
`;

const LogoArea = styled(FlexContainer)`
  height: 80px;
  padding-left: 32px;
`;

const HR = styled.hr`
  width: 160px;
  border-top: 1px solid ${colors.primary400};
  border-bottom: none;
`;

const PersonalArea = styled(FlexContainer)``;

const AppArea = styled(FlexContainer)``;

const AdminArea = styled(FlexContainer)``;

const Logo = styled.h1`
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: 23px;
  text-transform: uppercase;
`;
const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  padding: 0;

  &:active {
    background-color: ${colors.primary100};
  }

  &:-moz-focusring {
    outline: none;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 48px;

  &:hover {
    background-color: ${colors.primary200};
  }
`;

const NavContent = styled.div`
  overflow-y: auto;
`;

const LinkContent = styled(FlexContainer)`
  height: 100%;
  padding-left: 32px;
`;

const P = styled.p`
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  margin: ${(props) => (props.$noMargin ? '0' : '0 0 0 16px')};
  color: ${colors.primary700};
`;

const Header = styled.h4`
  color: ${(props) => (props.color ? props.color : colors.secondary600)};
  text-transform: uppercase;
  font-weight: 600;
  margin: 8px auto;
`;

export default SideNav;
