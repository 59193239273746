import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import SetRow from './SetRow';
import Button from '../../elements/Button';
import nextId from 'react-id-generator';

const SetSection = ({ sets, repUnits, weekNum, addSet, ...props }) => {
  const setRows = sets.map((set, i) => (
    <SetRow
      key={i}
      handleChange={props.handleChange}
      handleValueChanged={props.handleValueChanged}
      num={i}
      repUnits={repUnits}
      set={set}
      displayWeight={props.displayWeight}
      removeSet={props.removeSet}
    />
  ));

  const handleAdd = () => {
    let newSet;
    const id = nextId();

    if (sets.length === 0) {
      newSet = {
        setId: `week${weekNum}_${id}`,
        repsNumber: 0,
        weightInLbs: 0,
        week: weekNum,
      };
    } else {
      newSet = { ...sets[sets.length - 1], setId: `week${weekNum}_${id}` };
    }
    addSet(newSet);
  };

  return (
    <SetsContainer flexDirection="column">
      <HR />
      <Header>{`Week ${weekNum}`}</Header>
      <Table>
        <tbody>
          <TR>
            <TH>Sets</TH>
            <TH>
              {repUnits === 'Secs' ||
              repUnits === 'Mins' ||
              repUnits === 'Secs Per Side' ||
              repUnits === 'Mins Per Side'
                ? 'Duration'
                : repUnits === 'Reps Per Side'
                  ? 'Reps/Side'
                  : 'Reps'}
            </TH>
            <TH>Weight</TH>
          </TR>
          {setRows}
        </tbody>
      </Table>
      <ButtonContainer>
        <Button buttonText="+ Add Set" pink="true" onClick={handleAdd} />
      </ButtonContainer>
    </SetsContainer>
  );
};

const SetsContainer = styled(FlexContainer)``;

const Header = styled.h3`
  color: ${colors.primary800};
  text-align: center;
  padding-bottom: 8px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
const TH = styled.th`
  padding: 0 20px;
  text-transform: uppercase;
  border: none;
  text-align: left;
  font-size: 10pt;
  font-weight: 400;
  line-height: 14pt;
  color: ${colors.primary600};
`;

const TR = styled.tr`
  > :first-child {
    padding-left: 40px;
  }
`;

const HR = styled.hr`
  border: none;
  height: 1px;
  width: 600px;
  margin-top: 8px;
  margin-bottom: 12px;
  background-color: ${colors.primary200};
`;

const ButtonContainer = styled(FlexContainer)`
  width: 128px;
  align-self: center;
  padding: 12px 0 16px 0;
`;

export default SetSection;
