import React, { useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { Dialog, Slide } from '@mui/material';
import FlexContainer from '../elements/FlexContainer';
import CloseIcon from '../icons/CloseIcon';
import CheckBox from '../elements/CheckBox';
import useToggles from '../hooks/useToggles';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../store/general/currentUserSlice';

function PWAPrompt({ open }) {
  const [dialogOpen, setDialogOpen] = useState(open);

  const user = useSelector((state) => state.currentUser.user);
  const dispatch = useDispatch();
  const { toggleValues, handleToggle, setToggleValues } = useToggles({
    doNotShowAgain: false,
  });

  useEffect(() => {
    setToggleValues({ doNotShowAgain: user?.hidePWAPrompt ?? false });
  }, []);

  useEffect(() => {
    if (toggleValues.doNotShowAgain === true) {
      dispatch(updateUser({ _id: user._id, hidePWAPrompt: true }));
    } else if (
      toggleValues.doNotShowAgain === false &&
      user.hidePWAPrompt === true
    ) {
      dispatch(updateUser({ _id: user._id, hidePWAPrompt: false }));
    }
  }, [toggleValues.doNotShowAgain]);

  const isChrome = navigator.userAgent.match('CriOS');

  return (
    <CustomDialog
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
      }}
      TransitionComponent={Transition}
      data-test="pwa-prompt"
    >
      <ContentContainer flexDirection="column">
        <Header justify="space-between" alignItems="center">
          <Title>Install the app</Title>
          <StyledCloseIcon
            hideCircle={true}
            handleClose={() => {
              setDialogOpen(false);
            }}
            data-test="close-pwa-prompt"
          />
        </Header>
        <DisplayBox alignItems="center">
          <Img src={`${process.env.PUBLIC_URL}/logo64.png`} />
          <FlexContainer flexDirection="column">
            <AppName>Nutriology App</AppName>
            <AppAddress>app.nutriology.io</AppAddress>
          </FlexContainer>
        </DisplayBox>
        {isChrome ? (
          <ChromeMessage>
            Progressive Web Apps can only be installed (added to home screen) on
            Safari. Please open Safari to install this app locally.
          </ChromeMessage>
        ) : (
          <List>
            <Step>
              Make sure you are using Safari <SafariIcon /> in order to install
              this app
            </Step>
            <Step>
              Tap on <ShareImg /> in the bottom bar
            </Step>
            <Step>
              Scroll and select <A2HS />
            </Step>
            <Step>
              Look for the <NutriologyIcon /> icon on your home screen
            </Step>
          </List>
        )}

        <FlexContainer style={{ marginBottom: 24, paddingLeft: 4 }}>
          <CheckBox
            checked={toggleValues.doNotShowAgain || ''}
            label="Do not show prompt again"
            onChange={handleToggle()}
            name="doNotShowAgain"
            size="sm"
            data-test="pwa-doNotShow"
          />
        </FlexContainer>
      </ContentContainer>
    </CustomDialog>
  );
}

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    height: ${(props) => props.height || '53%'};
    max-height: ${(props) => props.maxheight || undefined};
    border: 1px solid ${colors.primary200};
    border-radius: 25px 25px 0 0;
    margin-bottom: ${(props) => (props.small ? '60px' : 0)};

    @media (max-height: 700px) {
      height: 65%;
    }
  }
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContentContainer = styled(FlexContainer)`
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  padding: 24px;
`;

const Header = styled(FlexContainer)`
  position: absolute;
  width: calc(100% - 48px);
  height: 48px;
  top: 12px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  transform: translateX(8px);
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.primary800};
`;

const List = styled.ol`
  list-style: none;
  counter-reset: item;
  text-align: left;
  width: 100%;
  padding-inline-start: 4px;
  margin-bottom: 8px;
`;

const Step = styled.li`
  counter-increment: item;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 28px;

  &:before {
    margin-right: 10px;
    content: counter(item) '.';
    font-weight: bold;
    list-style-type: decimal;
  }
`;

const DisplayBox = styled(FlexContainer)`
  flex-grow: 1;
  max-height: 75px;
  margin-top: 48px;
  padding: 12px;
  border: 1px solid ${colors.primary100};
  background-color: ${colors.primary050};
  gap: 16px;
`;

const Img = styled.img`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 48px;
  height: 48px;
`;

const InlineImg = styled.div`
  display: inline-block;
  height: 20px;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  transform: translateY(6px);
  border: 1px solid ${colors.primary100};
`;
const ShareImg = styled(InlineImg)`
  width: 20px;
  background-image: url(${process.env.PUBLIC_URL}/ShareIcon.png);
`;

const A2HS = styled(InlineImg)`
  width: 175px;
  background-image: url(${process.env.PUBLIC_URL}/AddToHome.png);
`;

const NutriologyIcon = styled(InlineImg)`
  width: 20px;
  background-image: url(${process.env.PUBLIC_URL}/logo64.png);
`;

const SafariIcon = styled(InlineImg)`
  width: 20px;
  background-image: url(${process.env.PUBLIC_URL}/Safari.png);
`;

const AppName = styled.h5`
  font-weight: 600;
  font-size: 14px;
  color: ${colors.primary800};
`;

const AppAddress = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.primary800};
`;

// Chrome elements

const ChromeMessage = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${colors.primary800};
  margin: 32px 0;
`;

export default PWAPrompt;
