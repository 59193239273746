import React, { useState } from 'react';

import { cloneDeep, omit, keys, pickBy } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';

import WorkoutForm from './WorkoutForm';
import { addWorkout } from '../../services/api/workout';

const AddWorkout = (props) => {
  const initialValues = {
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    locationType: 'Home',
    gender: 'All',
    numberOfWeeks: 1,
  };
  const initialWorkoutInfo = {
    intensity: 3,
    duration: 30,
    caloriesBurned: 100,
    week: 1,
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const initialToggleValues = {
    active: false,
    level: {},
    location: {},
    specialtyFocus: {},
  };
  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);
  const [imageUrl, setImageUrl] = useState();

  const [workoutInfo, setWorkoutInfo] = useState([{ ...initialWorkoutInfo }]);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [exercises, setExercises] = useState([]);

  const submitData = () => {
    setLoading(true);
    let payload = cloneDeep(values);
    payload.workoutInfo = workoutInfo;
    payload.active = toggleValues.active;
    payload.exercises = exercises.map((exercise) => omit(exercise, 'id'));
    payload.tags = {};
    payload.tags.level = keys(pickBy(toggleValues.level, Boolean));
    payload.tags.location = keys(pickBy(toggleValues.location, Boolean));
    payload.tags.specialtyFocus = keys(
      pickBy(toggleValues.specialtyFocus, Boolean),
    );

    addWorkout(payload)
      .then((result) => {
        setExercises([]);
        setWorkoutInfo([{ ...initialWorkoutInfo }]);
        setToggleValues(initialToggleValues);
        setStatus('success');
        setMessage(`Workout "${result.name}" was created successfully!`);
        setValues(initialValues);
        setImageUrl(null);
      })
      .catch((err) => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <WorkoutForm
      imageUrl={imageUrl}
      setImageUrl={setImageUrl}
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      toggleValues={toggleValues}
      handleToggle={handleToggle}
      exercises={exercises}
      setExercises={setExercises}
      workoutInfo={workoutInfo}
      setWorkoutInfo={setWorkoutInfo}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      buttonText="Create Workout"
    />
  );
};

export default AddWorkout;
