import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import GenericModalCard from './GenericModalCard';

const GenericAddModal = ({ setScreen, ...props }) => {
  const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

  const handleClick = (e) => {
    setScreen(e.currentTarget.attributes.name.value);
  };

  return (
    <Container flexDirection="column" justify="center" alignItems="center">
      <Title>Add To Planner</Title>
      <Grid wrap="wrap" justify="center">
        <GenericModalCard
          title="Meals"
          name="Add Food"
          icon={`${fileStorage}/AddFood.png`}
          handleClick={handleClick}
          data-test="generic-modal-addNutrition"
        />
        <GenericModalCard
          title="Fitness"
          name="Add Exercise"
          icon={`${fileStorage}/AddExercise.png`}
          handleClick={handleClick}
          data-test="generic-modal-addFitness"
        />
      </Grid>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;

  > * {
    margin-bottom: 48px;
  }
`;

const Grid = styled(FlexContainer)`
  max-width: 700px;

  & :nth-child(3n-1) {
    margin: 0 16px;
  }

  > * {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  color: ${colors.primary800};
`;

export default GenericAddModal;
