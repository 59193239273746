import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api/api';

const initialState = [];

const ingredientMapsSlice = createSlice({
  name: 'ingredientMaps',
  initialState,
  extraReducers(builder) {
    builder.addCase(getIngredientMaps.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const getIngredientMaps = createAsyncThunk(
  'shoppinglist/getIngredientMaps',
  async (_, { rejectWithValue }) => {
    try {
      const maps = await apiCall('get', '/ingredientmap');
      return maps;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default ingredientMapsSlice.reducer;
