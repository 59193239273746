import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import { getUnitList, convertNutrients } from '../../helpers/units';
import { without, capitalize } from 'lodash';

import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../elements/TextInput';
import TextBox from '../../elements/TextBox';
import Dropdown from '../../elements/Dropdown';
import CheckBox from '../../elements/CheckBox';
import AddImage from '../../elements/AddImage';
import AlertStack from '../../elements/AlertStack';
import NumericInput from '../../elements/NumericInput';
import FractionInput from '../../elements/FractionInput';
import Button from '../../elements/Button';
import AddIcon from '../../icons/AddIcon';
import RemoveXIcon from '../../icons/RemoveXIcon';
import SearchModalReturnObject from '../../universal/SearchModalReturnObject';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { recipeTags } from '../../helpers/recipeTags';

const RecipeForm = ({
  imageUrl,
  setImageUrl,
  handleChange = () => {},
  values,
  handleValueChanged,
  toggleValues,
  handleToggle,
  clearNutrientValues,
  ingredientsValue,
  setIngredientsValue,
  instructionsValue,
  setInstructionsValue,
  searchFoods,
  foods,
  setFoods,
  calculatedNutrients,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  // Modal
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Recipe Image

  const inputFile = useRef(null);

  const handleChangeImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleImage = () => {
    inputFile.current.click();
  };

  // Manage quantity and units for ingredients

  const handleQuantityValueChange = (name, value) => {
    //hacky, but we don't have access to the event here
    const i = name.split('-')[1];
    const n = 'quantity';
    const updatedFoods = [...foods];
    updatedFoods[i][n] = parseFloat(value);
    setFoods(updatedFoods);
  };

  const handleUnitsChanged = (name, idx, value) => {
    const originalUnits = foods[idx]['measureUnit'];
    const updatedFoods = [...foods];
    updatedFoods[idx]['measureUnit'] = value;

    const updatedNutrients = convertNutrients(
      updatedFoods[idx]['nutrientsPerUnit'],
      updatedFoods[idx]['gramWeightPerUnit'],
      value,
      originalUnits,
    );
    updatedFoods[idx]['nutrientsPerUnit'] = updatedNutrients;
    setFoods(updatedFoods);
  };

  const handleDeleteFood = (item) => {
    setFoods(without(foods, item));
  };

  // Ingredients

  const ingredients = foods.map((food, index) => {
    const ingNum = `ing-${index}`;
    const unitList = getUnitList(food.measureUnit);
    return (
      <Draggable key={index} draggableId={`food-${index}`} index={index}>
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TD>
              <IngredientName
                name={food.name}
                deleted={food.isDeleted}
                data-test="recipe-ingredient-name"
              >
                {capitalize(food.verboseName)}
              </IngredientName>
            </TD>
            <TD>
              <UnitsContainer>
                <FractionInput
                  id={ingNum}
                  name={ingNum}
                  data-idx={index}
                  data-name="quantity"
                  width="75px"
                  value={foods[index].quantity || 1}
                  onValueChanged={handleQuantityValueChange}
                  data-test="recipe-ingredient-quantity"
                />
                <Dropdown
                  width="150px"
                  options={unitList || ['Oz']}
                  data-idx={index}
                  data-name="units"
                  value={{
                    value: foods[index].measureUnit,
                    label: foods[index].measureUnit,
                  }}
                  onChange={(value) =>
                    handleUnitsChanged('units', index, value.label)
                  }
                  data-test="recipe-ingredient-measureUnit"
                />
              </UnitsContainer>
            </TD>
            <TD style={{ width: '10%' }}>
              <RemoveXIcon
                onClick={() => handleDeleteFood(food)}
                data-test="recipe-ingredient-remove"
              />
            </TD>
          </tr>
        )}
      </Draggable>
    );
  });

  // Instructions
  const addInstruction = () => {
    setInstructionsValue([
      ...instructionsValue,
      {
        num: instructionsValue[instructionsValue.length - 1].num + 1,
        step: '',
      },
    ]);
  };

  const removeInstruction = (instruction) => {
    setInstructionsValue(without(instructionsValue, instruction));
  };

  const handleInstructionChange = (e) => {
    const updatedInstructions = [...instructionsValue];
    updatedInstructions[e.target.dataset.idx][e.target.dataset.name] =
      e.target.value;
    setInstructionsValue(updatedInstructions);
  };

  const reorderFoods = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setFoods(result);
  };

  const onDragEndFoods = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    return reorderFoods(foods, result.source.index, result.destination.index);
  };

  const reorderInstructions = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setInstructionsValue(result);
  };

  const onDragEndInstruction = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    return reorderInstructions(
      instructionsValue,
      result.source.index,
      result.destination.index,
    );
  };

  const instructions = instructionsValue.map((instruction, index) => {
    const stepNum = `step-${index}`;
    return (
      <Draggable key={index} draggableId={stepNum} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <FlexContainer
              key={stepNum}
              alignItems="center"
              data-test="recipe-instruction"
            >
              <TextBox
                id={stepNum}
                name={stepNum}
                data-name="step"
                data-idx={index}
                onChange={handleInstructionChange}
                value={instructionsValue[index].step || ''}
                label={`Step #${index + 1}`}
                defaultHeight="50px"
                data-test="recipe-instruction-text"
              />
              <StyledRemoveXIcon
                onClick={() => removeInstruction(instruction)}
                data-test="recipe-instruction-remove"
              />
            </FlexContainer>
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <CheckBox
          checked={toggleValues.active || ''}
          label="Active"
          onChange={handleToggle()}
          name="active"
          data-test="recipe-active"
        />
        <CheckBox
          checked={toggleValues.placeholder || ''}
          label="Placeholder?"
          onChange={handleToggle()}
          name="placeholder"
          data-test="recipe-placeholder"
        />
        <input
          hidden
          ref={inputFile}
          type="file"
          name="image"
          onChange={handleChangeImage}
        />
        <AddImage src={imageUrl} onClick={handleImage} />
        <TextInput
          id="recipeName"
          name="name"
          onChange={handleChange}
          value={values.name || ''}
          placeholder="Recipe Name"
          label="Recipe Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Recipe Name')}
          data-test="recipe-name"
        />
        <Dropdown
          width="160px"
          options={['N/A', 'A', 'B', 'C']}
          label="Recipe Version"
          value={{
            value: values.version,
            label: values.version,
          }}
          onChange={(value) =>
            handleValueChanged('version', value.value, 'string')
          }
          data-test="recipe-version"
        />
        {!toggleValues.placeholder && (
          <>
            <NumericInput
              id="prepTime"
              name="prepTime"
              onChange={handleChange}
              onValueChanged={handleValueChanged}
              value={values.prepTime || ''}
              minValue="0"
              label="Prep Time"
              units="minutes"
              data-test="recipe-prepTime"
            />
            <NumericInput
              id="cookTime"
              name="cookTime"
              onChange={handleChange}
              onValueChanged={handleValueChanged}
              value={values.cookTime || ''}
              minValue="0"
              label="Cook Time"
              units="minutes"
              data-test="recipe-cookTime"
            />

            <FlexContainer alignItems="flex-end">
              <NumericInput
                label="Serving Size"
                name="servingSize"
                value={values.servingSize || ''}
                minValue="0"
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                data-test="recipe-servingSize"
              />
              <TextInput
                id="servingUnit"
                name="servingUnit"
                onChange={handleChange}
                value={values.servingUnit || ''}
                placeholder="Units"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'Units')}
                style={{ marginLeft: '5px' }}
                data-test="recipe-servingUnit"
              />
            </FlexContainer>
            <NumericInput
              label="Number of Servings (Yield)"
              id="yield"
              name="yield"
              value={values.yield || ''}
              minValue="0"
              onValueChanged={handleValueChanged}
              onChange={handleChange}
              units="Servings"
              data-test="recipe-yield"
            />
            <TextInput
              id="source"
              name="source"
              onChange={handleChange}
              value={values.source || ''}
              placeholder="Source"
              label="Source"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = 'Source')}
              data-test="recipe-source"
            />
            <TextInput
              id="attrLink"
              name="attrLink"
              onChange={handleChange}
              value={values.attrLink || ''}
              placeholder="Attribution Link"
              label="Attribution Link"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = 'Attribution Link')}
              data-test="recipe-attrLink"
            />
            <TextBox
              id="expertTip"
              name="expertTip"
              onChange={handleChange}
              value={values.expertTip || ''}
              placeholder="Expert Tip"
              label="Expert Tip"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = 'Expert Tip')}
              data-test="recipe-expertTip"
            />
          </>
        )}
        <h3>Nutrition Per Serving</h3>
        <p>
          Leave blank to allow nutrient totals to be calculated from ingredients
        </p>
        <NutrientsContainer wrap="wrap" flexDirection="column">
          <NumericInput
            label="Calories"
            name="nutrients.calories"
            value={values.nutrients.calories || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.calories || 0}
            units="kCal"
            data-test="recipe-nutrients-calories"
          />
          <NumericInput
            label="Total Fat"
            name="nutrients.fat"
            value={values.nutrients.fat || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.fat || 0}
            units="g"
            data-test="recipe-nutrients-fat"
          />
          <NumericInput
            label="Saturated Fat"
            name="nutrients.saturatedFat"
            value={values.nutrients.saturatedFat || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.saturatedFat || 0}
            units="g"
            data-test="recipe-nutrients-saturatedFat"
          />
          <NumericInput
            label="Trans Fat"
            name="nutrients.transFat"
            value={values.nutrients.transFat || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.transFat || 0}
            units="g"
            data-test="recipe-nutrients-transFat"
          />
          <NumericInput
            label="Cholesterol"
            name="nutrients.cholesterol_mg"
            value={values.nutrients.cholesterol_mg || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.cholesterol_mg || 0}
            units="mg"
            data-test="recipe-nutrients-cholesterol"
          />
          <NumericInput
            label="Sodium"
            name="nutrients.sodium_mg"
            value={values.nutrients.sodium_mg || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.sodium_mg || 0}
            units="mg"
            data-test="recipe-nutrients-sodium"
          />
          <NumericInput
            label="Total Carbs"
            name="nutrients.carbohydrates"
            value={values.nutrients.carbohydrates || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.carbohydrates || 0}
            units="g"
            data-test="recipe-nutrients-carbohydrates"
          />
          <NumericInput
            label="Dietary Fiber"
            name="nutrients.fiber"
            value={values.nutrients.fiber || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.fiber || 0}
            units="g"
            data-test="recipe-nutrients-fiber"
          />
          <NumericInput
            label="Total Sugars"
            name="nutrients.totalSugar"
            value={values.nutrients.totalSugar || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.totalSugar || 0}
            units="g"
            data-test="recipe-nutrients-totalSugar"
          />
          <NumericInput
            label="Added Sugars"
            name="nutrients.addedSugar"
            value={values.nutrients.addedSugar || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.addedSugar || 0}
            units="g"
            data-test="recipe-nutrients-addedSugar"
          />
          <NumericInput
            label="Protein"
            name="nutrients.proteins"
            value={values.nutrients.proteins || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.proteins || 0}
            units="g"
            data-test="recipe-nutrients-proteins"
          />
          <NumericInput
            label="Vitamin D"
            name="nutrients.vitaminD_mcg"
            value={values.nutrients.vitaminD_mcg || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.vitaminD_mcg || 0}
            units="mcg"
            data-test="recipe-nutrients-vitaminD"
          />
          <NumericInput
            label="Calcium"
            name="nutrients.calcium_mg"
            value={values.nutrients.calcium_mg || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.calcium_mg || 0}
            units="mg"
            data-test="recipe-nutrients-calcium"
          />
          <NumericInput
            label="Potassium"
            name="nutrients.potassium_mg"
            value={values.nutrients.potassium_mg || ''}
            onValueChanged={handleValueChanged}
            onChange={handleChange}
            minValue="0"
            placeholder={calculatedNutrients.potassium_mg || 0}
            units="mg"
            data-test="recipe-nutrients-potassium"
          />
        </NutrientsContainer>
        <Button
          buttonText="Clear"
          buttonSize="small"
          width="120px"
          handleClick={clearNutrientValues}
          data-test="recipe-nutrients-clear"
        />
        <HR />
        {!toggleValues.placeholder && (
          <>
            <Heading>Ingredients</Heading>
            {formType === 'edit' && ingredients.length > 0 && (
              <Notice style={{ textAlign: 'center' }}>
                Note: Adding, removing, or modifying ingredients will clear
                nutrient values above. Nutrient values will still calculate
                based on ingriedient information but manually entered values
                will be lost.
              </Notice>
            )}
            <IngredientsContainer flexDirection="column">
              <DragDropContext onDragEnd={onDragEndFoods}>
                <Table>
                  <Droppable droppableId="droppable-ingredients">
                    {(provided, snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        data-test="recipe-ingredientList"
                      >
                        {ingredients}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </DragDropContext>
              <AddIcon
                labelText="Add Ingredient"
                orientation="row"
                pink="true"
                onClick={handleOpenModal}
                data-test="recipe-ingredient-add"
              />
              <SearchModalReturnObject
                open={openModal}
                handleClose={handleCloseModal}
                value={ingredientsValue}
                setValue={setIngredientsValue}
                fetchService={searchFoods}
                getOptionLabel={(option) => capitalize(option.verboseName)}
                placeholder="Try tomato, etc."
              />
            </IngredientsContainer>
            <HR />
            <Heading>Instructions</Heading>
            <DragDropContext onDragEnd={onDragEndInstruction}>
              <InstructionsContainer flexDirection="column">
                <Droppable droppableId="droppable-instructions">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      data-test="recipe-instructionsList"
                    >
                      {instructions}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <AddIcon
                  labelText="Add Instruction"
                  orientation="row"
                  pink="true"
                  onClick={addInstruction}
                  data-test="recipe-instruction-add"
                />
              </InstructionsContainer>
            </DragDropContext>
            <HR />
            <Heading>Tags</Heading>

            {recipeTags.map((item, index) => (
              <FlexContainer
                key={index.toString()}
                flexDirection="column"
                flexShrink="0"
              >
                <Tag>{item.label}</Tag>
                <CheckContainer flexDirection="column">
                  {item.tags.map((tag, tIndex) => (
                    <CheckBox
                      key={tIndex.toString()}
                      checked={toggleValues[item.name][tag.name] || ''}
                      label={tag.label}
                      onChange={handleToggle(item.name)}
                      name={tag.name}
                      data-test={`recipe-${item.name}-${tag.name}`}
                    />
                  ))}
                </CheckContainer>
              </FlexContainer>
            ))}
          </>
        )}
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Recipe'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="recipe-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="recipe-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="recipe-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="recipe-undoDelete"
          />
        )}
    </Form>
  );
};

const InstructionsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 12px;
  }
`;

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const NutrientsContainer = styled(FlexContainer)`
  max-height: 350px;
  > * {
    margin-bottom: 6px;
  }
`;
const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const CheckContainer = styled(FlexContainer)`
  max-height: 275px;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
const Tag = styled.h3`
  color: ${colors.secondary500};
  margin-bottom: 15px;
`;

const IngredientsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
`;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  padding: 20px 0 0 12px;
`;

const IngredientName = styled.h6`
  color: ${(props) => (props.deleted ? 'red' : `${colors.primary800}`)};
`;
const Table = styled.table`
  width: 100%;
  margin: auto;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

const TD = styled.td`
  padding: 12px 8px;
`;

const UnitsContainer = styled(FlexContainer)`
  > * {
    margin-right: 8px;
  }
`;

const Notice = styled.p`
  color: #ff7c0e;
  margin: 16px 0;
  padding: 0px;
`;

export default RecipeForm;
