import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import Button from '../elements/Button';

const Subscription = ({ price }) => {
  const whatsIncludedPoints = [
    {
      heading: 'Expert-designed meal and fitness plans',
      subheading:
        "Each week you'll receive personalised plans based on your preferences and goals.",
    },
    {
      heading: 'Customization Features',
      subheading:
        'Customize your plans, add your favorite recipes, or build your own workouts!',
    },
    {
      heading: 'Efficient Progress Tracker',
      subheading:
        'Easily manage and track your meals and workouts against recommended targets',
    },
    {
      heading: 'Simple Drag & Drop Planner',
      subheading:
        'View and plan your week ahead with our easy and fun-to-use planning tool',
    },
  ];

  return (
    <>
      <SubscriptionDetailsContainer justify="space-between">
        <DetailsText data-test="subscription-price-nickname">
          {' '}
          {price.nickname}
        </DetailsText>
        <DetailsText data-test="subscription-price">
          {(price.unit_amount / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          /{price.interval_count > 1 && price.interval_count + ' '}
          {price.interval}
          {price.interval_count > 1 && 's'}
        </DetailsText>
      </SubscriptionDetailsContainer>
      {/* This will be included later once we implement change plan functionality */}
      <MobileIncludedHeading>
        <MobileSubDetailHeading color={colors.primary800}>
          What's Included?{' '}
          <MobileSubDetailHeading color={colors.primary800} fontWeight="400">
            We're Glad You Asked!
          </MobileSubDetailHeading>
        </MobileSubDetailHeading>
      </MobileIncludedHeading>
      <IncludedContainer flexDirection={isMobile() ? 'column' : 'row'}>
        {whatsIncludedPoints.map((point) => (
          <IncludedPointsContainer key={point.heading} alignItems="flex-start">
            <img
              src={`${process.env.PUBLIC_URL}/icons/CheckmarkIconCircleFilled.svg`}
              alt=""
            />
            <IncludedPointsTextContainer>
              <MobileSubDetailHeading>{point.heading}</MobileSubDetailHeading>
              <MobileSubDetailSubHeading>
                {point.subheading}
              </MobileSubDetailSubHeading>
            </IncludedPointsTextContainer>
          </IncludedPointsContainer>
        ))}
      </IncludedContainer>
      <FlexContainer
        justify={!isMobile() ? 'center' : 'initial'}
        style={{ width: '100%' }}
      >
        {/* TODO: Add Change Plan modal and ability to switch subscription */}
        {/* https://app.clickup.com/t/2b5m07q */}
        <StyledButton
          buttonText="Change Plan"
          variant="outlined"
          // onClick={() => add later)}
        />
      </FlexContainer>
    </>
  );
};

const SubscriptionDetailsContainer = styled(FlexContainer)`
  padding: 16px;

  background: #f7fbfb;
  border-radius: 8px;
`;

const DetailsText = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.secondary600};
`;

const MobileIncludedHeading = styled.div`
  padding: 13px 0;
  border-bottom: 0.6px solid ${colors.primary200};
  margin-bottom: 16px;
`;

const MobileSubDetailHeading = styled.span`
  font-weight: ${(props) => props.fontWeight || 700};
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.color || colors.secondary600};
`;

const IncludedPointsContainer = styled(FlexContainer)`
  flex-basis: 40%;
  flex-shrink: 1;
`;

const IncludedPointsTextContainer = styled.div`
  padding-left: 18px;
`;

const MobileSubDetailSubHeading = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary600};
  margin-top: 4px;
`;

const StyledButton = styled(Button)`
  width: ${(props) => (isMobile() ? '100%' : '282px')};
  height: 45px;
  border: 1px solid ${colors.secondary600};
  color: ${colors.secondary600};
  border-radius: 2px;
  text-transform: none;
`;

const IncludedContainer = styled(FlexContainer)`
  gap: 24px;
  margin-bottom: 32px;
  max-width: 90%;
  min-width: 100%;
  flex-wrap: wrap;
`;

export default Subscription;
