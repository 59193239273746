import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import useModal from '../../hooks/useModal';
import AddIcon from '../../icons/AddIcon';
import ModalContainer from '../../sharedModals/ModalContainer';
import PlanCard from './PlanCard';

const PlanList = ({
  fitnessPlans,
  setFitnessPlans,
  addPlan,
  removePlan,
  ...props
}) => {
  const { open, launchModal, closeModal } = useModal(false);

  const reorderPlans = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setFitnessPlans(result);
  };

  const onDragEndPlans = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    return reorderPlans(
      fitnessPlans,
      result.source.index,
      result.destination.index,
    );
  };

  const handleRemove = (e) => {
    const planId = e.target.dataset.name;
    removePlan(planId);
  };

  const fitnessPlanList = fitnessPlans.map((plan, index) => {
    const uniqueId = plan._id ? plan._id : plan.id;
    return (
      <Draggable key={uniqueId} draggableId={`plan-${uniqueId}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <PlanRow key={uniqueId} alignItems="center">
              <RowNum>{index + 1}</RowNum>
              <PlanCard
                itemId={uniqueId}
                name={plan.fitnessPlan.name}
                isDeleted={plan.fitnessPlan.isDeleted}
                handleRemove={handleRemove}
                remove={true}
              />
            </PlanRow>
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEndPlans}>
      <PlansContainer flexDirection="column">
        <Droppable droppableId="droppable-plans">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              data-test="track-planList"
            >
              {fitnessPlanList}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <AddIcon
          labelText="Add Plan"
          orientation="row"
          pink="true"
          onClick={launchModal}
          data-test="track-fitnessPlan-add"
        />
        <ModalContainer
          open={open}
          context={{
            variant: 'admin',
            showOnly: 'fitnessPlan',
          }}
          initialScreen="Exercise Search"
          handleClose={closeModal}
          addFitnessPlan={addPlan}
          {...props}
        />
      </PlansContainer>
    </DragDropContext>
  );
};

const PlansContainer = styled(FlexContainer)`
  width: 75%;
  padding: 25px;

  > * {
    margin-bottom: 12px;
  }
`;

const PlanRow = styled(FlexContainer)`
  padding: 12px 0;

  > * {
    margin-right: 24px;
  }
`;

const RowNum = styled.h3`
  color: ${colors.primary800};
`;

export default PlanList;
