import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { cloneDeep, keys, pickBy, pick, omit } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';
import { colors } from '../../styleConstants';

import RecipeForm from './RecipeForm';
import FlexContainer from '../../elements/FlexContainer';

import { addRecipe } from '../../services/api/recipe';
import { searchFoods } from '../../services/api/food';
import UploadCSV from './UploadCSV';

const AddRecipe = (props) => {
  const initialValues = {
    nutrients: {},
    yield: 1,
    version: 'N/A',
  };
  const initialToggleValues = {
    active: false,
    placeholder: false,
    dietType: {},
    intolerances: {},
    mealType: {},
    cookingStyle: {},
    primaryIngredient: {},
    prepTime: {},
  };

  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);

  const [ingredientsValue, setIngredientsValue] = useState([]);
  const [foods, setFoods] = useState([]);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [calculatedNutrients, setCalculatedNutrients] = useState({});
  const [uploadError, setUploadError] = useState();

  const [imageUrl, setImageUrl] = useState();

  const instruction = { num: 1, step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);
  const [failedImports, setFailedImports] = useState([]);

  const clearNutrientValues = () => {
    setValues({ ...values, nutrients: {} });
  };

  useEffect(() => {
    const foodsCopy = [...foods];
    ingredientsValue.forEach((ingredient) => {
      foodsCopy.push({
        quantity: ingredient.amount || 1,
        measureUnit: ingredient.measureUnit,
        food: ingredient.id,
        name: ingredient.name,
        verboseName: ingredient.verboseName,
        gramWeight: ingredient.gramWeight,
        gramWeightPerUnit: ingredient.gramWeightPerUnit,
        nutrientsPerUnit: {
          calories: ingredient.nutrientsPerUnit.calories,
          proteins: ingredient.nutrientsPerUnit.proteins,
          carbohydrates: ingredient.nutrientsPerUnit.carbohydrates,
          fiber: ingredient.nutrientsPerUnit.fiber,
          totalSugar: ingredient.nutrientsPerUnit.totalSugar,
          addedSugar: ingredient.nutrientsPerUnit.addedSugar,
          fat: ingredient.nutrientsPerUnit.fat,
          saturatedFat: ingredient.nutrientsPerUnit.saturatedFat,
          transFat: ingredient.nutrientsPerUnit.transFat,
          cholesterol_mg: ingredient.nutrientsPerUnit.cholesterol_mg,
          vitaminD_mcg: ingredient.nutrientsPerUnit.vitaminD_mcg,
          calcium_mg: ingredient.nutrientsPerUnit.calcium_mg,
          potassium_mg: ingredient.nutrientsPerUnit.potassium_mg,
          sodium_mg: ingredient.nutrientsPerUnit.sodium_mg,
        },
      });
    });
    setFoods(foodsCopy);
  }, [ingredientsValue]);

  useEffect(() => {
    const calculated = {};

    foods.forEach((food) => {
      const nutrients = food.nutrientsPerUnit;
      const quantity = food.quantity / (values.yield || 1);
      for (const nutrient in nutrients) {
        calculated[nutrient] = calculated[nutrient]
          ? calculated[nutrient] + quantity * nutrients[nutrient]
          : quantity * nutrients[nutrient];
      }
    });
    setCalculatedNutrients(calculated);
  }, [foods, values.nutrients, values.yield]);

  const submitData = () => {
    let payload = cloneDeep(values);
    // use calculated nutrient values if no manual value added
    for (const nutrient in calculatedNutrients) {
      if (!payload.nutrients[nutrient]) {
        payload.nutrients[nutrient] = calculatedNutrients[nutrient];
      }
    }
    payload.tags = {};
    payload.tags.mealType = keys(pickBy(toggleValues.mealType, Boolean));
    payload.tags.dietType = keys(pickBy(toggleValues.dietType, Boolean));
    payload.tags.intolerances = keys(
      pickBy(toggleValues.intolerances, Boolean),
    );
    payload.tags.cookingStyle = keys(
      pickBy(toggleValues.cookingStyle, Boolean),
    );
    payload.tags.primaryIngredient = keys(
      pickBy(toggleValues.primaryIngredient, Boolean),
    );
    payload.tags.prepTime = keys(pickBy(toggleValues.prepTime, Boolean));
    payload.cookingInstructions = instructionsValue.map(
      (instruction, index) => {
        return {
          num: index + 1,
          step: instruction.step,
        };
      },
    );
    payload.foods = foods;
    payload.foods.forEach((food, i) => {
      payload.foods[i].quantity = payload.foods[i].quantity / payload.yield;
      payload.foods[i] = pick(food, [
        'quantity',
        'measureUnit',
        'food',
        'name',
      ]);
    });
    payload.active = toggleValues.active;
    payload.placeholder = toggleValues.placeholder;
    if (payload.version === 'N/A') {
      // If there is no version specified, we should not save a value for version to the db
      payload = omit(payload, 'version');
    }

    setLoading(true);

    addRecipe(payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Recipe "${result.name}" was created successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setInstructionsValue([{ ...instruction }]);
        setFoods([]);
        setImageUrl(null);
      })
      .catch((err) => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');

        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const failedImportMessage = failedImports.map((item, index) => (
    <ErrorMessage key={index}>
      Name: {item.name} | Esha: {item.esha}
    </ErrorMessage>
  ));

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <Container flexDirection="column">
      <UploadCSV
        setValues={setValues}
        setInstructionsValue={setInstructionsValue}
        setIngredientsValue={setIngredientsValue}
        setFailedImports={setFailedImports}
        setFoods={setFoods}
        setUploadError={setUploadError}
      />
      {failedImports.length > 0 && (
        <ErrorMessageContainer data-test="recipe-upload-csv-error">
          <p>The following ingredients failed to be loaded.</p>
          {failedImportMessage}
        </ErrorMessageContainer>
      )}
      {uploadError && (
        <ErrorMessageContainer data-test="recipe-upload-csv-error">
          {uploadError}
        </ErrorMessageContainer>
      )}
      <RecipeForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        clearNutrientValues={clearNutrientValues}
        ingredientsValue={ingredientsValue}
        setIngredientsValue={setIngredientsValue}
        instructionsValue={instructionsValue}
        setInstructionsValue={setInstructionsValue}
        searchFoods={searchFoods}
        foods={foods}
        setFoods={setFoods}
        calculatedNutrients={calculatedNutrients}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        buttonText="Create Recipe"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

const ErrorMessageContainer = styled.div`
  color: ${colors.error};
  margin: 16px 0;
  padding: 0px;
`;

const ErrorMessage = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
`;

export default AddRecipe;
