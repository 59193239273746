import { createSlice } from '@reduxjs/toolkit';

const initialState = true;

const shouldAnimateSetSlice = createSlice({
  name: 'shouldAnimateSetRecord',
  initialState,
  reducers: {
    setShouldAnimateSetRecord(state, action) {
      return action.payload;
    },
  },
});
export const { setShouldAnimateSetRecord } = shouldAnimateSetSlice.actions;
export default shouldAnimateSetSlice.reducer;
