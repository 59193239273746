import React from 'react';
import { colors } from '../../styleConstants';
import styled from 'styled-components';
import BackIcon from '../../icons/BackIcon';
import MobileDialogHeaderText from './MobileDialogHeaderText';
import FlexContainer from '../../elements/FlexContainer';
import Dialog from '@mui/material/Dialog';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const AddOption = ({ icon, title, name, onClick }) => (
  <AddOptionContainer alignItems="center" onClick={onClick} name={name}>
    <OptionIcon image={icon} />
    <AddOptionLabel>{title}</AddOptionLabel>
  </AddOptionContainer>
);

const AddFitness = ({ open, handleClose, handleBackBtnClick, setScreen }) => {
  const handleClick = (e) => {
    setScreen(e.currentTarget.attributes.name.value);
  };

  return (
    <AddDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <AddDialogHeader justify="flex-start" alignItems="center">
        <StyledBackIcon
          color={colors.primary300}
          handleClick={handleBackBtnClick}
        />
        <MobileDialogHeaderText color={colors.primary050} marginLeft="12px">
          Add Fitness
        </MobileDialogHeaderText>
      </AddDialogHeader>
      <AddDialogBody
        flexDirection="column"
        alignItems="center"
        justify="center"
      >
        <AddOption
          title="Search"
          name="Exercise Search"
          icon={`${fileStorage}/Search.svg`}
          onClick={handleClick}
        />
      </AddDialogBody>
    </AddDialog>
  );
};
const AddDialog = styled(Dialog)`
  background-color: transparent;
  z-index: unset !important;

  .MuiDialog-paperScrollPaper {
    background-color: transparent;
    margin: 0;
    box-shadow: none;
  }
`;
const AddDialogHeader = styled(FlexContainer)`
  margin: 12px;
`;
const AddDialogBody = styled(FlexContainer)``;
const OptionIcon = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  height: 45px;
  width: 50px;
`;
const AddOptionContainer = styled(FlexContainer)`
  box-sizing: border-box;
  padding: 10px;
  border: 1.5px solid ${colors.primary200};
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0 30px 18px 30px;
  width: 100%;
`;
const AddOptionLabel = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
  margin-left: 30px;
`;
const StyledBackIcon = styled(BackIcon)`
  height: 18px;
  width: 18px;
`;
export default AddFitness;
