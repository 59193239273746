import Fraction from 'fraction.js';
import React from 'react';

export const isNumberBetween = (numToTest, a, b) => {
  var min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return numToTest > min && numToTest < max;
};

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const formatIngredientDisplay = (qty, unit) => {
  let quantity;
  if (qty !== 0 && Math.round(qty * 4) / 4 === 0) {
    quantity = 0.5;
  } else {
    quantity = Math.round(qty * 4) / 4;
  }
  if (quantity % 1 !== 0) {
    quantity = new Fraction(quantity).simplify().toFraction(true);
  }

  let measureUnit;
  if (unit === 'Tablespoon') {
    measureUnit = 'Tbsp';
  } else if (unit === 'Teaspoon') {
    measureUnit = 'Tsp';
  } else if (qty > 1 && unit !== 'Each') {
    measureUnit = `${unit}s`;
  } else {
    measureUnit = unit;
  }
  return { quantity, measureUnit };
};

// Used for links associated with the "source" field on recipes or workouts
// If an admin provides a source link that does not include the protocol, the link does not work
// We need to ensure we are adding https to any links that do not already include it.
export const formatAttrLink = (link) => {
  if (link.includes('http')) {
    return link;
  } else {
    return `https://${link}`;
  }
};

export const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};

export const sentenceCase = (text) => {
  // Split text based on sentences. Ensure to use a space after the period so
  // we don't split on decimals.
  let newText = text.split('. ');
  // Trim remaining white space, capitalize first letter, and join with rest of sentence
  newText = newText.map(
    (sentence) =>
      sentence.trim().charAt(0).toUpperCase() + sentence.trim().slice(1),
  );
  // Then rejoin sentences into new string
  return newText.join('. ');
};
