import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, forEach } from 'lodash';
import { colors } from '../styleConstants';
import { formatDate } from '../helpers/date';
import LinearProgress from '@mui/material/LinearProgress';

const MacroTable = ({ color, barSize, mealPlan, date = new Date() }) => {
  const [recommendedFat, setRecommendedFat] = useState(0);
  const [recommendedProtein, setRecommendedProtein] = useState(0);
  const [recommendedCarbs, setRecommendedCarbs] = useState(0);
  const [fatEaten, setFatEaten] = useState(0);
  const [carbsEaten, setCarbsEaten] = useState(0);
  const [proteinEaten, setProteinEaten] = useState(0);
  const [fatEatenValue, setFatEatenValue] = useState(0);
  const [carbsEatenValue, setCarbsEatenValue] = useState(0);
  const [proteinEatenValue, setProteinEatenValue] = useState(0);
  const [fatLeft, setFatLeft] = useState(0);
  const [fatOver, setFatOver] = useState(0);
  const [carbsLeft, setCarbsLeft] = useState(0);
  const [carbsOver, setCarbsOver] = useState(0);
  const [proteinLeft, setProteinLeft] = useState(0);
  const [proteinOver, setProteinOver] = useState(0);

  const defineEaten = (planData) => {
    const recommended = { ...planData.recommended };
    if (recommended) {
      setRecommendedFat(recommended.fat);
      setRecommendedProtein(recommended.protein);
      setRecommendedCarbs(recommended.carbs);
    }
    let fat = 0;
    let carbs = 0;
    let protein = 0;
    if (!isEmpty(planData)) {
      if (planData.recipes.length > 0) {
        forEach(planData.recipes, (recipe) => {
          if (
            recipe.eaten &&
            recipe.recipe &&
            recipe.recipe.nutrients &&
            formatDate(recipe.date) === formatDate(date)
          ) {
            fat = fat + recipe.recipe.nutrients.fat;
            carbs = carbs + recipe.recipe.nutrients.carbohydrates;
            protein = protein + recipe.recipe.nutrients.proteins;
          }
        });
      }
      if (planData.foods.length > 0) {
        forEach(planData.foods, (food) => {
          if (
            food.eaten &&
            food.food &&
            food.food.nutrientsPerUnit &&
            formatDate(food.date) === formatDate(date)
          ) {
            fat = fat + food.food.nutrientsPerUnit.fat * food.quantity;
            carbs =
              carbs + food.food.nutrientsPerUnit.carbohydrates * food.quantity;
            protein =
              protein + food.food.nutrientsPerUnit.proteins * food.quantity;
          }
        });
      }
      /* eslint-disable chai-friendly/no-unused-expressions */
      fat > 0 && recommendedFat
        ? setFatEatenValue(fat / recommendedFat)
        : setFatEatenValue(0);
      carbs > 0 && recommendedCarbs
        ? setCarbsEatenValue(carbs / recommendedCarbs)
        : setCarbsEatenValue(0);
      protein > 0 && recommendedProtein
        ? setProteinEatenValue(protein / recommendedProtein)
        : setProteinEatenValue(0);
      setFatEaten(fat.toFixed(1));
      setCarbsEaten(carbs.toFixed(1));
      setProteinEaten(protein.toFixed(1));
    }
    /* eslint-enable chai-friendly/no-unused-expressions */
  };

  useEffect(() => {
    let fatLeftTemp = recommendedFat - fatEaten || 0;
    setFatLeft(fatLeftTemp.toFixed(1));
    setFatOver(fatEaten / recommendedFat > 1);
  }, [fatEaten, recommendedFat]);

  useEffect(() => {
    let carbsLeftTemp = recommendedCarbs - carbsEaten || 0;
    setCarbsLeft(carbsLeftTemp.toFixed(1));
    setCarbsOver(carbsEaten / recommendedCarbs > 1);
  }, [carbsEaten, recommendedCarbs]);

  useEffect(() => {
    let proteinLeftTemp = recommendedProtein - proteinEaten || 0;
    setProteinLeft(proteinLeftTemp.toFixed(1));
    setProteinOver(proteinEaten / recommendedProtein > 1);
  }, [proteinEaten, recommendedProtein]);

  useEffect(() => {
    defineEaten(mealPlan);
  }, [mealPlan, recommendedFat, recommendedProtein, recommendedCarbs]);

  return (
    <TableContainer>
      <Table>
        <tbody>
          <TR>
            <TH $barSize={barSize}></TH>
            <TH $barSize={barSize}></TH>
            <TH $barSize={barSize}>Eaten</TH>
            <TH $barSize={barSize}>Left</TH>
          </TR>
          <TR>
            <MacroBarTD barSize={barSize}>
              <Progress
                value={proteinEatenValue * 100}
                $over={proteinOver}
                $color={color}
                $barSize={barSize}
                variant="determinate"
                data-test="macroTable-proteinBar"
              />
            </MacroBarTD>
            <MacroTitle $barSize={barSize}>Protein</MacroTitle>
            <TD data-test="macroTable-proteinEaten">{proteinEaten}g</TD>
            <MacroLeft $over={proteinOver} data-test="macroTable-proteinLeft">
              {proteinLeft}g
            </MacroLeft>
          </TR>
          <TR>
            <MacroBarTD barSize={barSize}>
              <Progress
                value={carbsEatenValue * 100}
                $over={carbsOver}
                $color={color}
                $barSize={barSize}
                variant="determinate"
                data-test="macroTable-carbsBar"
              />
            </MacroBarTD>
            <MacroTitle $barSize={barSize}>Carbs</MacroTitle>
            <TD data-test="macroTable-carbsEaten">{carbsEaten}g</TD>
            <MacroLeft $over={carbsOver} data-test="macroTable-carbsLeft">
              {carbsLeft}g
            </MacroLeft>
          </TR>
          <TR>
            <MacroBarTD>
              <Progress
                value={fatEatenValue * 100}
                $over={fatOver}
                $color={color}
                $barSize={barSize}
                variant="determinate"
                data-test="macroTable-fatBar"
              />
            </MacroBarTD>
            <MacroTitle $barSize={barSize}>Fat</MacroTitle>
            <TD data-test="macroTable-fatEaten">{fatEaten}g</TD>
            <MacroLeft $over={fatOver} data-test="macroTable-fatLeft">
              {fatLeft}g
            </MacroLeft>
          </TR>
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0 8px;
`;

const TH = styled.th`
  font-size: 11px;
  line-height: 14px;
  color: ${colors.primary600};
  font-weight: 400;
  text-align: left;
  width: ${(props) => (props.$barSize === 'large' ? '15%' : '25%')};
`;

const TD = styled.td`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary800};
  text-align: left;
`;

const MacroTitle = styled(TD)`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  width: ${(props) => (props.$barSize === 'large' ? '12%' : '33%')};
`;
const MacroBarTD = styled(TD)`
  text-align: left;
`;
const MacroLeft = styled(TD)`
  color: ${(props) => (props.$over ? colors.error : colors.primary400)};
`;

const TR = styled.tr`
  width: 100%;

  > *:not(:first-child) {
    padding-left: 24px;
  }
`;

const Progress = styled(LinearProgress)`
  width: ${(props) => (props.$barSize === 'large' ? '146px' : '80px')};
  height: 8px;
  border: none;
  border-radius: 4px;
  background-color: ${colors.primary100};

  & .MuiLinearProgress-bar {
    background-color: ${(props) =>
      props.$over
        ? colors.error
        : props.$color === 'teal'
          ? colors.secondary500
          : colors.hliteone400};
    border-radius: 4px;
  }
`;

export default MacroTable;
