import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { capitalize } from 'lodash';

const MealPlanCard = ({ mealPlan, setMealPlan, setScreen, ...props }) => {
  const handleClick = (e) => {
    setMealPlan(mealPlan);
    setScreen('Meal Plan Page');
  };

  return (
    <CardContainer
      flexDirection="column"
      alignItems="center"
      justify="center"
      onClick={handleClick}
      data-test="mealModal-mealPlanCard"
    >
      <TitleContainer>
        <MealTitle>{mealPlan.name}</MealTitle>
      </TitleContainer>
      {!mealPlan.active && (
        <InactiveContainer flexDirection="column">
          <Info data-test="mealPlanCard-inactive">Inactive</Info>
        </InactiveContainer>
      )}
      {mealPlan?.tags?.dietType && (
        <InfoContainer flexDirection="column">
          <Info data-test="mealPlanCard-dietType">
            {capitalize(mealPlan?.tags?.dietType)}
          </Info>
        </InfoContainer>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  height: 124px;
  width: 192px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`;

const TitleContainer = styled(FlexContainer)`
  padding: 0 16px;
  text-align: center;
`;

const MealTitle = styled.h4`
  color: ${colors.primary800};
`;

const InactiveContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const InfoContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const Info = styled.p`
  color: ${colors.primary500};
  font-size: 13px;
  line-height: 8px;
  margin: 4px;
`;

export default MealPlanCard;
