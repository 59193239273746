import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api/api';

const initialState = 'nutriology-transformation-apr-8-2024';

const circleSlugSlice = createSlice({
  name: 'circleSlug',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(getCircleSlug.fulfilled, editCircleSlug.fulfilled),
      (state, action) => {
        return action.payload;
      },
    );
  },
});

export const getCircleSlug = createAsyncThunk(
  'circle/getCircleSlug',
  async (_, { rejectWithValue }) => {
    try {
      const slug = await apiCall('get', '/circle_slug', { name: 'circle' });
      return slug;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editCircleSlug = createAsyncThunk(
  'circle/editCircleSlug',
  async (slug, { rejectWithValue }) => {
    try {
      const updatedSlug = await apiCall('put', `/circle_slug`, {
        name: 'circle',
        slug,
      });
      return updatedSlug;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default circleSlugSlice.reducer;
