import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isMobile } from '../helpers/utils';
import { useSelector } from 'react-redux';

const CheckAuth = () => {
  const mapState = (state) => ({
    isAuthenticated: state.currentUser.isAuthenticated,
    user: state.currentUser,
  });
  const { isAuthenticated } = useSelector(mapState);

  return isAuthenticated && localStorage.jwtToken && isMobile() ? (
    <Navigate to="/app/explore" />
  ) : isAuthenticated && localStorage.jwtToken ? (
    <Navigate to="/app" />
  ) : (
    <Outlet />
  );
};

export default CheckAuth;
