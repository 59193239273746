import React, { useEffect } from 'react';
import { pickBy, keys, reduce } from 'lodash';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import CheckBox from '../elements/CheckBox';
import Button from '../elements/Button';
import useToggles from '../hooks/useToggles';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../store/general/currentUserSlice';

const NotifOptions = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.currentUser.user._id);
  const notificationPreferences = useSelector((state) => {
    return reduce(
      state.currentUser.user.notificationPreferences,
      (result, value) => {
        return (result[value] = true), result; /* eslint-disable-line */
      },
      {},
    );
  });

  const { handleToggle, setToggleValues, toggleValues } = useToggles(
    notificationPreferences,
  );

  useEffect(() => {
    setToggleValues(notificationPreferences);
  }, [notificationPreferences, setToggleValues]);

  const handleSubmit = () => {
    dispatch(
      updateUser({
        id: userId,
        notificationPreferences: keys(pickBy(toggleValues, Boolean)),
      }),
    );
  };

  return (
    <FlexContainer flexDirection="column">
      <Option>
        <CheckBox
          checked={toggleValues.newPromos || false}
          value="newPromos"
          label="I would like to receive notifications about new promotions."
          onChange={handleToggle()}
          name="newPromos"
        />
      </Option>
      <Option>
        <CheckBox
          checked={toggleValues.mobileNotifs || false}
          value="mobileNotifs"
          label="I would like to receive mobile notifications."
          onChange={handleToggle()}
          name="mobileNotifs"
        />
      </Option>
      <Option>
        <CheckBox
          checked={toggleValues.emailNotifs || false}
          value="emailNotifs"
          label="I would like to receive email notifications."
          onChange={handleToggle()}
          name="emailNotifs"
        />
      </Option>
      <Option>
        <CheckBox
          checked={toggleValues.partnerPromos || false}
          value="partnerPromos"
          label="I would like to receive promotions from partners."
          onChange={handleToggle()}
          name="partnerPromos"
        />
      </Option>
      <ButtonContainer>
        <Button
          buttonText="Save Changes"
          buttonSize="small"
          handleClick={handleSubmit}
        />
      </ButtonContainer>
    </FlexContainer>
  );
};

const Option = styled(FlexContainer)`
  margin-bottom: 24px;
  align-items: center;
`;

const ButtonContainer = styled(FlexContainer)`
  flex-basis: content;
  float: left;
  margin-top: 24px;
`;

export default NotifOptions;
