import React, { useState } from 'react';
import ExploreSection from './ExploreSection';
import { fitnessCategories } from '../helpers/exploreCategories';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { trackEvent } from '../integrations/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { addWorkoutToFitnessPlan } from '../store/fitnessplan/selectedFitnessPlanSlice';

const FitnessSection = (props) => {
  const { open, launchModal, closeModal } = useModal(false);
  const dispatch = useDispatch();
  const fitnessPlan = useSelector((state) => state.activeFitnessPlan);
  const [category, setCategory] = useState({});

  const handleClick = (category) => {
    setCategory(category);
    launchModal();
  };

  async function addWorkoutToUserFitnessPlan(params) {
    trackEvent(`Add Workout (Explore Page)`, {
      workout: params.workout,
    });
    dispatch(
      addWorkoutToFitnessPlan({ fitnessPlanId: fitnessPlan._id, params }),
    );
  }

  return (
    <>
      <ExploreSection
        sectionName="Explore Workouts"
        items={fitnessCategories}
        handleClick={handleClick}
        data-test="explore-fitness"
      />
      <ModalContainer
        addWorkout={addWorkoutToUserFitnessPlan}
        open={open}
        category={category}
        handleClose={closeModal}
        initialScreen="Explore Modal"
        context={{ type: 'fitness' }}
        {...props}
      />
    </>
  );
};

export default FitnessSection;
