import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import FoodChipSmall from '../../mealPlan/food/FoodChipSmall';
import useModal from '../../hooks/useModal';
import AddIcon from '../../icons/AddIcon';
import ModalContainer from '../../sharedModals/ModalContainer';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from 'lodash';

const IngredientCategory = ({
  catName,
  ingredients,
  handleRemove,
  addIngredient,
  loading,
  ...props
}) => {
  const { open, launchModal, closeModal } = useModal(false);
  const ingredientList = ingredients.map((ingredient, i) => {
    return (
      <IngredientContainer key={ingredient._id || i} alignItems="center">
        <FoodChipSmall
          food={ingredient}
          instanceId={ingredient._id}
          variant="admin"
          handleRemove={handleRemove}
          {...props}
        />
      </IngredientContainer>
    );
  });
  return (
    <Category flexDirection="column" data-test="ingMap-category">
      <Header>{capitalize(catName)}</Header>
      {loading ? (
        <CircularProgress size={48} color="inherit" />
      ) : (
        <IngredientsList
          flexDirection="column"
          data-test={`ingList-${catName}`}
        >
          {ingredientList}
        </IngredientsList>
      )}
      <AddIcon
        labelText="Add Food"
        orientation="row"
        pink="true"
        onClick={launchModal}
        data-test={`ingMap-${catName}-add`}
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'recipe',
          category: catName,
        }}
        handleClose={closeModal}
        initialScreen="Meal Search"
        addFood={addIngredient}
        {...props}
      />
    </Category>
  );
};

const Category = styled(FlexContainer)`
  flex-direction: column;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid black;
`;

const Header = styled.h3`
  color: ${colors.primary800};
  margin-bottom: 16px;
`;
const IngredientContainer = styled(FlexContainer)`
  position: relative;
`;

const IngredientsList = styled(FlexContainer)`
  margin-bottom: 8px;
  > * {
    margin-bottom: 8px;
  }
`;

export default IngredientCategory;
