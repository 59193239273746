import { apiCall } from './api';

const trackPopulate = [
  {
    path: 'fitnessPlans.fitnessPlan',
  },
  {
    path: 'backTrack',
  },
  {
    path: 'remainTrack',
  },
  {
    path: 'nextTrack',
  },
  {
    path: 'nextLevel',
  },
];

export function getTrack(id, params = {}) {
  params.populate = trackPopulate;
  return apiCall('get', `/track/${id}`, params);
}

export function getTracks(params = {}) {
  params.populate = trackPopulate;
  return apiCall('get', `/track`, params);
}

export function addTrack(params) {
  return apiCall('post', `/track`, params);
}

export function editTrack(id, params) {
  return apiCall('put', `/track/${id}`, params);
}

export function autoAssignTrack(user, params) {
  return apiCall('post', `/track/assign_one/${user}`, params);
}

export function manualAssignTrack(userId, trackId, params) {
  return apiCall('post', `/track/${trackId}/user/${userId}`, params);
}

export function switchTrack(userId, trackId, params) {
  return apiCall('post', `/track/${trackId}/switch/${userId}`, params);
}

export function searchTracks(params) {
  return apiCall('get', `/track/search/`, params);
}

export function getTrackReferences(id, params = {}) {
  params.query = { nextTrack: id };
  return apiCall('get', `/track`, params);
}

export function replaceNextTrackOnTrack(track, oldTrack, newTrack) {
  return apiCall(
    'put',
    `/track/${track}/replace_track/${oldTrack}/${newTrack}`,
  );
}

export function swapUserTracks(oldTrack, newTrack, params) {
  return apiCall('post', `/track/swap_track/${oldTrack}/${newTrack}`, params);
}
