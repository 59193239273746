import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveUserFitnessPlan } from '../store/fitnessplan/activeFitnessPlanSlice';
import { getUserTrack } from '../store/fitnessplan/trackSlice';
import { getActiveUserMealPlan } from '../store/mealplan/activeMealplanSlice';
import { getShoppingLists } from '../store/shoppinglist/shoppingListSlice';
import { getDefaultPlaceholder } from '../store/mealplan/defaultPlaceholderSlice';
import { getCircleSlug } from '../store/general/circleSlugSlice';

const mapState = (state) => ({
  isVerified: state.currentUser.user.isVerified,
  isAuthenticated: state.currentUser.isAuthenticated,
  intakeComplete: state.currentUser.user.intakeComplete,
  isAdmin: state.currentUser.user.isAdmin,
  user: state.currentUser.user,
});

const RequireAuth = () => {
  const dispatch = useDispatch();
  const { isVerified, intakeComplete, isAuthenticated, isAdmin, user } =
    useSelector(mapState);
  const intakeOrAdmin = isAdmin || intakeComplete;

  const location = useLocation();

  // ensure active/current meal and fitness plans set on reload
  useEffect(() => {
    if (isAuthenticated && isVerified && intakeOrAdmin) {
      dispatch(getActiveUserMealPlan(user._id));
      dispatch(getActiveUserFitnessPlan(user._id));
      if (
        !['/intake/payment', '/app/shoppinglist'].includes(location.pathname)
      ) {
        dispatch(getShoppingLists({}));
      }
      if (user.fitnessTrack) {
        dispatch(getUserTrack(user));
      }
      dispatch(getDefaultPlaceholder());
      dispatch(getCircleSlug());
    }
  }, []);

  return !isAuthenticated ? <Navigate to="/login" /> : <Outlet />;
};

export default RequireAuth;
