import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useFormSubmit from '../../hooks/useFormSubmit';
import Button from '../../elements/Button';
import { colors } from '../../styleConstants';
import TextInput from '../../elements/TextInput';
import FlexContainer from '../../elements/FlexContainer';
import CircularProgress from '@mui/material/CircularProgress';
import AlertStack from '../../elements/AlertStack';
import { authUser } from '../../store/general/currentUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';
import { isMobile } from '../../helpers/utils';

const LoginForm = ({ getButtonState, ...props }) => {
  const [values, setValues, handleChange] = useOutletContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const [successOpen, setSuccessOpen] = useState(
    location?.state?.message?.length > 0,
  );
  const [showForgot, setShowForgot] = useState(false);
  const [loading, setLoading] = useState(false);
  const reduxErrors = useSelector((state) => state.errors);

  const submitData = async () => {
    setLoading(true);
    dispatch(authUser({ type: 'login', userData: values }));
  };

  useEffect(() => {
    if (reduxErrors.length) {
      setLoading(false);
      setShowForgot(true);
    }
  }, [reduxErrors]);

  let { handleSubmit, errors } = useFormSubmit(
    submitData,
    values,
    setValues,
    'login',
  );

  return (
    <Container flexDirection="column">
      <FieldsContainer flexDirection="column">
        <TextInput
          id="email"
          name="email"
          autoComplete="username"
          type="email"
          onChange={handleChange}
          value={values.email || ''}
          placeholder="Email"
          label="Email"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Email')}
          error={errors.email}
          disabled={loading}
          flat="true"
          theme={isMobile() ? 'light' : 'dark'}
          data-test="login-email"
        />
        <TextInput
          id="password"
          name="password"
          autoComplete="new-password"
          onChange={handleChange}
          type="password"
          value={values.password || ''}
          placeholder="Password"
          label="Password"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Password')}
          error={errors.password}
          disabled={loading}
          flat="true"
          theme={isMobile() ? 'light' : 'dark'}
          data-test="login-password"
        />
      </FieldsContainer>
      <StyledButton
        type="submit"
        buttonText="Log In"
        buttonSize="large"
        pink="true"
        backgrounddark="true"
        disabled={loading}
        onClick={handleSubmit}
        startIcon={
          loading && (
            <CircularProgress
              size={24}
              color="inherit"
              data-test="loading-spinner"
            />
          )
        }
        data-test="auth-submit"
      />
      <FlexContainer style={{ gap: 16 }} flexDirection="column">
        <FlexContainer style={{ gap: 4 }} justify="center">
          <LinkText>Need an account?</LinkText>
          <StyledLink to="/signup" data-test="link-to-signup">
            Sign up now
          </StyledLink>
        </FlexContainer>
        {showForgot && (
          <FlexContainer justify="center">
            <StyledLink to="/reset" data-test="link-to-reset">
              Forgot your password?
            </StyledLink>
          </FlexContainer>
        )}
      </FlexContainer>
      {location?.state?.message?.length > 0 && (
        <AlertStack
          messages={location?.state?.message}
          type="success"
          variant={isMobile() ? 'filled' : 'outlined'}
          open={successOpen}
          handleClose={() => setSuccessOpen(false)}
          data-test="auth-submit-success"
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  gap: 32px;
  width: 100%;
`;

const FieldsContainer = styled(FlexContainer)`
  gap: 14px;
  width: 100%;
`;

const LinkText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${() => (isMobile() ? colors.primary800 : colors.primary050)};
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-decoration: underline;
  color: ${() => (isMobile() ? colors.secondary600 : colors.primary050)};
`;

const StyledButton = styled(Button)`
  height: ${() => (isMobile() ? '51px' : null)};
  text-transform: ${() => (isMobile() ? 'inherit' : null)};
  font-weight: ${() => (isMobile() ? '700' : null)};
  font-size: ${() => (isMobile() ? '17x' : null)};
  line-height: ${() => (isMobile() ? '20px' : null)};
`;

export default LoginForm;
