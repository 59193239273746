import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { formatDate } from '../../helpers/date';
import { apiCall } from '../../services/api/api';
import { DateTime } from 'luxon';
import {
  addFoodToMealPlan,
  addRecipeToMealPlan,
  addMultipleRecipesToMealPlan,
  updateUserMealPlan,
  swapMealPlanItem,
} from './selectedMealPlanSlice';

const initialState = {
  id: null,
  type: 'Weight Loss',
  planLength: 7,
  recommendedCalories: 1800,
  active: true,
  recipes: [],
  foods: [],
  water: [],
};

const blankPlan = {
  startDate: formatDate(DateTime.now().startOf('week')),
  endDate: formatDate(DateTime.now().endOf('week')),
  planLength: 7,
  type: 'Weight Loss',
  recommendedCalories: 2000,
};

const activeMealplanSlice = createSlice({
  name: 'activeMealPlan',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getActiveUserMealPlan.fulfilled, (state, action) => {
        return action.payload;
      })
      .addMatcher(
        isAnyOf(
          addRecipeToMealPlan.fulfilled,
          addFoodToMealPlan.fulfilled,
          addMultipleRecipesToMealPlan.fulfilled,
          updateUserMealPlan.fulfilled,
          swapMealPlanItem.fulfilled,
        ),
        (state, action) => {
          if (
            formatDate(action.payload.startDate) ===
            formatDate(DateTime.now().startOf('week'))
          ) {
            return action.payload;
          }
          return state;
        },
      );
  },
});

export const getActiveUserMealPlan = createAsyncThunk(
  'mealPlan/getActiveUserMealPlan',
  async (userId, { rejectWithValue }) => {
    try {
      const date = new Date();
      const params = {
        query: {
          user: userId,
          startDate: formatDate(DateTime.fromJSDate(date).startOf('week')),
          endDate: formatDate(DateTime.fromJSDate(date).endOf('week')),
        },
      };
      let mealPlan = await apiCall('get', `/mealplanuser`, params);
      if (!mealPlan[0]) {
        mealPlan = await apiCall('post', `/mealplanuser`, blankPlan);
      } else {
        mealPlan = mealPlan[0];
      }
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default activeMealplanSlice.reducer;
