import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import { colors } from '../../styleConstants';

import AssignPlan from './AssignPlan';
import AssignTrack from './AssignTrack';
import FindUsers from './FindUsers';
import DesignateAdmin from './DesignateAdmin';

const AdminUser = (props) => {
  const [actionType, setActionType] = useState('findUsers');

  const handleChange = (e) => {
    setActionType(e.target.value);
  };

  return (
    <Container flexDirection="column">
      <H2 data-test="admin-title-user">User Admin</H2>
      <H3>Action Type</H3>
      <FlexContainer flexDirection="row" justify="space-around" wrap="wrap">
        <RadioInput
          checked={actionType === 'findUsers'}
          value="findUsers"
          label="Find Users"
          onChange={handleChange}
          name="recordType"
          data-test="user-radio-find"
        />
        <RadioInput
          checked={actionType === 'assignMealPlan'}
          value="assignMealPlan"
          label="Assign Meal Plan"
          onChange={handleChange}
          name="recordType"
          data-test="user-radio-assignMeal"
        />
        <RadioInput
          checked={actionType === 'assignFitnessPlan'}
          value="assignFitnessPlan"
          label="Assign Fitness Plan"
          onChange={handleChange}
          name="recordType"
          data-test="user-radio-assignFitness"
        />
        <RadioInput
          checked={actionType === 'assignTrack'}
          value="assignTrack"
          label="Assign Track"
          onChange={handleChange}
          name="recordType"
          data-test="user-radio-assignTrack"
        />
        <RadioInput
          checked={actionType === 'designateAdmin'}
          value="designateAdmin"
          label="Designate Admin"
          onChange={handleChange}
          name="recordType"
          data-test="user-radio-designateAdmin"
        />
      </FlexContainer>
      {actionType === 'assignMealPlan' ? (
        <AssignPlan type="mealPlan" />
      ) : actionType === 'assignFitnessPlan' ? (
        <AssignPlan type="fitnessPlan" />
      ) : actionType === 'assignTrack' ? (
        <AssignTrack />
      ) : actionType === 'findUsers' ? (
        <FindUsers />
      ) : actionType === 'designateAdmin' ? (
        <DesignateAdmin />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  max-width: 75%;
  margin-left: 25px;
  padding: 30px;
`;

const H2 = styled.h2`
  text-align: center;
  font-size: 28px;
  margin: 15px;
  color: ${colors.secondary600};
`;
const H3 = styled.h3`
  text-align: center;
  margin: 15px;
`;

export default AdminUser;
