import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import MobileTopNav from '../universal/MobileTopNav';
import { useSelector } from 'react-redux';

const CirclePage = () => {
  const circleSlug = useSelector((state) => state.circleSlug);
  return (
    <div style={{ height: '100vh' }}>
      <MobileTopNav arrowsDisabled />
      <Container justify="center" alignItems="center" flexDirection="column">
        <iframe
          title="circle-community-mobile"
          style={{
            border: 0,
            height: '100%',
            width: '100%',
            backgroundColor: '#fff',
            outline: 'none',
          }}
          src={`https://community.nutriology.io/c/${circleSlug}?iframe=true&automatic_login=true`}
        />
      </Container>
    </div>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-bottom: 64px;
  padding-top: 44px;
`;

export default CirclePage;
