import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import { useSelector, useDispatch } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import FlexContainer from '../../elements/FlexContainer';
import Truncate from '../../utils/Truncate.tsx';
import LeftoverIcon from '../../icons/LeftoverIcon';
import LogIcon from '../../icons/LogIcon';
import { useNavigate } from 'react-router-dom';
import RemoveXIcon from '../../icons/RemoveXIcon';
import { setMealPlanRecipeProperty } from '../../store/mealplan/selectedMealPlanSlice';
import { getImageURL } from '../../services/api/api';
import { trackEvent } from '../../integrations/analytics';

const RecipeChipSmall = ({
  recipe,
  instanceId,
  variant,
  toggleLeftover,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.selectedMealPlan);

  async function toggleEaten(event) {
    event.stopPropagation();
    trackEvent('Logged Recipe (Dashboard)', {
      recipe: recipe,
      eaten: !recipe.eaten,
    });
    dispatch(
      setMealPlanRecipeProperty({
        mealPlan,
        recipe,
        property: 'eaten',
        value: !recipe.eaten,
      }),
    );
  }

  const handleLeftover = (e) => {
    e.preventDefault();
    toggleLeftover(recipe);
  };

  const recipeImageURL = (ratio = 1) => {
    const width = 32 * ratio;
    const height = 32 * ratio;
    if (recipe.recipe.imageUrl) {
      return getImageURL(
        recipe.recipe.imageUrl,
        `resizing_type:fill/height:${height}/width:${width}`,
      );
    }
  };

  const handleLink = () => {
    if (mealPlan) {
      trackEvent('Clicked Recipe or Food Name (Dashboard)', {
        recipe: recipe._id,
      });
      navigate(`/app/mealplan/${mealPlan._id}/recipe/${recipe._id}`);
    }
  };

  // Necessary because leftover banner is positioned absolute and will overlap other chips otherwise
  const height = recipe?.leftover ? '54px' : '38px';

  return (
    <FlexContainer
      style={{ height: height, width: '100%' }}
      alignItems="flex-end"
    >
      <ChipContainer
        alignItems="center"
        leftover={recipe.leftover}
        deleted={variant === 'admin' && recipe.recipe.isDeleted}
        data-test="recipe-chip-small"
      >
        {recipe.leftover && (
          <LabelContainer
            justify="center"
            alignItems="center"
            data-test="leftover-banner"
          >
            <LeftoverLabel>Leftovers</LeftoverLabel>
          </LabelContainer>
        )}
        <Transparency alignItems="center">
          <Avatar
            alt="avatar"
            src={recipeImageURL()}
            srcSet={`${recipeImageURL(2)} 2x`}
          />
          <Label alt={recipe.recipe.name} onClick={handleLink}>
            <Truncate>{recipe.recipe.name}</Truncate>
          </Label>
        </Transparency>
        {variant === 'dashboard' ? (
          <LogIcon
            hideLabel
            small
            logged={recipe.eaten}
            toggleSelect={toggleEaten}
            data-test="recipe-chip-log"
          />
        ) : variant === 'admin' ? (
          <AdminIconsContainer>
            <LeftOverContainer selected={recipe.leftover}>
              <LeftoverIcon
                selected={recipe.leftover}
                toggleSelect={handleLeftover}
              />
            </LeftOverContainer>
            <RemoveXIcon
              size="24px"
              data-name={instanceId || ''}
              data-type="recipe"
              onClick={props.handleRemove}
              data-test="meal-card-delete"
            />
          </AdminIconsContainer>
        ) : null}
      </ChipContainer>
    </FlexContainer>
  );
};

const ChipContainer = styled(FlexContainer)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: ${(props) =>
    props.leftover
      ? `1px solid ${colors.secondary600}`
      : props.deleted
        ? '1px solid red'
        : `1px solid ${colors.primary300}`};
  border-radius: ${(props) => (props.leftover ? '20px 0 20px 20px' : '20px')};
  height: 38px;
  width: 100%;
  padding: 0px 4px;
  position: relative;

  & .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
`;

const Transparency = styled(FlexContainer)`
  height: 100%;
  width: 100%;
`;

const Label = styled.div`
  color: ${colors.primary800};
  font-size: 15px;
  margin-left: 8px;
  text-align: left;
  width: 100%;

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const AdminIconsContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 12px;
  }
`;

const LeftOverContainer = styled(FlexContainer)`
  visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};

  ${ChipContainer}:hover & {
    visibility: visible;
  }
`;

const LabelContainer = styled(FlexContainer)`
  position: absolute;
  top: -16px;
  right: -1px;
  border-radius: 4px 4px 0px 0px;
  width: 64px;
  height: 16px;
  background-color: ${colors.secondary600};
`;

const LeftoverLabel = styled.p`
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: #fff;
`;

export default RecipeChipSmall;
