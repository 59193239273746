import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';

import Meal from './Meal';

const MealList = ({ recipes, foods, date, editable, ...props }) => {
  const breakfastRecipes = recipes.filter(
    (recipe) => recipe.mealType === 'Breakfast',
  );
  const lunchRecipes = recipes.filter((recipe) => recipe.mealType === 'Lunch');
  const dinnerRecipes = recipes.filter(
    (recipe) => recipe.mealType === 'Dinner',
  );
  const snackRecipes = recipes.filter((recipe) => recipe.mealType === 'Snack');

  const breakfastFoods = foods.filter((food) => food.mealType === 'Breakfast');
  const lunchFoods = foods.filter((food) => food.mealType === 'Lunch');
  const dinnerFoods = foods.filter((food) => food.mealType === 'Dinner');
  const snackFoods = foods.filter((food) => food.mealType === 'Snack');

  return (
    <MealsContainer flexDirection="column" $workoutHeight={props.workoutHeight}>
      <Meal
        date={date}
        mealTitle="Breakfast"
        recipes={breakfastRecipes}
        foods={breakfastFoods}
        openModal={props.openModal}
        editable={editable}
        {...props}
      />
      <Meal
        date={date}
        mealTitle="Lunch"
        recipes={lunchRecipes}
        foods={lunchFoods}
        openModal={props.openModal}
        editable={editable}
        {...props}
      />
      <Meal
        date={date}
        mealTitle="Dinner"
        recipes={dinnerRecipes}
        foods={dinnerFoods}
        openModal={props.openModal}
        editable={editable}
        {...props}
      />
      <Meal
        date={date}
        mealTitle="Snack"
        recipes={snackRecipes}
        foods={snackFoods}
        openModal={props.openModal}
        editable={editable}
        {...props}
      />
    </MealsContainer>
  );
};

const MealsContainer = styled(FlexContainer)`
  height: calc(100% - ${(props) => props.$workoutHeight}px - 83px);
  overflow: auto;
`;

export default MealList;
