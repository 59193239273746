import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = (state) => ({
  isAdmin: state.currentUser.user.isAdmin,
});

const RequireAdmin = () => {
  const { isAdmin } = useSelector(mapState);
  return isAdmin ? <Outlet /> : <Navigate to="/app" />;
};

export default RequireAdmin;
