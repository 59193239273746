import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const MealPlanIcon = (props) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path d="M2.65,17.75h0a1.08,1.08,0,0,1-.14-1.51L11.68,6.13l.75.64L4.17,17.61A1.08,1.08,0,0,1,2.65,17.75Z" />
    <path d="M12.62,8.74A3,3,0,0,1,9.65,6a2.94,2.94,0,0,1,.67-2.19L13.74.08l.74.68-3.4,3.73a2,2,0,0,0,.27,2.79A2,2,0,0,0,14.17,7l3-4,.79.61L15,7.65A3,3,0,0,1,12.62,8.74Z" />
    <rect
      x="9.67"
      y="4.27"
      width="7.54"
      height="1"
      transform="matrix(0.64, -0.77, 0.77, 0.64, 1.18, 12.05)"
    />
    <path d="M2.66,2.27h0A4.13,4.13,0,0,1,5.6,3.86,4.63,4.63,0,0,1,6.88,6.51,1.9,1.9,0,0,1,6.14,8a1.93,1.93,0,0,1-1.27.51A4,4,0,0,1,2.11,6.81,4.51,4.51,0,0,1,1,4.31a2,2,0,0,1,.56-1.68,1.65,1.65,0,0,1,1.09-.36m0-1a2.61,2.61,0,0,0-1.73.6C-.46,3-.27,5.54,1.35,7.46,2.6,8.94,3.81,9.55,4.87,9.55A3,3,0,0,0,6.79,8.8c1.38-1.17,1.66-3.12-.42-5.58A5.08,5.08,0,0,0,2.66,1.27Z" />
    <path d="M6.5,8.45a1.26,1.26,0,0,1-.56.26l6.8,8.8a1.08,1.08,0,0,0,1.65-1.39L6.86,8A1.36,1.36,0,0,1,6.5,8.45Z" />
  </Icon>
);

const Icon = styled.svg`
  width: 18px;
  height: 18px;

  & path,
  rect {
    fill: ${colors.primary500};
  }
`;

export default MealPlanIcon;
