import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { isMobile } from '../../helpers/utils';
import { getRecentRecipes } from '../../services/api/recipe';
import { formatDate } from '../../helpers/date';

import DuplicateWeb from './DuplicateWeb';
import DuplicateMobile from './DuplicateMobile';

const Duplicate = ({ setRecipe, setScreen, ...props }) => {
  const userId = useSelector((state) => state.currentUser.user._id);
  const today = formatDate(new Date());

  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState({
    yesterday: [],
    twoDaysAgo: [],
    threeDaysAgo: [],
    fourDaysAgo: [],
    fiveDaysAgo: [],
  });

  useEffect(() => {
    const params = {
      userId,
      date: today,
    };
    getRecentRecipes(params).then((results) => {
      if (results) {
        setRecipes(results);
      } else {
        // TODO: Add a "no results found" error message
        // https://app.clickup.com/t/2ngapuv
      }
    });
  }, [userId, today]);

  useEffect(() => {
    const recipeDays = {
      yesterday: [],
      twoDaysAgo: [],
      threeDaysAgo: [],
      fourDaysAgo: [],
      fiveDaysAgo: [],
    };

    recipes.forEach((recipe) => {
      if (
        DateTime.fromISO(recipe.date).toFormat('yyyy-MM-dd') ===
        DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
      ) {
        recipeDays.yesterday.push(recipe.recipe);
      } else if (
        DateTime.fromISO(recipe.date).toFormat('yyyy-MM-dd') ===
        DateTime.now().minus({ days: 2 }).toFormat('yyyy-MM-dd')
      ) {
        recipeDays.twoDaysAgo.push(recipe.recipe);
      } else if (
        DateTime.fromISO(recipe.date).toFormat('yyyy-MM-dd') ===
        DateTime.now().minus({ days: 3 }).toFormat('yyyy-MM-dd')
      ) {
        recipeDays.threeDaysAgo.push(recipe.recipe);
      } else if (
        DateTime.fromISO(recipe.date).toFormat('yyyy-MM-dd') ===
        DateTime.now().minus({ days: 4 }).toFormat('yyyy-MM-dd')
      ) {
        recipeDays.fourDaysAgo.push(recipe.recipe);
      } else if (
        DateTime.fromISO(recipe.date).toFormat('yyyy-MM-dd') ===
        DateTime.now().minus({ days: 5 }).toFormat('yyyy-MM-dd')
      ) {
        recipeDays.fiveDaysAgo.push(recipe.recipe);
      }
    });
    setFilteredRecipes(recipeDays);
  }, [recipes]);

  if (isMobile()) {
    // mobile version
    return (
      <DuplicateMobile
        filteredRecipes={filteredRecipes}
        setScreen={setScreen}
        setRecipe={setRecipe}
        {...props}
      />
    );
  } else {
    // web version
    return (
      <DuplicateWeb
        filteredRecipes={filteredRecipes}
        setScreen={setScreen}
        setRecipe={setRecipe}
        {...props}
      />
    );
  }
};

export default Duplicate;
