import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import { capitalize } from 'lodash';
import { getUnitList } from '../../helpers/units';

import TextInput from '../../elements/TextInput';
import TextBox from '../../elements/TextBox';
import Dropdown from '../../elements/Dropdown';
import RemoveXIcon from '../../icons/RemoveXIcon';
import AddRecipeImageMobile from '../../elements/AddRecipeImageMobile';
import AddIcon from '../../icons/AddIcon';
import CircularProgress from '@mui/material/CircularProgress';
import FractionInput from '../../elements/FractionInput';
import Button from '../../elements/Button';
import StaticSearchBar from '../../elements/StaticSearchBar';
import MobileSearchModal from '../../universal/MobileSearchModal';
import RecipeNutritionContainer from '../../mealPlan/recipe/RecipeNutritionContainer';

const CustomRecipeMobile = ({
  values,
  foods,
  handleChange,
  handleValueChanged,
  handleSubmit,
  handleInstructionChange,
  handleUnitsChanged,
  handleQuantityValueChange,
  handleDeleteFood,
  addInstruction,
  removeInstruction,
  instructionsValue,
  ingredientsValue,
  setIngredientsValue,
  calculatedNutrients,
  handleImage,
  handleChangeImage,
  inputFile,
  imageUrl,
  status,
  message,
  loading,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (initialSearchTerm) => {
    setSearchTerm(initialSearchTerm);
    handleOpenModal();
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSearchTerm(' ');
    setOpenModal(false);
  };

  const instructionsRows = instructionsValue.map((instruction, index) => {
    const stepNum = `step-${index}`;
    return (
      <InstructionRow key={stepNum}>
        <TextBox
          id={stepNum}
          name={stepNum}
          data-name="step"
          data-idx={index}
          onChange={handleInstructionChange}
          value={instructionsValue[index].step || ''}
          label={`Step #${index + 1}`}
          defaultHeight="50px"
          width="90%"
          data-test="newRecipe-instruction"
        />
        <StyledRemoveXIcon
          onClick={() => removeInstruction(instruction)}
          data-test="newRecipe-removeInstruction"
          small="true"
          color={colors.hlitetwo400}
        />
      </InstructionRow>
    );
  });

  const ingredientRows = foods.map((food, index) => {
    const ingNum = `ing-${index}`;
    const unitList = getUnitList(food.measureUnit);
    return (
      <FlexContainer flexDirection="column" key={`${food._id}-${index}`}>
        <IngredientName data-test="newRecipe-ingredientName">
          {capitalize(food.verboseName)}
        </IngredientName>
        <FlexContainer justify="space-between" alignItems="center">
          <FractionInput
            id={ingNum}
            name={ingNum}
            data-idx={index}
            data-name="quantity"
            width="75px"
            value={foods[index].quantity || 1}
            onValueChanged={handleQuantityValueChange}
            data-test="newRecipe-ingQuantity"
          />
          <Dropdown
            width="150px"
            options={unitList || ['Oz']}
            data-idx={index}
            data-name="units"
            value={{
              value: foods[index].measureUnit,
              label: foods[index].measureUnit,
            }}
            onChange={(value) =>
              handleUnitsChanged('units', index, value.label)
            }
            data-test="newRecipe-ingUnits"
          />
          <RemoveXIcon
            onClick={() => handleDeleteFood(food)}
            data-test="newRecipe-removeIng"
            small="true"
            color={colors.hlitetwo400}
          />
        </FlexContainer>
      </FlexContainer>
    );
  });

  return (
    <CustomRecipeContainer flexDirection="column">
      <ImageContainer flexDirection="column">
        <input
          hidden
          ref={inputFile}
          type="file"
          name="image"
          onChange={handleChangeImage}
        />
        <AddRecipeImageMobile src={imageUrl} onClick={handleImage} />
      </ImageContainer>
      <FieldsContainer flexDirection="column">
        <TextInput
          label="Recipe Name"
          id="name"
          name="name"
          onChange={handleChange}
          placeholder="Recipe Name"
          value={values.name || ''}
          data-test="newRecipe-recipeName"
        />
        <FlexContainer alignItems="flex-end" style={{ gap: 24 }}>
          <FractionInput
            id="prepTime"
            name="prepTime"
            onValueChanged={handleValueChanged}
            value={values.prepTime || 0}
            minValue="0"
            label="Prep Time"
            units="min"
            width="100px"
            data-test="newRecipe-prepTime"
          />
          <FractionInput
            id="cookTime"
            name="cookTime"
            onValueChanged={handleValueChanged}
            value={values.cookTime || 0}
            minValue="0"
            label="Cook Time"
            units="min"
            width="100px"
            data-test="newRecipe-cookTime"
          />
        </FlexContainer>

        <FlexContainer alignItems="flex-end">
          <FractionInput
            label="Serving Size"
            name="servingSize"
            value={values.servingSize || 2}
            minValue="0"
            onValueChanged={handleValueChanged}
            data-test="newRecipe-servingSize"
          />
          <TextInput
            id="servingUnit"
            name="servingUnit"
            onChange={handleChange}
            value={values.servingUnit || ''}
            minValue="0"
            width="200px"
            placeholder="Units"
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = 'Units')}
            style={{ marginLeft: '5px' }}
            data-test="newRecipe-servingUnits"
          />
        </FlexContainer>
        <FractionInput
          label="Number of Servings (Yield)"
          id="yield"
          name="yield"
          value={values.yield || 0}
          minValue="0"
          onValueChanged={handleValueChanged}
          units="Servings"
          data-test="newRecipe-yield"
        />
      </FieldsContainer>
      <RecipeDetailsContainer flexDirection="column" flexShrink="0">
        <RecipeSection flexDirection="column">
          <RecipeSectionHeader justify="space-between" alignItems="center">
            <RecipeSectionName>Ingredients</RecipeSectionName>
          </RecipeSectionHeader>
          <IngredientsList flexDirection="column" data-test="newRecipe-ingList">
            <StaticSearchBar
              handleSearch={handleSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              autoFocus={false}
              data-test="ingredient-search"
            />
            {ingredientRows}
          </IngredientsList>
          {openModal && (
            <MobileSearchModal
              open={openModal}
              title="Custom Recipe"
              handleClose={handleCloseModal}
              initialSearchTerm={searchTerm}
              value={ingredientsValue}
              setValue={setIngredientsValue}
              getOptionLabel={(option) => capitalize(option.verboseName)}
              placeholder="Try tomato, etc."
            />
          )}
        </RecipeSection>
        <RecipeSection flexDirection="column">
          <RecipeSectionHeader justify="space-between" alignItems="center">
            <RecipeSectionName>Instructions</RecipeSectionName>
          </RecipeSectionHeader>
          <InstructionsList flexDirection="column">
            {instructionsRows}
            <AddIcon
              labelText="Add Instruction"
              orientation="row"
              pink="true"
              labelWeight="normal"
              labelColor={colors.hlitetwo400}
              onClick={addInstruction}
              data-test="newRecipe-addInstruction"
            />
          </InstructionsList>
        </RecipeSection>
      </RecipeDetailsContainer>
      <MobileNutrientContainer>
        <RecipeNutritionContainer
          nutrients={calculatedNutrients}
          totalCalories={calculatedNutrients.calories}
        />
      </MobileNutrientContainer>
      <ButtonContainer justify="center" alignItems="center">
        <Button
          pink="true"
          width="325px"
          buttonText="Create Custom Recipe"
          buttonSize="large"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="newRecipe-submit"
        />
      </ButtonContainer>
    </CustomRecipeContainer>
  );
};

const CustomRecipeContainer = styled(FlexContainer)`
  width: 100%;
  gap: 24px;
  justify-content: center;
`;

const FieldsContainer = styled(FlexContainer)`
  width: calc(100% - 32px);
  padding: 0 16px;
  gap: 24px;
`;
const ImageContainer = styled(FlexContainer)`
  width: 100%;
`;

const MobileNutrientContainer = styled.div`
  padding-bottom: 104px;
`;

const ButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 64px;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const RecipeDetailsContainer = styled(FlexContainer)`
  gap: 40px;
`;

const RecipeSection = styled(FlexContainer)`
  width: 100%;
`;

const RecipeSectionHeader = styled(FlexContainer)`
  width: calc(100% - 48px);
  margin: 0 16px;
  padding: 0 8px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const RecipeSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

const IngredientName = styled.h4`
  color: ${colors.primary800};
  font-weight: 600;
  margin-bottom: 12px;
`;

const IngredientsList = styled(FlexContainer)`
  gap: 28px;
  padding: 28px 16px 0 24px;
  text-decoration: none;
`;

const InstructionsList = styled(FlexContainer)`
  padding: 28px 16px 0 16px;
  gap: 28px;
`;

const InstructionRow = styled(FlexContainer)`
  position: relative;
`;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  position: absolute;
  top: 50%;
  right: 0%;
`;

export default CustomRecipeMobile;
