import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import { capitalize } from 'lodash';
import { searchFoods } from '../../services/api/food';
import { getUnitList } from '../../helpers/units';
import AddImage from '../../elements/AddImage';

import TextInput from '../../elements/TextInput';
import TextBox from '../../elements/TextBox';
import Dropdown from '../../elements/Dropdown';
import RemoveXIcon from '../../icons/RemoveXIcon';
import AddIcon from '../../icons/AddIcon';
import CircularProgress from '@mui/material/CircularProgress';
import TextLink from '../../elements/TextLink';
import NumericInput from '../../elements/NumericInput';
import FractionInput from '../../elements/FractionInput';
import Button from '../../elements/Button';
import Messages from '../../universal/Messages';
import SearchModalReturnObject from '../../universal/SearchModalReturnObject';
import RecipeNutritionContainer from '../../mealPlan/recipe/RecipeNutritionContainer';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const CustomRecipeWeb = ({
  values,
  foods,
  handleChange,
  handleValueChanged,
  handleSubmit,
  handleInstructionChange,
  handleUnitsChanged,
  handleQuantityValueChange,
  handleDeleteFood,
  addInstruction,
  removeInstruction,
  instructionsValue,
  ingredientsValue,
  setIngredientsValue,
  calculatedNutrients,
  handleImage,
  handleChangeImage,
  inputFile,
  imageUrl,
  status,
  message,
  loading,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const ingredientRows = foods.map((food, index) => {
    const ingNum = `ing-${index}`;
    const unitList = getUnitList(food.measureUnit);
    return (
      <TR key={index}>
        <TD>
          <UnitsContainer>
            <FractionInput
              id={ingNum}
              name={ingNum}
              data-idx={index}
              data-name="quantity"
              value={foods[index].quantity || 1}
              onValueChanged={handleQuantityValueChange}
              data-test="newRecipe-ingQuantity"
            />
            <Dropdown
              width="140px"
              options={unitList || ['Oz']}
              data-idx={index}
              data-name="units"
              value={{
                value: foods[index].measureUnit,
                label: foods[index].measureUnit,
              }}
              onChange={(value) =>
                handleUnitsChanged('units', index, value.label)
              }
              data-test="newRecipe-ingUnits"
            />
          </UnitsContainer>
        </TD>
        <TD style={{ width: '50%' }}>
          <IngredientName name={food.name} data-test="newRecipe-ingredientName">
            {capitalize(food.verboseName)}
          </IngredientName>
        </TD>
        <TD style={{ width: '50px' }}>
          <RemoveXIcon
            onClick={() => handleDeleteFood(food)}
            color={colors.hlitetwo400}
            data-test="newRecipe-removeIng"
          />
        </TD>
      </TR>
    );
  });

  const instructionsRows = instructionsValue.map((instruction, index) => {
    const stepNum = `step-${index}`;
    return (
      <InstructionRow key={stepNum}>
        <TextBox
          id={stepNum}
          name={stepNum}
          data-name="step"
          data-idx={index}
          onChange={handleInstructionChange}
          value={instructionsValue[index].step || ''}
          label={`Step #${index + 1}`}
          defaultHeight="50px"
          width="95%"
          data-test="newRecipe-instruction"
        />
        <StyledRemoveXIcon
          onClick={() => removeInstruction(instruction)}
          data-test="newRecipe-removeInstruction"
          color={colors.hlitetwo400}
        />
      </InstructionRow>
    );
  });

  return (
    <CustomRecipeContainer flexDirection="column">
      {status === 'error' ? (
        <Messages
          messages={message}
          type="error"
          testName="newRecipe-errorMsg"
        />
      ) : status === 'success' ? (
        <Messages
          messages={message}
          type="success"
          testName="newRecipe-successMsg"
        />
      ) : null}
      <TopContainer>
        <Column flexDirection="column">
          <input
            hidden
            ref={inputFile}
            type="file"
            name="image"
            onChange={handleChangeImage}
          />
          <AddImage
            src={imageUrl}
            onClick={handleImage}
            size={{ width: '550px', height: '500px' }}
          />
        </Column>
        <Column flexDirection="column">
          <Header>Create New Recipe</Header>
          <TextInput
            label="Recipe Name"
            id="name"
            name="name"
            onChange={handleChange}
            width="100%"
            placeholder="Recipe Name"
            value={values.name || ''}
            data-test="newRecipe-recipeName"
          />
          <ServingInfoContainer flexDirection="column">
            <InfoRow alignItems="flex-end">
              <NumericInput
                id="prepTime"
                name="prepTime"
                onChange={handleChange}
                onValueChanged={handleValueChanged}
                value={values.prepTime || ''}
                minValue="0"
                label="Prep Time"
                units="minutes"
                data-test="newRecipe-prepTime"
              />
              <NumericInput
                id="cookTime"
                name="cookTime"
                onChange={handleChange}
                onValueChanged={handleValueChanged}
                value={values.cookTime || ''}
                minValue="0"
                label="Cook Time"
                units="minutes"
                data-test="newRecipe-cookTime"
              />
            </InfoRow>
            <InfoRow alignItems="flex-end">
              <NumericInput
                label="Serving Size"
                name="servingSize"
                value={values.servingSize || ''}
                minValue="0"
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                data-test="newRecipe-servingSize"
              />
              <TextInput
                id="servingUnit"
                name="servingUnit"
                label="Units"
                onChange={handleChange}
                value={values.servingUnit || ''}
                minValue="0"
                placeholder="Units"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'Units')}
                data-test="newRecipe-servingUnits"
              />
            </InfoRow>

            <NumericInput
              label="Number of Servings (Yield)"
              id="yield"
              name="yield"
              value={values.yield || 0}
              minValue="0"
              onValueChanged={handleValueChanged}
              onChange={handleChange}
              units="Servings"
              data-test="newRecipe-yield"
            />
          </ServingInfoContainer>
        </Column>
      </TopContainer>
      <BottomContainer flexDirection="column">
        <RecipeDetailsContainer flexDirection="column" flexShrink="0">
          <RecipeSection flexDirection="column">
            <RecipeSectionHeader justify="space-between" alignItems="center">
              <RecipeSectionName>Ingredients</RecipeSectionName>
              <RecipeHeaderNote>
                Based on {values.yield} servings
              </RecipeHeaderNote>
            </RecipeSectionHeader>
            <IngredientsList flexDirection="column">
              <IngredientsContainer flexDirection="column">
                <Table>
                  <tbody data-test="newRecipe-ingList">{ingredientRows}</tbody>
                </Table>
                <SearchLinkContainer alignItems="center">
                  <SearchIcon
                    onClick={handleOpenModal}
                    data-test="newRecipe-searchIcon"
                  />
                  <SearchLink
                    size="small"
                    handleClick={handleOpenModal}
                    linkText="Search Ingredients"
                  />
                </SearchLinkContainer>
                <SearchModalReturnObject
                  open={openModal}
                  handleClose={handleCloseModal}
                  value={ingredientsValue}
                  setValue={setIngredientsValue}
                  fetchService={searchFoods}
                  getOptionLabel={(option) => capitalize(option.verboseName)}
                  placeholder="Try tomato, etc."
                />
              </IngredientsContainer>
            </IngredientsList>
          </RecipeSection>
          <RecipeSection flexDirection="column">
            <RecipeSectionHeader justify="space-between" alignItems="center">
              <RecipeSectionName>Instructions</RecipeSectionName>
            </RecipeSectionHeader>
            <InstructionsList flexDirection="column">
              <InstructionsContainer
                flexDirection="column"
                data-test="newRecipe-instructionsList"
              >
                {instructionsRows}
                <AddIcon
                  labelText="Add Instruction"
                  orientation="row"
                  pink="true"
                  onClick={addInstruction}
                  data-test="newRecipe-addInstruction"
                />
              </InstructionsContainer>
            </InstructionsList>
          </RecipeSection>
        </RecipeDetailsContainer>
        <RecipeNutritionContainer
          nutrients={calculatedNutrients}
          totalCalories={calculatedNutrients.calories}
        />
      </BottomContainer>
      <AddButtonContainer
        flexDirection="column"
        justify="center"
        alignItems="center"
      >
        <Button
          pink="true"
          width="320px"
          buttonText="Create Custom Recipe"
          buttonSize="large"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="newRecipe-submit"
        />
      </AddButtonContainer>
    </CustomRecipeContainer>
  );
};

const CustomRecipeContainer = styled(FlexContainer)`
  width: 100%;
`;

const TopContainer = styled(FlexContainer)`
  gap: 32px;
  width: 100%;
`;

const BottomContainer = styled(FlexContainer)`
  margin-top: 32px;
  padding-bottom: 64px;
  gap: 40px;
  width: 100%;
`;

const Column = styled(FlexContainer)`
  flex-basis: 50%;
  gap: 24px;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const UnitsContainer = styled(FlexContainer)`
  max-width: 220px;
  gap: 8px;
`;

const ServingInfoContainer = styled(FlexContainer)`
  padding-bottom: 32px;

  > * {
    margin-bottom: 16px;
  }
  > :first-child {
    margin-bottom: 24px;
  }
  > :last-child {
    margin-top: 8px;
  }
`;
const InfoRow = styled(FlexContainer)`
  width: 80%;

  > * {
    margin-right: 24px;
  }
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

const SearchLinkContainer = styled(FlexContainer)`
  padding-top: 12px;
  > * {
    margin-right: 16px;
  }
`;

const SearchLink = styled(TextLink)`
  font-size: 17px;
  font-weight: 400;
`;

const SearchIcon = styled.div`
  background-image: url(${fileStorage}/Search+icon.svg);
  background-repeat: no-repeat;
  height: 26px;
  width: 26px;

  &:hover {
    cursor: pointer;
  }
`;

const IngredientsContainer = styled(FlexContainer)``;
const InstructionsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 12px;
  }
`;

const Table = styled.table`
  width: 650px;
  border-spacing: 8px;
`;

const TR = styled.tr``;
const TD = styled.td`
  padding-bottom: 12px;
`;

const RecipeDetailsContainer = styled(FlexContainer)`
  gap: 40px;
`;

const RecipeSection = styled(FlexContainer)`
  width: 100%;
`;

const RecipeSectionHeader = styled(FlexContainer)`
  width: calc(100% - 16px);
  padding: 0 8px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const RecipeSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;
const RecipeHeaderNote = styled.h4`
  color: ${colors.primary800};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const IngredientsList = styled(FlexContainer)`
  gap: 28px;
  padding: 28px 0 0 8px;
  text-decoration: none;
`;

const IngredientName = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.primary800};
`;

const InstructionsList = styled(FlexContainer)`
  padding: 28px 0 0 8px;
  gap: 28px;
`;

const InstructionRow = styled(FlexContainer)`
  position: relative;
`;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  position: absolute;
  top: 50%;
  right: 0%;
`;

export default CustomRecipeWeb;
