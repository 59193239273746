import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { cloneDeep, isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useModal from '../../hooks/useModal';

import ModalContainer from '../../sharedModals/ModalContainer';

import ActivityForm from './ActivityForm';
import { getActivity, editActivity } from '../../services/api/activity';

const EditActivity = (props) => {
  const initialValues = {
    intensity: 0,
    duration: 0,
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [imageUrl, setImageUrl] = useState();
  const { open, launchModal, closeModal } = useModal(false);
  const [activity, setActivity] = useState({});

  async function loadActivity(params) {
    try {
      setMessage('');
      const activityId = params.activity;
      const result = await getActivity(activityId);
      if (result) {
        setActivity(result);
      } else {
        throw new Error('Activity could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded activity
  useEffect(() => {
    if (!isEmpty(activity)) {
      setValues({
        name: activity.name,
        intensity: activity.intensity,
        imageUrl: activity.imageUrl,
        duration: activity.duration,
        caloriesBurned: activity.caloriesBurned,
      });

      setImageUrl(activity.imageUrl);
    }
  }, [activity]);

  const submitData = () => {
    const activityId = activity._id;
    let payload = cloneDeep(values);

    setLoading(true);

    editActivity(activityId, payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Activity "${result.name}" was modified successfully!`);
        setValues(initialValues);
        setImageUrl(null);
      })
      .catch((err) => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Activity"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="activity-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'activity',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addActivity={loadActivity}
        {...props}
      />
      <ActivityForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        viewOnly={isEmpty(activity)}
        buttonText="Edit Activity"
        formType="edit"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default EditActivity;
