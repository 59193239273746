import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  authUser,
  updatePassword,
  getUser,
  updateUser,
  forgotPassword,
  verifyEmail,
  resendVerificationEmail,
} from './currentUserSlice';

import {
  getShoppingLists,
  getFilteredShoppingLists,
  addFoodToShoppingList,
  removeFoodFromShoppingList,
  removeRecipeFromShoppingList,
  setFoodCompleted,
  clearCompletedFoods,
  adjustFoodQuantity,
} from '../shoppinglist/shoppingListSlice';

import { getActiveUserMealPlan } from '../mealplan/activeMealplanSlice';

import {
  getUserMealPlanByDate,
  getUserMealPlanById,
  addRecipeToMealPlan,
  addFoodToMealPlan,
  addMultipleRecipesToMealPlan,
  updateUserMealPlan,
  swapMealPlanItem,
} from '../mealplan/selectedMealPlanSlice';

import { getActiveUserFitnessPlan } from '../fitnessplan/activeFitnessPlanSlice';

import {
  getUserFitnessPlanByDate,
  getUserFitnessPlanById,
  addActivityToFitnessPlan,
  addWorkoutToFitnessPlan,
  updateUserFitnessPlan,
  setFitnessPlanActivityProperty,
  setFitnessPlanWorkoutProperty,
} from '../fitnessplan/selectedFitnessPlanSlice';

import { getUserTrack, switchTrack } from '../fitnessplan/trackSlice';
import { getDefaultPlaceholder } from '../mealplan/defaultPlaceholderSlice';
import { getIngredientMaps } from '../shoppinglist/ingredientMapsSlice';

const initialState = [];

const formatError = (err) => {
  if (err?.error?.message) {
    return err.error.message;
  }
  if (err?.name === 'NetworkError') {
    console.error('There was a network error.');
    return 'There was a network error.';
  }
  if (err?.message) {
    return err.message;
  }
  if (typeof err === 'string') {
    return err;
  }
  return 'Error encountered';
};

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError(state, action) {
      state.push(action.payload);
    },
    removeError(state, action) {
      return state.filter((error) => error?.type !== action.payload?.type);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getIngredientMaps.rejected, (state, action) => {
        state.push({
          type: 'ingredientMaps',
          error: formatError(action.payload),
        });
      })
      .addCase(getIngredientMaps.fulfilled, (state, action) => {
        return state.filter((error) => error?.type !== 'ingredientMaps');
      })
      .addMatcher(
        isAnyOf(
          authUser.rejected,
          updatePassword.rejected,
          getUser.rejected,
          updateUser.rejected,
          forgotPassword.rejected,
          verifyEmail.rejected,
          resendVerificationEmail.rejected,
        ),
        (state, action) => {
          state.push({ type: 'user', error: formatError(action.payload) });
        },
      )
      .addMatcher(
        isAnyOf(
          authUser.fulfilled,
          updatePassword.fulfilled,
          getUser.fulfilled,
          updateUser.fulfilled,
          forgotPassword.fulfilled,
          verifyEmail.fulfilled,
          resendVerificationEmail.fulfilled,
        ),
        (state, action) => {
          return state.filter((error) => error?.type !== 'user');
        },
      )
      .addMatcher(
        isAnyOf(
          getShoppingLists.rejected,
          getFilteredShoppingLists.rejected,
          addFoodToShoppingList.rejected,
          removeFoodFromShoppingList.rejected,
          removeRecipeFromShoppingList.rejected,
          setFoodCompleted.rejected,
          clearCompletedFoods.rejected,
          adjustFoodQuantity.rejected,
        ),
        (state, action) => {
          state.push({
            type: 'shoppingList',
            error: formatError(action.payload),
          });
        },
      )
      .addMatcher(
        isAnyOf(
          getShoppingLists.fulfilled,
          getFilteredShoppingLists.fulfilled,
          addFoodToShoppingList.fulfilled,
          removeFoodFromShoppingList.fulfilled,
          removeRecipeFromShoppingList.fulfilled,
          setFoodCompleted.fulfilled,
          clearCompletedFoods.fulfilled,
          adjustFoodQuantity.fulfilled,
        ),
        (state, action) => {
          return state.filter((error) => error?.type !== 'shoppingList');
        },
      )
      .addMatcher(
        isAnyOf(
          getActiveUserMealPlan.rejected,
          getUserMealPlanByDate.rejected,
          getUserMealPlanById.rejected,
          addRecipeToMealPlan.rejected,
          addFoodToMealPlan.rejected,
          addMultipleRecipesToMealPlan.rejected,
          updateUserMealPlan.rejected,
          swapMealPlanItem.rejected,
        ),
        (state, action) => {
          state.push({ type: 'mealPlan', error: formatError(action.payload) });
        },
      )
      .addMatcher(
        isAnyOf(
          getActiveUserMealPlan.fulfilled,
          getUserMealPlanByDate.fulfilled,
          getUserMealPlanById.fulfilled,
          addRecipeToMealPlan.fulfilled,
          addFoodToMealPlan.fulfilled,
          addMultipleRecipesToMealPlan.fulfilled,
          updateUserMealPlan.fulfilled,
          swapMealPlanItem.fulfilled,
        ),
        (state, action) => {
          return state.filter((error) => error?.type !== 'mealPlan');
        },
      )
      .addMatcher(
        isAnyOf(
          getActiveUserFitnessPlan.rejected,
          getUserFitnessPlanByDate.rejected,
          addActivityToFitnessPlan.rejected,
          getUserFitnessPlanById.fulfilled,
          addWorkoutToFitnessPlan.rejected,
          updateUserFitnessPlan.rejected,
          setFitnessPlanActivityProperty.rejected,
          setFitnessPlanWorkoutProperty.rejected,
        ),
        (state, action) => {
          state.push({
            type: 'fitnessPlan',
            error: formatError(action.payload),
          });
        },
      )
      .addMatcher(
        isAnyOf(
          getActiveUserFitnessPlan.fulfilled,
          getUserFitnessPlanByDate.fulfilled,
          addActivityToFitnessPlan.fulfilled,
          getUserFitnessPlanById.fulfilled,
          addWorkoutToFitnessPlan.fulfilled,
          updateUserFitnessPlan.fulfilled,
          setFitnessPlanActivityProperty.fulfilled,
          setFitnessPlanWorkoutProperty.fulfilled,
        ),
        (state, action) => {
          return state.filter((error) => error?.type !== 'fitnessPlan');
        },
      )
      .addMatcher(
        isAnyOf(getUserTrack.rejected, switchTrack.rejected),
        (state, action) => {
          state.push({ type: 'track', error: formatError(action.payload) });
        },
      )
      .addMatcher(
        isAnyOf(getUserTrack.fulfilled, switchTrack.fulfilled),
        (state, action) => {
          return state.filter((error) => error?.type !== 'track');
        },
      )
      .addMatcher(isAnyOf(getDefaultPlaceholder.rejected), (state, action) => {
        state.push({ type: 'placeholder', error: formatError(action.payload) });
      })
      .addMatcher(isAnyOf(getDefaultPlaceholder.fulfilled), (state, action) => {
        return state.filter((error) => error?.type !== 'placeholder');
      });
  },
});

export const { addError, removeError } = errorSlice.actions;
export default errorSlice.reducer;
