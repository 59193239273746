import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const ExpertTipIcon = (props) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M24.6,15c0-4.7-3.8-8.6-8.5-8.6c-4.7,0-8.6,3.8-8.6,8.5c0,3.2,1.8,6.2,4.7,7.7v5.2c0,0.6,0.5,1.1,1,1.1
      c0,0,0,0,0,0h5.5c0.6,0,1.1-0.5,1.1-1.1v-5.2C22.7,21.2,24.6,18.2,24.6,15z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6,28.8v1c0,0.9-0.7,1.6-1.6,1.5l0,0c-0.9,0-1.6-0.7-1.6-1.6v-1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="16"
      y1="24"
      x2="16"
      y2="19.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="16"
      y1="19.2"
      x2="18.3"
      y2="16.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="16"
      y1="19.2"
      x2="13.7"
      y2="16.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="27.7"
      y1="14.9"
      x2="30.2"
      y2="14.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="24.3"
      y1="23.2"
      x2="26.1"
      y2="25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="26.1"
      y1="5.1"
      x2="24.3"
      y2="6.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="4.3"
      y1="14.9"
      x2="1.8"
      y2="14.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="7.7"
      y1="23.2"
      x2="5.9"
      y2="25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="5.9"
      y1="5.1"
      x2="7.7"
      y2="6.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="16"
      y1="0.6"
      x2="16"
      y2="3.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="12.2"
      y1="24.3"
      x2="19.9"
      y2="24.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="12.2"
      y1="26.5"
      x2="19.9"
      y2="26.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

const Icon = styled.svg`
  width: 32px;
  height: 32px;

  & path,
  line {
    fill: none;
    stroke: ${colors.secondary500};
  }
`;

export default ExpertTipIcon;
