import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';

const Banner = ({
  title,
  color,
  textBody,
  imageUrl,
  actionType,
  handleClick,
  ...props
}) => {
  return (
    <BannerContainer
      color={color}
      onClick={handleClick}
      data-test={props['data-test']}
    >
      <BannerText flexDirection="column" justify="center">
        <BannerTitle data-test="banner-title">{title}</BannerTitle>
        <BannerBody data-test="banner-body">{textBody}</BannerBody>
      </BannerText>
      <BannerImage image={imageUrl} data-test="banner-image" />
    </BannerContainer>
  );
};

const BannerContainer = styled(FlexContainer)`
  width: ${isMobile() ? '100%' : '382px'};
  height: 154px;
  text-align: left;
  background: ${(props) =>
    props.color === 'Green'
      ? 'linear-gradient(255deg, #F3F3F3 9.18%, #C4F0CC 90.82%)'
      : props.color === 'Blue'
        ? 'linear-gradient(75deg, #C9F5FC 9.18%, #F3F3F3 90.82%)'
        : `linear-gradient(
    180deg,
    #cad0db 0%,
    #d5d8e1 8.74%,
    #dbdfe6 17.23%,
    #e6e8ed 30.54%,
    #edeef2 44.41%,
    #f4f5f7 68.4%,
    #f6f7f8 81.3%,
    #f8f8fa 100%
  )`};
  border-radius: 16px;
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
`;

const BannerImage = styled(FlexContainer)`
  flex-basis: 30%;
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const BannerText = styled(FlexContainer)`
  flex-basis: 70%;
  padding: 0 16px;
  gap: 8px;
`;

const BannerTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.primary800};
`;

const BannerBody = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary800};
`;

export default Banner;
