import React, { useEffect, useState } from 'react';
import {
  getUserFavoriteRecipes,
  getUserCustomFavoriteRecipes,
} from '../../services/api/recipe';
import {
  getUserFavoriteFoods,
  getUserCustomFavoriteFoods,
} from '../../services/api/food';
import { useSelector } from 'react-redux';
import { isMobile } from '../../helpers/utils';
import FavoritesWeb from './FavoritesWeb';
import FavoritesMobile from './FavoritesMobile';

const MyFavorites = ({
  setScreen,
  setRecipe,
  setFood,
  searchTypeSelected,
  setSearchTypeSelected,
  ...props
}) => {
  const currentUser = useSelector((state) => state.currentUser.user);
  const userId = currentUser._id;

  const pageSizeFavoriteRecipes = 100;
  const pageSizeFavoriteFoods = 100;
  const [favoriteRecipes, setFavoriteRecipes] = useState([]);
  const [customFavoriteRecipes, setCustomFavoriteRecipes] = useState([]);
  const [allFavoriteRecipes, setAllFavoriteRecipes] = useState([]);
  const [favoriteFoods, setFavoriteFoods] = useState([]);
  const [customFavoriteFoods, setCustomFavoriteFoods] = useState([]);
  const [allFavoriteFoods, setAllFavoriteFoods] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasMoreRecipes, setHasMoreRecipes] = useState(false);
  const [hasMoreCustomRecipes, setHasMoreCustomRecipes] = useState(false);
  const [hasMoreFoods, setHasMoreFoods] = useState(false);
  const [hasMoreCustomFoods, setHasMoreCustomFoods] = useState(false);

  useEffect(() => {
    getFavoriteRecipes(0);
    getFavoriteFoods(0);
  }, []);

  const getFavoriteRecipes = (page) => {
    getUserFavoriteRecipes(userId, currentUser.favoriteRecipes)
      .then((recipes) => {
        if (recipes.length === 0 || recipes.length < pageSizeFavoriteRecipes) {
          setHasMoreRecipes(false);
        } else {
          setHasMoreRecipes(true);
        }
        if (page === 0) {
          setFavoriteRecipes(recipes);
          setAllFavoriteRecipes(recipes);
        } else {
          setFavoriteRecipes(favoriteRecipes.concat(recipes));
          setAllFavoriteRecipes(allFavoriteRecipes.concat(recipes));
        }
      })
      .catch((err) => {
        console.error(err);
        setFavoriteRecipes([]);
        setHasMoreRecipes(false);
      });
    getUserCustomFavoriteRecipes(userId, currentUser.favoriteRecipes)
      .then((recipes) => {
        if (recipes.length === 0 || recipes.length < pageSizeFavoriteRecipes) {
          setHasMoreCustomRecipes(false);
        } else {
          setHasMoreCustomRecipes(true);
        }
        if (page === 0) {
          setCustomFavoriteRecipes(recipes);
        } else {
          setCustomFavoriteRecipes(favoriteRecipes.concat(recipes));
        }
      })
      .catch((err) => {
        console.error(err);
        setCustomFavoriteRecipes([]);
        setHasMoreCustomRecipes(false);
      });
  };

  const getFavoriteFoods = (page) => {
    getUserFavoriteFoods(userId, currentUser.favoriteFoods)
      .then((foods) => {
        if (foods.length === 0 || foods.length < pageSizeFavoriteFoods) {
          setHasMoreFoods(false);
        } else {
          setHasMoreFoods(true);
        }
        if (page === 0) {
          setFavoriteFoods(foods);
          setAllFavoriteFoods(foods);
        } else {
          setFavoriteFoods(favoriteFoods.concat(foods));
          setAllFavoriteFoods(allFavoriteFoods.concat(foods));
        }
      })
      .catch((err) => {
        console.error(err);
        setFavoriteFoods([]);
        setHasMoreFoods(false);
      });
    getUserCustomFavoriteFoods(userId, currentUser.favoriteFoods)
      .then((foods) => {
        if (foods.length === 0 || foods.length < pageSizeFavoriteFoods) {
          setHasMoreCustomFoods(false);
        } else {
          setHasMoreCustomFoods(true);
        }
        if (page === 0) {
          setCustomFavoriteFoods(foods);
        } else {
          setCustomFavoriteFoods(favoriteFoods.concat(foods));
        }
      })
      .catch((err) => {
        console.error(err);
        setCustomFavoriteFoods([]);
        setHasMoreCustomFoods(false);
      });
  };

  const handleSearch = (inputValue) => {
    setSearchQuery(inputValue);
    if (inputValue === '') {
      setFavoriteRecipes(allFavoriteRecipes);
      setFavoriteFoods(allFavoriteFoods);
      return;
    }
    const filteredRecipes = allFavoriteRecipes.filter(function (recipe) {
      const name = recipe.name.toLowerCase();
      return name.includes(inputValue.toLowerCase());
    });

    const filteredFoods = allFavoriteFoods.filter(function (food) {
      const name = food.name.toLowerCase();
      return name.includes(inputValue.toLowerCase());
    });

    setFavoriteRecipes(filteredRecipes);
    setFavoriteFoods(filteredFoods);
  };

  if (isMobile()) {
    // mobile version
    return (
      <FavoritesMobile
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        favoriteRecipes={favoriteRecipes}
        favoriteFoods={favoriteFoods}
        customFavoriteRecipes={customFavoriteRecipes}
        customFavoriteFoods={customFavoriteFoods}
        pageSizeFavoriteFoods={pageSizeFavoriteFoods}
        getFavoriteFoods={getFavoriteFoods}
        getFavoriteRecipes={getFavoriteRecipes}
        setScreen={setScreen}
        setRecipe={setRecipe}
        setFood={setFood}
        pageSizeFavoriteRecipes={pageSizeFavoriteRecipes}
        loadMoreItems={getFavoriteRecipes}
        searchTypeSelected={searchTypeSelected}
        setSearchTypeSelected={setSearchTypeSelected}
        hasMoreFoods={hasMoreFoods}
        hasMoreRecipes={hasMoreRecipes}
        hasMoreCustomFoods={hasMoreCustomFoods}
        hasMoreCustomRecipes={hasMoreCustomRecipes}
        {...props}
      />
    );
  } else {
    // web version
    return (
      <FavoritesWeb
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        favoriteRecipes={favoriteRecipes}
        favoriteFoods={favoriteFoods}
        customFavoriteRecipes={customFavoriteRecipes}
        customFavoriteFoods={customFavoriteFoods}
        pageSizeFavoriteFoods={pageSizeFavoriteFoods}
        pageSizeFavoriteRecipes={pageSizeFavoriteRecipes}
        getFavoriteFoods={getFavoriteFoods}
        getFavoriteRecipes={getFavoriteRecipes}
        setScreen={setScreen}
        setRecipe={setRecipe}
        setFood={setFood}
        hasMoreFoods={hasMoreFoods}
        hasMoreRecipes={hasMoreRecipes}
        hasMoreCustomFoods={hasMoreCustomFoods}
        hasMoreCustomRecipes={hasMoreCustomRecipes}
        {...props}
      />
    );
  }
};

export default MyFavorites;
