import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const RemoveXIcon = ({
  handleClick,
  small,
  hideCircle,
  size,
  color,
  ...props
}) => (
  <Icon
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    onClick={handleClick}
    data-name={props['data-name']}
    data-type={props['data-type']}
    data-id={props['data-id']}
    data-test={props['data-test']}
    $size={size}
    $small={small}
    $color={color}
    {...props}
  >
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
      data-name={props['data-name']}
      data-type={props['data-type']}
      data-id={props['data-id']}
      // data-test={props['data-test']}
    >
      <g
        id="Style-Guide---Elements"
        transform="translate(-198.000000, -5239.000000)"
        strokeWidth="1.5"
        data-name={props['data-name']}
        data-type={props['data-type']}
        data-id={props['data-id']}
        // data-test={props['data-test']}
      >
        <g
          id="Remove-X-icon-Default"
          transform="translate(199.000000, 5240.000000)"
          data-name={props['data-name']}
          data-type={props['data-type']}
          data-id={props['data-id']}
          // data-test={props['data-test']}
        >
          {!hideCircle && (
            <circle
              id="Oval-3"
              cx="12"
              cy="12"
              r="12"
              data-name={props['data-name']}
              data-type={props['data-type']}
              data-id={props['data-id']}
            ></circle>
          )}

          <g
            id="Group"
            transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) translate(6.000000, 6.000000)"
            strokeLinecap="round"
            data-name={props['data-name']}
            data-type={props['data-type']}
            data-id={props['data-id']}
            // data-test={props['data-test']}
          >
            <path
              d="M0,6 L12,6"
              id="Line-2"
              data-name={props['data-name']}
              data-type={props['data-type']}
              data-id={props['data-id']}
            ></path>
            <path
              d="M6,0 L6,12"
              id="Line-2-Copy"
              data-name={props['data-name']}
              data-type={props['data-type']}
              data-id={props['data-id']}
            ></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

const Icon = styled.svg`
  width: ${(props) =>
    props.$size ? props.$size : props.$small ? '20px' : '26px'};
  height: ${(props) =>
    props.$size ? props.$size : props.$small ? '20px' : '26px'};

  & circle {
    fill: none;
    stroke: ${(props) =>
      props.$color
        ? props.$color
        : props.$small
          ? colors.secondary500
          : colors.primary600};
    stroke-width: 1.5;
  }

  & path {
    stroke: ${(props) =>
      props.$color
        ? props.$color
        : props.$small
          ? colors.secondary500
          : colors.primary600};
    stroke-width: 1.5;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover circle {
    fill: ${colors.primary050};
  }

  &:focus circle {
    fill: ${colors.primary050};
  }

  &:active circle {
    fill: ${colors.primary100};
  }

  &:disabled circle {
    fill: none;
    stroke: ${colors.primary300};
  }
  &:disabled path {
    fill: none;
    stroke: ${colors.primary300};
  }
`;

export default RemoveXIcon;
