export const workoutSequenceMap = [
  {
    id: 'warm-up',
    title: 'Warm Up',
    order: 0,
  },
  {
    id: 'core',
    title: 'Core',
    order: 1,
  },
  {
    id: 'strength',
    title: 'Strength',
    order: 2,
  },
  {
    id: 'circuit',
    title: 'Circuit',
    order: 3,
  },
  {
    id: 'conditioning',
    title: 'Conditioning',
    order: 4,
  },
  {
    id: 'cool-down',
    title: 'Cool Down',
    order: 5,
  },
];

export function getWorkoutSequenceBy(key, value) {
  const workoutSequence = workoutSequenceMap.find(
    (sequence) => sequence[key] === value,
  );
  if (workoutSequence) {
    return workoutSequence;
  } else {
    throw Error(`Cannot find workout sequence with ${key} that equal ${value}`);
  }
}

export function getWorkoutSequenceFromDroppableObj(destination) {
  return getWorkoutSequenceBy('id', destination.droppableId);
}

export function getWorkoutSequenceTitle(id) {
  return getWorkoutSequenceBy('id', id).title;
}

export function getWorkoutSequenceOrder(id) {
  return getWorkoutSequenceBy('id', id).order;
}

export function getWorkoutDestinationIndex(source, destination) {
  const sequenceSource = getWorkoutSequenceFromDroppableObj(source);
  const sequenceDestination = getWorkoutSequenceFromDroppableObj(destination);

  // When moving an item from a section down to a section up, we need to
  // subtract 1 to the index to account for the item being moved itself
  if (
    getWorkoutSequenceOrder(sequenceSource.id) <
    getWorkoutSequenceOrder(sequenceDestination.id)
  ) {
    return destination.index - 1;
  } else {
    return destination.index;
  }
}
