import { apiCall } from './api';

export function getIngredientMap(id, params = {}) {
  return apiCall('get', `/ingredientmap/${id}`, params);
}

export function getIngredientMaps(params = {}) {
  return apiCall('get', `/ingredientmap`, params);
}

export function addIngredientMap(params) {
  return apiCall('post', `/ingredientmap`, params);
}

export function editIngredientMap(id, params) {
  return apiCall('put', `/ingredientmap/${id}`, params);
}

export function deleteIngredientMap(id, params) {
  return apiCall('delete', `/ingredientmap`, {
    query: {
      _id: id,
    },
  });
}
