import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import { colors } from '../../styleConstants';

import AddMealPlan from './AddMealPlan';
import EditMealPlan from './EditMealPlan';
import ViewMealPlan from './ViewMealPlan';
import DuplicateMealPlan from './DuplicateMealPlan';
import DeleteMealPlan from './DeleteMealPlan';

const AdminMealPlan = (props) => {
  const [actionType, setActionType] = useState('create');

  const handleChange = (e) => {
    setActionType(e.target.value);
  };

  return (
    <Container flexDirection="column">
      <H2 data-test="admin-title-mealPlan">Meal Plan Admin</H2>
      <H3>Action Type</H3>
      <FlexContainer flexDirection="row" justify="space-around">
        <RadioInput
          checked={actionType === 'create'}
          value="create"
          label="Create"
          onChange={handleChange}
          name="recordType"
          data-test="mealPlan-radio-create"
        />
        <RadioInput
          checked={actionType === 'duplicate'}
          value="duplicate"
          label="Duplicate"
          onChange={handleChange}
          name="recordType"
          data-test="mealPlan-radio-duplicate"
        />
        <RadioInput
          checked={actionType === 'edit'}
          value="edit"
          label="Edit"
          onChange={handleChange}
          name="recordType"
          data-test="mealPlan-radio-edit"
        />
        <RadioInput
          checked={actionType === 'view'}
          value="view"
          label="View"
          onChange={handleChange}
          name="recordType"
          data-test="mealPlan-radio-view"
        />
        <RadioInput
          checked={actionType === 'delete'}
          value="delete"
          label="Delete"
          onChange={handleChange}
          name="recordType"
          data-test="mealPlan-radio-delete"
        />
      </FlexContainer>
      {actionType === 'create' ? (
        <AddMealPlan />
      ) : actionType === 'duplicate' ? (
        <DuplicateMealPlan />
      ) : actionType === 'edit' ? (
        <EditMealPlan />
      ) : actionType === 'view' ? (
        <ViewMealPlan />
      ) : actionType === 'delete' ? (
        <DeleteMealPlan />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  max-width: 75%;
  margin-left: 25px;
  padding: 30px;
`;

const H2 = styled.h2`
  text-align: center;
  font-size: 28px;
  margin: 15px;
  color: ${colors.secondary600};
`;
const H3 = styled.h3`
  text-align: center;
  margin: 15px;
`;
export default AdminMealPlan;
