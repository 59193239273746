import React, { useState } from 'react';
import { colors } from '../styleConstants';
import styled from 'styled-components';
import FlexContainer from './FlexContainer';
import SearchIcon from '../icons/SearchIcon';
import { isMobile } from '../helpers/utils';

const DynamicSearchBar = ({
  handleChange,
  placeholder,
  searchTerm,
  ...props
}) => {
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <Container alignItems="center" focused={inputFocused} isMobile={isMobile()}>
      <SearchIcon />
      <Input
        id="search"
        name="search"
        onChange={handleChange}
        value={searchTerm}
        placeholder={placeholder || 'Type text here'}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        autoFocus={!isMobile}
        isMobile={isMobile()}
        {...props}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  height: 48px;
  width: ${(props) => (props.isMobile ? 'auto' : '580px')};
  padding: ${(props) =>
    props.isMobile ? '0px 0px 0px 16px' : '0 12px 0 16px'};
  border: ${(props) =>
    props.focused
      ? `1px solid ${colors.secondary500}`
      : `1px solid ${colors.primary400}`};
  border-radius: 24px;
`;

const Input = styled.input`
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) none repeat scroll 0px center;
  border: 0px none;
  opacity: 1;
  font-size: 17px;
  line-height: 20px;
  font-family: 'Work Sans';
  outline: currentcolor none 0px;
  padding: ${(props) => (props.isMobile ? '14px 14px 14px 0px' : '0px')};
  color: inherit;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary300};
    opacity: 1; /* Firefox */
  }
`;

export default DynamicSearchBar;
