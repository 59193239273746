import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import TextInput from '../elements/TextInput';
import PasswordStrengthMeter from '../elements/PasswordStrengthMeter';
import { isMobile } from '../helpers/utils';

const PasswordDisplay = ({ values, errors, handleChange, ...props }) => {
  return (
    <FieldsContainer flexDirection="column">
      <TextInput
        label="Current Password"
        id="currentPassword"
        name="currentPassword"
        onChange={handleChange}
        type="password"
        value={values.currentPassword || ''}
        placeholder="Current Password"
        onFocus={(e) => (e.target.placeholder = '')}
        onBlur={(e) => (e.target.placeholder = 'Current Password')}
        error={errors.currentPassword}
        width={!isMobile() ? '300px' : undefined}
        data-test="profile-current-password"
      />
      <FlexContainer
        flexDirection={isMobile() ? 'column' : 'row'}
        wrap={!isMobile() ? 'wrap' : 'no-wrap'}
      >
        <NewPasswordContainer flexDirection="column">
          <TextInput
            label="New Password"
            id="password"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password || ''}
            placeholder="New Password"
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = 'New Password')}
            error={errors.password}
            width={!isMobile() ? '300px' : undefined}
            data-test="profile-new-password"
          />

          <PasswordStrengthMeter password={values.password || ''} />
        </NewPasswordContainer>
        <TextInput
          label="Confirm New Password"
          id="password2"
          name="password2"
          onChange={handleChange}
          type="password"
          value={values.password2 || ''}
          placeholder="Confirm New Password"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Confirm New Password')}
          error={errors.password}
          width={!isMobile() ? '300px' : undefined}
          data-test="profile-confirm-password"
        />
      </FlexContainer>
    </FieldsContainer>
  );
};

const FieldsContainer = styled(FlexContainer)`
  margin-top: ${(props) => !isMobile() && '16px'};

  > * {
    margin-bottom: 16px;
  }
`;

const NewPasswordContainer = styled(FlexContainer)`
  > * {
    margin: 0 24px 12px 0;
  }
`;

export default PasswordDisplay;
