import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';
import useToggles from '../../hooks/useToggles';

import ExerciseForm from './ExerciseForm';
import RelatedRecords from '../related/RelatedRecords';
import { getExercise, editExercise } from '../../services/api/exercise';

const DeleteExercise = (props) => {
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const initialValues = {
    repUnits: 'Reps',
  };
  const initialToggleValues = {
    bodyWeight: false,
    active: false,
    level: {},
    location: {},
    specialtyFocus: {},
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);
  const [imageUrl, setImageUrl] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const instruction = { num: '', step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);

  const { open, launchModal, closeModal } = useModal(false);

  const [exercise, setExercise] = useState({});
  const [prevExercise, setPrevExercise] = useState({});

  async function loadExercise(params) {
    try {
      const exerciseId = params.exercise;
      const result = await getExercise(exerciseId);
      if (result) {
        setExercise(result);
      } else {
        throw new Error('Exercise could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded exercise
  useEffect(() => {
    if (!isEmpty(exercise)) {
      setValues({
        name: exercise.name,
        repUnits: exercise.repUnits,
        imageUrl: exercise.imageUrl,
        thumbnailUrl: exercise.thumbnailUrl,
        expertTip: exercise.expertTip,
      });
      const toggleTmp = {
        bodyWeight: exercise.bodyWeight,
        active: exercise.active,
        level: {},
        location: {},
        specialtyFocus: {},
      };

      const tags = exercise.tags;
      for (let tagType in tags) {
        tags[tagType].forEach((tagVal) => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setInstructionsValue([...exercise.instructions]);
      setImageUrl(exercise.imageUrl);
      setThumbnailUrl(exercise.thumbnailUrl);
    }
  }, [exercise]);

  const deleteItem = (e) => {
    setLoading(true);
    e.preventDefault();

    const exerciseId = exercise._id;
    let payload = {
      isDeleted: true,
    };
    editExercise(exerciseId, payload)
      .then((result) => {
        setPrevExercise(exerciseId);
        setStatus('success');
        setMessage(`Exercise "${result.name}" was deleted successfully!`);
        setValues(initialValues);
        setInstructionsValue([{ ...instruction }]);
        setToggleValues(initialToggleValues);
        setImageUrl(null);
        setThumbnailUrl(null);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const undoDelete = (e) => {
    e.preventDefault();
    let payload = {
      isDeleted: false,
    };
    editExercise(prevExercise, payload)
      .then((result) => {
        setStatus('');
        setMessage('');
        loadExercise({
          exercise: prevExercise,
        });
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Exercise"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="exercise-find"
      />
      {exercise._id && (
        <RelatedRecords recordType="exercise" recordId={exercise._id} />
      )}
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'exercise',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addExercise={loadExercise}
        {...props}
      />
      <ExerciseForm
        imageUrl={imageUrl}
        thumbnailUrl={thumbnailUrl}
        setImageUrl={setImageUrl}
        setThumbnailUrl={setThumbnailUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        instructionsValue={instructionsValue}
        setInstructionsValue={setInstructionsValue}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        viewOnly={true}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={deleteItem}
        undoDelete={undoDelete}
        buttonText="Delete Exercise"
        formType="delete"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DeleteExercise;
