import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

import Exercise from './Exercise';
import AddIcon from '../../icons/AddIcon';

import ModalContainer from '../../sharedModals/ModalContainer';
import useModal from '../../hooks/useModal';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getWorkoutSequenceTitle } from '../../helpers/workout';

const WorkoutSection = ({
  sectionId,
  rest,
  exercises,
  removeExercise,
  updateSets,
  variant,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const handleRemove = (e) => {
    removeExercise(e.target.dataset.id);
  };

  const exerciseList = exercises.map((exercise, index) => {
    // _id will exist if the workout is loaded from db, id is for new exercises that have not yet been saved
    const exerciseId = exercise._id ? exercise._id : exercise.id;

    if (props.viewOnly) {
      return (
        <Exercise
          key={exerciseId}
          exerciseId={exerciseId}
          exercise={exercise}
          handleRemove={handleRemove}
          updateSets={updateSets}
          exerciseSets={exercise.sets.length > 0 ? [...exercise.sets] : []}
          exerciseIndex={index}
          exercisesLength={exercises.length}
          {...props}
        />
      );
    } else {
      return (
        <Draggable
          key={exerciseId}
          draggableId={`exercise-${exerciseId}`}
          index={exercise.globalIndex}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Exercise
                key={exerciseId}
                exerciseId={exerciseId}
                exercise={exercise}
                handleRemove={handleRemove}
                updateSets={updateSets}
                exerciseSets={
                  exercise.sets.length > 0 ? [...exercise.sets] : []
                }
                exerciseIndex={index}
                exercisesLength={exercises.length}
                {...props}
              />
            </div>
          )}
        </Draggable>
      );
    }
  });

  const exerciseContainer = () => {
    if (props.viewOnly) {
      return (
        <ExerciseContainer flexDirection="column">
          {exerciseList}
        </ExerciseContainer>
      );
    } else {
      return (
        <Droppable droppableId={sectionId}>
          {(provided, snapshot) => (
            <DropZone ref={provided.innerRef} {...provided.droppableProps}>
              <ExerciseContainer
                flexDirection="column"
                data-test={`workout-${sectionId}-list`}
              >
                {exerciseList}
                {provided.placeholder}
              </ExerciseContainer>
            </DropZone>
          )}
        </Droppable>
      );
    }
  };

  return (
    <Section flexDirection="column">
      <SectionTitle>{getWorkoutSequenceTitle(sectionId)}</SectionTitle>
      {exerciseContainer()}
      {!props.viewOnly && (
        <BottomRow justify="center" alignItems="center">
          <AddIcon
            labelText="Add Exercise"
            data-sequence={getWorkoutSequenceTitle(sectionId)}
            data-variant={variant}
            data-show-only="exercise"
            orientation="row"
            pink="true"
            onClick={launchModal}
            data-test={`workout-${sectionId}-add`}
          />
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Add Exercise"
            {...props}
          />
        </BottomRow>
      )}
    </Section>
  );
};

const Section = styled(FlexContainer)`
  border-top: 0.5px solid ${colors.primary400};
  padding: 16px 0 16px 0;
`;

const BottomRow = styled(FlexContainer)`
  width: 100%;
  margin-top: 8px;
`;

const SectionTitle = styled.h4`
  color: ${colors.primary600};
  text-transform: uppercase;
`;

const ExerciseContainer = styled(FlexContainer)`
  > * {
    ${'' /* border-top: 1px solid ${colors.primary200}; */}
    ${'' /* padding: 16px 0; */}
    width: 100%;
    background-color: white;
  }

  ${
    '' /* > :last-child {
    border-bottom: 1px solid ${colors.primary200};
  } */
  }
`;

const DropZone = styled.div`
  min-height: 1px;
`;

export default WorkoutSection;
