import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Dropdown from '../elements/Dropdown';
import Button from '../elements/Button';
import MealList from '../mealPlan/MealList';

import useFormValues from '../hooks/useFormValues';

const MealPlanModalView = ({
  mealPlan,
  context,
  handleClose,
  addMealPlan,
  ...props
}) => {
  const { values, handleDateChanged } = useFormValues({
    dayNum: {
      value: 1,
      label: `Monday`,
    },
  });
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);

  const dayOptions = [];
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  for (let i = 0; i < mealPlan.planLength; i++) {
    let dayNum = i + 1;
    dayOptions.push({ value: dayNum, label: days[dayNum - 1] });
  }

  useEffect(() => {
    const filteredRec = mealPlan.recipes.filter((recipe) => {
      return recipe.dayNum === values.dayNum.value;
    });
    const filteredFood = mealPlan.foods.filter((food) => {
      return food.dayNum === values.dayNum.value;
    });
    setFilteredRecipes(filteredRec);
    setFilteredFoods(filteredFood);
  }, [mealPlan.recipes, mealPlan.foods, values.dayNum]);

  const handleSubmit = () => {
    const mealPlanId = mealPlan._id;

    const params = {
      mealPlan: mealPlanId,
    };

    addMealPlan(params);
    handleClose();
  };

  return (
    <Container justify="space-between">
      <LeftColumn flexDirection="column">
        <MealPlanName data-test="mealPlanModal-name">
          {mealPlan.name || 'Meal Plan Name'}
        </MealPlanName>
        <MealListContainer flexDirection="column">
          <Dropdown
            width="220px"
            options={dayOptions}
            label="Meal Plan Day:"
            value={{
              value: values.dayNum.value,
              label: values.dayNum.label,
            }}
            onChange={(value) =>
              handleDateChanged('dayNum', value.value, value.label)
            }
          />
          <MealList
            recipes={filteredRecipes}
            foods={filteredFoods}
            date={values.dayNum.value}
            variant="dashboard"
          />
        </MealListContainer>
      </LeftColumn>
      <RightColumn flexDirection="column" alignItems="flex-end">
        <MealPlanInfoContainer flexDirection="column">
          <Info>Plan Length: {mealPlan.planLength} days</Info>
          <Info>Recommended Calories: {mealPlan.recommendedCalories}</Info>
          <Info>Recommended Fat: {mealPlan.recommended.fat}</Info>
          <Info>Recommended Carbs: {mealPlan.recommended.carbs}</Info>
          <Info>Recommended Protein: {mealPlan.recommended.protein}</Info>
          <Info>Date Created: {mealPlan.dateCreated || 'N/A'}</Info>
          <Info>Last Modified: {mealPlan.dateModified || 'N/A'}</Info>
        </MealPlanInfoContainer>
      </RightColumn>
      <AddButtonContainer justify="center" alignItems="center">
        <Button
          buttonSize="large"
          width="300px"
          buttonText="Add Meal Plan"
          pink="true"
          onClick={handleSubmit}
          data-test="mealPlanModal-submit"
        />
      </AddButtonContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 130%;
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 75%;
`;

const RightColumn = styled(FlexContainer)`
  padding-top: 15%;
  flex-basis: 25%;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const MealListContainer = styled(FlexContainer)`
  max-width: 90%;

  > * {
    margin-bottom: 10px;
  }
`;

const MealPlanInfoContainer = styled(FlexContainer)`
  margin: 16px 0;
`;

const MealPlanName = styled.h1`
  color: ${colors.primary800};
  margin: 16px 0 32px 0;
`;

const Info = styled.p`
  margin-bottom: 6px;
  color: ${colors.secondary600};
`;

export default MealPlanModalView;
