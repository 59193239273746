import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';

import RemoveXIcon from '../../icons/RemoveXIcon';
import NumericInput from '../../elements/NumericInput';
import { getFormattedUnits } from '../../helpers/exercise';

const SetRow = ({
  num,
  weekNum,
  set,
  displayWeight,
  repUnits,
  removeSet,
  handleValueChanged,
  handleChange,
  ...props
}) => {
  const formattedUnits = getFormattedUnits(repUnits);
  return (
    <TR>
      <TD>
        <Num>{num + 1}</Num>
      </TD>
      <TD>
        <NumericInput
          name={`repsNumber-${set.setId}`}
          value={set.repsNumber || ''}
          onChange={handleChange}
          onValueChanged={handleValueChanged}
          minValue="0"
          units={formattedUnits}
        />
      </TD>
      <TD>
        {displayWeight ? (
          <NumericInput
            name={`weightInLbs-${set.setId}`}
            value={set.weightInLbs || ''}
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            minValue="0"
            units="lbs"
          />
        ) : (
          <NoWeight>-</NoWeight>
        )}
      </TD>
      <TD absolute>
        <RemoveXIcon data-index={num} onClick={() => removeSet(set)} />
      </TD>
    </TR>
  );
};

const TR = styled.tr`
  height: 64px;
  position: relative;

  > :first-child {
    padding-left: 40px;
  }

  > :last-child {
    padding-right: 16px;
  }

  > :nth-child(5) {
    padding-left: 10px;
    padding-right: 56px;
  }

  &:hover {
    background-color: ${colors.primary050};
  }
  &:hover & ${RemoveXIcon} {
    visibility: none;
  }
`;

const NoWeight = styled.h4`
  color: ${colors.primary400};
  width: 68px;
`;

const TD = styled.td`
  padding: 0 20px;
  border: none;
  top: ${(props) => (props.absolute ? '50%' : 'inherit')};
  margin-top: ${(props) => (props.absolute ? '-15px' : 'inherit')};
  right: ${(props) => (props.absolute ? '10px' : 'inherit')};
`;

const Num = styled.h4`
  color: ${colors.primary800};
`;

export default SetRow;
