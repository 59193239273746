import { getTracks, editTrack } from '../../services/api/track';
import { trackTypes } from './defaults';

const loadTracks = async () => {
  const params = {
    query: {
      $or: [{ primary: true }, { loop: true }],
    },
  };
  const tracks = await getTracks(params);
  return tracks;
};

export const generateMap = async () => {
  const map = {};
  const tracks = await loadTracks();
  trackTypes.forEach((trackType) => {
    const primaryTrack = tracks.filter(
      (track) =>
        track.primary === true &&
        track.level === trackType.level &&
        track.locationType === trackType.locationType &&
        track.gender === trackType.gender,
    )[0];
    map[
      `${trackType.level}-${trackType.locationType}-${trackType.gender} (Primary)`
    ] = primaryTrack?._id;

    const loopTrack = tracks.filter(
      (track) =>
        track.loop === true &&
        track.level === trackType.level &&
        track.locationType === trackType.locationType &&
        track.gender === trackType.gender,
    )[0];
    map[
      `${trackType.level}-${trackType.locationType}-${trackType.gender} (Loop)`
    ] = loopTrack?._id;
  });
  return map;
};

export const assignProgression = async (
  incomingTrackId,
  loop,
  primary,
  trackType,
) => {
  const trackMap = await generateMap();
  try {
    if (primary === true) {
      if (trackType.level === 'Beginner') {
        // B - H - A (Primary):
        // -----------------
        // Back: B - H - A (Loop)
        // Remain: B - H - A (Loop)
        // Next: I - H - A (Primary)
        // NextL: I - H - A (Primary)
        // - do nothing to other tracks
        await editTrack(incomingTrackId, {
          active: true,
          primary: primary,
          loop: loop,
          backTrack:
            trackMap[
              `Beginner-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          remainTrack:
            trackMap[
              `Beginner-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          nextTrack:
            trackMap[
              `Intermediate-${trackType.locationType}-${trackType.gender} (Primary)`
            ] || incomingTrackId,
          nextLevel:
            trackMap[
              `Intermediate-${trackType.locationType}-${trackType.gender} (Primary)`
            ] || incomingTrackId,
        });
      } else if (trackType.level === 'Intermediate') {
        // I - H - A (Primary):
        // -----------------
        // Back: B - H - A (Loop)
        // Remain: I - H - A (Loop)
        // Next: A - H - A (Primary)
        // NextL: A - H - A (Primary)
        // - Update nextTrack and nextLevel for Beginner Primary
        // - Update nextLevel for Beginner Loop
        await editTrack(incomingTrackId, {
          active: true,
          primary: primary,
          loop: loop,
          backTrack:
            trackMap[
              `Beginner-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          remainTrack:
            trackMap[
              `Intermediate-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          nextTrack:
            trackMap[
              `Advanced-${trackType.locationType}-${trackType.gender} (Primary)`
            ] || incomingTrackId,
          nextLevel:
            trackMap[
              `Advanced-${trackType.locationType}-${trackType.gender} (Primary)`
            ] || incomingTrackId,
        });
        let beginnerPrimary =
          trackMap[
            `Beginner-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (beginnerPrimary) {
          await editTrack(beginnerPrimary, {
            nextTrack: incomingTrackId,
            nextLevel: incomingTrackId,
          });
        }
        let beginnerLoop =
          trackMap[
            `Beginner-${trackType.locationType}-${trackType.gender} (Loop)`
          ];
        if (beginnerLoop) {
          await editTrack(beginnerLoop, {
            nextLevel: incomingTrackId,
          });
        }
      } else if (trackType.level === 'Advanced') {
        // A - H - A (Primary):
        // -----------------
        // Back: I - H - A (Loop)
        // Remain: A - H - A (Loop)
        // Next: A - H - A (Loop)
        // NextL: A - H - A (Loop)
        // - Update nextTrack and nextLevel for Intermediate Primary
        // - Update nextLevel for Intermediate Loop
        await editTrack(incomingTrackId, {
          active: true,
          primary: primary,
          loop: loop,
          backTrack:
            trackMap[
              `Intermediate-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          remainTrack:
            trackMap[
              `Advanced-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          nextTrack:
            trackMap[
              `Advanced-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          nextLevel:
            trackMap[
              `Advanced-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
        });
        let intermediatePrimary =
          trackMap[
            `Intermediate-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (intermediatePrimary) {
          await editTrack(intermediatePrimary, {
            nextTrack: incomingTrackId,
            nextLevel: incomingTrackId,
          });
        }
        let intermediateLoop =
          trackMap[
            `Intermediate-${trackType.locationType}-${trackType.gender} (Loop)`
          ];
        if (intermediateLoop) {
          await editTrack(intermediateLoop, {
            nextLevel: incomingTrackId,
          });
        }
      }
    } else if (loop === true) {
      if (trackType.level === 'Beginner') {
        // B - H - A (Loop):
        // -----------------
        // Back: B - H - A (Loop)
        // Remain: B - H - A (Loop)
        // Next: B - H - A (Loop)
        // NextL: I - H - A (Primary)
        // - Update remainTrack and backTrack for Beginnner Primary
        // - Update backTrack for both Intermediate Primary and Intermediate Loop
        await editTrack(incomingTrackId, {
          active: true,
          primary: primary,
          loop: loop,
          backTrack: incomingTrackId,
          remainTrack: incomingTrackId,
          nextTrack: incomingTrackId,
          nextLevel:
            trackMap[
              `Intermediate-${trackType.locationType}-${trackType.gender} (Primary)`
            ] || incomingTrackId,
        });
        let beginnerPrimary =
          trackMap[
            `Beginner-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (beginnerPrimary) {
          await editTrack(beginnerPrimary, {
            remainTrack: incomingTrackId,
            backTrack: incomingTrackId,
          });
        }
        let intermediatePrimary =
          trackMap[
            `Intermediate-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (intermediatePrimary) {
          await editTrack(intermediatePrimary, {
            backTrack: incomingTrackId,
          });
        }
        let intermediateLoop =
          trackMap[
            `Intermediate-${trackType.locationType}-${trackType.gender} (Loop)`
          ];
        if (intermediateLoop) {
          await editTrack(intermediateLoop, {
            backTrack: incomingTrackId,
          });
        }
      } else if (trackType.level === 'Intermediate') {
        // I - H - A (Loop):
        // -----------------
        // Back: B - H - A (Loop)
        // Remain: I - H - A (Loop)
        // Next: I - H - A (Loop)
        // NextL: A - H - A (Primary)
        // - Update remainTrack for Intermediate Primary
        // - update backTrack for both Advanced Primary and Advanced Loop
        await editTrack(incomingTrackId, {
          active: true,
          primary: primary,
          loop: loop,
          backTrack:
            trackMap[
              `Beginner-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          remainTrack: incomingTrackId,
          nextTrack: incomingTrackId,
          nextLevel:
            trackMap[
              `Advanced-${trackType.locationType}-${trackType.gender} (Primary)`
            ] || incomingTrackId,
        });
        let intermediatePrimary =
          trackMap[
            `Intermediate-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (intermediatePrimary) {
          await editTrack(intermediatePrimary, {
            remainTrack: incomingTrackId,
          });
        }
        let advancedLoop =
          trackMap[
            `Advanced-${trackType.locationType}-${trackType.gender} (Loop)`
          ];
        if (advancedLoop) {
          await editTrack(advancedLoop, {
            backTrack: incomingTrackId,
          });
        }
        let advancedPrimary =
          trackMap[
            `Advanced-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (advancedPrimary) {
          await editTrack(advancedPrimary, {
            backTrack: incomingTrackId,
          });
        }
      } else if (trackType.level === 'Advanced') {
        // A - H - A (Loop):
        // -----------------
        // Back: I - H - A (Loop)
        // Remain: A - H - A (Loop)
        // Next: A - H - A (Loop)
        // NextL: A - H - A (Loop)
        // - update the remainTrack, nextTrack, and nextLevel for the Primary in same category
        await editTrack(incomingTrackId, {
          active: true,
          primary: primary,
          loop: loop,
          backTrack:
            trackMap[
              `Intermediate-${trackType.locationType}-${trackType.gender} (Loop)`
            ] || incomingTrackId,
          remainTrack: incomingTrackId,
          nextTrack: incomingTrackId,
          nextLevel: incomingTrackId,
        });
        let advancedPrimary =
          trackMap[
            `Advanced-${trackType.locationType}-${trackType.gender} (Primary)`
          ];
        if (advancedPrimary) {
          await editTrack(advancedPrimary, {
            remainTrack: incomingTrackId,
            nextTrack: incomingTrackId,
            nextLevel: incomingTrackId,
          });
        }
      }
    }
  } catch (err) {
    console.error(err.message);
    throw new Error(err.message);
  }
};
