import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MealPlanIcon from '../icons/MealPlanIcon';
import FitnessPlanIcon from '../icons/FitnessPlanIcon';
import DashboardIcon from '../icons/DashboardIcon';
import AddIconGreen from '../icons/AddIconGreen';
import ShoppingListIcon from '../icons/ShoppingListIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import styled from 'styled-components';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { formatDate } from '../helpers/date';
import { trackEvent } from '../integrations/analytics';
import {
  addFoodToMealPlan,
  addRecipeToMealPlan,
} from '../store/mealplan/selectedMealPlanSlice';
import {
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
} from '../store/fitnessplan/selectedFitnessPlanSlice';

const path = [
  '/app/explore',
  '/app/mealplan',
  '/app',
  '/app/fitnessplan',
  '/app/shoppinglist',
];

const BottomNav = ({ title: selectedTabTitle, path: addressUrl }) => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.selectedDate);
  const selectedMealType = useSelector((state) => state.selectedMealType);
  const mealPlan = useSelector((state) => state.selectedMealPlan);
  const fitnessPlan = useSelector((state) => state.fitnessPlan);

  const navigate = useNavigate();
  const location = useLocation();
  const { open, context, launchModal, closeModal } = useModal(false);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (selectedTabTitle === 'Meal Plan') setSelectedTabIndex(1);
    if (selectedTabTitle === 'Fitness Plan') setSelectedTabIndex(3);
    if (selectedTabTitle === 'Shopping List') setSelectedTabIndex(4);
  }, []);

  const addFoodToUserMealPlan = (params) => {
    trackEvent(`Add Food (${selectedTabTitle} - Mobile)`, {
      food: params.food,
    });
    dispatch(addFoodToMealPlan({ mealPlanId: mealPlan._id, params }));
  };

  const addRecipeToUserMealPlan = (params) => {
    trackEvent(`Add Recipe (${selectedTabTitle} - Mobile)`, {
      recipe: params.recipe,
    });
    dispatch(addRecipeToMealPlan({ mealPlanId: mealPlan._id, params }));
  };

  const addWorkoutToUserFitnessPlan = (params) => {
    trackEvent(`Add Workout (${selectedTabTitle} - Mobile)`, {
      workout: params.workout,
    });
    dispatch(
      addWorkoutToFitnessPlan({ fitnessPlanId: fitnessPlan.id, params }),
    );
  };

  const addActivityToUserFitnessPlan = (params) => {
    trackEvent(`Add Activity (${selectedTabTitle} - Mobile)`, {
      activity: params.activity,
    });
    dispatch(
      addActivityToFitnessPlan({ fitnessPlanId: fitnessPlan.id, params }),
    );
  };

  const handleCloseModal = () => {
    if (location.pathname.includes('/mealplan')) {
      closeModal();
      navigate('/app/mealplan');
    } else {
      closeModal();
    }
  };

  return (
    <StyledAppBar>
      <StyledBottomNavigation
        value={selectedTabIndex}
        onChange={(event, newValue) => {
          setSelectedTabIndex(newValue);
          closeModal();
          if (newValue === 2) return;
          navigate(path[newValue]);
        }}
        showLabels
      >
        <BottomNavigationAction
          label="Explore"
          icon={<DashboardIcon />}
          data-test="bottomNav-home"
        />
        <BottomNavigationAction
          label="Meals"
          icon={<MealPlanIcon />}
          data-test="bottomNav-meals"
        />
        <BottomNavigationAction
          label=""
          icon={
            <div style={{ transform: open ? 'rotate(45deg)' : 'none' }}>
              <AddIconGreen />
            </div>
          }
          onClick={(e) => {
            if (open) {
              closeModal();
            } else {
              launchModal(e);
            }
          }}
          data-date={formatDate(selectedDate)}
          data-variant={addressUrl.slice(5) || 'dashboard'}
          data-meal={selectedMealType}
          data-show-only="both"
          data-hide={selectedTabTitle === 'Shopping List' && 'recipe'}
          data-type={selectedTabTitle === 'Shopping List' && 'shoppinglist'}
          data-test="bottomNav-add"
        />
        <BottomNavigationAction
          label="Fitness"
          icon={<FitnessPlanIcon />}
          data-test="bottomNav-fitness"
        />
        <BottomNavigationAction
          label="Shopping"
          icon={<ShoppingListIcon />}
          data-test="bottomNav-shopping"
        />
      </StyledBottomNavigation>

      {open && (
        <ModalContainer
          context={context}
          open={open}
          handleClose={handleCloseModal}
          initialScreen={
            selectedTabTitle === 'Explore'
              ? 'Add Generic'
              : selectedTabTitle === 'Meal Plan' ||
                  selectedTabTitle === 'Recipe' ||
                  selectedTabTitle === 'Food'
                ? 'Add Meal Mobile'
                : selectedTabTitle === 'Shopping List'
                  ? 'Meal Search'
                  : 'Exercise Search' // TODO: When more fitness options added, make this go to 'Add Fitness'
            // https://app.clickup.com/t/1mzumqk
          }
          addFood={addFoodToUserMealPlan}
          addRecipe={addRecipeToUserMealPlan}
          addWorkout={addWorkoutToUserFitnessPlan}
          addActivity={addActivityToUserFitnessPlan}
        />
      )}
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  top: auto;
  bottom: 0;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: sticky;
  bottom: 0;
  width: 100vw;
  height: 64px;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.08);
`;

export default BottomNav;
