import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const RemoveIcon = ({
  selected,
  toggleSelect,
  orientation,
  shrinkIcon,
  hideLabel,
  small,
  ...props
}) => (
  <IconContainer
    flexDirection={orientation === 'row' ? 'row' : 'column'}
    alignItems="center"
    orientation={orientation}
    onClick={() => (orientation === 'row' ? toggleSelect() : null)}
  >
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      name="remove"
      onClick={toggleSelect}
      selected={selected}
      orientation={orientation}
      $small={small}
      {...props}
    >
      {orientation !== 'row' && (
        <circle
          cx="16"
          cy="16"
          r="14.8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <g style={shrinkIcon && { transform: 'scale(0.8) translate(4px, 4px)' }}>
        <path
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
          d="M9.9,10.5h12.1l0,0v12.8c0,1.4-1.2,2.6-2.6,2.6h-7c-1.4,0-2.6-1.2-2.6-2.6V10.5L9.9,10.5z"
        />
        <line
          x1="8.4"
          y1="8.3"
          x2="23.6"
          y2="8.3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <line
          x1="12.8"
          y1="6.1"
          x2="19.2"
          y2="6.1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <line
          x1="14"
          y1="13.5"
          x2="14"
          y2="22.9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <line
          x1="18"
          y1="13.5"
          x2="18"
          y2="22.9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
    </Icon>
    {!hideLabel && <Label orientation={orientation}>Remove</Label>}
  </IconContainer>
);

const IconContainer = styled(FlexContainer)`
  padding: ${(props) =>
    props.orientation === 'row' ? '2px 16px 2px 8px' : 'initial'};
  border-radius: ${(props) =>
    props.orientation === 'row' ? '4px' : 'initial'};
  background-color: ${(props) =>
    props.orientation === 'row' ? colors.hlitetwo050 : 'initial'};

  &:hover {
    cursor: ${(props) => (props.orientation === 'row' ? 'pointer' : 'initial')};
    background-color: ${(props) =>
      props.orientation === 'row' ? '#FBF1F1' : 'initial'};
  }
`;

const Icon = styled.svg`
  width: ${(props) =>
    props.width ? props.width : props.$small ? '24px' : '32px'};
  height: ${(props) =>
    props.height ? props.height : props.$small ? '24px' : '32px'};

  & circle {
    fill: ${(props) => (props.selected ? colors.hlitetwo400 : '#fff')};
    stroke: ${colors.hlitetwo400};
  }

  & path {
    fill: ${(props) => (props.selected ? colors.hlitetwo400 : '#fff')};
    stroke: ${(props) => (props.selected ? '#fff' : colors.hlitetwo400)};
  }

  & line {
    fill: ${(props) => (props.selected ? colors.hlitetwo400 : '#fff')};
    stroke: ${(props) => (props.selected ? '#fff' : colors.hlitetwo400)};
  }

  &:hover {
    cursor: pointer;
  }

  &:hover circle {
    fill: ${(props) =>
      props.orientation === 'row' ? 'inherit' : colors.hlitetwo400};
  }
  &:hover path,
  &:hover line {
    stroke: ${(props) =>
      props.orientation === 'row' ? 'colors.hlitetwo400' : '#fff'};
    fill: ${(props) =>
      props.orientation === 'row' ? 'fff' : colors.hlitetwo400};
  }

  &:focus circle {
    fill: ${(props) =>
      props.orientation === 'row' ? 'fff' : colors.hlitetwo400};
  }
  &:focus path,
  &:focus line {
    stroke: ${(props) =>
      props.orientation === 'row' ? 'colors.hlitetwo400' : '#fff'};
    fill: ${(props) =>
      props.orientation === 'row' ? 'fff' : colors.hlitetwo400};
  }

  &:active circle {
    fill: ${colors.hlitetwo300};
  }
  &:active path,
  &:active line {
    stroke: ${(props) =>
      props.orientation === 'row' ? 'colors.hlitetwo200' : '#fff'};
    fill: ${(props) =>
      props.orientation === 'row' ? 'fff' : colors.hlitetwo300};
  }

  &:disabled path,
  &:disabled circle,
  &:disabled line {
    fill: #fff;
    stroke: ${colors.primary300};
  }
`;

const Label = styled.p`
  font-size: ${(props) => (props.orientation === 'row' ? '15px' : '12px')};
  line-height: 14px;
  font-weight: ${(props) => (props.orientation === 'row' ? '500' : '700')};
  color: ${(props) =>
    props.orientation === 'row' ? colors.hlitetwo400 : colors.primary800};
  margin: ${(props) =>
    props.orientation === 'row' ? '0 0 0 4px' : '8px 0 0 0'};

  ${Icon}:disabled & {
    color: ${colors.primary400};
  }

  &:hover {
    cursor: ${(props) => (props.orientation === 'row' ? 'pointer' : 'inherit')};
  }
`;

export default RemoveIcon;
