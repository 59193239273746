import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

const ExerciseDetails = ({ exercise, sets, ...props }) => {
  const instructions = exercise ? exercise.instructions : [];
  const instructionsList = instructions.map((step) => {
    return <InstructionsStep key={step._id}>{step.step}</InstructionsStep>;
  });

  return (
    <ExerciseDetailsContainer flexDirection="column">
      <ExerciseSection flexDirection="column">
        <ExerciseSectionHeader>Instructions</ExerciseSectionHeader>
        <InstructionsList>{instructionsList}</InstructionsList>
      </ExerciseSection>
    </ExerciseDetailsContainer>
  );
};

const ExerciseDetailsContainer = styled(FlexContainer)``;

const ExerciseSection = styled(FlexContainer)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ExerciseSectionHeader = styled.h3`
  color: ${colors.primary800};
  margin-bottom: 16px;
`;

const Li = styled.li`
  font-size: 15px;
  line-height: 24px;
  color: ${colors.primary700};

  &::before {
    font-size: 14px;
    margin-right: 16px;
    margin-left: 12px;
  }
`;

const InstructionsList = styled.ol`
  margin: 0 0 0 12px;
  padding: 0 0 0 12px;
  list-style-position: outside;
`;

const InstructionsStep = styled(Li)`
  padding-left: 12px;
`;

export default ExerciseDetails;
