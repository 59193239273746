import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import ModalContent from './ModalContent';
import DialogContent from '@mui/material/DialogContent';
import { isMobile } from '../helpers/utils';
import MobileDialog from './mobile/MobileDialog';
import AddMealMobile from './mobile/AddMealMobile';
import AddFitness from './mobile/AddFitness';
import AddGenericDialog from './mobile/AddGenericDialog';
import { useLocation } from 'react-router-dom';

const ModalContainer = ({
  open,
  handleClose,
  data,
  initialScreen,
  ...props
}) => {
  const [screen, setScreen] = useState(initialScreen || 'Add Meal'); // take initial state from props
  const location = useLocation();

  useEffect(() => {
    setScreen(initialScreen);
  }, [initialScreen, open]);

  if (isMobile()) {
    // mobile version
    if (screen === 'Add Generic') {
      return (
        <AddGenericDialog
          open={open}
          handleClose={handleClose}
          setScreen={setScreen}
        />
      );
    }
    if (screen === 'Add Meal Mobile') {
      return (
        <AddMealMobile
          open={open}
          handleClose={handleClose}
          handleBackBtnClick={() => {
            if (location.pathname.includes('/mealplan')) {
              handleClose();
            } else {
              setScreen('Add Generic');
            }
          }}
          setScreen={setScreen}
        />
      );
    }
    if (screen === 'Add Fitness') {
      return (
        <AddFitness
          open={open}
          handleClose={handleClose}
          handleBackBtnClick={() => {
            if (location.pathname.includes('/fitnessplan')) {
              handleClose();
            } else {
              setScreen('Add Generic');
            }
          }}
          setScreen={setScreen}
        />
      );
    } else {
      return (
        <MobileDialog
          open={open}
          onClose={handleClose}
          data-test="mobile-modal"
        >
          <ModalContent
            open={open}
            handleClose={handleClose}
            screen={screen}
            setScreen={setScreen}
            initialScreen={initialScreen}
            {...props}
          />
        </MobileDialog>
      );
    }
  } else {
    // web version
    return (
      <Modal open={open} onClose={handleClose} data-test="modal">
        <StyledDialogContent onClick={(e) => e.stopPropagation()}>
          <ModalContent
            handleClose={handleClose}
            screen={screen}
            setScreen={setScreen}
            {...props}
          />
        </StyledDialogContent>
      </Modal>
    );
  }
};

const StyledDialogContent = styled(DialogContent)`
  height: 95%;
  width: 95%;
  margin: 1.5% auto;
  padding: 0;
  overflow: hidden;

  & .MuiDialogContent-root {
    padding: 0;
  }
`;

export default ModalContainer;
