import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api/api';

const initialState = {
  id: null,
  name: 'Default',
  level: 'Beginner',
  locationType: 'Home',
  gender: 'All',
  fitnessPlans: [],
  backTrack: '33aa433927361803a11a1712',
  remainTrack: '33aa433927361803a11a1718',
  nextTrack: '33aa433927361803a11a1723',
  nextLevel: '33aa433927361803a11a1723',
  active: true,
  primary: true,
  loop: false,
};

const trackPopulate = [
  {
    path: 'fitnessPlans.fitnessPlan',
  },
  {
    path: 'backTrack',
  },
  {
    path: 'remainTrack',
  },
  {
    path: 'nextTrack',
  },
  {
    path: 'nextLevel',
  },
];

const trackSlice = createSlice({
  name: 'track',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getUserTrack.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(switchTrack.fulfilled, (state, action) => {
        return action.payload;
      });
  },
});

export const getUserTrack = createAsyncThunk(
  'track/getUserTrack',
  async (user, { rejectWithValue }) => {
    const trackId = user.fitnessTrack?._id || user.fitnessTrack;
    const track = await apiCall('get', `/track/${trackId}`, {
      populate: trackPopulate,
    });
    return track;
  },
);

export const switchTrack = createAsyncThunk(
  'track/switchTrack',
  async ({ userId, trackId }, { rejectWithValue }) => {
    try {
      const track = apiCall('post', `/track/${trackId}/switch/${userId}`, {
        populate: trackPopulate,
      });
      return track;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default trackSlice.reducer;
