import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';

// NOTE: Future iterations of the UI will likely bring all text inputs into a similar style

const TextInput = ({
  label,
  hideLabel,
  error,
  required,
  flat,
  theme,
  minValue,
  ...props
}) => (
  <Container flexDirection="column" small={props.small} width={props.width}>
    {label && (
      <div style={{ marginBottom: '8px' }}>
        <Label htmlFor={props.name} theme={theme} flat={flat}>
          {label}
        </Label>
        {required && <Asterisk>*</Asterisk>}
      </div>
    )}
    <Input
      type={props.type || 'text'}
      error={error}
      required={props.required || false}
      flat={flat}
      theme={theme}
      $minValue={minValue}
      {...props}
    />
    {error && (
      <ErrorMessage data-test={`error-${props.name}`} theme={theme}>
        {error}
      </ErrorMessage>
    )}
  </Container>
);

const Container = styled(FlexContainer)`
  width: ${(props) =>
    props.width ? props.width : props.small ? '161px' : '100%'};
`;

const Input = styled.input`
  color: ${(props) => (props.error ? colors.error : colors.primary900)};
  box-sizing: border-box;
  height: ${(props) => (props.small ? '28px' : '41px')};
  width: 100%;
  margin: 0;
  padding: ${(props) =>
    props.style?.padding ? props.style?.padding : '0 12px'};
  font-size: ${(props) =>
    props.flat ? '14px' : props.small ? '15px' : '17px'};
  line-height: ${(props) =>
    props.flat ? '16px' : props.small ? '18px' : '20px'};
  border-radius: ${(props) => (props.flat ? '6px' : '2px')};
  background-color: ${(props) =>
    props.flat && props.theme === 'dark'
      ? '#f8fffb'
      : props.flat && props.theme === 'light'
        ? colors.primary050
        : '#fff'};
  position: relative;
  border: ${(props) =>
    props.error
      ? `1px solid ${colors.error}`
      : props.border === 'none' || (props?.flat && props?.theme === 'dark')
        ? 'none'
        : `1px solid ${colors.primary400}`};

  &::placeholder {
    color: ${colors.primary500};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &:focus {
      border: ${(props) =>
        props.error
          ? `1px solid ${colors.error}`
          : props.border === 'none' || (props?.flat && props?.theme === 'dark')
            ? 'none'
            : props?.theme === 'light'
              ? '1px solid rgba(0, 0, 0, 0.13)'
              : `1px solid ${colors.secondary500}`};
      box-shadow: ${(props) =>
        props.border === 'none' ? 'none' : '0 2px 4px 0 rgba(0, 0, 0, 0.1)'};
    }

    &:disabled {
      background-color: ${(props) =>
        props?.flat && props?.theme === 'dark'
          ? 'rgba(248, 255, 251, 0.38)'
          : colors.primary050};
      color: ${colors.primary300};
    }
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) =>
    props?.theme === 'dark' ? colors.hlitetwo300 : colors.error};
  letter-spacing: 0.03em;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin: ${(props) => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: ${(props) => (props.flat ? '14px' : '13px')};
  line-height: ${(props) => (props.flat ? '16px' : '15px')};
  color: ${(props) =>
    props?.theme === 'dark'
      ? colors.primary050
      : props?.theme === 'light'
        ? colors.primary800
        : colors.primary600};
`;

const Asterisk = styled.label`
  color: #ff6868;
`;

export default TextInput;
