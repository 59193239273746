import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import StaticSearchBar from '../../elements/StaticSearchBar';
import { getAllUsers, searchUsers } from '../../services/api/user';
import { colors } from '../../styleConstants';
import useFormValues from '../../hooks/useFormValues';
import TextInput from '../../elements/TextInput';
import Dropdown from '../../elements/Dropdown';
import Button from '../../elements/Button';
import RadioInput from '../../elements/RadioInput';
import UserExport from './UserExport';
import DateInput from '../../elements/DateInput';
import { DateTime } from 'luxon';

const UserSearch = ({ setUser, type, ...props }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchType, setSearchType] = useState('all');

  // Simple Search
  useEffect(() => {
    if (!searchQuery) {
      return;
    }
    getAllUsers()
      .then((results) => {
        const userList = results.filter((user) =>
          user.fullName.toLowerCase().includes(searchQuery.toLowerCase()),
        );
        setUsers(userList);
      })
      .catch((err) => {
        console.error(err);
        setUsers([]);
      });
  }, [searchQuery]);

  const handleSearch = (inputValue) => {
    setSearchQuery(inputValue);
  };

  // Advanced Search
  const handleRadio = (e) => {
    setSearchType(e.target.value);
  };

  const searchAll = () => {
    getAllUsers()
      .then((results) => {
        setUsers(results);
        setCount(results.length);
      })
      .catch((err) => {
        console.error(err);
        setUsers([]);
      });
  };

  const advancedSearch = () => {
    const query = { name: values.name, email: values.email };

    if (['True', 'False'].includes(values.isAdmin)) {
      // ignore N/A
      query.isAdmin = values.isAdmin.toLowerCase();
    }
    if (['True', 'False'].includes(values.isVerified)) {
      // ignore N/A
      query.isVerified = values.isVerified.toLowerCase();
    }
    if (['True', 'False'].includes(values.intakeComplete)) {
      // ignore N/A
      query.intakeComplete = values.intakeComplete.toLowerCase();
    }

    query.createdAt = { $gte: values.dateFrom, $lte: values.dateTo };

    searchUsers({ query })
      .then((results) => {
        setUsers(results);
        setCount(results.length);
      })
      .catch((err) => {
        console.error(err);
        setUsers([]);
      });
  };

  const handleAdvancedSearch = async () => {
    if (searchType === 'all') {
      searchAll();
    } else if (searchType === 'advanced') {
      advancedSearch();
    }
  };

  const initialValues = {
    dateFrom: DateTime.now().minus({ years: 4 }).startOf('day'),
    dateTo: DateTime.now().endOf('day'),
  };

  const { values, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  // User select

  const handleClick = (e) => {
    const userId = e.target.dataset.name;
    const user = users.find((user) => user._id === userId);
    setSelected(userId);
    setUser(user);
  };

  const searchFields =
    type === 'advanced' ? (
      <SearchContainer flexDirection="column">
        <Header>Search Options</Header>
        <FlexContainer flexDirection="row" justify="space-around">
          <RadioInput
            checked={searchType === 'all'}
            value="all"
            label="All Users"
            onChange={handleRadio}
            name="search"
            data-test="user-searchType-all"
          />
          <RadioInput
            checked={searchType === 'advanced'}
            value="advanced"
            label="Advanced Search"
            onChange={handleRadio}
            name="search"
            data-test="user-searchType-advanced"
          />
        </FlexContainer>
        <Form>
          {searchType === 'advanced' && (
            <FieldSet disabled={searchType === 'all'}>
              <TextInput
                id="userName"
                name="name"
                onChange={handleChange}
                value={values.name || ''}
                placeholder="Name"
                label="Name"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'Name')}
                data-test="user-name"
              />
              <TextInput
                id="userEmail"
                name="email"
                onChange={handleChange}
                value={values.email || ''}
                placeholder="Email"
                label="Email"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = 'Email')}
                data-test="user-email"
              />
              <Dropdown
                width="320px"
                options={['N/A', 'True', 'False']}
                label="Admin user?"
                value={{
                  value: values.isAdmin,
                  label: values.isAdmin,
                }}
                onChange={(value) =>
                  handleValueChanged('isAdmin', value.value, 'string')
                }
                data-test="user-isAdmin"
              />
              <Dropdown
                width="320px"
                options={['N/A', 'True', 'False']}
                label="Verified user?"
                value={{
                  value: values.isVerified,
                  label: values.isVerified,
                }}
                onChange={(value) =>
                  handleValueChanged('isVerified', value.value, 'string')
                }
                data-test="user-isVerified"
              />
              <Dropdown
                width="320px"
                options={['N/A', 'True', 'False']}
                label="Intake Complete?"
                value={{
                  value: values.intakeComplete,
                  label: values.intakeComplete,
                }}
                onChange={(value) =>
                  handleValueChanged('intakeComplete', value.value, 'string')
                }
                data-test="user-intakeComplete"
              />
              <DateInput
                label="From:"
                name="dateFrom"
                width="140px"
                value={
                  values.dateFrom ||
                  DateTime.now().minus({ years: 4 }).startOf('day')
                }
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                showMonthYearDropdown
              />

              <DateInput
                label="To:"
                name="dateTo"
                width="140px"
                value={values.dateTo || DateTime.now().endOf('day')}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                showMonthYearDropdown
              />
            </FieldSet>
          )}
        </Form>
        <Button
          type="submit"
          buttonText="Search"
          buttonSize="large"
          onClick={handleAdvancedSearch}
          data-test="user-search-submit"
        />
      </SearchContainer>
    ) : (
      <StaticSearchBar handleSearch={handleSearch} data-test="user-search" />
    );

  const usersList = users.map((user) => {
    return (
      <ResultRow
        flexDirection="column"
        justify="center"
        key={user._id}
        data-name={user._id}
        selected={user._id === selected}
        onClick={handleClick}
      >
        <ResultText data-name={user._id} onClick={handleClick}>
          {user.fullName}
        </ResultText>
      </ResultRow>
    );
  });

  return (
    <Container flexDirection="column">
      {searchFields}
      {users.length > 0 && (
        <ResultsContainer flexDirection="column">
          <Header>Results</Header>
          <ResultsList flexDirection="column" data-test="user-results">
            {usersList}
          </ResultsList>
          <Count>{count} users</Count>
          {type === 'advanced' && <UserExport users={users} />}
        </ResultsContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  min-width: 350px;
`;

const SearchContainer = styled(FlexContainer)`
  margin-right: 24px;
`;

const ResultsContainer = styled(FlexContainer)``;

const ResultsList = styled(FlexContainer)`
  overflow-y: scroll;
  height: 200px;
  max-height: 500px;
  width: 300px;
  border: 0.5px solid black;
  border-radius: 8px;
`;

const Header = styled.h2`
  margin: 16px 0;
  color: ${colors.primary800};
`;

const Count = styled.h4`
  margin: 16px 0;
  color: ${colors.primary500};
`;

const ResultRow = styled(FlexContainer)`
  height: 16px;
  padding: 12px;
  width: calc(100% - 24px);
  background-color: ${(props) =>
    props.selected ? colors.secondary200 : '#fff'};

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.selected ? colors.secondary200 : '#ccc'};
  }
`;

const ResultText = styled.p`
  color: ${colors.primary800};
`;

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

export default UserSearch;
