import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';

const MobileDialogHeaderText = ({ children, color, marginLeft }) => (
  <HeaderText
    color={color}
    $marginLeft={marginLeft}
    data-test="mobileModal-header"
  >
    {children}
  </HeaderText>
);

const HeaderText = styled.div`
  color: ${(props) => props.color || colors.primary800};
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: ${(props) => props.$marginLeft || '8px'};
`;
export default MobileDialogHeaderText;
