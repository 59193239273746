import { DateTime } from 'luxon';

/**
 * Format the given date in a ISO 8601 UTC date time format with the time set to 0
 * @param dateInput
 * @param convert boolean Whether or not to take into consideration the timezone
 * @returns the date formatted.
 */
export const formatDate = (dateInput = new Date(), convert = true) => {
  // Parse date input and ensure it's in UTC
  let dateOutput;
  if (typeof dateInput === 'string') {
    // Handle different string formats
    dateOutput = DateTime.fromISO(dateInput, { setZone: true });
    if (!dateOutput.isValid) {
      dateOutput = DateTime.fromRFC2822(dateInput, { setZone: true });
    }
    if (!dateOutput.isValid) {
      dateOutput = DateTime.fromHTTP(dateInput, { setZone: true });
    }
    if (!dateOutput.isValid) {
      dateOutput = DateTime.fromFormat(
        dateInput,
        'EEE MMM dd yyyy HH:mm:ss zzz',
        { setZone: true },
      );
    }
    if (!dateOutput.isValid) {
      dateOutput = DateTime.fromFormat(dateInput, 'M/d/yyyy');
    }
  } else if (dateInput instanceof Date) {
    // Handle Date object
    dateOutput = DateTime.fromJSDate(dateInput);
  } else if (typeof dateInput === 'object') {
    let newDate = new Date(dateInput);
    dateOutput = DateTime.fromJSDate(newDate);
  } else {
    // Fallback in case of unexpected input
    throw new Error('Unsupported date input format');
  }

  // Check if dateOutput is valid
  if (!dateOutput.isValid) {
    throw new Error('Invalid date input');
  }

  // Return the formatted date string
  return dateOutput.startOf('day').toISO({ includeOffset: false });
};

export const getFormattedDate = (dateInput = DateTime.now().toISO()) => {
  return DateTime.fromISO(dateInput).toFormat('yyyy-MM-dd');
};

export const isPastDate = (date) => {
  const today = DateTime.now().toFormat('yyyy-MM-dd');
  const lDate = DateTime.fromISO(formatDate(date)).toFormat('yyyy-MM-dd');
  return today > lDate;
};

export const getMonthFromString = (mon) => {
  return new Date(Date.parse(mon + ' 1, 2021')).getMonth() + 1;
};
