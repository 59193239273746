import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import CustomRouter from './CustomRouter';
import { Provider } from 'react-redux';
import { configureRedux } from './store';
import { StyledEngineProvider } from '@mui/material/styles';
import ReactGA from 'react-ga';
import { logAppInfo } from './utils/appUtils';

import App from './App';
import { initializeAnalytics } from './integrations/analytics';
import { createBrowserHistory } from 'history';

// Display troubleshooting info in the console
logAppInfo();

const store = configureRedux();

if (process.env.REACT_APP_ENV === 'production') {
  // Initialize Userpilot
  initializeAnalytics();

  ReactGA.initialize(
    [
      {
        trackingId: 'UA-145311058-1',
        gaOptions: {
          name: 'marketingTracker',
          allowLinker: true,
        },
      },
      {
        trackingId: 'UA-145311058-2',
        gaOptions: { name: 'appTracker' },
      },
    ],
    { alwaysSendToDefaultTracker: false },
  );
  ReactGA.ga('marketingTracker.require', 'linker');
  ReactGA.ga('marketingTracker.linker:autoLink', ['nutriology.io']);

  ReactGA.pageview(window.location.pathname + window.location.search, [
    'marketing-tracker',
    'app-tracker',
  ]);
}

const history = createBrowserHistory();

const Nutriology = (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <CustomRouter history={history}>
        <App />
      </CustomRouter>
    </StyledEngineProvider>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(Nutriology);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
