import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../elements/TextInput';
import NumericInput from '../../elements/NumericInput';
import Dropdown from '../../elements/Dropdown';
import Button from '../../elements/Button';
import CheckBox from '../../elements/CheckBox';
import AddImage from '../../elements/AddImage';
import AlertStack from '../../elements/AlertStack';

import { getExercise } from '../../services/api/exercise';
import {
  getWorkoutDestinationIndex,
  getWorkoutSequenceFromDroppableObj,
} from '../../helpers/workout';

import WorkoutSimplified from './WorkoutSimplified';
import { fitnessTags } from '../../helpers/fitnessTags';

const WorkoutForm = ({
  imageUrl,
  setImageUrl,
  handleChange,
  values,
  handleValueChanged,
  toggleValues,
  handleToggle,
  exercises,
  setExercises,
  workoutInfo,
  setWorkoutInfo,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  // Workout Image
  const inputFile = useRef(null);

  const handleChangeImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleImage = () => {
    inputFile.current.click();
  };

  // Exercise sort
  function sortExercises() {
    exercises.sort((a, b) => {
      switch (a.workoutSequence) {
        case 'Warm Up':
          if (b.workoutSequence === 'Warm Up') {
            return 0;
          } else {
            return -1;
          }
        case 'Core':
          if (b.workoutSequence === 'Core') {
            return 0;
          } else if (b.workoutSequence === 'Warm Up') {
            return 1;
          } else {
            return -1;
          }
        case 'Strength':
          if (b.workoutSequence === 'Strength') {
            return 0;
          } else if (
            b.workoutSequence === 'Warm Up' ||
            b.workoutSequence === 'Core'
          ) {
            return 1;
          } else {
            return -1;
          }
        case 'Circuit':
          if (b.workoutSequence === 'Circuit') {
            return 0;
          } else if (
            b.workoutSequence === 'Warm Up' ||
            b.workoutSequence === 'Core' ||
            b.workoutSequence === 'Strength'
          ) {
            return 1;
          } else {
            return -1;
          }
        case 'Conditioning':
          if (b.workoutSequence === 'Conditioning') {
            return 0;
          } else if (
            b.workoutSequence === 'Warm Up' ||
            b.workoutSequence === 'Core' ||
            b.workoutSequence === 'Strength' ||
            b.workoutSequence === 'Circuit'
          ) {
            return 1;
          } else {
            return -1;
          }
        case 'Cool Down':
          if (b.workoutSequence === 'Cool Down') {
            return 0;
          } else if (
            b.workoutSequence === 'Warm Up' ||
            b.workoutSequence === 'Core' ||
            b.workoutSequence === 'Strength' ||
            b.workoutSequence === 'Circuit' ||
            b.workoutSequence === 'Conditioning'
          ) {
            return 1;
          } else {
            return -1;
          }
        default:
          return 0;
      }
    });
    // We need to keep track of the index within the global array
    // to be able to reorder the exercises once they have been filter by sequence
    exercises.forEach((element, index) => {
      element.globalIndex = index;
    });
  }
  // Make sure we start on the right foot and have the exercises sorted on first render.
  sortExercises();

  async function addExerciseToForm(params) {
    const result = await getExercise(params.exercise);
    const exercise = {
      workoutSequence: params.workoutSequence,
      exercise: result,
      sets: [],
      id: exercises && exercises.length + 1,
    };

    setExercises([...exercises, exercise]);
  }

  async function removeExerciseFromForm(exerciseId) {
    try {
      const updatedExercises = exercises.filter((exercise) => {
        if (exercise._id) {
          return exercise._id !== exerciseId;
        }
        return exercise.id !== parseInt(exerciseId, 10);
      });
      setExercises(updatedExercises);
    } catch (err) {
      console.error(err);
    }
  }

  async function reorderExercises(exercisesList, source, destination) {
    const sourceIndex = source.index;
    const destinationIndex = getWorkoutDestinationIndex(source, destination);
    const destinationSequence = getWorkoutSequenceFromDroppableObj(destination);

    const result = Array.from(exercisesList);
    let [exerciseRemoved] = result.splice(sourceIndex, 1);
    exerciseRemoved.workoutSequence = destinationSequence.title;
    result.splice(destinationIndex, 0, exerciseRemoved);

    setExercises(result);
  }

  useEffect(() => {
    sortExercises();
  }, [exercises]);

  async function updateSets(exerciseToUpdate, sets) {
    try {
      const exerciseIndex = exercises.findIndex(
        (exercise) =>
          exerciseToUpdate === exercise._id || exerciseToUpdate === exercise.id,
      );

      const exerciseTmp = [...exercises];
      exerciseTmp[exerciseIndex].sets = sets;
      setExercises(exerciseTmp);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    exercises.forEach((exercise) => {
      const exerciseId = exercise._id ? exercise._id : exercise.id;
      const updatedSets = exercise.sets.filter(
        (set) => set.week <= values.numberOfWeeks,
      );
      updateSets(exerciseId, updatedSets);
    });
    const updatedWorkoutInfo = workoutInfo.filter(
      (info) => info.week <= values.numberOfWeeks,
    );

    while (updatedWorkoutInfo.length < values.numberOfWeeks) {
      const newWorkout = {
        ...updatedWorkoutInfo[updatedWorkoutInfo.length - 1],
        week: updatedWorkoutInfo.length + 1,
      };
      updatedWorkoutInfo.push(newWorkout);
    }
    setWorkoutInfo(updatedWorkoutInfo);
  }, [values.numberOfWeeks]);

  const handleDynFieldChange = (name, index, value) => {
    const updatedWorkoutInfo = [...workoutInfo];
    updatedWorkoutInfo[index][name] = parseFloat(value);
    setWorkoutInfo(updatedWorkoutInfo);
  };

  const handleDynFieldValueChange = (name, value) => {
    const n = name.split('-')[0];
    const i = name.split('-')[1];

    const updatedWorkoutInfo = [...workoutInfo];
    updatedWorkoutInfo[i][n] = parseFloat(value);
    setWorkoutInfo(updatedWorkoutInfo);
  };

  const headers = [];
  for (let i = 1; i <= values.numberOfWeeks; i++) {
    headers.push(<th key={i}>{`Week ${i}`}</th>);
  }

  const caloriesBurned = workoutInfo.map((info, index) => {
    return (
      <td key={index}>
        <NumericInput
          id={`caloriesBurned-${index}`}
          name={`caloriesBurned-${index}`}
          onChange={(e) =>
            handleDynFieldChange('caloriesBurned', index, e.target.value)
          }
          onValueChanged={handleDynFieldValueChange}
          value={workoutInfo[index].caloriesBurned || ''}
          minValue="0"
          label="Estimated Calories Burned"
          units="kCal"
        />
      </td>
    );
  });

  const intensity = workoutInfo.map((info, index) => {
    return (
      <td key={index}>
        <Dropdown
          width="320px"
          options={[1, 2, 3, 4, 5]}
          label="Workout Intensity"
          value={{
            value: workoutInfo[index].intensity,
            label: workoutInfo[index].intensity,
          }}
          onChange={(value) =>
            handleDynFieldChange('intensity', index, value.value)
          }
        />
      </td>
    );
  });

  const duration = workoutInfo.map((info, index) => {
    return (
      <td key={index}>
        <NumericInput
          id={`duration-${index}`}
          name={`duration-${index}`}
          onChange={(e) =>
            handleDynFieldChange('duration', index, e.target.value)
          }
          onValueChanged={handleDynFieldValueChange}
          value={workoutInfo[index].duration || ''}
          minValue="0"
          label="Workout Duration"
          units="mins"
        />
      </td>
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <CheckBox
          checked={toggleValues.active || ''}
          label="Active"
          onChange={handleToggle()}
          name="active"
          data-test="workout-active"
        />
        <input
          hidden
          ref={inputFile}
          type="file"
          name="image"
          onChange={handleChangeImage}
        />
        <AddImage src={imageUrl} onClick={handleImage} />
        <TextInput
          id="workoutName"
          name="name"
          onChange={handleChange}
          value={values.name || ''}
          placeholder="Workout Name"
          label="Workout Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Workout Name')}
          data-test="workout-name"
        />
        <Dropdown
          width="430px"
          options={['Gym', 'Home']}
          label="Primary Location/Setting"
          value={{
            value: values.locationType,
            label: values.locationType,
          }}
          onChange={(value) =>
            handleValueChanged('locationType', value.value, 'string')
          }
          data-test="workout-locationType"
        />
        <Dropdown
          width="430px"
          options={['All', 'Men', 'Women']}
          label="Target Gender"
          value={{
            value: values.gender,
            label: values.gender,
          }}
          onChange={(value) =>
            handleValueChanged('gender', value.value, 'string')
          }
          data-test="workout-gender"
        />
        <TextInput
          id="source"
          name="source"
          onChange={handleChange}
          value={values.source || ''}
          placeholder="Source"
          label="Source"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Source')}
          data-test="workout-source"
        />
        <TextInput
          id="attrLink"
          name="attrLink"
          onChange={handleChange}
          value={values.attrLink || ''}
          placeholder="Attribution Link"
          label="Attribution Link"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Attribution Link')}
          data-test="recipe-attrLink"
        />
        <Dropdown
          width="320px"
          options={[1, 2, 3, 4, 5]}
          label="Number of Weeks"
          value={{
            value: values.numberOfWeeks,
            label: values.numberOfWeeks,
          }}
          onChange={(value) =>
            handleValueChanged('numberOfWeeks', value.value, 'string')
          }
          data-test="workout-numWeeks"
        />

        <FlexContainer
          style={{ width: '100%', marginBottom: '50px' }}
          justify="space-between"
        >
          <NumericInput
            id="warmUpRest"
            name="restBetweenSets.warmUp"
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            value={values.restBetweenSets.warmUp || ''}
            minValue="0"
            label="Warm Up Rest"
            units="mins"
            data-test="workout-warmUpRest"
          />
          <NumericInput
            id="coreRest"
            name="restBetweenSets.core"
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            value={values.restBetweenSets.core || ''}
            minValue="0"
            label="Core Rest"
            units="mins"
            data-test="workout-coreRest"
          />
          <NumericInput
            id="strengthRest"
            name="restBetweenSets.strength"
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            value={values.restBetweenSets.strength || ''}
            minValue="0"
            label="Strength Rest"
            units="mins"
            data-test="workout-strengthRest"
          />
          <NumericInput
            id="circuitRest"
            name="restBetweenSets.circuit"
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            value={values.restBetweenSets.circuit || ''}
            minValue="0"
            label="Circuit Rest"
            units="mins"
            data-test="workout-circuitRest"
          />
          <NumericInput
            id="conditioningRest"
            name="restBetweenSets.conditioning"
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            value={values.restBetweenSets.conditioning || ''}
            minValue="0"
            label="Conditioning Rest"
            units="mins"
            data-test="workout-conditioningRest"
          />
          <NumericInput
            id="coolDownRest"
            name="restBetweenSets.coolDown"
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            value={values.restBetweenSets.coolDown || ''}
            minValue="0"
            label="Cool Down Rest"
            units="mins"
            data-test="workout-coolDownRest"
          />
        </FlexContainer>
        <HR />
        <Heading>Week-Specific Workout Info</Heading>
        <TableContainer>
          <Table>
            <tbody>
              <TR>{headers}</TR>
              <TR>{intensity}</TR>
              <TR>{duration}</TR>
              <TR>{caloriesBurned}</TR>
            </tbody>
          </Table>
        </TableContainer>
        <HR />
        <Heading>Exercises</Heading>
        <WorkoutSimplified
          exercises={exercises}
          numberOfWeeks={values.numberOfWeeks}
          addExercise={addExerciseToForm}
          removeExercise={removeExerciseFromForm}
          reorderExercises={reorderExercises}
          updateSets={updateSets}
          viewOnly={viewOnly}
          variant="admin"
        />
        <HR />
        <Heading>Tags</Heading>
        {fitnessTags.map((tagGroup, index) => (
          <FlexContainer
            key={index.toString()}
            flexDirection="column"
            flexShrink="0"
          >
            <Tag>{tagGroup.label}</Tag>
            <CheckContainer flexDirection="column">
              {tagGroup.tags.map((tag, tIndex) => (
                <CheckBox
                  key={tIndex.toString()}
                  checked={toggleValues[tagGroup.name][tag.name] || ''}
                  label={tag.label}
                  onChange={handleToggle(tagGroup.name)}
                  name={tag.name}
                  data-test={`workout-${tagGroup.name}-${tag.name}`}
                />
              ))}
            </CheckContainer>
          </FlexContainer>
        ))}
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Workout'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="workout-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="workout-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="workout-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="workout-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  max-width: 100%;
  margin-top: 30px;

  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const TableContainer = styled(FlexContainer)`
  max-width: 900px;
  overflow: scroll;
`;

const Table = styled.table`
  max-width: 100%;
  overflow: scroll;
`;

const TR = styled.tr`
  > * {
    padding-right: 24px;
    padding-bottom: 12px;
  }
`;
const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const CheckContainer = styled(FlexContainer)`
  max-height: 275px;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
const Tag = styled.h3`
  color: ${colors.secondary500};
  margin-bottom: 15px;
`;

export default WorkoutForm;
