import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { useDispatch } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import IntensitySet from '../elements/IntensitySet';
import LogIcon from '../icons/LogIcon';
import TrashIcon from '../icons/TrashIcon';
import { getImageURL } from '../services/api/api';
import { toggleAllComplete } from '../services/api/workout';

import {
  setFitnessPlanWorkoutProperty,
  setFitnessPlanActivityProperty,
} from '../store/fitnessplan/selectedFitnessPlanSlice';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const FitnessRow = ({ item, userItem, removeItem, fitnessPlan }) => {
  const dispatch = useDispatch();
  const handleRemove = (e) => {
    removeItem(userItem._id);
  };

  async function handleLog(event) {
    if (userItem.activity) {
      dispatch(
        setFitnessPlanActivityProperty({
          fitnessPlan,
          activity: userItem,
          property: 'logged',
          value: !userItem.logged,
        }),
      );
    } else if (userItem.workout) {
      const params = {
        allComplete: userItem.allComplete,
      };

      await toggleAllComplete(userItem.workout._id, params);
      dispatch(
        setFitnessPlanWorkoutProperty({
          fitnessPlan,
          workout: userItem,
          property: 'allComplete',
          value: !userItem.allComplete,
        }),
      );
    }
  }

  const itemLogged = userItem.activity ? userItem.logged : userItem.allComplete;

  const imgUrl = (item, ratio = 1) => {
    if (item.imageUrl) {
      const height = 64 * ratio;
      const width = 64 * ratio;
      return getImageURL(
        item.imageUrl,
        `resizing_type:fill/height:${height}/width:${width}`,
      );
    } else {
      return `${fileStorage}/WorkoutIcon.png`;
    }
  };

  return (
    <Container justify="space-between" data-test="fitnessRow">
      <LeftContainer alignItems="center" data-test="fitness-info-container">
        <StyledAvatar src={imgUrl(item, 1)} srcSet={`${imgUrl(item, 2)} 2x`} />
        <FitnessInfoContainer flexDirection="column">
          <FitnessHeader data-test="fitness-name">{item.name}</FitnessHeader>
          <FitnessInfoRow alignItems="center">
            <FitnessInfo data-test="fitness-caloriesBurned">
              {item.caloriesBurned} calories
            </FitnessInfo>
            <FitnessTime data-test="fitness-duration">
              {item.duration} mins
            </FitnessTime>
          </FitnessInfoRow>
          <IntensityContainer>
            <FitnessInfo>Intensity: </FitnessInfo>
            <IntensitySet
              intensity={item.intensity}
              data-test="fitness-intensity"
            />
          </IntensityContainer>
        </FitnessInfoContainer>
      </LeftContainer>
      <RightContainer>
        <LogIcon
          hideLabel
          logged={itemLogged}
          toggleSelect={handleLog}
          data-test="fitness-log"
        />
      </RightContainer>
      <DeleteContainer>
        <StyledTrashIcon onClick={handleRemove} data-test="fitness-remove" />
      </DeleteContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 0 32px 0;
  border-top: 1px solid ${colors.primary200};
`;

const StyledAvatar = styled(Avatar)`
  height: 64px;
  width: 64px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 32px;
  width: 32px;
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
`;
const RightContainer = styled(FlexContainer)`
  height: 100%;
`;

const DeleteContainer = styled(FlexContainer)`
  visibility: hidden;
  position: absolute;
  right: -32px;
  top: 26px;
  z-index: 10;

  ${Container}:hover & {
    visibility: visible;
  }
`;

const FitnessInfoContainer = styled(FlexContainer)`
  margin-left: 32px;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const FitnessInfoRow = styled(FlexContainer)`
  > * {
    margin-right: 24px;
  }
`;

const FitnessHeader = styled.h2`
  color: ${colors.primary700};
`;

const FitnessTime = styled.h6`
  font-weight: 400;
  color: ${colors.primary500};
`;

const FitnessInfo = styled.h6`
  color: ${colors.primary600};
`;

const IntensityContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 8px;
  }
`;

export default FitnessRow;
