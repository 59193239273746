import { apiCall } from './api';

export function getMealPlan(id, params = {}) {
  return apiCall('get', `/mealplan/${id}`, params);
}

export function getMealPlans(params) {
  return apiCall('get', `/mealplan`, params);
}

export function getUserMealPlans(params) {
  return apiCall('get', `/mealplanuser`, params);
}

export function addUserMealPlan(params) {
  return apiCall('post', `/mealplanuser`, params);
}

export function addMealPlan(params) {
  return apiCall('post', `/mealplan`, params);
}

export function editMealPlan(id, params) {
  return apiCall('put', `/mealplan/${id}`, params);
}

export function updateAdminMealPlan(id, updateType, params) {
  return apiCall('put', `/mealplan/${updateType}/${id}`, params);
}

export function deleteUserMealPlan(id, params) {
  return apiCall('delete', `/mealplanuser/${id}`, params);
}

// deletes meal plans for the given user in a specific time slot
export function deleteUserMealPlans(user, startDate) {
  if (!user || !startDate) {
    return;
  }
  const params = {
    query: {
      user: user,
      startDate: startDate,
    },
  };
  return apiCall('delete', `/mealplanuser`, params);
}

export function createUserMealPlan(id, params) {
  return apiCall('post', `/mealplan/assign/${id}`, params);
}

export function autoAssignMealPlan(user, params) {
  return apiCall('post', `/mealplan/assign_one/${user}`, params);
}

export function searchMealPlans(params) {
  return apiCall('get', `/mealplan/search`, params);
}
