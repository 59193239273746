import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { useSelector } from 'react-redux';
import { FavoriteBorder } from '@mui/icons-material';

const FoodCard = ({ food, setFood, setScreen, isMobile, ...props }) => {
  const favoriteFoods = useSelector(
    (state) => state.currentUser.user.favoriteFoods,
  );
  const handleClick = (e) => {
    e.stopPropagation();
    setFood(food);
    setScreen('Food Page');
  };
  const isFavorite = favoriteFoods.includes(food._id);

  return (
    <CardContainer
      flexDirection="column"
      alignItems="center"
      justify="center"
      onClick={handleClick}
      data-test="mealModal-foodCard"
      isMobile={isMobile}
    >
      {isFavorite && <StyledFavoriteIcon data-test="foodCard-favorite" />}
      <TitleContainer>
        <MealTitle data-test="foodCard-name">{food.verboseName}</MealTitle>
      </TitleContainer>
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  height: 124px;
  width: ${(props) => (props.isMobile ? '100%' : '192px')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`;

const TitleContainer = styled(FlexContainer)`
  padding: 0 16px;
  text-align: center;
`;

const MealTitle = styled.h4`
  color: ${colors.primary800};
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  display: -webkit-box;
`;

const StyledFavoriteIcon = styled(FavoriteBorder)`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  color: ${colors.hlitetwo400};
`;

export default FoodCard;
