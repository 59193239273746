import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../elements/TextInput';
import TextBox from '../../elements/TextBox';
import AddImage from '../../elements/AddImage';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';
import Banner from '../../explore/Banner';
import ActionTypeSection from './ActionTypeSection';
import AlertStack from '../../elements/AlertStack';

const ExploreBannerForm = ({
  imageUrl,
  setImageUrl,
  modalImageUrl,
  setModalImageUrl,
  handleChange,
  values,
  handleValueChanged,
  handleDateChanged,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  handleAddItem,
  itemToAdd,
  handleAddItemsToShow,
  itemsToShow,
  clearItemsToShow,
  clearAddItem,
  ...props
}) => {
  const bannerImageFile = useRef(null);

  const handleChangeBannerImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };
  const handleBannerImage = () => {
    bannerImageFile.current.click();
  };

  const actionTypeOptions = [
    {
      value: 'link',
      label: 'External Link',
    },
    {
      value: 'addItem',
      label: 'Add Item To Plan',
    },
    {
      value: 'showItems',
      label: 'Show Items in Search',
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <ImageLabel>Image</ImageLabel>
        <input
          hidden
          ref={bannerImageFile}
          type="file"
          name="image"
          onChange={handleChangeBannerImage}
        />
        <AddImage src={imageUrl} onClick={handleBannerImage} />
        <Dropdown
          width="320px"
          options={['Grey', 'Blue', 'Green']}
          label="Color"
          value={{
            value: values.color,
            label: values.color,
          }}
          onChange={(value) =>
            handleValueChanged('color', value.value, 'string')
          }
          data-test="exploreBanner-color"
        />
        <TextInput
          id="exploreBannerName"
          name="name"
          onChange={handleChange}
          value={values.name || ''}
          placeholder="Banner Name"
          label="Banner Name (Internal)"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Banner Name')}
          data-test="exploreBanner-name"
        />
        <TextInput
          id="title"
          name="title"
          onChange={handleChange}
          value={values.title || ''}
          placeholder="Title"
          label="Title"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Title')}
          data-test="exploreBanner-title"
        />
        <TextBox
          id="textBody"
          name="textBody"
          onChange={handleChange}
          value={values.textBody || ''}
          placeholder="Banner Body"
          label="Banner Body"
          maxLength={75}
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Banner Body')}
          data-test="exploreBanner-textBody"
        />
        <Dropdown
          width="320px"
          options={actionTypeOptions}
          label="Action Type"
          value={{
            value: values.actionType.value,
            label: values.actionType.label,
          }}
          onChange={(value) =>
            handleDateChanged('actionType', value.value, value.label)
          }
          data-test="exploreBanner-actionType"
        />
        <ActionTypeSection
          values={values}
          handleChange={handleChange}
          handleAddItem={handleAddItem}
          handleAddItemsToShow={handleAddItemsToShow}
          itemToAdd={itemToAdd}
          itemsToShow={itemsToShow}
          clearAddItem={clearAddItem}
          clearItemsToShow={clearItemsToShow}
          modalImageUrl={modalImageUrl}
          setModalImageUrl={setModalImageUrl}
        />
        <HR />
      </FieldSet>
      <Banner
        title={values.title}
        textBody={values.textBody}
        color={values.color}
        imageUrl={values.imageUrl}
        actionType={values.actionType}
        data-test="exploreBanner-sampleBanner"
      />
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Explore Banner'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="exploreBanner-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="exploreBanner-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="exploreBanner-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="exploreBanner-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const HR = styled.hr`
  margin: 15px 0;
`;

const ImageLabel = styled.h4`
  color: ${colors.secondary600};
`;

export default ExploreBannerForm;
