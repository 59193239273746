import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const TextLink = ({ handleClick, linkText, textCase, size, ...props }) => {
  return (
    <StyledLink
      onClick={handleClick}
      $textCase={textCase}
      $size={size}
      {...props}
    >
      {linkText}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  text-transform: ${(props) =>
    props.$textCase === 'upper' ? 'uppercase' : 'none'};
  color: ${colors.secondary500};
  display: inline;
  font-weight: ${(props) => (props.$size === 'small' ? '400' : '500')};
  font-size: ${(props) => (props.$size === 'small' ? '13px' : '15px')};
  line-height: 18px;

  &:hover {
    color: ${colors.secondary400};
    cursor: pointer;
  }

  &:active {
    color: ${colors.secondary300};
  }
`;

export default TextLink;
