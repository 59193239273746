import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import { isMobile } from '../helpers/utils';
// credit to @yenteho for the model for this button: https://codesandbox.io/s/ymwo5419yv

const CheckBox = ({
  value,
  checked,
  onChange,
  label,
  size,
  theme,
  error,
  ...props
}) => (
  <Container alignItems="center">
    <Item size={size}>
      <Box
        type="checkbox"
        size={size}
        value={value}
        checked={checked}
        onChange={onChange}
        theme={theme}
        {...props}
      />
      <CheckBoxStyle size={size} theme={theme} $error={error} />
    </Item>
    {(label || value) && (
      <CheckBoxLabel size={size}>{label || value}</CheckBoxLabel>
    )}
  </Container>
);

const Container = styled(FlexContainer)``;

const CheckBoxLabel = styled.div`
  color: ${colors.primary700};
  margin: 0 0 0 16px;
  width: 100%;
  font-size: ${(props) => (props.size === 'sm' ? 12 : 15)}px;
  line-height: 24px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.size === 'sm' ? '15px' : '23px')};
  height: ${(props) => (props.size === 'sm' ? '15px' : '23px')};
  position: relative;
`;

const CheckBoxStyle = styled.label`
  position: absolute;
  top: 0;
  width: ${(props) => (props.size === 'sm' ? '15px' : '21px')};
  height: ${(props) => (props.size === 'sm' ? '15px' : '21px')};
  border-radius: 3px;
  background: white;
  border: 1px solid
    ${(props) =>
      props.$error
        ? colors.hlitetwo300
        : isMobile() && !props.theme === 'light'
          ? colors.secondary600
          : colors.primary500};
`;

const Box = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 3px;
  width: ${(props) => (props.size === 'sm' ? 15 : 21)}px;
  height: ${(props) => (props.size === 'sm' ? 15 : 21)}px;

  ${!isMobile() &&
  `&:hover {
    cursor: pointer;
  }
  &:hover + ${CheckBoxStyle} {
    background: ${(props) =>
      props.theme === 'dark' ? colors.secondary100 : colors.secondary500};
    opacity: 0.5;
    border: 1px solid ${colors.secondary600};
  }`}

  &:focus + ${CheckBoxStyle} {
    border: 1px solid ${colors.secondary600};
  }

  &:checked + ${CheckBoxStyle} {
    background: ${(props) =>
      props.theme === 'dark' ? '#f8fffb' : colors.secondary500};
    opacity: 1;
    border: 1px solid ${colors.secondary600};
    &::after {
      content: ${(props) =>
        props.theme === 'dark'
          ? `url('/green_checkmark.svg')`
          : `url('/checkmark.svg')`};
      position: absolute;
      left: ${(props) => (props.size === 'sm' ? '-8%' : '10%')};
      top: ${(props) => (props.size === 'sm' ? '-6%' : '6%')};
      transform: ${(props) => props.size === 'sm' && 'scale(.9)'};
    }
  }
  &:disabled {
    background: ${colors.primary050};
    border: 1px solid ${colors.primary300};
  }
  &:disabled ~ ${CheckBoxLabel} {
    color: ${colors.primary400};
  }
`;

export default CheckBox;
