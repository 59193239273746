import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const DashboardIcon = (props) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <circle cx="9" cy="9" r="4.68" strokeMiterlimit="10" />
    <polygon points="9 0 7.63 2.38 10.37 2.38 9 0" />
    <polygon points="9 18 10.37 15.62 7.63 15.62 9 18" />
    <polygon points="18 9 15.62 7.63 15.62 10.37 18 9" />
    <polygon points="0 9 2.38 10.37 2.38 7.63 0 9" />
    <polygon points="15.36 2.64 12.71 3.35 14.65 5.29 15.36 2.64" />
    <polygon points="2.64 15.36 5.29 14.65 3.35 12.71 2.64 15.36" />
    <polygon points="15.36 15.36 14.65 12.71 12.71 14.65 15.36 15.36" />
    <polygon points="2.64 2.64 3.35 5.29 5.29 3.35 2.64 2.64" />
  </Icon>
);

const Icon = styled.svg`
  width: 18px;
  height: 18px;

  & circle {
    fill: none;
    stroke: ${colors.primary500};
  }
  & polygon {
    fill: ${colors.primary500};
  }
`;

export default DashboardIcon;
