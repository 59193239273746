import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import Button from '../elements/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';
import { isMobile } from '../helpers/utils';
import { getInvoicesInfo } from '../services/api/billing';

const InvoiceDetails = () => {
  const [invoices, setInvoices] = useState([]);
  const [hasMoreInvoices, setHasMoreInvoices] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  var usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const fetchInvoicesInfo = async () => {
    try {
      const result = await getInvoicesInfo();
      setInvoices(result.data);
      setHasMoreInvoices(result.has_more);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    console.debug('loading invoices info');
    fetchInvoicesInfo();
  }, []);

  const rows = invoices.map((invoice) => {
    return (
      <TableRow key={invoice.id}>
        <Data>
          {isMobile() ? (
            <InvoiceLink href={invoice.pdf} target="_blank">
              {DateTime.fromSeconds(invoice.date).toFormat('MM/dd/yyyy')}
            </InvoiceLink>
          ) : (
            DateTime.fromSeconds(invoice.date).toFormat('MM/dd/yyyy')
          )}
        </Data>
        {!isMobile() && (
          <Data>
            <InvoiceLink href={invoice.pdf} target="_blank">
              {invoice.item}
            </InvoiceLink>
          </Data>
        )}
        {/* <Data>{invoice.payment_method}</Data> */}
        <Data>{usdFormatter.format(invoice.total / 100)}</Data>
        <Data>{invoice.status}</Data>
      </TableRow>
    );
  });
  return (
    <>
      {invoices.length === 0 ? (
        <NoInvoicesContainer>
          {errorMessage ? (
            <p>Error: {errorMessage}</p>
          ) : (
            <p>You don't have any invoices yet.</p>
          )}
        </NoInvoicesContainer>
      ) : (
        <>
          <Table>
            <Header>
              <TableRow>
                <TableCell>Date</TableCell>
                {!isMobile() && <TableCell>Item</TableCell>}
                {/* TODO: Fetch the payment method from the payment intend attached to the invoice */}
                {/* <TableCell>Payment Method</TableCell> */}
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </Header>
            <TableBody data-test="invoice-rows">{rows}</TableBody>
          </Table>
          {hasMoreInvoices && (
            <ButtonContainer justify="center">
              <Button buttonText="Show More" buttonSize="small" />
            </ButtonContainer>
          )}
        </>
      )}
    </>
  );
};

const Header = styled(TableHead)`
  & .MuiTableCell-head {
    font-family: 'Work Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.primary800};
  }
`;

const Data = styled(TableCell)`
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary600};
`;

const NoInvoicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primary700};
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-top: 5rem;
`;

const InvoiceLink = styled.a`
  color: ${colors.secondary500};

  &:hover {
    color: ${colors.secondary400};
    cursor: pointer;
  }

  &:active {
    color: ${colors.secondary300};
  }
`;

const ButtonContainer = styled(FlexContainer)`
  margin-top: 24px;
`;

export default InvoiceDetails;
