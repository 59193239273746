import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import FitnessPlanForm from './FitnessPlanForm';
import RelatedRecords from '../related/RelatedRecords';
import {
  getFitnessPlan,
  editFitnessPlan,
} from '../../services/api/fitnessPlan';

const DeleteFitnessPlan = (props) => {
  const initialValues = {
    locationType: 'Gym',
    gender: 'All',
    dayNum: {
      value: 1,
      label: `Monday`,
    },
    type: 'Weight Loss',
    level: 'Beginner',
    planLength: 7,
  };

  const initialToggleValues = {
    active: false,
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);

  const { open, launchModal, closeModal } = useModal(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [workouts, setWorkouts] = useState([]);
  const [activities, setActivities] = useState([]);

  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  const [fitnessPlan, setFitnessPlan] = useState({});
  const [prevFitnessPlan, setPrevFitnessPlan] = useState({});

  async function loadFitnessPlan(params) {
    try {
      const fitnessPlanId = params.fitnessPlan;
      const result = await getFitnessPlan(fitnessPlanId);

      if (result) {
        setFitnessPlan(result);
      } else {
        throw new Error('Fitness Plan could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded fitness plan
  useEffect(() => {
    if (!isEmpty(fitnessPlan)) {
      setWorkouts([...fitnessPlan.workouts]);
      setActivities([...fitnessPlan.activities]);

      setValues({
        ...values,
        planRef: fitnessPlan.planRef,
        name: fitnessPlan.name,
        type: fitnessPlan.type,
        planLength: fitnessPlan.planLength,
        level: fitnessPlan.level,
        gender: fitnessPlan.gender,
        locationType: fitnessPlan.locationType,
      });

      setToggleValues({ active: fitnessPlan.active });
    }
  }, [fitnessPlan]);

  useEffect(() => {
    const filteredWorkout = workouts.filter((workout) => {
      return workout.dayNum === values.dayNum.value;
    });
    const filteredActivity = activities.filter((activity) => {
      return activity.dayNum === values.dayNum.value;
    });
    setFilteredWorkouts(filteredWorkout);
    setFilteredActivities(filteredActivity);
  }, [workouts, activities, values.dayNum]);

  const deleteItem = (e) => {
    setLoading(true);
    e.preventDefault();

    const fitnessPlanId = fitnessPlan._id;
    let payload = {
      isDeleted: true,
    };
    editFitnessPlan(fitnessPlanId, payload)
      .then((result) => {
        setPrevFitnessPlan(fitnessPlanId);
        setStatus('success');
        setMessage(`Fitness Plan "${result.name}" was deleted successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setWorkouts([]);
        setActivities([]);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const undoDelete = (e) => {
    e.preventDefault();
    let payload = {
      isDeleted: false,
    };
    editFitnessPlan(prevFitnessPlan, payload)
      .then((result) => {
        setStatus('');
        setMessage('');
        loadFitnessPlan({
          fitnessPlan: prevFitnessPlan,
        });
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Fitness Plan"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="fitnessPlan-find"
      />
      {fitnessPlan._id && (
        <RelatedRecords recordType="fitnessPlan" recordId={fitnessPlan._id} />
      )}
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'fitnessPlan',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addFitnessPlan={loadFitnessPlan}
        {...props}
      />
      <FitnessPlanForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        filteredWorkouts={filteredWorkouts}
        filteredActivities={filteredActivities}
        activities={activities}
        setActivities={setActivities}
        workouts={workouts}
        setWorkouts={setWorkouts}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={deleteItem}
        undoDelete={undoDelete}
        buttonText="Delete Fitness Plan"
        viewOnly={true}
        formType="delete"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DeleteFitnessPlan;
