import React from 'react';
import styled from 'styled-components';
import FlexContainer from './FlexContainer';
import Avatar from '@mui/material/Avatar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import { colors } from '../styleConstants';

const AddImage = (props) => {
  return (
    <Container {...props}>
      <AvatarBox src={props.src} variant="rounded" size={props?.size}>
        <PhotoCameraIconBox />
      </AvatarBox>
      <EditBox variant="rounded" size={props?.size}>
        <FlexContainer flexDirection="column" alignItems="center">
          <EditIconBox />
          Add Image
        </FlexContainer>
      </EditBox>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: fit-content;
`;

const EditBox = styled(Avatar)`
  width: ${(props) => (props?.size?.width ? props?.size?.width : '160px')};
  height: ${(props) => (props?.size?.height ? props?.size?.height : '90px')};
  position: absolute;
  cursor: pointer;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 2;
  visibility: hidden;
  &:hover {
    visibility: visible;
  }
`;

const EditIconBox = styled(EditIcon)`
  margin-bottom: 15px;
  border: 2px solid white;
  border-radius: 100%;
  padding: 5px;
`;

const AvatarBox = styled(Avatar)`
  width: ${(props) => (props?.size?.width ? props?.size?.width : '160px')};
  height: ${(props) => (props?.size?.height ? props?.size?.height : '90px')};
  cursor: pointer;
  border-radius: 8px;
  background-color: ${colors.primary100};

  &:hover + ${EditBox} {
    visibility: visible;
  }
`;

const PhotoCameraIconBox = styled(PhotoCameraIcon)`
  width: 70px;
  height: 70px;
  color: ${colors.primary400};

  ${Container}:hover & {
    visibility: hidden;
  }
`;

export default AddImage;
