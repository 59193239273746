import React, { useState } from 'react';

import { cloneDeep } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';

import TrackForm from './TrackForm';
import { addTrack } from '../../services/api/track';

const AddTrack = (props) => {
  const initialValues = {
    locationType: 'Home',
    gender: 'All',
    level: 'Beginner',
  };

  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [fitnessPlans, setFitnessPlans] = useState([]);

  const submitData = () => {
    setLoading(true);

    let payload = cloneDeep(values);
    const fitnessPlanRefs = fitnessPlans.map((fitnessPlan, i) => {
      return {
        week: i + 1,
        fitnessPlan: fitnessPlan.fitnessPlan._id,
      };
    });
    payload.fitnessPlans = fitnessPlanRefs;

    // On initial creation, set track to inactive. Track progression will be set on the map page.
    payload.active = false;

    addTrack(payload)
      .then((result) => {
        setStatus('success');
        setMessage(
          `Track "${result.name}" was created successfully! Add to a track slot on the map page to activate.`,
        );
        setValues(initialValues);
        setFitnessPlans([]);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <TrackForm
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      fitnessPlans={fitnessPlans}
      setFitnessPlans={setFitnessPlans}
      status={status}
      setStatus={setStatus}
      setMessage={setMessage}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      buttonText="Create Fitness Track"
    />
  );
};

export default AddTrack;
