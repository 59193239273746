import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import ArrowIcon from '../icons/ArrowIcon';
import { colors } from '../styleConstants';
import { useNavigate, useLocation } from 'react-router-dom';
import DefaultProfilePicture from '../icons/DefaultProfilePicture';
import ProfilePhoto from '../elements/ProfilePhoto';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/general/currentUserSlice';

const MobileProfileDialog = ({ handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser.user);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLink = (path) => {
    if (path === location.pathname) {
      handleClose();
    } else {
      navigate(path);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <DialogContainer
      flexDirection="column"
      alignItems="center"
      data-test="mobile-profile-dialog"
    >
      <Puller />
      <TopContainer flexDirection="column" alignItems="center">
        {user?.avatarUrl ? (
          <ProfilePhoto
            src={user.avatarUrl}
            onClick={() => handleLink('/app/profile')}
            data-test="mobile-dialog-avatar"
          />
        ) : (
          <DefaultProfilePicture
            onClick={() => handleLink('/app/profile')}
            data-test="mobile-dialog-avatar"
          />
        )}
        <Name data-test="mobileDialog-userName">{user.fullName}</Name>
        <Text data-test="mobileDialog-userEmail">{user.email}</Text>
      </TopContainer>
      <OptionsList flexDirection="column">
        <OptionRow onClick={() => handleLink('/app/profile')}>
          <FlexContainer alignItems="center">
            <IconContainer>
              <img
                src={`${process.env.PUBLIC_URL}/icons/supervised_user_circle.svg`}
                alt="Profile"
              />
            </IconContainer>
            <Text>My Profile</Text>
          </FlexContainer>
          <ArrowIcon color={colors.secondary600} medium={true} />
        </OptionRow>

        <OptionRow onClick={() => handleLink('/app/profile/preferences')}>
          <FlexContainer alignItems="center">
            <IconContainer>
              <img
                src={`${process.env.PUBLIC_URL}/icons/settings.svg`}
                alt="Preferences"
              />
            </IconContainer>
            <Text>Preferences</Text>
          </FlexContainer>
          <ArrowIcon color={colors.secondary600} medium={true} />
        </OptionRow>

        <OptionRow onClick={() => handleLink('/app/profile/billing')}>
          <FlexContainer alignItems="center">
            <IconContainer>
              <img
                src={`${process.env.PUBLIC_URL}/icons/billing.svg`}
                alt="Billing"
              />
            </IconContainer>
            <Text>Billing & Subscription</Text>
          </FlexContainer>
          <ArrowIcon color={colors.secondary600} medium={true} />
        </OptionRow>

        <StyledLink
          href="https://forms.clickup.com/f/2d9kx-365/SDBI1TTVHUC2PJ659Y"
          target="_blank"
          rel="noopener noreferrer"
        >
          <OptionRow>
            <FlexContainer alignItems="center">
              <IconContainer>
                <img
                  src={`${process.env.PUBLIC_URL}/icons/sticky_note_2.svg`}
                  alt="Feature Request"
                />
              </IconContainer>
              <Text>Request a Feature</Text>
            </FlexContainer>
            <ArrowIcon color={colors.secondary600} medium={true} />
          </OptionRow>
        </StyledLink>
        <StyledLink
          href="https://forms.clickup.com/f/2d9kx-295/IRF24Y4HEZ2RMBFRV6"
          target="_blank"
          rel="noopener noreferrer"
        >
          <OptionRow>
            <FlexContainer alignItems="center">
              <IconContainer>
                <img
                  src={`${process.env.PUBLIC_URL}/icons/bug_report.svg`}
                  alt="Bug Report"
                />
              </IconContainer>
              <Text>Report a Bug</Text>
            </FlexContainer>
            <ArrowIcon color={colors.secondary600} medium={true} />
          </OptionRow>
        </StyledLink>
        <StyledLink
          href="https://nutriology.io/user-support/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <OptionRow>
            <FlexContainer alignItems="center">
              <IconContainer>
                <img
                  src={`${process.env.PUBLIC_URL}/icons/help.svg`}
                  alt="Help"
                />
              </IconContainer>
              <Text>Help & Support</Text>
            </FlexContainer>
            <ArrowIcon color={colors.secondary600} medium={true} />
          </OptionRow>
        </StyledLink>
      </OptionsList>
      <StyledButton
        buttonSize="large"
        // width="354px"
        buttonText="Log Out"
        pink="true"
        onClick={handleLogout}
        data-test="mobile-logout"
      />
    </DialogContainer>
  );
};

const DialogContainer = styled(FlexContainer)`
  background-color: #fbfbfb;
  background-image: url(${process.env.PUBLIC_URL}/Bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 24px;
`;

const TopContainer = styled(FlexContainer)`
  padding-top: 32px;
`;

const OptionsList = styled(FlexContainer)`
  margin-top: 16px;
  width: calc(100% - 64px);
  padding: 0 32px;
`;

const OptionRow = styled(FlexContainer)`
  height: 48px;
  border-bottom: 1px solid ${colors.primary100};
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled(FlexContainer)`
  width: 32px;
  height: 32px;
  border-radius: 18px;
  background-color: ${colors.primary050};
  border: 1px solid ${colors.primary200};
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Name = styled.h2`
  color: ${colors.primary800};
  font-weight: 700;
  margin: 6px 0;
  line-height: 28px;
`;

const Text = styled.h5`
  font-weight: 500;
  color: ${colors.primary800};
`;

const Puller = styled.div`
  width: 46px;
  height: 3px;
  background-color: ${colors.primary100};
  border-radius: 16px;
  position: absolute;
  top: 8px;
`;

const StyledButton = styled(Button)`
  font-size: 18px;
  margin-top: 16px;
  width: 80%;
  max-width: 354px;
`;
const StyledLink = styled.a`
  :visited {
    color: ${colors.primary200};
  }
`;

export default MobileProfileDialog;
