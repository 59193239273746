export const getFormattedUnits = (repUnits) => {
  return repUnits === 'Reps Per Side'
    ? 'Reps/Side'
    : repUnits === 'Secs Per Side'
      ? 'Secs/Side'
      : repUnits === 'Mins Per Side'
        ? 'Mins/Side'
        : repUnits
          ? repUnits
          : '';
};

export const getDisplayWeight = (exercise) => {
  return (
    !exercise.bodyWeight &&
    exercise.repUnits !== 'Secs' &&
    exercise.repUnits !== 'Mins' &&
    exercise.repUnits !== 'Secs Per Side' &&
    exercise.repUnits !== 'Mins Per Side'
  );
};
