import { omit } from 'lodash';
import { apiCall } from './api';

export function getRecipeUser(params) {
  params.populate = [{ path: 'foods.food' }];
  params.query = {
    _id: params.recipeId,
    user: params.userId,
  };
  return apiCall('get', `/recipeuser`, omit(params, ['recipeId'])).then(
    (recipesUser) => {
      return recipesUser[0];
    },
  );
}

export function getRecipesUser(userId, recipeIds) {
  let params = {};
  params.populate = [{ path: 'foods.food' }];
  params.query = {
    _id: {
      $in: recipeIds,
    },
    user: userId,
  };
  return apiCall('get', `/recipeuser`, params);
}

export function getRecentRecipes(params) {
  params.populate = [{ path: 'foods.food' }];
  return apiCall('get', `/mealplanuser/recent_recipes`, params);
}

export function getRecipes(params) {
  return apiCall('get', `/recipe`, params);
}

export function getRecipeUsers(params) {
  params.populate = [{ path: 'foods.food' }];
  params.query = {
    user: params.userId,
    recipeAdmin: null,
  };
  return apiCall('get', `/recipeuser`, params);
}

export function searchRecipes(params) {
  return apiCall('get', `/recipe/search/${params.query}`, params);
}

export function searchUserRecipes(params) {
  return apiCall('get', `/recipeuser/search/${params.query}`, params);
}

export function getUserFavoriteRecipes(
  userId,
  recipeIds,
  populateAdmin = false,
) {
  let params = {};
  params.populate = populateAdmin
    ? [
        {
          path: 'recipeAdmin',
          populate: { path: 'foods.food' },
        },
      ]
    : [{ path: 'foods.food' }];
  params.query = {
    user: userId,
    recipeAdmin: {
      $in: recipeIds,
    },
  };
  return apiCall('get', `/recipeuser`, params);
}

export function getUserCustomFavoriteRecipes(userId, recipeIds) {
  let params = {};
  params.populate = [{ path: 'foods.food' }];
  params.query = {
    user: userId,
    _id: {
      $in: recipeIds,
    },
  };
  return apiCall('get', `/recipeuser`, params);
}

export function getRecipe(id, params = {}) {
  params.populate = [{ path: 'foods.food' }];
  return apiCall('get', `/recipe/${id}`, params);
}

export function addRecipe(params) {
  if (params.image && !params.imageUrl) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then((result) => {
      params.imageUrl = result.file;
      return apiCall('post', `/recipe`, params);
    });
  }
  return apiCall('post', `/recipe`, params);
}

export function addUserRecipe(params) {
  params.populate = [{ path: 'foods.food' }];
  if (params.image && !params.imageUrl) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then((result) => {
      params.imageUrl = result.file;
      return apiCall('post', `/recipeuser`, params);
    });
  }
  return apiCall('post', `/recipeuser`, params);
}

export function editRecipeUser(id, params) {
  if (params.image) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then((result) => {
      params.imageUrl = result.file;
      return apiCall('put', `/recipeuser/${id}`, params);
    });
  }
  return apiCall('put', `/recipeuser/${id}`, params);
}

export function editRecipe(id, params) {
  if (params.image) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then((result) => {
      params.imageUrl = result.file;
      return apiCall('put', `/recipe/${id}`, params);
    });
  }
  return apiCall('put', `/recipe/${id}`, params);
}

export function getRecipeReferences(id, params = {}) {
  params.query = { 'recipes.recipe': id };
  return apiCall('get', `/mealplan`, params);
}

export function replaceRecipesOnMealPlan(mealPlan, oldRecipe, newRecipe) {
  return apiCall(
    'put',
    `/mealplan/${mealPlan}/replace_recipe/${oldRecipe}/${newRecipe}`,
  );
}
