import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { setDefaultUser, getUser } from './store/general/currentUserSlice';
import { jwtDecode } from 'jwt-decode';

import './App.css';
import Questionnaire from './intake/Questionnaire';
import PaymentScreen from './intake/PaymentScreen';
import AppContainer from './routes/AppContainer';
import CheckAuth from './routes/CheckAuth';
import CheckToken from './routes/CheckToken';
import AuthPage from './authorization/AuthPage';
import RequireVerification from './routes/RequireVerification';
import VerificationPage from './authorization/VerificationPage';
import ForgotPassWrapper from './authorization/ForgotPassWrapper';
import OAuthPage from './authorization/OAuthPage';
import RequireAuth from './routes/RequireAuth';
import RequireIntake from './routes/RequireIntake';
import RequireSubscribe from './routes/RequireSubscribe';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { identifyUser, trackPage } from './integrations/analytics';
import { isIOS, isInStandaloneMode } from './utils/appUtils';
import { isMobile } from './helpers/utils';
import PWAPrompt from './universal/PWAPrompt';
import Messages from './universal/Messages';
import Hotjar from '@hotjar/browser';
import SamplesContainer from './routes/SamplesContainer';

import LoginForm from './authorization/forms/LoginForm';
import SignupForm from './authorization/forms/SignupForm';
import ResetForm from './authorization/forms/ResetForm';

export const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const errors = useSelector((state) => state.errors);
  const { isChecked, isAuthenticated } = currentUser;
  const {
    isVerified,
    hidePWAPrompt,
    isAdmin,
    subscriptionStatus,
    intakeComplete,
  } = currentUser.user;

  useEffect(() => {
    const initialize = () => {
      try {
        if (localStorage.jwtToken) {
          const token = jwtDecode(localStorage.jwtToken);
          dispatch(getUser(token.id));
        } else {
          dispatch(setDefaultUser());
        }
      } catch (error) {
        dispatch(setDefaultUser());
        console.error(error);
      }
    };

    /* this piece of code is required for url bar to shrink/collapse on mobile browsers */
    if (isMobile()) {
      const tags = document?.getElementsByTagName('html');
      tags[0].style.height = 'unset';
    }

    initialize();
    if (process.env.REACT_APP_ENV === 'production') {
      // Facebook pixel
      ReactPixel.init('261415902142030');
      // Hotjar setup
      const siteId = 3726305;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  let location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactPixel.pageView();
      trackPage();
      if (
        location.pathname.includes('/app') &&
        currentUser?.user?.isAdmin !== true
      ) {
        ReactGA.set({ page: location.pathname }, ['appTracker']); // Update the user's current page
        ReactGA.pageview(location.pathname, ['appTracker']); // Record a pageview for the given page
      } else if (location.pathname.includes('verify-email')) {
        // don't worry about the token
        ReactGA.set({ page: '/verify-email' }, ['marketingTracker']); // Update the user's current page
        ReactGA.pageview('/verify-email', ['marketingTracker']); // Record a pageview for the given page
      } else {
        ReactGA.set({ page: location.pathname }, ['marketingTracker']); // Update the user's current page
        ReactGA.pageview(location.pathname, ['marketingTracker']); // Record a pageview for the given page
      }

      // Hotjar setup
      const siteId = 3726305;
      const hotjarVersion = 6;

      Hotjar.init(siteId, hotjarVersion);
    }
  }, [location]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === 'production' &&
      isChecked &&
      !currentUser.user.isAdmin
    ) {
      identifyUser(currentUser.user._id, {
        name: currentUser.user.fullName,
        email: currentUser.user.email,
        is_verified: currentUser.user.isVerified,
        created_at: currentUser.user.createdAt,
        birthdate: currentUser.user.birthDate,
        age: currentUser.user.age,
        gender: currentUser.user.gender,
        body_comp_goal: currentUser.user.detailPreferences.nutriologyGoal,
        recommended_calories: currentUser.user.recommendedMealPlanCalories,
        eating_preference: currentUser.user.foodPreferences.eatingPreference,
        workout_location: currentUser.user.fitnessPreferences.fitnessStyle,
        fitness_level: currentUser.user.detailPreferences.activityLevel,
        subscription_status: currentUser.user.subscriptionStatus,
      });
    }
  }, [isChecked]);

  // Determine whether to prompt user
  const [showPrompt, setShowPrompt] = useState(false);

  const shouldShowPrompt = async () => {
    if (
      isChecked &&
      !isAdmin &&
      isIOS() &&
      !isInStandaloneMode() &&
      !hidePWAPrompt &&
      isAuthenticated &&
      isVerified &&
      subscriptionStatus === 'active' &&
      intakeComplete
    ) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  };

  useLayoutEffect(() => {
    shouldShowPrompt();
  }, [
    isChecked,
    isAdmin,
    isAuthenticated,
    isVerified,
    subscriptionStatus,
    hidePWAPrompt,
    intakeComplete,
  ]);

  return (
    <>
      {isChecked && (
        <Routes>
          <Route element={<CheckAuth />}>
            <Route element={<AuthPage />}>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/signup" element={<SignupForm />} />
              <Route path="/reset" element={<ResetForm />} />
            </Route>
            <Route path="reset/:token" element={<ForgotPassWrapper />} />
          </Route>
          {/* Routes requiring authorization */}
          <Route element={<RequireAuth />}>
            <Route path="/intake/payment" element={<PaymentScreen />} />
            {/* Routes requiring subscription */}
            <Route element={<RequireSubscribe />}>
              <Route path="/intake/questionnaire" element={<Questionnaire />} />
              {/* Routes requiring intake complete */}
              <Route element={<RequireIntake />}>
                <Route element={<CheckToken />}>
                  <Route path="/please-verify" element={<VerificationPage />} />
                  <Route
                    path="/verify-email/:token"
                    element={<VerificationPage />}
                  />
                </Route>
                {/* Routes requiring verification */}
                <Route element={<RequireVerification />}>
                  <Route path="/app/*" element={<AppContainer />} />
                  <Route path="/oauth" element={<OAuthPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="/samples" element={<SamplesContainer />} />
        </Routes>
      )}
      {showPrompt && <PWAPrompt open={true} />}
      {errors.length > 0 && (
        <Messages messages={errors} type="error" testName="error-message" />
      )}
    </>
  );
};

export default App;
