import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../helpers/date';

import TextLink from '../elements/TextLink';
import StatsBox from '../universal/StatsBox';

// import WeeklyReportBox from '../universal/WeeklyReportBox'; TODO: Post-Beta feature
import WorkoutCard from './WorkoutCard';
import FitnessStats from './FitnessStats';
import CurrentPlanCard from './CurrentPlanCard';
import EmptyStateCard from './EmptyStateCard';
import Activity from './activity/Activity';

import { calculateWorkoutStats } from './calculateWorkoutStats';

import MobileTopNav from '../universal/MobileTopNav';
import { isMobile } from '../helpers/utils';
import { toggleAllComplete } from '../services/api/workout';
import { trackEvent } from '../integrations/analytics';

import {
  getUserFitnessPlanByDate,
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
  updateUserFitnessPlan,
} from '../store/fitnessplan/selectedFitnessPlanSlice';

import { addError } from '../store/general/errorSlice';

import { editUserWorkout } from '../services/api/workout';
import ErrorBoundary from '../services/ErrorBoundary';
import DateNavigator from '../elements/DateNavigator';

const UserFitnessPlan = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.currentUser.user.id);
  const fitnessPlan = useSelector((state) => state.selectedFitnessPlan);

  const [dateValue, setDateValue] = useState(new Date());

  const { workouts, activities, totalCaloriesBurned, totalCaloriesWorkout } =
    calculateWorkoutStats(fitnessPlan, dateValue);

  function addWorkoutToUserFitnessPlan(params) {
    trackEvent(
      `Add Workout (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { workout: params.workout },
    );
    dispatch(
      addWorkoutToFitnessPlan({ fitnessPlanId: fitnessPlan._id, params }),
    );
  }

  function removeWorkoutFromFitnessPlan(workoutId) {
    trackEvent(
      `Remove Workout (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { workout: workoutId },
    );
    let updatedFitnessPlan = cloneDeep(fitnessPlan);
    updatedFitnessPlan.workouts = updatedFitnessPlan.workouts.filter(
      (workout) => workout._id !== workoutId,
    );
    dispatch(
      updateUserFitnessPlan({
        fitnessPlanId: fitnessPlan._id,
        params: updatedFitnessPlan,
      }),
    );
  }

  function addActivityToUserFitnessPlan(params) {
    trackEvent(
      `Add Activity (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { activity: params.activity },
    );
    dispatch(
      addActivityToFitnessPlan({ fitnessPlanId: fitnessPlan._id, params }),
    );
  }

  function removeActivityFromFitnessPlan(activityId) {
    trackEvent(
      `Remove Activity (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { activity: activityId },
    );
    let updatedFitnessPlan = cloneDeep(fitnessPlan);
    updatedFitnessPlan.activities = updatedFitnessPlan.activities.filter(
      (activity) => activity._id !== activityId,
    );
    dispatch(
      updateUserFitnessPlan({
        fitnessPlanId: fitnessPlan._id,
        params: updatedFitnessPlan,
      }),
    );
  }

  async function addExerciseToUserWorkout(params) {
    try {
      trackEvent(
        `Add Exercise (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
        { exercise: params.exercise },
      );
      const workout = fitnessPlan.workouts.find(
        (workout) => workout.workout._id === params.workout,
      ).workout;
      const exercise = {
        workoutSequence: params.workoutSequence,
        exercise: params.exercise,
        sets: [],
        date: dateValue,
      };
      const updatedWorkout = cloneDeep(workout);
      updatedWorkout.exercises = [...workout.exercises, exercise];
      await editUserWorkout(workout._id, updatedWorkout);
      dispatch(getUserFitnessPlanByDate({ userId, date: dateValue }));
    } catch (err) {
      dispatch(
        addError({
          type: 'fitnessPlan',
          error: 'Error while updating your fitness plan, please try again.',
        }),
      );
      console.error(err);
    }
  }
  async function removeExerciseFromWorkout(workout, exerciseId) {
    try {
      trackEvent(
        `Remove Exercise (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
        { exercise: exerciseId },
      );
      const updatedWorkout = cloneDeep(workout);
      updatedWorkout.exercises = updatedWorkout.exercises.filter(
        (exercise) => exercise._id !== exerciseId,
      );
      await editUserWorkout(workout._id, updatedWorkout);
      dispatch(getUserFitnessPlanByDate({ userId, date: dateValue }));
    } catch (err) {
      dispatch(
        addError({
          type: 'fitnessPlan',
          error: 'Error while updating your fitness plan, please try again.',
        }),
      );
      console.error(err);
    }
  }

  async function logAllComplete() {
    trackEvent(
      `Toggle All Complete (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    const updatedFitnessPlan = cloneDeep(fitnessPlan);
    const dateSelected = DateTime.fromJSDate(dateValue).toFormat('yyyy-MM-dd');
    for (const activity of updatedFitnessPlan.activities) {
      const activityDate = DateTime.fromISO(activity.date).toFormat(
        'yyyy-MM-dd',
      );
      if (dateSelected === activityDate && !activity.logged) {
        activity.logged = new Date();
      }
    }
    for (const workout of updatedFitnessPlan.workouts) {
      const workoutDate = DateTime.fromISO(workout.date).toFormat('yyyy-MM-dd');
      if (dateSelected === workoutDate && !workout.allComplete) {
        const params = {
          allComplete: workout.allComplete,
        };
        await toggleAllComplete(workout.workout._id, params);
        workout.allComplete = new Date();
      }
    }
    dispatch(
      updateUserFitnessPlan({
        fitnessPlanId: fitnessPlan._id,
        params: updatedFitnessPlan,
      }),
    );
  }

  async function updateSets(workout, exerciseId, sets) {
    try {
      const updatedWorkout = cloneDeep(workout);
      const exerciseIndex = updatedWorkout.exercises.findIndex(
        (exercise) => exerciseId === exercise._id,
      );
      const exerciseTmp = [...updatedWorkout.exercises];
      exerciseTmp[exerciseIndex].sets = sets;
      updatedWorkout.exercises = exerciseTmp;
      await editUserWorkout(workout._id, updatedWorkout);
      dispatch(getUserFitnessPlanByDate({ userId, date: dateValue }));
    } catch (err) {
      dispatch(
        addError({
          type: 'fitnessPlan',
          error:
            'There was an error while updating your sets, please try again',
        }),
      );
      console.error(err);
    }
  }

  const activityList = activities.map((activity) => (
    <Activity
      key={activity._id}
      id={activity._id}
      activity={activity}
      fitnessPlan={fitnessPlan}
      removeActivity={removeActivityFromFitnessPlan}
    />
  ));

  const workoutList = workouts.map((workout) => (
    <WorkoutCard
      key={workout._id}
      workout={workout}
      removeWorkout={removeWorkoutFromFitnessPlan}
      fitnessPlan={fitnessPlan}
      addExercise={addExerciseToUserWorkout}
      updateSets={updateSets}
      removeExercise={removeExerciseFromWorkout}
      date={formatDate(dateValue)}
    />
  ));

  const luxonDateValue = DateTime.fromJSDate(dateValue).toFormat('MMM d');

  const dateText =
    luxonDateValue === DateTime.now().toFormat('MMM d')
      ? 'Today '
      : luxonDateValue === DateTime.now().plus({ days: 1 }).toFormat('MMM d')
        ? 'Tomorrow '
        : luxonDateValue === DateTime.now().minus({ days: 1 }).toFormat('MMM d')
          ? 'Yesterday, '
          : '';

  useEffect(() => {
    const fromExplore = localStorage.getItem('from_explore');
    // If we are arriving here from the Explore tab, we need to default to the correct date
    if (fromExplore === 'yes' || isMobile()) {
      const dValue = localStorage.getItem('fitnessplan_dateValue');
      if (dValue) {
        let newDate = new Date(JSON.parse(dValue));
        setDateValue(newDate);
        localStorage.removeItem('fitnessplan_dateValue');
      }
    }
  }, []);

  if (!isMobile()) {
    // Web version
    return (
      <PageContainer justify="space-between">
        <LeftContainer flexDirection="column">
          <ErrorBoundary>
            <>
              <DateContainer alignItems="center" justify="space-between">
                <DateDisplayContainer>
                  <DateDisplay data-test="fitnessPlan-date-display">
                    {dateText}
                    <DateToday>
                      {DateTime.fromJSDate(dateValue).toFormat('MMMM d')}
                    </DateToday>
                  </DateDisplay>
                </DateDisplayContainer>
                <TextLink
                  linkText="Log All As Complete"
                  size="small"
                  onClick={logAllComplete}
                  data-test="fitnessPlan-logAll"
                />
              </DateContainer>
              <ItemsContainer flexDirection="column">
                {workouts.length > 0 && workoutList}
                {activities.length > 0 && activityList}
                <EmptyStateContainer
                  flexDirection={
                    !workouts.length > 0 && activities.length > 0
                      ? 'row'
                      : 'column'
                  }
                >
                  {workouts.length === 0 && (
                    <EmptyStateCard
                      type="Workout"
                      half={activities.length > 0 ? 'true' : 'false'}
                      addWorkout={addWorkoutToUserFitnessPlan}
                      addActivity={addActivityToUserFitnessPlan}
                      date={formatDate(dateValue)}
                    />
                  )}
                  <EmptyStateCard
                    type="Activity"
                    medium={activityList.length > 0 ? 'true' : 'false'}
                    half={
                      workouts.length === 0 && activityList.length > 0
                        ? 'true'
                        : 'false'
                    }
                    addWorkout={addWorkoutToUserFitnessPlan}
                    addActivity={addActivityToUserFitnessPlan}
                    date={formatDate(dateValue)}
                  />
                </EmptyStateContainer>
              </ItemsContainer>
            </>
          </ErrorBoundary>
        </LeftContainer>
        <RightContainer flexDirection="column">
          <DateContainer alignItems="center" justify="flex-end">
            <FlexContainer>
              <DateNavigator date={dateValue} setDate={setDateValue} />
            </FlexContainer>
          </DateContainer>
          <StatsBox>
            <FitnessStats
              caloriesBurned={totalCaloriesBurned || 0}
              workoutGoal={totalCaloriesWorkout || 1}
              steps={4965} // TODO: Post-Beta feature - remove hard coded values
              stepsGoal={10000} // TODO: https://app.clickup.com/t/8ethkc
            />
          </StatsBox>
          <StatsBox>
            <CurrentPlanCard />
          </StatsBox>
          {/* TODO: Post-Beta feature
          https://app.clickup.com/t/8etjmm
        <StatsBox>
          <WeeklyReportBox type="fitness" />
        </StatsBox> */}
        </RightContainer>
      </PageContainer>
    );
  } else {
    // Mobile version
    return (
      <>
        <MobileTopNav
          dateText={dateText}
          dateValue={dateValue}
          setDateValue={setDateValue}
        ></MobileTopNav>
        <MobileContainer>
          <FitnessContainer justify="center" alignItems="center">
            {workouts.length === 0 && activities.length === 0 && (
              <StateDisplay>Nothing planned yet for today!</StateDisplay>
            )}
          </FitnessContainer>
          <MobileItemsContainer flexDirection="column">
            {workouts.length > 0 && workoutList}
            {activities.length > 0 && activityList}
            <MobileEmptyStateContainer
              flexDirection="column"
              alignItems="center"
            >
              {workouts.length === 0 && (
                <EmptyStateCard
                  type="Workout"
                  medium={activities.length > 0 ? 'true' : 'false'}
                  addWorkout={addWorkoutToUserFitnessPlan}
                  addActivity={addActivityToUserFitnessPlan}
                  date={formatDate(dateValue)}
                />
              )}
              <EmptyStateCard
                type="Activity"
                medium={
                  workouts.length > 0 || activities.length > 0
                    ? 'true'
                    : 'false'
                }
                addWorkout={addWorkoutToUserFitnessPlan}
                addActivity={addActivityToUserFitnessPlan}
                date={formatDate(dateValue)}
              />
            </MobileEmptyStateContainer>
          </MobileItemsContainer>
          <FitnessStats
            caloriesBurned={totalCaloriesBurned || 0}
            workoutGoal={totalCaloriesWorkout || 1}
            steps={4965} // TODO: Post-Beta feature - remove hard coded value
            stepsGoal={10000} // TODO: https://app.clickup.com/t/8ethkc
          />
        </MobileContainer>
      </>
    );
  }
};

/** Web **/

const PageContainer = styled(FlexContainer)`
  padding: 0 55px 55px 55px;
  position: relative;
`;

const DateContainer = styled(FlexContainer)`
  height: 77px;
`;

const DateDisplayContainer = styled(FlexContainer)``;

const DateToday = styled.span`
  color: ${colors.primary600};
  font-weight: 500;
`;

const DateDisplay = styled.p`
  color: ${colors.primary800};
  text-transform: uppercase;
  font-weight: bold;
`;

const EmptyStateContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-right: ${(props) =>
      props.flexDirection === 'column' ? '0' : '12px'};
    margin-bottom: ${(props) =>
      props.flexDirection === 'column' ? '12px' : '0'};
  }
`;

const LeftContainer = styled(FlexContainer)`
  flex-basis: 50%;
  margin-right: 14px;
`;

const RightContainer = styled(FlexContainer)`
  flex-basis: 330px;
  min-width: 320px;
  margin-left: 14px;
`;

const ItemsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
`;

/**
 * Mobile
 */

const MobileContainer = styled.div`
  margin-top: 38px;
`;

const FitnessContainer = styled(FlexContainer)`
  margin-bottom: 8px;
`;

const StateDisplay = styled.h6`
  height: 36px;
  width: 157px;
  color: ${colors.secondary600};
  text-align: center;
  margin-top: 30px;
`;

const MobileItemsContainer = styled(FlexContainer)`
  overflow-x: hidden;
`;

const MobileEmptyStateContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-right: ${(props) =>
      props.flexDirection === 'column' ? '0' : '12px'};
    margin-bottom: 0px;
  }
`;

export default UserFitnessPlan;
