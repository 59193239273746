import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import { colors } from '../../styleConstants';

import { isEmpty } from 'lodash';
import useModal from '../../hooks/useModal';
import AlertStack from '../../elements/AlertStack';
import CircularProgress from '@mui/material/CircularProgress';

import ModalContainer from '../../sharedModals/ModalContainer';
import RecipeCard from '../../mealModals/RecipeCard';
import { getRecipe, getRecipes, editRecipe } from '../../services/api/recipe';

const DefaultPlaceholder = () => {
  const { open, launchModal, closeModal } = useModal(false);
  const [recipe, setRecipe] = useState({});
  const [initialDefault, setInitialDefault] = useState({});
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const loadDefault = async () => {
    const defaultPlaceholder = await getRecipes({
      query: { placeholderDefault: true },
    });
    setRecipe(defaultPlaceholder[0] ?? {});
    setInitialDefault(defaultPlaceholder[0] ?? {});
  };

  const updateDefault = async () => {
    try {
      setLoading(true);
      if (!isEmpty(recipe) && recipe?._id !== initialDefault?._id) {
        // We have updated the default placeholder recipe
        if (!isEmpty(initialDefault)) {
          // Set the current placeholder as no longer the default
          await editRecipe(initialDefault._id, { placeholderDefault: false });
        }
        // Set the placeholder to update as the new default
        await editRecipe(recipe._id, { placeholderDefault: true });
        await loadDefault();
        setLoading(false);
        setStatus('success');
        setMessage('The default placeholder has been successfully updated!');
      }
    } catch (err) {
      setLoading(false);
      setStatus('error');
      if (err.message.includes('E11000')) {
        setMessage('There is already a default placeholder defined.');
      } else {
        setMessage(err.message);
      }
      console.error(err);
    }
  };

  useEffect(() => {
    loadDefault();
  }, []);

  async function loadRecipe(params) {
    try {
      const recipeId = params.recipe;
      const result = await getRecipe(recipeId);
      if (result) {
        setRecipe(result);
      } else {
        throw new Error('Recipe could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Container flexDirection="column" gap="36px">
      <Button
        buttonText="Find Placeholder"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="recipe-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
        }}
        initialScreen="Meal Search"
        handleClose={closeModal}
        addRecipe={loadRecipe}
        placeholderOnly={true}
      />
      <Title data-test="recipe-placeholder-title">
        {recipe?._id === initialDefault?._id
          ? 'Current Default Placeholder'
          : 'Placeholder to Update'}
      </Title>
      {!isEmpty(initialDefault) || !isEmpty(recipe) ? (
        <RecipeCard
          recipe={recipe}
          onClick={null}
          isMobile={true}
          width="600px"
        />
      ) : (
        <EmptyCard alignItems="center" justify="center">
          No Default Placeholder Defined
        </EmptyCard>
      )}
      {recipe?._id !== initialDefault?._id && (
        <Button
          buttonText="Update Default Placeholder"
          buttonSize="large"
          pink="true"
          handleClick={updateDefault}
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="update-default"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="recipe-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="recipe-message-success"
        />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -12px;
`;

const EmptyCard = styled(FlexContainer)`
  width: 600px;
  height: 100px;
  border: 1px dashed ${colors.primary500};
`;

export default DefaultPlaceholder;
