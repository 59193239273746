import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { DateTime } from 'luxon';
import { ArrowBack, Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import FlexContainer from '../elements/FlexContainer';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LogIcon from '../icons/LogIcon';
import FractionInput from '../elements/FractionInput';
import FavoriteIcon from '../icons/FavoriteIcon';
import SwapIcon from '../icons/SwapIcon';
import RemoveIcon from '../icons/RemoveIcon';
import { useSelector } from 'react-redux';
import {
  formatCalories,
  formatProtein,
  formatTotalCarbohydrate,
  formatTotalFat,
} from '../helpers/nutrition';
import { getImageURL } from '../services/api/api';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const Modal = ({
  open,
  handleClose,
  item,
  toggleEaten,
  toggleFavorite,
  swap,
  remove,
  adjustQuantity,
}) => {
  const currentUser = useSelector((state) => state.currentUser.user);
  const foodImageURL = (ratio = 1) => {
    const height = 144 * ratio;
    const width = 232 * ratio;
    return getImageURL(
      `${fileStorage}/Nutriology_Symbol_467x334.png`,
      `resizing_type:fill/height:${height}/width:${width}`,
    );
  };
  const calories = formatCalories(item.food.nutrientsPerUnit.calories);
  const protein = formatProtein(item.food.nutrientsPerUnit.proteins);
  const carbs = formatTotalCarbohydrate(
    item.food.nutrientsPerUnit.carbohydrates,
  );
  const fat = formatTotalFat(item.food.nutrientsPerUnit.fat);

  const handleFoodPropertyChanged = (field, value) => {
    adjustQuantity(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      data-test="planner-foodModal"
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        {/* Action buttons */}
        <FlexContainer>
          <Tooltip title="Back">
            <IconButton
              aria-label="back"
              onClick={handleClose}
              data-test="planner-foodModal-back"
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>

          {/* Align right */}
          <FlexContainer justify="flex-end" flexGrow="1">
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                data-test="planner-foodModal-close"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </FlexContainer>
        </FlexContainer>
      </DialogTitle>

      <DialogContent>
        <Content>
          {/* Left column */}
          <FlexContainer
            flexBasis="50%"
            flexDirection="column"
            margin="0 10px 0 0"
          >
            <FoodImage
              image={foodImageURL()}
              image2x={foodImageURL(2)}
              data-test="planner-foodModal-image"
            />
          </FlexContainer>

          {/* Right column */}
          <FlexContainer
            flexBasis="50%"
            flexDirection="column"
            margin="0 0 0 10px"
            gap="16px"
          >
            <MealTitle>{item.mealType}</MealTitle>
            <FoodName data-test="planner-foodModal-name">
              {item.food.verboseName}
            </FoodName>
            <IconsContainer alignItems="center">
              <FavoriteIcon
                toggleSelect={toggleFavorite}
                selected={
                  currentUser.favoriteFoods.includes(item.food._id) ||
                  currentUser.favoriteFoods.includes(item.food.foodAdmin)
                }
                color="purple"
                width="32px"
                height="32px"
                shrinkIcon={true}
                hideLabel={true}
                data-test="planner-foodModal-favorite"
              />
              <SwapIcon
                toggleSelect={swap}
                width="32px"
                height="32px"
                color="teal"
                shrinkIcon={true}
                hideLabel={true}
                data-test="planner-foodModal-swap"
              />
              <RemoveIcon
                toggleSelect={remove}
                width="32px"
                height="32px"
                shrinkIcon={true}
                hideLabel={true}
                data-test="planner-foodModal-delete"
              />
              <LogIcon
                logged={item.eaten}
                toggleSelect={toggleEaten}
                dateLogged={DateTime.fromISO(item.eaten).toFormat('MMMM d')}
                width="32px"
                height="32px"
                hideLabel={true}
                data-test="planner-foodModal-log"
              />
            </IconsContainer>
            <QuantityContainer
              flexBasis="50%"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <p style={{ marginRight: 8 }}>Quantity:</p>
              <Quantity
                name="quantity"
                id="quantity"
                value={item.quantity || 1}
                small={true}
                onValueChanged={handleFoodPropertyChanged}
                minValue="0"
                width="50px"
                data-test="planner-foodModal-quantity"
              />
              <Units>{item.food.measureUnit}</Units>
            </QuantityContainer>
          </FlexContainer>
        </Content>

        <Content style={{ marginTop: 24, paddingBottom: 32 }}>
          <StatsBox alignItems="center" justify="space-between">
            <Calories data-test="planner-foodModal-calories">
              {calories} Calories
            </Calories>
            <FlexContainer gap="24px">
              <MacroNum data-test="planner-foodModal-protein">
                {protein} <MacroLabel>Protein</MacroLabel>
              </MacroNum>
              <MacroNum data-test="planner-foodModal-carbs">
                {carbs} <MacroLabel>Carbs</MacroLabel>
              </MacroNum>
              <MacroNum data-test="planner-foodModal-fat">
                {fat} <MacroLabel>Fat</MacroLabel>
              </MacroNum>
            </FlexContainer>
          </StatsBox>
        </Content>
      </DialogContent>
    </Dialog>
  );
};

const Content = styled(FlexContainer)`
  padding: 0 28px;
`;

const FoodName = styled.h2`
  color: ${colors.primary800};
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
`;

const FoodImage = styled.div`
  height: 200px; // Update image dimensions accordingly.
  width: 235px; // Update image dimensions accordingly.
  border-radius: 6px;
  background-image: ${(props) => `url(${props.image})`};
  background-image: ${(props) =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${(props) =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const MealTitle = styled.h4`
  color: ${colors.secondary600};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: -12px;
`;

const Units = styled.p`
  color: ${colors.primary800};
  font-size: 12px;
  font-weight: 400;
`;

const IconsContainer = styled(FlexContainer)`
  gap: 22px;
  margin-top: 8px;
`;

const StatsBox = styled(FlexContainer)`
  height: 64px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.primary200};
  padding: 0 20px;
`;

const Calories = styled.p`
  color: ${colors.primary900};
  font-size: 14px;
  font-weight: 700;
`;

const MacroNum = styled.p`
  color: ${colors.primary900};
  font-size: 12px;
  font-weight: 700;
`;

const MacroLabel = styled.span`
  color: ${colors.primary900};
  font-size: 12px;
  font-weight: 400;
`;

const Quantity = styled(FractionInput)`
  width: 50px;
`;

const QuantityContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 12px;
  }
`;

export default Modal;
