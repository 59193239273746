import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import Fraction from 'fraction.js';
import { isMobile } from '../helpers/utils';

const FractionInput = ({
  label,
  value,
  onValueChanged,
  units,
  unitColor,
  minValue,
  absUnits,
  ...props
}) => {
  const node = useRef();
  const [focused, setFocused] = useState(false);
  const [stale, setStale] = useState(false);
  const [displayValue, setDisplayValue] = useState(
    new Fraction(value).simplify().toFraction(true),
  );

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      setFocused(true);
      return;
    }
    // outside click
    setFocused(false);
    setStale(false);

    // Ideally here we want to clean the component and set the fraction value based on the prop `value`
    // This would make the stale input of `1/` become 1 again,
    // It would also make sure we always display the minimal fraction representation, ie if the user enter 2/4, we would display 1/2
    // However it doesn't work since value is not updated dynamically here...
    // console.log("current value is : ", value);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const getFractionValue = () => {
    if (stale || focused) {
      return displayValue;
    } else {
      return new Fraction(value).simplify().toFraction(true);
    }
  };

  const handleKeyPress = (e) => {
    const allowedKeys = /[0-9/ .]/;
    if (!e.key.match(allowedKeys)) {
      console.error(`Error, Key "${e.key}" not allowed here`);
      e.stopPropagation();
      e.preventDefault();
      return;
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    const validInput =
      /^([0-9]+$|[0-9]+\.{1}[0-9]+$|^[0-9]* ?[0-9]+\/[1-9]{1}[0-9]*$)/;

    if (newValue.match(validInput)) {
      console.debug('Valid input');
      setStale(false);
      setDisplayValue(newValue);
      updateValue(newValue);
    } else if (!newValue.match(/\/.*\//)) {
      // Here we are on a stale mode. The input is not a valid number but it has to go
      // through this stage in order to become one. For example you have to type "1/" before "1/2"
      // or "1."  before "1.5" in this case we mark the component as stale,
      // we only update the display value, not the underlying value until it's a valid input.
      console.debug('stale input');
      setStale(true);
      setDisplayValue(newValue);
    }
  };

  const updateValue = (fractionValue) => {
    const decimalValue = new Fraction(fractionValue).valueOf();
    onValueChanged(props.name, decimalValue);
  };

  return (
    <div ref={node}>
      <FlexContainer flexDirection="column">
        {label && <Label>{label}</Label>}
        <ContentContainer alignItems="center" small={props.small}>
          <InputContainer value={value} width={props.width}>
            <Input
              value={getFractionValue()}
              onChange={handleChange}
              $focused={focused}
              $minValue={minValue}
              onKeyPress={handleKeyPress}
              {...props}
            />
          </InputContainer>
          {units && (
            <Units
              unitColor={unitColor}
              $absUnits={absUnits}
              width={props.width}
            >
              {units}
            </Units>
          )}
        </ContentContainer>
        {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
      </FlexContainer>
    </div>
  );
};

const ContentContainer = styled(FlexContainer)`
  height: ${(props) => (props.small ? '29px' : '40px')};
  position: relative;
  width: fit-content;
`;

const Units = styled.h4`
  color: ${(props) =>
    props.unitColor === 'grey' ? colors.primary500 : colors.primary800};
  margin-left: 10.5px;
  user-select: none;
  position: ${(props) => (props.$absUnits ? 'absolute' : 'static')};
  left: ${(props) => (props.$absUnits && props.width ? props.width : 'auto')};
`;

const InputContainer = styled(FlexContainer)`
  display: inline-flex;
  position: relative;
  min-width: ${(props) => (props.width ? props.width : '68px')};
  max-width: ${isMobile() ? '300px' : '116px'};
  overflow: hidden;
  width: ${(props) => (props.width ? props.width : '1.25em')};
  height: 100%;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: ${(props) =>
    props.error
      ? '1px solid #ff7c0e'
      : (props) =>
          props.$focused
            ? `1px solid ${colors.secondary500}`
            : `1px solid ${colors.primary400}`};
  background-color: #ffffff;
  padding: 0 12px;
  font-size: ${(props) => (props.small ? '15px' : '17px')};
  line-height: ${(props) => (props.small ? '18px' : '20px')};
  border-radius: 2px;

  &:focus {
    border: ${(props) =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: ${colors.primary050};
    color: ${colors.primary300};
    border: ${colors.primary300};
  }

  &::placeholder {
    color: ${colors.primary400};
  }
`;

const ErrorMessage = styled.p`
  color: #ff7c0e;
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  margin: ${(props) => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-size: 11px;
  line-height: 14px;
`;

export default FractionInput;
