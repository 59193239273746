import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api/api';
import { defaults } from 'lodash';

const initialState = {
  isAuthenticated: false, // hopefully be true, when logged in
  user: {
    id: null,
    birthDate: null,
    email: '',
    name: {
      first: '',
      last: '',
    },
    fullName: '',
    gender: '',
    height: {
      feet: 5,
      inches: 8,
    },
    weight: 150,
    notificationPreferences: [],
    detailPreferences: {
      weightGoal: 150,
      nutriologyGoal: 'Lose body fat',
      activityLevel: 'Competent',
    },
    foodPreferences: {
      eatingStyle: '',
      eatingPreference: 'I like everything',
      alcoholPreference: '',
      foodAvoidances: [],
      likes: [],
      dislikes: [],
    },
    fitnessPreferences: {
      fitnessStyle: 'At the gym',
      equipments: [],
      exerciseLikes: [],
      exerciseDislikes: [],
    },
    favoriteRecipes: [],
    favoriteFoods: [],
    isVerified: false,
    isSubscribed: false,
    isSubscriptionActive: false,
  },
  isChecked: false, // will be updated when we check the JWT so it's to capture the intermediate state of a user
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setDefaultUser(state, action) {
      const newState = { ...initialState, isChecked: true };
      return newState;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(logout.fulfilled, (state, action) => {
      //   return initialState;
      //   // TODO: Could be an issue
      // })
      .addMatcher(
        isAnyOf(
          authUser.fulfilled,
          updatePassword.fulfilled,
          getUser.fulfilled,
          updateUser.fulfilled,
          forgotPassword.fulfilled,
          verifyEmail.fulfilled,
          resendVerificationEmail.fulfilled,
        ),
        (state, action) => {
          state.isAuthenticated = action.payload !== initialState.user;
          state.isChecked = true;
          state.user = defaults(action.payload, initialState.user);
          return state;
        },
      );
  },
});

export const logout = createAsyncThunk(
  'currentUser/logout',
  async (_, { dispatch }) => {
    await apiCall('delete', `/users/logout`);
    localStorage.clear();
    return dispatch(setDefaultUser());
  },
);

export const authUser = createAsyncThunk(
  'currentUser/authUser',
  async ({ type, userData }, { rejectWithValue }) => {
    try {
      const { jwt, refresh_token, user } = await apiCall(
        'post',
        `/users/${type}`,
        userData,
      );
      localStorage.setItem('jwtToken', jwt);
      localStorage.setItem('jwtTokenRefresh', refresh_token);
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePassword = createAsyncThunk(
  'currentUser/updatePassword',
  async ({ resetToken, password }, { rejectWithValue }) => {
    try {
      const user = await apiCall('put', `/users/reset`, {
        resetToken,
        password,
      });
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUser = createAsyncThunk(
  'currentUser/getUser',
  async (userId, { rejectWithValue }) => {
    try {
      const user = await apiCall('get', `/users/${userId}`);
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUser = createAsyncThunk(
  'currentUser/updateUser',
  async (userData, { rejectWithValue }) => {
    try {
      const userId = userData._id;
      if (userData.avatar) {
        const formData = new FormData();
        for (let key in userData) {
          formData.append(key, userData[key]);
        }
        userData = formData;
      }
      const user = await apiCall('put', `/users/${userId}`, userData);
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'currentUser/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const user = await apiCall('post', `/users/reset`, { email });
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const verifyEmail = createAsyncThunk(
  'currentUser/verifyEmail',
  async (token, { rejectWithValue }) => {
    try {
      const data = await apiCall('post', `/users/verify_email/${token}`);
      return data.user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resendVerificationEmail = createAsyncThunk(
  'currentUser/resendVerificationEmail',
  async (email, { rejectWithValue }) => {
    try {
      const user = await apiCall('post', `/users/resend_verification_email`, {
        email,
      });
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const { setDefaultUser } = currentUserSlice.actions;
export default currentUserSlice.reducer;
