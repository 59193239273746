import React, { useState, useEffect } from 'react';

import { omit, cloneDeep, keys, pickBy } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';

import MealPlanForm from './MealPlanForm';

import { addMealPlan } from '../../services/api/mealplan';

const AddMealPlan = ({ ...props }) => {
  const initialValues = {
    type: 'Weight Loss',
    planLength: 7,
    dayNum: {
      value: 1,
      label: 'Monday',
    },
    recommendedCalories: 1800,
  };

  const initialToggleValues = {
    active: false,
    dietType: { any: true },
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);

  const [recipes, setRecipes] = useState([]);
  const [foods, setFoods] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const filteredRec = recipes.filter((recipe) => {
      return recipe.dayNum === values.dayNum.value;
    });
    const filteredFood = foods.filter((food) => {
      return food.dayNum === values.dayNum.value;
    });
    setFilteredRecipes(filteredRec);
    setFilteredFoods(filteredFood);
  }, [recipes, foods, values.dayNum]);

  const submitData = () => {
    let payload = cloneDeep(omit(values, ['dayNum']));
    const recRefs = recipes.map((recipe) => {
      return {
        name: recipe.recipe.name,
        mealType: recipe.mealType,
        dayNum: recipe.dayNum,
        recipe: recipe.recipe._id,
        leftover: recipe.leftover,
      };
    });
    const foodRefs = foods.map((food) => {
      return {
        name: food.food.name,
        mealType: food.mealType,
        dayNum: food.dayNum,
        quantity: food.quantity,
        food: food.food._id,
      };
    });
    payload.tags = {};
    payload.tags.dietType = keys(pickBy(toggleValues.dietType, Boolean));
    payload.recipes = recRefs;
    payload.foods = foodRefs;
    payload.active = toggleValues.active;

    setLoading(true);

    addMealPlan(payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Meal Plan "${result.name}" was created successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setRecipes([]);
        setFoods([]);
      })
      .catch((err) => {
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <MealPlanForm
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      handleDateChanged={handleDateChanged}
      toggleValues={toggleValues}
      handleToggle={handleToggle}
      filteredRecipes={filteredRecipes}
      filteredFoods={filteredFoods}
      foods={foods}
      recipes={recipes}
      setRecipes={setRecipes}
      setFoods={setFoods}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      viewOnly={false}
      buttonText={'Create Meal Plan'}
      formType="create"
    />
  );
};

export default AddMealPlan;
