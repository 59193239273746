import { setDefaultUser, logout } from './general/currentUserSlice';
import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const errorHandlerMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith('rejected')) {
    // Check for specific error pattern in action.payload
    if (action.payload?.error?.message === 'Not Authorized') {
      // Dispatch logout action or any other necessary actions
      store.dispatch(logout());
      store.dispatch(setDefaultUser());
    }
  }
  return next(action);
};

export function configureRedux() {
  const store = configureStore({
    reducer: rootReducer,
    // Add the RTK Query API middleware
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        errorHandlerMiddleware,
      ),
  });

  return store;
}
