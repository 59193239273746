import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import TextInput from '../../elements/TextInput';
import FractionInput from '../../elements/FractionInput';
import Dropdown from '../../elements/Dropdown';
import Button from '../../elements/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Messages from '../../universal/Messages';
import { allUnits } from '../../helpers/units';

const CustomFoodMobile = ({
  values,
  handleChange,
  handleValueChanged,
  handleSubmit,
  status,
  message,
  loading,
  ...props
}) => {
  return (
    <Container flexDirection="column">
      <FieldsContainer flexDirection="column">
        <TextInput
          id="foodName"
          name="name"
          onChange={handleChange}
          value={values.name}
          placeholder="Food Name"
          label="Food Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Food Name')}
          data-test="customFood-name"
        />
        <TextInput
          id="foodType"
          name="type"
          onChange={handleChange}
          value={values.type}
          placeholder="Food Type"
          label="Food Type"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Food Type')}
          data-test="customFood-type"
        />
        <AmountContainer>
          <FractionInput
            id="amount"
            name="amount"
            data-name="amount"
            label="Amount"
            minValue="0"
            value={values.amount || 1}
            onValueChanged={handleValueChanged}
            data-test="customFood-amount"
          />
          <Dropdown
            width="320px"
            options={allUnits || ['']}
            label="Measure Unit"
            value={{
              value: values.measureUnit,
              label: values.measureUnit,
            }}
            onChange={(value) =>
              handleValueChanged('measureUnit', value.value, 'string')
            }
            data-test="customFood-unit"
          />
        </AmountContainer>
        <FractionInput
          id="gramWeight"
          name="gramWeight"
          onValueChanged={handleValueChanged}
          value={values.gramWeight}
          label="Gram Weight"
          units="grams"
          width="90%"
          data-test="customFood-gramWeight"
        />
      </FieldsContainer>
      <NutritionSection>
        <FoodSectionHeader justify="space-between" alignItems="center">
          <FoodSectionName>Nutrition</FoodSectionName>
        </FoodSectionHeader>
        <Info>
          Nutrient values should reflect the total for the amount you specified
          in the section above.
        </Info>
        <NutrientsContainer wrap="wrap" flexDirection="column">
          <RequiredContainer wrap="wrap">
            <FractionInput
              label="Calories*"
              name="calories"
              value={values.calories}
              onValueChanged={handleValueChanged}
              units="kCal"
              width="100px"
              absUnits={true}
              data-test="customFood-calories"
              required
            />
            <FractionInput
              label="Total Fat*"
              name="fat"
              value={values.fat}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-fat"
              required
            />

            <FractionInput
              label="Carbs*"
              name="carbohydrates"
              value={values.carbohydrates}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-carbs"
              required
            />

            <FractionInput
              label="Protein*"
              name="proteins"
              value={values.proteins}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-protein"
              required
            />
          </RequiredContainer>
          <BottomContainer wrap="wrap">
            <FractionInput
              label="Saturated Fat"
              name="saturatedFat"
              value={values.saturatedFat}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-satFat"
            />

            <FractionInput
              label="Trans Fat"
              name="transFat"
              value={values.transFat}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-transFat"
            />
            <FractionInput
              label="Cholesterol"
              name="cholesterol_mg"
              value={values.cholesterol_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              width="100px"
              absUnits={true}
              data-test="customFood-cholesterol"
            />
            <FractionInput
              label="Sodium"
              name="sodium_mg"
              value={values.sodium_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              width="100px"
              absUnits={true}
              data-test="customFood-sodium"
            />
            <FractionInput
              label="Dietary Fiber"
              name="fiber"
              value={values.fiber}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-fiber"
            />
            <FractionInput
              label="Total Sugars"
              name="totalSugar"
              value={values.totalSugar}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-totalSugar"
            />
            <FractionInput
              label="Added Sugars"
              name="addedSugar"
              value={values.addedSugar}
              onValueChanged={handleValueChanged}
              units="g"
              width="100px"
              absUnits={true}
              data-test="customFood-addedSugar"
            />
            <FractionInput
              label="Vitamin D"
              name="vitaminD_mcg"
              value={values.vitaminD_mcg}
              onValueChanged={handleValueChanged}
              units="mcg"
              width="100px"
              absUnits={true}
              data-test="customFood-vitD"
            />
            <FractionInput
              label="Calcium"
              name="calcium_mg"
              value={values.calcium_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              width="100px"
              absUnits={true}
              data-test="customFood-calcium"
            />
            <FractionInput
              label="Potassium"
              name="potassium_mg"
              value={values.potassium_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              width="100px"
              absUnits={true}
              data-test="customFood-potassium"
            />
          </BottomContainer>
        </NutrientsContainer>
      </NutritionSection>
      {status === 'error' ? (
        <Messages messages={message} type="error" testName="customFood-error" />
      ) : status === 'success' ? (
        <Messages
          messages={message}
          type="success"
          testName="customFood-success"
        />
      ) : null}
      <AddButtonContainer justify="center" alignItems="center">
        <Button
          pink="true"
          width="300px"
          buttonText="Create Custom Food"
          buttonSize="large"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="customFood-submit"
        />
      </AddButtonContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
`;

const FieldsContainer = styled(FlexContainer)`
  width: 100%;
  gap: 16px;
`;

const AmountContainer = styled(FlexContainer)`
  > * {
    margin-right: 12px;
  }
`;

const NutrientsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 6px;
  }
`;

const RequiredContainer = styled(FlexContainer)`
  padding: 25px 0 5px 0;
  border-top: 1px solid ${colors.primary300};
  border-bottom: 1px solid ${colors.primary300};

  > * {
    margin-bottom: 20px;
  }
  > :nth-child(odd) {
    margin-right: 74px;
  }
`;

const BottomContainer = styled(FlexContainer)`
  margin-top: 25px;

  > * {
    margin-bottom: 20px;
  }
  > :nth-child(odd) {
    margin-right: 74px;
  }
`;

const NutritionSection = styled.div`
  width: 100%;
  padding: 32px 0 104px 0;
`;

const FoodSectionHeader = styled(FlexContainer)`
  padding: 0 8px;
  margin-bottom: 16px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const FoodSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

const Info = styled.p`
  color: ${colors.primary600};
  margin-bottom: 16px;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 64px;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

export default CustomFoodMobile;
