import React, { useEffect, useState } from 'react';
import AuthPage from './AuthPage';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { apiCall } from '../services/api/api';
import { useDispatch } from 'react-redux';

const ForgotPassWrapper = () => {
  const dispatch = useDispatch();
  const [resetToken, setResetToken] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getToken() {
      try {
        const tokenReset = await apiCall('post', `/users/reset/${token}`);
        setResetToken(tokenReset.token);
        navigate('/reset', { state: { resetToken: tokenReset.token } });
      } catch (err) {
        dispatch({ type: 'ADD_ERROR', error: 'Reset token expired' });
        return <Navigate to="/signup" />;
      }
    }
    getToken();
  }, [dispatch, token]);

  return <AuthPage resetToken={resetToken} />;
};

export default ForgotPassWrapper;
