import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Banner from './Banner';
import Slider from 'react-slick';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { getActivity } from '../services/api/activity';
import { getRecipe } from '../services/api/recipe';
import { getWorkout } from '../services/api/workout';
import { getFood } from '../services/api/food';
import { isEmpty } from 'lodash';

const BannerSection = ({
  activeBanners,
  addRecipe,
  addFood,
  addWorkout,
  addActivity,
  ...props
}) => {
  const { open, launchModal, closeModal } = useModal(false);
  const [context, setContext] = useState({});
  const [banner, setBanner] = useState({});

  const handleBannerClick = async (e, clickedBanner) => {
    e.stopPropagation();
    if (clickedBanner?.actionType === 'link') {
      window.open(clickedBanner.link, '_blank').focus();
    } else if (!isEmpty(clickedBanner)) {
      setBanner(clickedBanner);
    }
  };

  // Determine which action to take based on which action type specified on the clicked banner
  useEffect(() => {
    if (banner?.actionType === 'addItem') {
      // Load full object based on ID returned by modal
      getItemToAdd();
    } else if (banner?.actionType === 'showItems') {
      // Set the context that will be passed to modal, including the items to show
      setContext({
        initialScreen: 'Explore Modal',
        action: 'showItems',
        items: banner?.itemsToShow,
        itemType: banner?.itemsToShow[0].itemType,
        title: banner?.modalHeaderTitle ?? banner?.title,
        image: banner?.modalImageUrl,
      });
    }
  }, [banner]);

  // Add Item Flow
  const getItemToAdd = async () => {
    let item = {};
    const { itemId, itemType } = banner?.itemToAdd;
    if (itemType === 'recipe') {
      item = await getRecipe(itemId);
    } else if (itemType === 'food') {
      item = await getFood(itemId);
    } else if (itemType === 'workout') {
      item = await getWorkout(itemId);
    } else if (itemType === 'activity') {
      item = await getActivity(itemId);
    }
    if (itemType === 'recipe') {
      // Set context that will be used by the modal, including which item to queue to be added
      setContext({
        initialScreen: 'Recipe Page',
        initialRecipe: item,
        variant: 'explore',
      });
    } else if (itemType === 'food') {
      setContext({
        initialScreen: 'Food Page',
        initialFood: item,
        variant: 'explore',
      });
    } else if (itemType === 'workout') {
      setContext({
        initialScreen: 'Workout Page',
        initialWorkout: item,
        variant: 'explore',
      });
    }
  };

  useEffect(() => {
    // Modal depends on context, so we should only launch it when the context changes and is populated
    if (!isEmpty(context)) {
      launchModal();
    }
  }, [context]);

  const handleClose = () => {
    // When modal closes, reset all state
    setContext({});
    setBanner({});
    closeModal();
  };

  const banners = activeBanners
    .sort((a, b) => a.activeIndex - b.activeIndex)
    .map((banner) => (
      <Banner
        key={banner._id}
        color={banner.color}
        title={banner.title}
        textBody={banner.textBody}
        imageUrl={banner.imageUrl}
        handleClick={(e) => handleBannerClick(e, banner)}
        data-test="explore-banner"
      />
    ));

  return (
    <>
      <Slider {...sliderSettings}>{banners}</Slider>
      <ModalContainer
        addRecipe={addRecipe}
        addFood={addFood}
        addWorkout={addWorkout}
        addActivity={addActivity}
        open={open}
        handleClose={handleClose}
        initialScreen={context?.initialScreen}
        context={context}
      />
    </>
  );
};

const DotsContainer = styled.ul`
  position: absolute;
  bottom: 10px;
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  appendDots: (dots) => (
    <DotsContainer style={{ bottom: '10px' }}>{dots}</DotsContainer>
  ),
};

export default BannerSection;
