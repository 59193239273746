import { useState } from 'react';
import { isMobile } from '../helpers/utils';

const useModal = (initialValues = {}) => {
  const [open, setOpen] = useState(initialValues);
  const [context, setContext] = useState({});

  const launchModal = (e) => {
    if (e && e.currentTarget) {
      const targetContext = { ...e.currentTarget.dataset };
      setContext(targetContext);
    }
    setOpen(true);
    if (isMobile()) {
      document.querySelector('#mobile-top-nav').style['z-index'] = 'unset';
    }
  };

  const closeModal = (e) => {
    setOpen(false);
    setContext({});
    if (isMobile()) {
      document.querySelector('#mobile-top-nav').style['z-index'] = 1;
    }
  };

  return {
    open,
    context,
    launchModal,
    closeModal,
  };
};

export default useModal;
