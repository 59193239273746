import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from '../helpers/utils';
import { DateTime } from 'luxon';
import useFormValues from '../hooks/useFormValues';
import {
  getShoppingLists,
  addFoodToShoppingList as addFood,
  removeFoodFromShoppingList as removeFood,
  removeRecipeFromShoppingList as removeRecipe,
  clearCompletedFoods,
  setFoodCompleted as setComplete,
  adjustFoodQuantity,
  getFilteredShoppingLists,
} from '../store/shoppinglist/shoppingListSlice';
import ShoppingListWeb from './ShoppingListWeb';
import ShoppingListMobile from './ShoppingListMobile';
import { trackEvent } from '../integrations/analytics';
import { UNSAFE_NavigationContext } from 'react-router-dom';

const ShoppingList = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.currentUser.user._id);
  const shoppingList = useSelector((state) => state.shoppingList.shoppingList);
  const shoppingListId = useSelector((state) => state.shoppingList._id);
  const recipes = useSelector(
    (state) => state.shoppingList.shoppingListRecipes,
  );

  const history = useContext(UNSAFE_NavigationContext).navigator;
  const initialValues = {
    dateFrom: DateTime.now().startOf('day').toISO(),
    dateTo: DateTime.now().plus({ days: 7 }).startOf('day').toISO(),
  };
  const { values, handleChange, handleValueChanged, setValues } =
    useFormValues(initialValues);

  // Load user's shopping list and their meal plans for the selected date range.
  useEffect(() => {
    dispatch(
      getShoppingLists({
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
      }),
    );
  }, [values.dateFrom, values.dateTo]);

  // Complete a food
  async function setFoodCompleted(foodId, completed) {
    trackEvent(
      `Set Food Complete (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    dispatch(
      setComplete({
        shoppingListId,
        foodId,
        completed,
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
      }),
    );
  }

  // Add a food
  async function addFoodToShoppingList(params) {
    trackEvent(`Add Food (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`);
    dispatch(
      addFood({
        shoppingListId,
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
        params: { ...params },
      }),
    );
  }

  // Remove a food
  async function removeFoodFromShoppingList(foodId) {
    trackEvent(
      `Remove Food (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    dispatch(
      removeFood({
        shoppingListId,
        foodId,
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
      }),
    );
  }

  // Remove a recipe
  async function removeRecipeFromShoppingList(recipeId) {
    trackEvent(
      `Remove Recipe (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    dispatch(
      removeRecipe({
        shoppingListId,
        recipeId,
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
      }),
    );
  }

  // Clear completed
  async function clearCompleted() {
    trackEvent(
      `Clear Completed (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    dispatch(
      clearCompletedFoods({
        shoppingListId,
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
      }),
    );
  }

  // Adjust quantity
  async function adjustQuantity(foodId, value) {
    trackEvent(
      `Adjust Food Quantity (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`,
    );
    dispatch(
      adjustFoodQuantity({
        shoppingListId,
        foodId,
        value,
        startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
      }),
    );
  }

  // Filter ingredients when clicking a recipe
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const handleRecipeClick = (e, recipe) => {
    e.preventDefault();
    if (selectedRecipe?.instanceId === recipe?.instanceId) {
      setSelectedRecipe(null);
      dispatch(
        getShoppingLists({
          startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
          endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
        }),
      );
    } else {
      trackEvent(
        `Filter List by Recipe (Shopping List - ${
          isMobile() ? 'Mobile' : 'Web'
        })`,
      );
      setSelectedRecipe(recipe);
      dispatch(
        getFilteredShoppingLists({
          startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
          endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
          recipeId: recipe.instanceId,
        }),
      );
    }
  };

  // Ensure we reload the full unfiltered shopping list when navigating away from the page
  useEffect(() => {
    const historyListener = history.listen((location) => {
      if (history.action === 'PUSH') {
        dispatch(
          getShoppingLists({
            startDate: DateTime.fromISO(values.dateFrom).toFormat('yyyy-MM-dd'),
            endDate: DateTime.fromISO(values.dateTo).toFormat('yyyy-MM-dd'),
          }),
        );
      }
    });

    return function cleanup() {
      historyListener();
    };
  }, []);

  const categories = [
    'produce',
    'grocery',
    'protein',
    'dairy',
    'frozen',
    'other',
  ];

  if (!isMobile()) {
    // Web view
    return (
      <ShoppingListWeb
        shoppingList={shoppingList}
        recipes={recipes}
        addFoodToShoppingList={addFoodToShoppingList}
        removeFoodFromShoppingList={removeFoodFromShoppingList}
        removeRecipeFromShoppingList={removeRecipeFromShoppingList}
        adjustQuantity={adjustQuantity}
        setFoodCompleted={setFoodCompleted}
        clearCompleted={clearCompleted}
        values={values}
        handleChange={handleChange}
        handleValueChanged={handleValueChanged}
        selectedRecipe={selectedRecipe}
        handleRecipeClick={handleRecipeClick}
        categories={categories}
        userId={userId}
      />
    );
  } else {
    // Mobile view
    return (
      <ShoppingListMobile
        shoppingList={shoppingList}
        recipes={recipes}
        addFoodToShoppingList={addFoodToShoppingList}
        removeFoodFromShoppingList={removeFoodFromShoppingList}
        removeRecipeFromShoppingList={removeRecipeFromShoppingList}
        adjustQuantity={adjustQuantity}
        setFoodCompleted={setFoodCompleted}
        clearCompleted={clearCompleted}
        values={values}
        setValues={setValues}
        selectedRecipe={selectedRecipe}
        handleRecipeClick={handleRecipeClick}
        categories={categories}
        userId={userId}
      />
    );
  }
};

export default ShoppingList;
