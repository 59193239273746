import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setShouldAnimateSetRecord } from '../../store/general/shouldAnimateSetSlice';
import useFormValues from '../../hooks/useFormValues';
import { trackEvent } from '../../integrations/analytics';

//Web import
import RemoveXIcon from '../../icons/RemoveXIcon';
import LogIcon from '../../icons/LogIcon';
import NumericInput from '../../elements/NumericInput';

//Mobile import
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

import MobileNumericInput from '../../elements/MobileNumericInput';
import FitnessLogSwipe from '../../icons/FitnessLogSwipe';
import FlexContainer from '../../elements/FlexContainer';
import { isMobile } from '../../helpers/utils';
import { simulate, simulateSwipe } from '../../utils/mouseEventUtils';

const SetRow = ({
  num,
  set,
  displayWeight,
  repUnits,
  handleSetUpdate,
  removeSet,
  logged,
  toggleLogged,
  fullSwipe,
  isExpanded,
  ...props
}) => {
  const dispatch = useDispatch();
  const shouldAnimateSetRecord = useSelector(
    (state) => state.shouldAnimateSetRecord,
  );

  const initialValues = {
    repsNumber: set.repsNumber || 0,
    weightInLbs: set.weightInLbs || 0,
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  useLayoutEffect(() => {
    if (values.repsNumber !== set.repsNumber) {
      trackEvent(
        `Updated Reps (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      );
      handleSetUpdate('repsNumber', num, values.repsNumber);
    }
  }, [values.repsNumber]);

  useLayoutEffect(() => {
    if (values.repsNumber !== set.repsNumber) {
      setValues({ repsNumber: set.repsNumber });
    }
  }, [set.repsNumber]);

  useLayoutEffect(() => {
    if (
      values.weightInLbs !== set.weightInLbs &&
      set.weightInLbs !== undefined &&
      values.weightInLbs !== undefined
    ) {
      trackEvent(
        `Updated Weight (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      );
      handleSetUpdate('weightInLbs', num, values.weightInLbs);
    }
  }, [values.weightInLbs]);

  const [autoSwipe, setAutoSwipe] = useState(false);
  useLayoutEffect(() => {
    if (isMobile() && autoSwipe) {
      const el = document.querySelector(
        `.list-${set._id}-0 .swipeable-list-item__content`,
      );
      if (el) {
        const pos = el.getBoundingClientRect();
        const center1X = Math.floor((pos.left + pos.right) / 2);
        const center1Y = Math.floor((pos.top + pos.bottom) / 2);
        setTimeout(() => {
          swipeRight(el, center1X, center1Y);
        }, 500);
        setTimeout(() => {
          swipeLeft(el, center1X, center1Y);
        }, 1500);

        dispatch(setShouldAnimateSetRecord(false));
      }
    }
  }, [autoSwipe]);

  function swipeLeft(el, center1X, center1Y) {
    simulate(el, 'mousedown', { pointerX: center1X, pointerY: center1Y });
    simulateSwipe(
      el,
      'rightToLeft',
      { x: center1X, y: center1Y },
      center1X - 100,
      10,
    );
  }

  function swipeRight(el, center1X, center1Y) {
    simulate(el, 'mousedown', { pointerX: center1X, pointerY: center1Y });
    simulateSwipe(
      el,
      'leftToRight',
      { x: center1X, y: center1Y },
      center1X + 100,
      10,
    );
  }

  useLayoutEffect(() => {
    if (isExpanded && shouldAnimateSetRecord) {
      setAutoSwipe(true);
    }
  }, [isExpanded, shouldAnimateSetRecord]);

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => toggleLogged(set)}>
        <ActionContent>
          <FitnessLogSwipe logged={logged}></FitnessLogSwipe>
        </ActionContent>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction onClick={() => removeSet(set)}>
        <ActionContent>
          <MobRemoveXIcon data-index={num}>
            <TextStyle>Delete</TextStyle>
          </MobRemoveXIcon>
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );

  if (!isMobile()) {
    // Web view
    return (
      <TR data-test="setRow">
        <TD>
          <Num logged={logged} data-test="setRow-setNum">
            {num + 1}
          </Num>
        </TD>
        <TD>
          <NumericInput
            name={`repsNumber`}
            value={values.repsNumber || ''}
            onChange={handleChange}
            onValueChanged={handleValueChanged}
            minValue="0"
            disabled={logged}
            units={repUnits}
            data-test="setRow-repsNumber"
          />
        </TD>
        <TD>
          {displayWeight ? (
            <NumericInput
              name={`weightInLbs`}
              value={values.weightInLbs || ''}
              onChange={handleChange}
              onValueChanged={handleValueChanged}
              minValue="0"
              units="lbs"
              disabled={logged}
              data-test="setRow-weightInLbs"
            />
          ) : (
            <NoWeight>-</NoWeight>
          )}
        </TD>
        <TD>
          <LogIcon
            hideLabel
            logged={logged}
            toggleSelect={() => toggleLogged(set)}
            data-test="setRow-log"
          />
        </TD>
        <AbsTD>
          <RemoveXIcon
            data-index={num}
            onClick={() => removeSet(set)}
            data-test="setRow-remove"
          />
        </AbsTD>
      </TR>
    );
  } else {
    // Mobile view
    return (
      <div data-test="setRow">
        <SwipeableList fullSwipe className={`list-${set._id}-${num}`}>
          <SwipeableListItem
            leadingActions={leadingActions()}
            trailingActions={trailingActions()}
            data-test="swipeable-list-item"
          >
            <ItemContent justify="center" alignItems="center" dark={num & 1}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <MobNum logged={logged} data-test="setRow-setNum">
                    {num + 1}
                  </MobNum>
                </Grid>
                <Grid item xs={4} style={{ marginLeft: '10px' }}>
                  <MobileNumericInput
                    name={`repsNumber`}
                    value={values.repsNumber}
                    onChange={handleChange}
                    onValueChanged={handleValueChanged}
                    minValue="0"
                    disabled={logged}
                    units={repUnits}
                    data-test="setRow-repsNumber"
                  />
                </Grid>
                <Grid item xs={4} data-test="setRow-weightGrid">
                  {displayWeight ? (
                    <MobileNumericInput
                      name={`weightInLbs`}
                      value={values.weightInLbs || ''}
                      onChange={handleChange}
                      onValueChanged={handleValueChanged}
                      minValue="0"
                      units="lbs"
                      disabled={logged}
                      data-test="setRow-weightInLbs"
                    />
                  ) : (
                    <MobNoWeight data-test="setRow-noWeight">-</MobNoWeight>
                  )}
                </Grid>
              </Grid>
            </ItemContent>
          </SwipeableListItem>
        </SwipeableList>
      </div>
    );
  }
};

/**
 * Web
 */
const TR = styled.tr`
  height: 64px;
  position: relative;

  > :first-child {
    padding-left: 40px;
  }

  > :nth-child(5) {
    padding-left: 10px;
  }

  &:hover {
    background-color: ${colors.primary050};
  }
`;

const NoWeight = styled.h4`
  color: ${colors.primary400};
  width: 68px;
`;

const TD = styled.td`
  padding: 0 20px;
  border: none;
`;

const AbsTD = styled.td`
  padding-right: 16px;
  margin-top: -15px;
  border: none;
`;

const Num = styled.h4`
  color: ${(props) =>
    props.logged ? `${colors.primary400};` : `${colors.primary800}`};
`;

/**
 * Mobile
 */

const ItemContent = styled(FlexContainer)`
  height: 64px;
  width: 100%;
  user-select: none;
  padding-left: 18px;
  background-color: ${({ dark }) => (dark ? '#fdfdfd' : colors.primary050)};
`;

const ActionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
`;

const MobRemoveXIcon = styled.div`
  height: 75px;
  width: 120px;
  background-color: #ec0a0a;
  transition: 0.3s;
`;

const TextStyle = styled.div`
  height: 18px;
  width: 49px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 28px;
  padding-left: 36px;
`;

const MobNum = styled.h4`
  color: ${(props) =>
    props.logged ? `${colors.primary400};` : `${colors.primary900}`};
  text-align: center;
  font-weight: bold;
`;

const MobNoWeight = styled.h4`
  color: ${colors.primary400};
  width: 68px;
  margin-left: 16px;
  text-align: center;
`;

export default SetRow;
