import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import {
  getExploreBanners,
  editExploreBanner,
} from '../../services/api/campaigns';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import DraggableBanner from './DraggableBanner';
import AlertStack from '../../elements/AlertStack';

const ManageExploreBanners = () => {
  const [inactiveBanners, setInactiveBanners] = useState([]);
  const [activeBanners, setActiveBanners] = useState([]);

  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');

  const loadBanners = async () => {
    try {
      setStatus('');
      setMessage('');
      const exploreBanners = await getExploreBanners();
      setInactiveBanners(exploreBanners.filter((banner) => !banner.active));
      setActiveBanners(
        exploreBanners
          .filter((banner) => banner.active)
          .sort((a, b) => a.activeIndex > b.activeIndex),
      );
      // TODO: Add alert if more than 3 active banners
    } catch (err) {
      console.error(err);
      setStatus('error');
      setMessage(
        'There was an error while loading the explore banners. Please refresh and try again.',
      );
    }
  };

  useEffect(() => {
    loadBanners();
  }, []);

  const inactiveList = inactiveBanners.map((banner, index) => (
    <DraggableBanner
      key={banner._id}
      index={index}
      bannerId={banner._id}
      title={banner.title}
      name={banner.name}
      imageUrl={banner.imageUrl}
      textBody={banner.textBody}
      color={banner.color}
      active={banner.active}
      data-test="inactive-banner"
    />
  ));

  const renderDroppable = (index) => {
    const bannerToRender = activeBanners.find(
      (banner) => banner.activeIndex === index,
    );
    if (bannerToRender) {
      return (
        <DraggableBanner
          index={index}
          bannerId={bannerToRender._id}
          title={bannerToRender.title}
          name={bannerToRender.name}
          imageUrl={bannerToRender.imageUrl}
          textBody={bannerToRender.textBody}
          color={bannerToRender.color}
          active={bannerToRender.active}
          data-test="active-banner"
        />
      );
    }
    return <div data-test="empty-slot"></div>;
  };

  const disableDrop = (index) => {
    if (activeBanners.find((banner) => banner?.activeIndex === index)) {
      return true;
    }
    return false;
  };

  const onDragEndBanners = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    // Determine whether moving from inactive to active or vice versa
    let bannerToUpdate = {};
    if (
      source.droppableId === 'inactive-list' &&
      destination.droppableId.includes('droppable')
    ) {
      const droppableSlot = parseInt(destination.droppableId.split('-')[1], 10);
      bannerToUpdate = inactiveBanners.find(
        (banner) => banner._id === result.draggableId,
      );
      bannerToUpdate.activeIndex = droppableSlot;
      // Update banner as active and set the index to prevent flicker and reordering of the list
      editExploreBanner(result.draggableId, {
        active: true,
        activeIndex: droppableSlot,
      })
        .then((success) => {
          setActiveBanners([...activeBanners, bannerToUpdate]);
          setInactiveBanners(
            inactiveBanners.filter(
              (banner) => banner._id !== result.draggableId,
            ),
          );
          setStatus('success');
          setMessage('');
          loadBanners();
        })
        .catch((err) => {
          console.error(err);
          setStatus('error');
          setMessage(
            `There was an error while activating the banner: ${
              err.message ?? err.error.message
            }`,
          );
        });
    } else if (
      source.droppableId.includes('droppable') &&
      destination.droppableId === 'inactive-list'
    ) {
      bannerToUpdate = activeBanners.find(
        (banner) => banner._id === result.draggableId,
      );
      // Deactiveate banner
      editExploreBanner(result.draggableId, {
        active: false,
        activeIndex: undefined,
      })
        .then((success) => {
          setActiveBanners([
            activeBanners.filter((banner) => banner._id !== result.draggableId),
          ]);
          setInactiveBanners([...inactiveBanners, bannerToUpdate]);
          setStatus('success');
          setMessage('');
          loadBanners();
        })
        .catch((err) => {
          console.error(err);
          setStatus('error');
          setMessage(
            `There was an error while inactivating the banner: ${
              err.message ?? err.error.message
            }`,
          );
        });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEndBanners}>
      <Container>
        <LeftContainer flexDirection="column">
          <Header>Available Banners</Header>
          <Droppable droppableId="inactive-list">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <InactiveContainer
                  flexDirection="column"
                  hovered={snapshot.isDraggingOver}
                  data-test="inactive-list"
                >
                  {inactiveList}
                  {provided.placeholder}
                </InactiveContainer>
              </div>
            )}
          </Droppable>
        </LeftContainer>
        <RightContainer flexDirection="column">
          <Header>Active Slots</Header>
          <Droppable
            droppableId={`droppable-0`}
            isDropDisabled={disableDrop(0)}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <EmptyBanner
                  hovered={snapshot.isDraggingOver}
                  data-test="active-slot-1"
                >
                  {renderDroppable(0)}
                  {provided.placeholder}
                </EmptyBanner>
              </div>
            )}
          </Droppable>
          <Droppable
            droppableId={`droppable-1`}
            isDropDisabled={disableDrop(1)}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <EmptyBanner
                  hovered={snapshot.isDraggingOver}
                  data-test="active-slot-2"
                >
                  {renderDroppable(1)}
                  {provided.placeholder}
                </EmptyBanner>
              </div>
            )}
          </Droppable>
          <Droppable
            droppableId={`droppable-2`}
            isDropDisabled={disableDrop(2)}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <EmptyBanner
                  hovered={snapshot.isDraggingOver}
                  data-test="active-slot-3"
                >
                  {renderDroppable(2)}
                  {provided.placeholder}
                </EmptyBanner>
              </div>
            )}
          </Droppable>
        </RightContainer>
        {status === 'error' && (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'error'}
            handleClose={() => setStatus(null)}
            autoHideDuration={20000}
            data-test="activity-message-error"
          />
        )}
      </Container>
    </DragDropContext>
  );
};

const Container = styled(FlexContainer)`
  padding-top: 48px;
  gap: 32px;
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

const LeftContainer = styled(FlexContainer)`
  flex-basis: 50%;
  gap: 16px;
  height: 100vh;
  overflow-y: scroll;
`;

const RightContainer = styled(FlexContainer)`
  flex-basis: 50%;
  gap: 16px;
`;

const EmptyBanner = styled(FlexContainer)`
  width: 382px;
  height: 154px;
  border-radius: 16px;
  border: 1px dashed ${colors.primary700};
  background-color: ${(props) => (props.hovered ? colors.primary100 : '#fff')};
`;

const InactiveContainer = styled(FlexContainer)`
  gap: 16px;
  min-height: 50px;
  padding-bottom: 100px;
  border: ${(props) =>
    props.hovered ? `1px dashed ${colors.primary700}` : 'none'};
`;

export default ManageExploreBanners;
