import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = (state) => ({
  intakeComplete: state.currentUser.user.intakeComplete,
  isSubscribed: state.currentUser.user.isSubscribed,
  isAdmin: state.currentUser.user.isAdmin,
});

const RequireIntake = (props) => {
  const { intakeComplete, isSubscribed, isAdmin } = useSelector(mapState);
  return intakeComplete || isAdmin ? (
    <Outlet />
  ) : !isSubscribed ? (
    <Navigate to="/intake/payment" />
  ) : (
    <Navigate to="/intake/questionnaire" />
  );
};

export default RequireIntake;
