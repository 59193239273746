import { formatDate } from '../helpers/date';
import { formatCalories } from '../helpers/nutrition';

export const calculateMealStats = (mealPlan, dateValue) => {
  const recipes = mealPlan.recipes.filter(
    (r) => formatDate(dateValue) === formatDate(r.date),
  );

  const foods = mealPlan.foods.filter(
    (f) => formatDate(dateValue) === formatDate(f.date),
  );

  console.log(foods);
  const totalCalories =
    foods
      .map(
        (food) =>
          formatCalories(
            food.food?.nutrientsPerUnit.calories * food.quantity,
          ) || 0,
      )
      .reduce((acc, cur) => acc + cur, 0) +
    recipes
      .map((recipe) => formatCalories(recipe.recipe?.nutrients?.calories) || 0)
      .reduce((acc, cur) => acc + cur, 0);

  const totalCaloriesComplete =
    foods
      .map(
        (food) =>
          (food.eaten
            ? formatCalories(
                food.food?.nutrientsPerUnit.calories * food.quantity,
              )
            : 0) || 0,
      )
      .reduce((acc, cur) => acc + cur, 0) +
    recipes
      .map(
        (recipe) =>
          (recipe.eaten
            ? formatCalories(recipe.recipe?.nutrients?.calories)
            : 0) || 0,
      )
      .reduce((acc, cur) => acc + cur, 0);

  return { recipes, foods, totalCalories, totalCaloriesComplete };
};
