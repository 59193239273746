import React, { useRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { colors } from '../styleConstants';
import Button from '../elements/Button';
import ShoppingListDateHeader from './ShoppingListDateHeader';
// import ExportIcon from '../icons/ExportIcon'; // TODO: Add later (https://app.clickup.com/t/2np7jj6)
import FlexContainer from '../elements/FlexContainer';
import FoodRow from './FoodRow';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import RecipeItem from './RecipeItemMobile';

const ShoppingListMobile = ({
  shoppingList,
  recipes,
  addFoodToShoppingList,
  removeFoodFromShoppingList,
  removeRecipeFromShoppingList,
  adjustQuantity,
  setFoodCompleted,
  clearCompleted,
  values,
  setValues,
  categories,
  selectedRecipe,
  handleRecipeClick,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const [newItem, setNewItem] = useState(null);
  const prevShoppingListRef = useRef();

  useLayoutEffect(() => {
    if (!selectedRecipe) {
      if (
        prevShoppingListRef?.current?.length &&
        shoppingList.length > prevShoppingListRef?.current?.length
      ) {
        const prevIds = prevShoppingListRef?.current.map((food) => food.foodId);
        const newFood = shoppingList.filter(
          (food) => prevIds.indexOf(food.foodId) === -1,
        )[0];
        setNewItem(newFood);
      }
      //assign the ref's current value to the count Hook
      prevShoppingListRef.current = shoppingList;
    }
  }, [shoppingList]);

  // Generate component lists
  const categoryLists = categories.map((category, i) => {
    const foodList = shoppingList
      .filter((food) => food?.quantity > 0 && food?.category === category)
      .map((food, i, arr) => (
        <FoodRow
          key={food?.foodId}
          food={food}
          handleComplete={setFoodCompleted}
          handleRemove={removeFoodFromShoppingList}
          handleQuantity={debounce(adjustQuantity, 500)}
          isLastRow={i === arr.length - 1}
          newItem={newItem}
          disabled={selectedRecipe}
        />
      ));
    return (
      <CategoryContainer flexDirection="column" data-test="slCategory" key={i}>
        <CategoryHeaderBanner
          alignItems="center"
          addSpace={!foodList.length}
          justify="space-between"
        >
          <CategoryHeader data-test="slCategory-name">
            {category === 'other' ? 'Additional Items' : category}
          </CategoryHeader>
        </CategoryHeaderBanner>
        {foodList}
      </CategoryContainer>
    );
  });

  const recipeList = recipes.map((recipe, i) => (
    <RecipeItem
      key={i}
      recipe={recipe}
      handleClick={(e) => handleRecipeClick(e, recipe)}
      handleRemove={removeRecipeFromShoppingList}
      isSelected={selectedRecipe?.instanceId === recipe.instanceId}
      isFiltered={
        selectedRecipe && selectedRecipe?.instanceId !== recipe.instanceId
      }
      userId={props.userId}
    />
  ));

  return (
    <div>
      <ShoppingListDateHeader
        values={values}
        setValues={setValues}
        launchModal={launchModal}
      />
      <PageContainer alignItems="flex-start" flexDirection="column">
        <RecipeContainer flexDirection="column" alignItems="flex-start">
          <RecipeList>{recipeList}</RecipeList>
        </RecipeContainer>
        <FoodContainer flexDirection="column">
          {categoryLists}
          <ModalContainer
            open={open}
            context={{ ...context, type: 'shoppinglist' }}
            handleClose={closeModal}
            initialScreen="Meal Search"
            addFood={addFoodToShoppingList}
            {...props}
          />
          <ButtonContainer alignSelf="center" alignItems="center">
            <Button
              buttonText="Clear Completed"
              buttonSize="small"
              width="224px"
              handleClick={clearCompleted}
              data-test="shoppingList-clearCompleted"
            />
          </ButtonContainer>
        </FoodContainer>
      </PageContainer>
    </div>
  );
};

const PageContainer = styled(FlexContainer)``;

const FoodContainer = styled(FlexContainer)``;

const RecipeContainer = styled(FlexContainer)`
  max-width: 100%;
  overflow-x: scroll;
  margin-top: 144px;
  /* border-radius: 4px; */
  box-sizing: border-box;
  padding: 24px 0 24px 20px;
`;

const ButtonContainer = styled(FlexContainer)`
  padding-top: 24px;
  padding-bottom: 100px;
`;

const RecipeList = styled(FlexContainer)`
  gap: 8px;
`;

const CategoryContainer = styled(FlexContainer)`
  width: 100vw;
`;

const CategoryHeaderBanner = styled(FlexContainer)`
  background-color: ${colors.primary050};
  height: 40px;
  padding: 0 32px;
  margin-bottom: ${(props) => (props.addSpace ? '24px' : 0)};
`;

const CategoryHeader = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
`;

export default ShoppingListMobile;
