import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { formatDate } from '../../helpers/date';
import { apiCall } from '../../services/api/api';
import { DateTime } from 'luxon';
import {
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
  updateUserFitnessPlan,
} from './selectedFitnessPlanSlice';

const initialState = {
  id: null,
  planRef: 'B1-1',
  active: true,
  type: 'Weight Loss',
  level: 'Beginner',
  locationType: 'Gym',
  gender: 'All',
  planLength: 7,
  activities: [],
  workouts: [],
};

const blankPlan = {
  startDate: formatDate(DateTime.now().startOf('week')),
  endDate: formatDate(DateTime.now().endOf('week')),
  planLength: 7,
  type: 'Weight Loss',
  level: 'Beginner',
  locationType: 'Home',
  gender: 'All',
  activities: [],
  workouts: [],
};

const activeFitnessplanSlice = createSlice({
  name: 'activeFitnessPlan',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getActiveUserFitnessPlan.fulfilled, (state, action) => {
        return action.payload;
      })
      .addMatcher(
        isAnyOf(
          addActivityToFitnessPlan.fulfilled,
          addWorkoutToFitnessPlan.fulfilled,
          updateUserFitnessPlan.fulfilled,
        ),
        (state, action) => {
          if (
            action.payload.startDate ===
            formatDate(DateTime.now().startOf('week'))
          ) {
            return action.payload;
          }
          return state;
        },
      );
  },
});

export const getActiveUserFitnessPlan = createAsyncThunk(
  'fitnessPlan/getActiveUserFitnessPlan',
  async (userId, { rejectWithValue }) => {
    try {
      const date = new Date();
      const params = {
        query: {
          user: userId,
          startDate: formatDate(DateTime.fromJSDate(date).startOf('week')),
          endDate: formatDate(DateTime.fromJSDate(date).endOf('week')),
        },
      };
      let fitnessPlan = await apiCall('get', `/fitnessplanuser`, params);
      if (!fitnessPlan[0]) {
        fitnessPlan = await apiCall('post', `/fitnessplanuser`, blankPlan);
      } else {
        fitnessPlan = fitnessPlan[0];
      }
      return fitnessPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default activeFitnessplanSlice.reducer;
