import React from 'react';
import { colors } from '../styleConstants';

const AddIconSmall = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g clipPath="url(#clip0_2443_5325)">
        <path
          fill={
            props.color === 'pink' ? colors.hlitetwo200 : colors.secondary200
          }
          d="M12.5 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm5 8a1 1 0 01-1 1h-2a1 1 0 00-1 1v2a1 1 0 11-2 0v-2a1 1 0 00-1-1h-2a1 1 0 110-2h2a1 1 0 001-1V8a1 1 0 112 0v2a1 1 0 001 1h2a1 1 0 011 1z"
          opacity="0.3"
        ></path>
        <path
          fill={
            props.color === 'pink' ? colors.hlitetwo400 : colors.secondary500
          }
          d="M13.5 8a1 1 0 10-2 0v2a1 1 0 01-1 1h-2a1 1 0 100 2h2a1 1 0 011 1v2a1 1 0 102 0v-2a1 1 0 011-1h2a1 1 0 100-2h-2a1 1 0 01-1-1V8zm-1-6c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2443_5325">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddIconSmall;
