import React, { useState } from 'react';
import FlexContainer from '../../elements/FlexContainer';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';
import Button from '../../elements/Button';
import {
  getWorkout,
  replaceWorkoutsOnFitnessPlan,
} from '../../services/api/workout';
import {
  getFitnessPlan,
  replaceFitnessPlansOnTrack,
} from '../../services/api/fitnessPlan';
import { getTrack, replaceNextTrackOnTrack } from '../../services/api/track';
import {
  getActivity,
  replaceActivitiesOnFitnessPlan,
} from '../../services/api/activity';
import { getFood, replaceFoodsOnMealPlan } from '../../services/api/food';
import { getRecipe, replaceRecipesOnMealPlan } from '../../services/api/recipe';
import {
  getExercise,
  replaceExercisesOnWorkout,
} from '../../services/api/exercise';
import AlertStack from '../../elements/AlertStack';

const RecordRow = ({ record, recordType, relatedRecord, ...props }) => {
  const { open, launchModal, closeModal } = useModal(false);
  const [newRecord, setNewRecord] = useState();
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  let getItem;
  let showOnly;
  let hide;
  let initialScreen;
  switch (recordType) {
    case 'workout':
      showOnly = 'workout';
      initialScreen = 'Exercise Search';
      getItem = getWorkout;
      break;
    case 'fitnessPlan':
      showOnly = 'fitnessPlan';
      initialScreen = 'Exercise Search';
      getItem = getFitnessPlan;
      break;
    case 'track':
      showOnly = 'track';
      initialScreen = 'Exercise Search';
      getItem = getTrack;
      break;
    case 'activity':
      showOnly = 'activity';
      initialScreen = 'Exercise Search';
      getItem = getActivity;
      break;
    case 'exercise':
      showOnly = 'exercise';
      initialScreen = 'Exercise Search';
      getItem = getExercise;
      break;
    case 'food':
      hide = 'recipe';
      initialScreen = 'Meal Search';
      getItem = getFood;
      break;
    case 'recipe':
      hide = 'food';
      initialScreen = 'Meal Search';
      getItem = getRecipe;
      break;
    default:
      showOnly = '';
      hide = '';
  }

  async function loadItem(params) {
    try {
      const itemId = params[recordType];
      const result = await getItem(itemId);
      if (result) {
        setNewRecord(result);
        setStatus('');
      } else {
        throw new Error('Item could not be found');
      }
    } catch (err) {
      console.error(err);
      setStatus('loadError');
      setMessage('Unable to load item');
    }
  }

  async function replaceRecord() {
    try {
      if (recordType === 'workout') {
        if (newRecord?.active === false) {
          setStatus('loadError');
          setNewRecord(null);
          setMessage('Please select an active workout');
          return;
        }
        replaceWorkoutsOnFitnessPlan(
          relatedRecord._id,
          record,
          newRecord._id,
        ).then((result) => {
          setStatus('success');
          setMessage('Replaced!');
        });
      } else if (recordType === 'fitnessPlan') {
        if (newRecord?.active === false) {
          setStatus('loadError');
          setNewRecord(null);
          setMessage('Please select an active plan');
          return;
        }
        replaceFitnessPlansOnTrack(
          relatedRecord._id,
          record,
          newRecord._id,
        ).then((result) => {
          setStatus('success');
          setMessage('Replaced!');
        });
      } else if (recordType === 'track') {
        replaceNextTrackOnTrack(relatedRecord._id, record, newRecord._id).then(
          (result) => {
            setStatus('success');
            setMessage('Replaced!');
          },
        );
      } else if (recordType === 'activity') {
        replaceActivitiesOnFitnessPlan(
          relatedRecord._id,
          record,
          newRecord._id,
        ).then((result) => {
          setStatus('success');
          setMessage('Replaced!');
        });
      } else if (recordType === 'exercise') {
        if (newRecord?.active === false) {
          setStatus('loadError');
          setNewRecord(null);
          setMessage('Please select an active exercise');
          return;
        }
        replaceExercisesOnWorkout(
          relatedRecord._id,
          record,
          newRecord._id,
        ).then((result) => {
          setStatus('success');
          setMessage('Replaced!');
        });
      } else if (recordType === 'food' && relatedRecord.__t === 'RecipeAdmin') {
        setStatus('replaceError');
        setMessage('Foods on recipes must be manually replaced');
      } else if (recordType === 'food') {
        replaceFoodsOnMealPlan(relatedRecord._id, record, newRecord._id).then(
          (result) => {
            setStatus('success');
            setMessage('Replaced!');
          },
        );
      } else if (recordType === 'recipe') {
        if (newRecord?.active === false) {
          setStatus('loadError');
          setNewRecord(null);
          setMessage('Please select an active recipe');
          return;
        }
        replaceRecipesOnMealPlan(relatedRecord._id, record, newRecord._id).then(
          (result) => {
            setStatus('success');
            setMessage('Replaced!');
          },
        );
      }
    } catch (err) {
      console.error(err);
      setStatus('replaceError');
      setMessage('An error occurred');
    }
  }

  return (
    <tr>
      <TD>
        <RecordInfo data-test="recordRow-name">{relatedRecord.name}</RecordInfo>
      </TD>
      <TD data-test="recordRow-recordType">{relatedRecord.__t}</TD>
      <TD
        style={{ color: colors.secondary500 }}
        data-test="recordRow-replaceWith"
      >
        {newRecord && newRecord.name}
      </TD>
      <TD>
        {newRecord && status === 'success' ? (
          <SuccessMessage data-test="recordRow-message-success">
            {message}
          </SuccessMessage>
        ) : newRecord && status !== 'replaceError' ? (
          <Button
            pink="true"
            buttonText="Replace"
            buttonSize="small"
            handleClick={replaceRecord}
            data-test="recordRow-replaceButton"
          />
        ) : newRecord && status === 'replaceError' ? (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'replaceError'}
            handleClose={() => setStatus(null)}
            autoHideDuration={20000}
            data-test="recordRow-message-error"
          />
        ) : status === 'loadError' && !newRecord ? (
          <FlexContainer flexDirection="column">
            <Button
              buttonText="Find New"
              buttonSize="small"
              handleClick={launchModal}
              data-test="recordRow-findButton"
            />
            <AlertStack
              messages={message}
              type="error"
              variant="filled"
              open={status === 'loadError'}
              handleClose={() => setStatus(null)}
              autoHideDuration={20000}
              data-test="recordRow-message-error"
            />
          </FlexContainer>
        ) : (
          <Button
            buttonText="Find New"
            buttonSize="small"
            handleClick={launchModal}
            data-test="recordRow-findButton"
          />
        )}
      </TD>
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: showOnly,
          hide: hide,
        }}
        initialScreen={initialScreen}
        handleClose={closeModal}
        addWorkout={loadItem}
        addFitnessPlan={loadItem}
        addTrack={loadItem}
        addActivity={loadItem}
        addExercise={loadItem}
        addFood={loadItem}
        addRecipe={loadItem}
      />
    </tr>
  );
};

const TD = styled.td`
  padding: 12px 8px;
`;

const RecordInfo = styled.h6`
  color: ${colors.primary800};
`;

const SuccessMessage = styled.p`
  color: ${colors.secondary600};
  margin: 16px 0;
  padding: 0px;
`;

export default RecordRow;
