import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import { allUnits } from '../../helpers/units';
import TextInput from '../../elements/TextInput';
import FractionInput from '../../elements/FractionInput';
import Dropdown from '../../elements/Dropdown';
import Button from '../../elements/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Messages from '../../universal/Messages';

const CustomFoodWeb = ({
  values,
  handleChange,
  handleValueChanged,
  handleSubmit,
  status,
  message,
  loading,
  ...props
}) => {
  return (
    <Container flexDirection="column">
      <Header>Create Custom Food</Header>
      <FormContainer>
        <LeftContainer flexDirection="column" justify="space-between">
          <FieldsContainer flexDirection="column">
            <TextInput
              id="foodName"
              name="name"
              onChange={handleChange}
              value={values.name}
              placeholder="Food Name"
              label="Food Name"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = 'Food Name')}
              data-test="customFood-name"
            />
            <TextInput
              id="foodType"
              name="type"
              onChange={handleChange}
              value={values.type}
              placeholder="Food Type"
              label="Food Type"
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = 'Food Type')}
              data-test="customFood-type"
            />
            <AmountContainer>
              <FractionInput
                id="amount"
                name="amount"
                data-name="amount"
                label="Amount"
                minValue="0"
                value={values.amount || 1}
                onValueChanged={handleValueChanged}
                data-test="customFood-amount"
              />
              <Dropdown
                width="320px"
                options={allUnits || ['']}
                label="Measure Unit"
                value={{
                  value: values.measureUnit,
                  label: values.measureUnit,
                }}
                onChange={(value) =>
                  handleValueChanged('measureUnit', value.value, 'string')
                }
                data-test="customFood-unit"
              />
            </AmountContainer>
            <FractionInput
              id="gramWeight"
              name="gramWeight"
              onValueChanged={handleValueChanged}
              value={values.gramWeight}
              label="Gram Weight"
              units="grams"
              data-test="customFood-gramWeight"
            />
          </FieldsContainer>
          {status === 'error' ? (
            <Messages
              messages={message}
              type="error"
              testName="customFood-error"
            />
          ) : status === 'success' ? (
            <Messages
              messages={message}
              type="success"
              testName="customFood-success"
            />
          ) : null}
        </LeftContainer>
        <RightContainer flexDirection="column">
          <FoodSectionHeader justify="space-between" alignItems="center">
            <FoodSectionName>Nutrition</FoodSectionName>
          </FoodSectionHeader>
          <p>
            Nutrient values should reflect the total for the amount you
            specified in the first column.
          </p>
          <NutrientsContainer wrap="wrap" flexDirection="column">
            <FractionInput
              label="Calories*"
              name="calories"
              value={values.calories}
              onValueChanged={handleValueChanged}
              units="kCal"
              data-test="customFood-calories"
              required
            />
            <FractionInput
              label="Total Fat*"
              name="fat"
              value={values.fat}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-fat"
              required
            />
            <FractionInput
              label="Carbs*"
              name="carbohydrates"
              value={values.carbohydrates}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-carbs"
              required
            />
            <FractionInput
              label="Protein*"
              name="proteins"
              value={values.proteins}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-protein"
              required
            />
            <FractionInput
              label="Saturated Fat"
              name="saturatedFat"
              value={values.saturatedFat}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-satFat"
            />

            <FractionInput
              label="Trans Fat"
              name="transFat"
              value={values.transFat}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-transFat"
            />
            <FractionInput
              label="Cholesterol"
              name="cholesterol_mg"
              value={values.cholesterol_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              data-test="customFood-cholesterol"
            />
            <FractionInput
              label="Sodium"
              name="sodium_mg"
              value={values.sodium_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              data-test="customFood-sodium"
            />
            <FractionInput
              label="Dietary Fiber"
              name="fiber"
              value={values.fiber}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-fiber"
            />
            <FractionInput
              label="Total Sugars"
              name="totalSugar"
              value={values.totalSugar}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-totalSugar"
            />
            <FractionInput
              label="Added Sugars"
              name="addedSugar"
              value={values.addedSugar}
              onValueChanged={handleValueChanged}
              units="g"
              data-test="customFood-addedSugar"
            />
            <FractionInput
              label="Vitamin D"
              name="vitaminD_mcg"
              value={values.vitaminD_mcg}
              onValueChanged={handleValueChanged}
              units="mcg"
              data-test="customFood-vitD"
            />
            <FractionInput
              label="Calcium"
              name="calcium_mg"
              value={values.calcium_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              data-test="customFood-calcium"
            />
            <FractionInput
              label="Potassium"
              name="potassium_mg"
              value={values.potassium_mg}
              onValueChanged={handleValueChanged}
              units="mg"
              data-test="customFood-potassium"
            />
          </NutrientsContainer>
        </RightContainer>
      </FormContainer>
      <AddButtonContainer justify="center" alignItems="center">
        <Button
          pink="true"
          width="300px"
          buttonText="Create Custom Food"
          buttonSize="large"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="customFood-submit"
        />
      </AddButtonContainer>
    </Container>
  );
};
const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const FormContainer = styled(FlexContainer)``;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const LeftContainer = styled(FlexContainer)`
  margin-top: 48px;
  padding-right: 48px;
  border-right: 1px solid ${colors.primary200};
`;

const FieldsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
`;

const RightContainer = styled(FlexContainer)`
  padding-left: 48px;
  margin-top: 48px;
  flex-basis: 50%;
  flex-grow: 1;

  > * {
    margin-bottom: 24px;
  }
`;
const Header = styled.h2`
  color: ${colors.primary800};
`;

const AmountContainer = styled(FlexContainer)`
  > * {
    margin-right: 12px;
  }
`;

const NutrientsContainer = styled(FlexContainer)`
  max-height: 350px;
  > * {
    margin-bottom: 6px;
  }
`;

const FoodSectionHeader = styled(FlexContainer)`
  width: calc(100% - 16px);
  padding: 0 8px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const FoodSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

export default CustomFoodWeb;
