import React, { useState } from 'react';
import FlexContainer from '../../elements/FlexContainer';
import TextInput from '../../elements/TextInput';
import Button from '../../elements/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useFormValues from '../../hooks/useFormValues';
import { editCircleSlug } from '../../store/general/circleSlugSlice';
import AlertStack from '../../elements/AlertStack';
import { useSelector, useDispatch } from 'react-redux';

const CircleSlugConfig = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const circleSlug = useSelector((state) => state.circleSlug);

  const { values, handleChange } = useFormValues({
    slug: circleSlug,
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      dispatch(editCircleSlug(values.slug));
      setLoading(false);
      setStatus('success');
      setMessage('URL updated successfully');
    } catch (err) {
      setLoading(false);
      setStatus('error');
      setMessage('There was an issue while updating the URL');
      console.log(err);
    }
  };

  return (
    <FlexContainer flexDirection="column">
      <TextInput
        id="slug"
        name="slug"
        onChange={handleChange}
        value={values.slug}
        label="Circle URL"
        data-test="circleSlug-input"
      />
      <Button
        type="submit"
        buttonText="Update URL"
        buttonSize="large"
        onClick={handleSubmit}
        disabled={loading}
        startIcon={loading && <CircularProgress size={24} color="inherit" />}
        data-test="circleSlug-submit"
      />
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="circleSlug-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="circleSlug-message-success"
        />
      ) : null}
    </FlexContainer>
  );
};

export default CircleSlugConfig;
