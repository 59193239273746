import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { getWorkoutReferences } from '../../services/api/workout';
import { getFitnessPlanReferences } from '../../services/api/fitnessPlan';
import { getTrackReferences } from '../../services/api/track';
import { getActivityReferences } from '../../services/api/activity';
import { getFoodReferences } from '../../services/api/food';
import { getRecipeReferences } from '../../services/api/recipe';
import { getExerciseReferences } from '../../services/api/exercise';
import { colors } from '../../styleConstants';
import { capitalize } from 'lodash';

import RecordRow from './RecordRow';

const RelatedRecords = ({ recordType, recordId, ...props }) => {
  const [relatedRecords, setRelatedRecords] = useState([]);

  const findRelated = () => {
    if (recordType === 'workout') {
      getWorkoutReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (recordType === 'activity') {
      getActivityReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (recordType === 'exercise') {
      getExerciseReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (recordType === 'fitnessPlan') {
      getFitnessPlanReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (recordType === 'track') {
      getTrackReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (recordType === 'food') {
      getFoodReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (recordType === 'recipe') {
      getRecipeReferences(recordId)
        .then((result) => {
          setRelatedRecords(result);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    findRelated();
  }, [recordType, recordId]);

  const recordRows = relatedRecords.map((relatedRecord) => {
    return (
      <RecordRow
        key={relatedRecord._id}
        record={recordId}
        relatedRecord={relatedRecord}
        recordType={recordType}
      />
    );
  });

  const formattedRecordType =
    recordType === 'fitnessPlan'
      ? 'Fitness Plan'
      : recordType === 'mealPlan'
        ? 'Meal Plan'
        : recordType;

  return (
    <Container flexDirection="column">
      <Heading>Related Records</Heading>
      <Text>
        Please review all records that reference this record and replace with a
        more appropriate item or remove it entirely.
      </Text>
      <Table>
        <THead>
          <tr>
            <TH>Referencing Record</TH>
            <TH>Referencing Record Type</TH>
            <TH>Replace {capitalize(formattedRecordType)} With</TH>
            <TH></TH>
          </tr>
        </THead>
        <tbody data-test={`${recordType}-recordRows`}>{recordRows}</tbody>
      </Table>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  padding: 24px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const Text = styled.p`
  color: ${colors.secondary600};
  margin: 16px 0;
  text-align: center;
  padding: 0px;
`;

const Table = styled.table`
  width: 100%;
  margin: auto;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

const TH = styled.th`
  padding: 12px 8px;
  text-align: left;
`;

const THead = styled.thead`
  font-weight: bold;
`;

export default RelatedRecords;
