import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import Dropdown from '../../elements/Dropdown';
import IntensityIcon from '../../icons/IntensityIcon';
import MobileDropdown from '../../elements/MobileDropdown';
import useFormValues from '../../hooks/useFormValues';
import NumericInput from '../../elements/NumericInput';
import MobileNumericInput from '../../elements/MobileNumericInput';
import { formatCalories } from '../../helpers/nutrition';
import { isMobile } from '../../helpers/utils';
import { trackEvent } from '../../integrations/analytics';

const RecipeOptions = ({
  recipe,
  recipeYield,
  handleRecipePropertyChanged,
  ...props
}) => {
  const prepTime = recipe?.recipe?.prepTime ?? 0;
  const cookTime = recipe?.recipe?.cookTime ?? 0;
  const totalTime = prepTime + cookTime;

  const { values, handleChange, handleValueChanged } = useFormValues({
    numServings: recipeYield,
  });

  useEffect(() => {
    if (values.numServings !== recipeYield) {
      trackEvent(
        `Adjust Recipe Servings (Recipe Page - ${
          isMobile() ? 'Mobile' : 'Web'
        })`,
      );
      handleRecipePropertyChanged('yield', values.numServings);
    }
  }, [values.numServings]);

  return (
    <Container flexDirection="column">
      {!recipe.recipe.placeholder && (
        <RecipeInfoContainer justify={isMobile() ? 'center' : 'inherit'}>
          <InfoItem alignItems="center">
            <PrepIcon src={`${process.env.PUBLIC_URL}/icons/Preparation.svg`} />
            <InfoColumn flexDirection="column">
              <InfoLabel>Ready In: </InfoLabel>
              <Info data-test="recDetails-totalTime">{totalTime} minutes</Info>
            </InfoColumn>
          </InfoItem>
          {recipe.recipe?.nutrients?.calories > 0 && (
            <InfoItem alignItems="center">
              <IntensityIcon
                filled={true}
                color={colors.secondary600}
                width="22px"
                height="22px"
              />
              <InfoColumn flexDirection="column">
                <InfoLabel>Calories: </InfoLabel>
                <Info data-test="recipePage-calories">
                  {formatCalories(recipe.recipe?.nutrients?.calories)}
                </Info>
              </InfoColumn>
            </InfoItem>
          )}
        </RecipeInfoContainer>
      )}
      <MealActionContainer flexDirection="column" justify="center">
        <ActionContainer alignItems="center">
          <ActionLabel>Meal:</ActionLabel>
          {isMobile() ? (
            <MobileDropdown
              width="220px"
              options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
              value={{
                value: recipe.mealType,
                label: recipe.mealType,
              }}
              onChange={(value) =>
                handleRecipePropertyChanged('mealType', value.value)
              }
              data-test="recipePage-mealSelect"
            />
          ) : (
            <Dropdown
              width="220px"
              options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
              value={{
                value: recipe.mealType,
                label: recipe.mealType,
              }}
              onChange={(value) =>
                handleRecipePropertyChanged('mealType', value.value)
              }
              data-test="recipePage-mealSelect"
            />
          )}
        </ActionContainer>
        {!recipe.recipe.placeholder && (
          <ActionContainer alignItems="center">
            <ActionLabel>I'm Making:</ActionLabel>
            {isMobile() ? (
              <FlexContainer alignItems="center" style={{ gap: '16px' }}>
                <MobileNumericInput
                  name="numServings"
                  value={values.numServings || 1}
                  onValueChanged={handleValueChanged}
                  onChange={handleChange}
                  minValue="0"
                  units="Servings"
                  data-test="recDetails-numServings"
                />
                <ServingsLabel>Servings</ServingsLabel>
              </FlexContainer>
            ) : (
              <NumericInput
                name="numServings"
                value={values.numServings || 1}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                minValue="0"
                units="Servings"
                data-test="recDetails-numServings"
              />
            )}
          </ActionContainer>
        )}
      </MealActionContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)``;

const ActionContainer = styled(FlexContainer)`
  gap: 32px;
`;

const ActionLabel = styled.h4`
  color: ${colors.primary600};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100px;
`;

const MealActionContainer = styled(FlexContainer)`
  width: ${(props) => (isMobile() ? 'calc(100% - 64px)' : 'calc(100% - 32px)')};
  background-color: ${colors.primary050};
  gap: 32px;
  padding: 16px;
  margin: ${(props) => (isMobile() ? '0 16px' : 'initial')};
  border-radius: ${(props) => (!isMobile() ? '4px' : 'initial')};
`;

const RecipeInfoContainer = styled(FlexContainer)`
  margin-bottom: 28px;
  gap: 32px;
`;

const InfoColumn = styled(FlexContainer)`
  gap: 4px;
`;

const Info = styled.p`
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const InfoLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;

const PrepIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const InfoItem = styled(FlexContainer)`
  gap: 12px;
`;

const ServingsLabel = styled.p`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

export default RecipeOptions;
