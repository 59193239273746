import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import { useNavigate } from 'react-router-dom';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container flexDirection="column">
      <TopContainer flexDirection="column" alignItems="center">
        <ImageContainer
          image={`${process.env.PUBLIC_URL}/404Image1x.png`}
          image2x={`${process.env.PUBLIC_URL}/404Image2x.png`}
        />
        <H3>Looking for something?</H3>
        <H4>We couldn’t find the page that you’re looking for...</H4>
        <StyledButton
          buttonText="Back to home"
          buttonSize="large"
          width="170px"
          pink="true"
          fontcase="titleCase"
          handleClick={() => navigate('/signup')}
          data-test="back-to-home"
        />
      </TopContainer>
      <BottomContainer></BottomContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
`;

const TopContainer = styled(FlexContainer)`
  width: 100%;
  padding-top: ${() => (isMobile() ? '40%' : '12px')};
  flex-grow: 1;
  position: relative;
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  height: 33%;
  position: relative;
  display: block;
  margin-top: -100px;
  overflow: hidden;
  background-image: ${() =>
    !isMobile() ? `url(${process.env.PUBLIC_URL}/NotFoundArc.svg)` : null};
  background-repeat: none;
  background-size: cover;
`;

const ImageContainer = styled(FlexContainer)`
  background-image: ${(props) => `url(${props.image})`};
  background-image: ${(props) =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${(props) =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: ${() => (isMobile() ? '190px' : '380px')};
  width: ${() => (isMobile() ? '253px' : '506px')};
`;

const H3 = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: ${colors.primary900};
  margin-bottom: 16px;
  margin-top: ${() => (isMobile() ? '24px' : null)};
  padding: ${() => (isMobile() ? '0 36px' : null)};
  text-align: center;
`;

const H4 = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: ${() => (isMobile() ? '0 36px' : null)};
  color: ${colors.primary900};
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  font-size: 16px;
  height: 42px;
  position: absolute;
  bottom: ${() => (isMobile() ? '0' : '80px')};
  z-index: 5;
  box-shadow: 0px 33px 33px -8px rgba(40, 92, 89, 0.66);
`;

export default NotFound;
