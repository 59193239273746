import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const PlannerIcon = (props) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path d="M14.71,3.11V14.88H2.94V3.11H14.71m1-1H1.94V15.88H15.71V2.11Z" />
    <line x1="1.94" y1="9" x2="15.71" y2="9" strokeMiterlimit="10" />
    <line x1="8.83" y1="2.12" x2="8.83" y2="15.77" strokeMiterlimit="10" />
  </Icon>
);

const Icon = styled.svg`
  width: 18px;
  height: 18px;

  & path {
    fill: ${colors.primary500};
  }

  & line {
    fill: none;
    stroke: ${colors.primary500};
  }
`;

export default PlannerIcon;
