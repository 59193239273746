import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import Dropdown from '../../elements/Dropdown';
import { colors } from '../../styleConstants';

import ManageExploreBanners from './ManageExploreBanners';
import AddExploreBanner from './AddExploreBanner';
import EditExploreBanner from './EditExploreBanner';
import ViewExploreBanner from './ViewExploreBanner';
import DeleteExploreBanner from './DeleteExploreBanner';
import CircleSlugConfig from './CircleSlugConfig';

const AdminCampaign = (props) => {
  const [campaignType, setCampaignType] = useState('');
  const [actionType, setActionType] = useState('manage');

  const campaignOptions = ['Explore Banner', 'Signup Sidebar', 'Circle URL'];

  const handleChange = (e) => {
    setActionType(e.target.value);
  };

  const handleCampaignChange = (option) => {
    setCampaignType(option.value);
  };

  return (
    <Container flexDirection="column">
      <H2 data-test="admin-title-campaigns">Campaign Admin</H2>
      <H3>Campaign Type</H3>
      <Dropdown
        width="320px"
        options={campaignOptions}
        label="Campaign Types"
        value={{
          value: campaignType,
          label: campaignType,
        }}
        onChange={handleCampaignChange}
        data-test="campaign-types"
      />
      {campaignType && campaignType !== 'Circle URL' && (
        <>
          <H3>Action Type</H3>
          <FlexContainer flexDirection="row" justify="space-around">
            <RadioInput
              checked={actionType === 'manage'}
              value="manage"
              label="Manage"
              onChange={handleChange}
              name="recordType"
              data-test="campaign-radio-manage"
            />
            <RadioInput
              checked={actionType === 'create'}
              value="create"
              label="Create"
              onChange={handleChange}
              name="recordType"
              data-test="campaign-radio-create"
            />
            <RadioInput
              checked={actionType === 'edit'}
              value="edit"
              label="Edit"
              onChange={handleChange}
              name="recordType"
              data-test="campaign-radio-edit"
            />
            <RadioInput
              checked={actionType === 'view'}
              value="view"
              label="View"
              onChange={handleChange}
              name="recordType"
              data-test="campaign-radio-view"
            />
            <RadioInput
              checked={actionType === 'delete'}
              value="delete"
              label="Delete"
              onChange={handleChange}
              name="recordType"
              data-test="campaign-radio-delete"
            />
          </FlexContainer>
        </>
      )}

      {campaignType === 'Circle URL' ? (
        <CircleSlugConfig />
      ) : actionType === 'create' && campaignType === 'Explore Banner' ? (
        <AddExploreBanner />
      ) : actionType === 'edit' && campaignType === 'Explore Banner' ? (
        <EditExploreBanner />
      ) : actionType === 'manage' && campaignType === 'Explore Banner' ? (
        <ManageExploreBanners />
      ) : actionType === 'view' ? (
        <ViewExploreBanner />
      ) : actionType === 'delete' ? (
        <DeleteExploreBanner />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  max-width: 75%;
  margin-left: 25px;
  padding: 30px;
`;

const H2 = styled.h2`
  text-align: center;
  font-size: 28px;
  margin: 15px;
  color: ${colors.secondary600};
`;
const H3 = styled.h3`
  text-align: center;
  margin: 15px;
`;
export default AdminCampaign;
