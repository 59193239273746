import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';

const LogIcon = ({
  logged,
  toggleSelect,
  dateLogged,
  hideLabel,
  small,
  ...props
}) => (
  <Container flexDirection="column" alignItems="center">
    <Icon
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $logged={logged}
      $small={small}
      onClick={toggleSelect}
      {...props}
    >
      <g id="Log Icon Default" name="log" strokeWidth="2.5">
        <Circle
          id="Oval"
          cx="21.5"
          cy="22"
          r="20"
          fill="white"
          stroke="#21CCBF"
          strokeWidth="2.5"
          $logged={logged}
        />
        <Path
          id="Path 3"
          d="M12.75 23.1721L18.3059 28.728L31.2839 15.75"
          stroke="#21CCBF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          $logged={logged}
        />
      </g>
    </Icon>
    {!hideLabel && <Label>{logged ? 'Logged' : 'Log'}</Label>}
  </Container>
);

const Container = styled(FlexContainer)`
  position: relative;
`;

const Path = styled.path`
  pointer-events: none;
  fill: #fff;
  stroke: ${(props) =>
    props.$logged ? colors.secondary200 : colors.secondary400};
`;

const Circle = styled.circle`
  filter: ${(props) =>
    props.$logged ? 'none' : 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'};
  fill: #fff;
  stroke: ${(props) =>
    props.$logged ? colors.secondary200 : colors.secondary400};

  &:hover {
    cursor: pointer;
    stroke: ${colors.secondary400};
    fill: ${colors.secondary400};
  }

  &:hover + ${Path} {
    stroke: #fff;
    fill: ${colors.secondary400};
  }

  &:active {
    stroke: ${() => (!isMobile() ? colors.secondary300 : '')};
    fill: ${() => (!isMobile() ? colors.secondary300 : '')};
  }

  &:active + ${Path} {
    stroke: ${() => (!isMobile() ? '#fff' : '')};
    fill: ${() => (!isMobile() ? colors.secondary300 : '')};
  }
`;

const Icon = styled.svg`
  width: ${(props) =>
    props.width ? props.width : props.$small ? '30px' : '42px'};
  height: ${(props) =>
    props.height ? props.height : props.$small ? '30px' : '42px'};

  &:disabled Circle,
  &:disabled Path {
    fill: #fff;
    stroke: ${colors.primary300};
  }
  &:disabled Path {
    fill: #fff;
    stroke: ${colors.primary300};
  }
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${colors.primary800};
  margin: 8px 0 0 0;
`;

export default LogIcon;
