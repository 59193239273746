import React from 'react';
import styled from 'styled-components';
import { isMobile } from '../helpers/utils';
import FlexContainer from '../elements/FlexContainer';
import { withStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';
import { colors, screenSize } from '../styleConstants';

const QuestionnaireProgress = ({ step }) => {
  let progress = null;
  let title = null;

  if (step) {
    progress = (step / 5) * 100;
    title = (
      <Text>
        <Green>Question {step}</Green> of 5
      </Text>
    );
    if (step === 6) {
      title = (
        <Text>
          <Green>Completed!</Green>
        </Text>
      );
      progress = 100;
    }
  }

  return (
    <ProgressContainer justify="flex-end" flexGrow="1" alignItems="center">
      {title}
      {step && <Progress variant="determinate" value={progress} />}
    </ProgressContainer>
  );
};

const Green = styled.span`
  color: ${colors.secondary600};
  font-weight: 500;
`;

const Text = styled.span`
  font-size: 15px;
  color: ${colors.primary400};

  @media (max-width: ${screenSize.mobileL}) {
    margin-bottom: 10px;
    /* margin-left: 20px; */
  }
`;

const ProgressContainer = styled(FlexContainer)`
  @media (max-width: ${screenSize.mobileL}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Progress = withStyles(LinearProgress, {
  root: {
    marginLeft: !isMobile() ? '20px' : 0,
    width: '240px',
    height: '8px',
    borderRadius: '20px',
    backgroundColor: colors.primary100,
  },
  bar: {
    borderRadius: '20px',
    backgroundColor: colors.secondary600,
  },
});

export default QuestionnaireProgress;
