import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = (state) => ({
  isVerified: state.currentUser.user.isVerified,
});

const RequireVerification = () => {
  const { isVerified } = useSelector(mapState);

  return !isVerified ? <Navigate to="/please-verify" /> : <Outlet />;
};

export default RequireVerification;
