import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { cloneDeep, keys, pickBy, pick, isEmpty, omit } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';

import ModalContainer from '../../sharedModals/ModalContainer';
import RecipeForm from './RecipeForm';

import { getRecipe, editRecipe } from '../../services/api/recipe';
import { getFoods, searchFoods } from '../../services/api/food';
import { convertNutrients } from '../../helpers/units';

const EditRecipe = (props) => {
  const initialValues = {
    nutrients: {},
  };
  const initialToggleValues = {
    active: false,
    placeholder: false,
    dietType: {},
    intolerances: {},
    mealType: {},
    cookingStyle: {},
    primaryIngredient: {},
    prepTime: {},
  };

  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);
  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);
  const [ingredientsValue, setIngredientsValue] = useState([]);
  const [foods, setFoods] = useState([]);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [calculatedNutrients, setCalculatedNutrients] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const instruction = { num: '', step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);
  const { open, launchModal, closeModal } = useModal(false);
  const [recipe, setRecipe] = useState({});
  const [initialFoods, setInitialFoods] = useState({});

  async function loadRecipe(params) {
    try {
      setMessage('');
      const recipeId = params.recipe;
      const result = await getRecipe(recipeId);
      if (result) {
        setRecipe(result);
      } else {
        throw new Error('Recipe could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  const clearNutrientValues = () => {
    setValues({ ...values, nutrients: {} });
  };

  // Populate form values from loaded recipe
  useEffect(() => {
    if (!isEmpty(recipe)) {
      setValues({
        attrLink: recipe.attrLink,
        cookTime: recipe.cookTime,
        expertTip: recipe.expertTip,
        imageUrl: recipe.imageUrl,
        name: recipe.name,
        nutrients: recipe.nutrients,
        prepTime: recipe.prepTime,
        servingUnit: recipe.servingUnit,
        servingWeight: recipe.servingWeight,
        source: recipe.source,
        servingSize: recipe.servingSize,
        yield: recipe.yield,
        version: recipe.version || 'N/A',
      });

      const toggleTmp = {
        active: recipe.active,
        placeholder: recipe.placeholder,
        dietType: {},
        intolerances: {},
        mealType: {},
        cookingStyle: {},
        primaryIngredient: {},
        prepTime: {},
      };

      const tags = recipe.tags;
      for (let tagType in tags) {
        tags[tagType].forEach((tagVal) => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setImageUrl(recipe.imageUrl);
      setInstructionsValue([...recipe.cookingInstructions]);
      const foodsCopy = [];
      recipe.foods.forEach((food) => {
        const quantity = food.quantity * recipe.yield;
        const convertedNutrients = convertNutrients(
          food.food.nutrientsPerUnit,
          food.food.gramWeightPerUnit,
          food.measureUnit,
          food.food.measureUnit,
        );

        foodsCopy.push({
          quantity: quantity,
          measureUnit: food.measureUnit,
          food: food.food.id,
          name: food.food.name,
          verboseName: food.food.verboseName,
          gramWeight: food.food.gramWeight,
          gramWeightPerUnit: food.food.gramWeightPerUnit,
          nutrientsPerUnit: convertedNutrients,
          isDeleted: food.food.isDeleted,
        });
      });
      setInitialFoods(foodsCopy);
      setFoods(foodsCopy);
    }
  }, [recipe]);

  // If we make any modifications to ingredients we need to clear entered values
  // since they will no longer be accurate. Calculated values will display instead.
  useEffect(() => {
    if (foods !== initialFoods) {
      clearNutrientValues();
    }
  }, [foods]);

  useEffect(() => {
    const foodsCopy = [...foods];
    ingredientsValue.forEach((ingredient) => {
      foodsCopy.push({
        quantity: ingredient.amount || 1,
        measureUnit: ingredient.measureUnit,
        food: ingredient._id,
        name: ingredient.name,
        verboseName: ingredient.verboseName,
        gramWeight: ingredient.gramWeight,
        gramWeightPerUnit: ingredient.gramWeightPerUnit,
        nutrientsPerUnit: {
          calories: ingredient.nutrientsPerUnit.calories,
          proteins: ingredient.nutrientsPerUnit.proteins,
          carbohydrates: ingredient.nutrientsPerUnit.carbohydrates,
          fiber: ingredient.nutrientsPerUnit.fiber,
          totalSugar: ingredient.nutrientsPerUnit.totalSugar,
          addedSugar: ingredient.nutrientsPerUnit.addedSugar,
          fat: ingredient.nutrientsPerUnit.fat,
          saturatedFat: ingredient.nutrientsPerUnit.saturatedFat,
          transFat: ingredient.nutrientsPerUnit.transFat,
          cholesterol_mg: ingredient.nutrientsPerUnit.cholesterol_mg,
          vitaminD_mcg: ingredient.nutrientsPerUnit.vitaminD_mcg,
          calcium_mg: ingredient.nutrientsPerUnit.calcium_mg,
          potassium_mg: ingredient.nutrientsPerUnit.potassium_mg,
          sodium_mg: ingredient.nutrientsPerUnit.sodium_mg,
        },
      });
    });
    setFoods(foodsCopy);
  }, [ingredientsValue]);

  useEffect(() => {
    const calculated = {};

    foods.forEach((food) => {
      const nutrients = food.nutrientsPerUnit;
      const quantity = food.quantity / (values.yield || 1);
      for (const nutrient in nutrients) {
        calculated[nutrient] = calculated[nutrient]
          ? calculated[nutrient] + quantity * nutrients[nutrient]
          : quantity * nutrients[nutrient];
      }
    });

    setCalculatedNutrients(calculated);
  }, [foods, values.nutrients, values.yield]);

  const getDeletedRecordNames = () => {
    if (foods.some((food) => food.isDeleted)) {
      const deletedFoods = foods
        .filter((food) => food.isDeleted)
        .map((food) => food.name);
      return deletedFoods.join(', ');
    }
    return false;
  };

  const submitData = () => {
    setLoading(true);
    // Prevent saving if any foods/ingredients have been deleted
    const deletedRecordNames = getDeletedRecordNames();
    if (deletedRecordNames) {
      setStatus('error');
      setMessage(
        `The following items are deleted and must be removed or replaced before saving: ${deletedRecordNames}`,
      );
      setLoading(false);
      return;
    }
    const recipeId = recipe._id;
    let payload = cloneDeep(values);
    // use calculated nutrient values if no manual value added
    for (const nutrient in calculatedNutrients) {
      if (!payload.nutrients[nutrient]) {
        payload.nutrients[nutrient] = calculatedNutrients[nutrient];
      }
    }
    payload.tags = {};
    payload.tags.mealType = keys(pickBy(toggleValues.mealType, Boolean));
    payload.tags.dietType = keys(pickBy(toggleValues.dietType, Boolean));
    payload.tags.intolerances = keys(
      pickBy(toggleValues.intolerances, Boolean),
    );
    payload.tags.cookingStyle = keys(
      pickBy(toggleValues.cookingStyle, Boolean),
    );
    payload.tags.primaryIngredient = keys(
      pickBy(toggleValues.primaryIngredient, Boolean),
    );
    payload.tags.prepTime = keys(pickBy(toggleValues.prepTime, Boolean));
    payload.cookingInstructions = instructionsValue.map(
      (instruction, index) => {
        return {
          num: index + 1,
          step: instruction.step,
        };
      },
    );
    payload.foods = foods;
    payload.foods.forEach((food, i) => {
      payload.foods[i].quantity = payload.foods[i].quantity / payload.yield;
      payload.foods[i] = pick(food, [
        'quantity',
        'measureUnit',
        'food',
        'name',
      ]);
    });
    payload.active = toggleValues.active;
    payload.placeholder = toggleValues.placeholder;
    if (payload.version === 'N/A') {
      // If there is no version specified, we should not save a value for version to the db
      payload = omit(payload, 'version');
    }

    editRecipe(recipeId, payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Recipe "${result.name}" was modified successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setInstructionsValue([]);
        setFoods([]);
        setImageUrl(null);
        setInitialFoods({});
        setRecipe([]);
      })
      .catch((err) => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );
  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Recipe"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="recipe-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
        }}
        initialScreen="Meal Search"
        handleClose={closeModal}
        addRecipe={loadRecipe}
        {...props}
      />
      <RecipeForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        clearNutrientValues={clearNutrientValues}
        ingredientsValue={ingredientsValue}
        setIngredientsValue={setIngredientsValue}
        instructionsValue={instructionsValue}
        setInstructionsValue={setInstructionsValue}
        getFoods={getFoods}
        foods={foods}
        searchFoods={searchFoods}
        setFoods={setFoods}
        calculatedNutrients={calculatedNutrients}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        viewOnly={isEmpty(recipe)}
        buttonText="Edit Recipe"
        formType="edit"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default EditRecipe;
