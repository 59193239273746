import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { useSelector, useDispatch } from 'react-redux';
import { trackEvent } from '../../integrations/analytics';
import { isMobile } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { upperFirst } from 'lodash';
import LogIcon from '../../icons/LogIcon';
import { setMealPlanFoodProperty } from '../../store/mealplan/selectedMealPlanSlice';
import { formatCalories } from '../../helpers/nutrition';
import { getImageURL } from '../../services/api/api';
const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const FoodCardLarge = ({ food }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.selectedMealPlan);

  async function toggleEaten(event) {
    event.preventDefault();
    event.stopPropagation();
    trackEvent(`Logged Food (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`, {
      food: food,
      eaten: !food.eaten,
    });
    dispatch(
      setMealPlanFoodProperty({
        mealPlan,
        food,
        property: 'eaten',
        value: !food.eaten,
      }),
    );
  }

  const link = `/app/mealplan/${mealPlan._id}/food/${food._id}`;
  const imgURL = (ratio = 1) => {
    const width = 112 * ratio;
    return getImageURL(
      `${fileStorage}/Nutriology_Symbol_467x334.png`,
      `resizing_type:fill/width:${width}`,
    );
  };

  const handleLink = () => {
    trackEvent('Clicked Recipe or Food Name (Meal Plan - Web)', {
      food: food._id,
    });
    navigate(link);
  };

  return (
    <CardContainer
      alignItems="center"
      justify="space-between"
      key={food._id}
      onClick={handleLink}
      data-test="meal-card"
    >
      <LeftContainer alignItems="center" data-test="meal-card-left">
        <ImageThumb image={imgURL()} image2x={imgURL(2)} />
        <FoodTitleContainer wrap="wrap">
          <FoodTitle
            $nameLength={food.food.verboseName.length}
            data-test="meal-card-name"
          >
            {upperFirst(food.food.verboseName)}
          </FoodTitle>
        </FoodTitleContainer>
        <FoodNutritionContainer flexDirection="column">
          <FoodNutrition data-test="meal-card-quantity">
            {food.quantity} {food.food.measureUnit}
            {food.quantity > 1 && 's'}
          </FoodNutrition>
          <FoodNutrition data-test="meal-card-calories">
            {formatCalories(
              food.food.nutrientsPerUnit.calories * food.quantity,
            )}{' '}
            Cal
          </FoodNutrition>
        </FoodNutritionContainer>
      </LeftContainer>
      <RightContainer alignItems="center">
        <LogIconContainer>
          <LogIcon
            hideLabel
            logged={food.eaten}
            toggleSelect={toggleEaten}
            data-test="meal-card-log"
          />
        </LogIconContainer>
      </RightContainer>
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 80px;
  width: 550px;
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
  width: 85%;
`;
const RightContainer = styled(FlexContainer)`
  height: 100%;
`;

const LogIconContainer = styled(FlexContainer)`
  padding: 0 24px 0 16px;
`;

const ImageThumb = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-image: ${(props) =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${(props) =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  height: 100%;
  width: 112px; // Update image dimensions accordingly.
  min-width: 112px;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const FoodTitleContainer = styled(FlexContainer)`
  margin-left: 32px;
  width: 50%;
`;

const FoodTitle = styled.h3`
  color: ${colors.primary700};
  font-size: ${(props) => (props.$nameLength > 35 ? '16px' : '19px')};
  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const FoodNutritionContainer = styled(FlexContainer)`
  margin-left: 16px;
`;

const FoodNutrition = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary500};
`;

export default FoodCardLarge;
