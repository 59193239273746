import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const SwapIcon = ({
  selected,
  toggleSelect,
  color,
  shrinkIcon,
  hideLabel,
  ...props
}) => (
  <FlexContainer flexDirection="column" alignItems="center">
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      onClick={toggleSelect}
      selected={selected}
      color={color}
      name="swap"
      {...props}
    >
      <circle
        cx="16"
        cy="16"
        r="14.78"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g
        id="swapIconGroup"
        style={shrinkIcon && { transform: 'scale(0.8) translate(4px, 4px)' }}
      >
        <line
          x1="7.22"
          y1="12.13"
          x2="23.92"
          y2="12.13"
          strokeWidth="1.5px"
          strokeMiterlimit="10"
        />
        <line
          x1="19.85"
          y1="8.06"
          x2="24.45"
          y2="12.66"
          strokeWidth="1.5px"
          strokeMiterlimit="10"
        />
        <line
          x1="19.85"
          y1="16.19"
          x2="23.92"
          y2="12.12"
          strokeWidth="1.5px"
          strokeMiterlimit="10"
        />
        <line
          x1="24.78"
          y1="19.87"
          x2="8.08"
          y2="19.87"
          strokeWidth="1.5px"
          strokeMiterlimit="10"
        />
        <line
          x1="12.15"
          y1="23.94"
          x2="7.55"
          y2="19.34"
          strokeWidth="1.5px"
          strokeMiterlimit="10"
        />
        <line
          x1="12.15"
          y1="15.81"
          x2="8.08"
          y2="19.88"
          strokeWidth="1.5px"
          strokeMiterlimit="10"
        />
      </g>
    </Icon>
    {!hideLabel && <Label color={color}>Swap</Label>}
  </FlexContainer>
);

const Icon = styled.svg`
  width: ${(props) => (props.width ? props.width : '32px')};
  height: ${(props) => (props.height ? props.height : '32px')};

  & circle {
    fill: ${(props) =>
      props.color === 'teal' && props.selected
        ? colors.secondary600
        : props.selected
          ? colors.hlitetwo400
          : '#fff'};
    stroke: ${(props) =>
      props.color === 'teal' ? colors.secondary600 : colors.hlitetwo400};
  }

  & line {
    fill: ${(props) =>
      props.color === 'teal' && props.selected
        ? colors.secondary600
        : props.selected
          ? colors.hlitetwo400
          : '#fff'};
    stroke: ${(props) =>
      props.selected
        ? '#fff'
        : props.color === 'teal'
          ? colors.secondary600
          : colors.hlitetwo400};
  }

  &:hover {
    cursor: pointer;
  }

  &:hover circle {
    fill: ${(props) =>
      props.color === 'teal' ? colors.secondary600 : colors.hlitetwo400};
  }
  &:hover line {
    stroke: #fff;
    fill: ${(props) =>
      props.color === 'teal' ? colors.secondary600 : colors.hlitetwo400};
  }

  &:focus circle {
    fill: ${(props) =>
      props.color === 'teal' ? colors.secondary600 : colors.hlitetwo400};
  }
  &:focus line {
    stroke: #fff;
    fill: ${(props) =>
      props.color === 'teal' ? colors.secondary600 : colors.hlitetwo400};
  }

  &:active circle {
    stroke: ${(props) =>
      props.color === 'teal' ? colors.secondary500 : colors.hlitetwo300};
    fill: ${(props) =>
      props.color === 'teal' ? colors.secondary500 : colors.hlitetwo300};
  }
  &:active line {
    stroke: #fff;
    fill: ${(props) =>
      props.color === 'teal' ? colors.secondary500 : colors.hlitetwo300};
  }

  &:disabled circle {
    fill: #fff;
    stroke: ${colors.primary300};
  }
  &:disabled line {
    fill: #fff;
    stroke: ${colors.primary300};
  }
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${(props) =>
    props.color === 'teal' ? colors.primary800 : colors.hlitetwo400};
  margin: 8px 0 0 0;

  ${Icon}:disabled & {
    color: ${colors.primary400};
  }
`;

export default SwapIcon;
