import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api/api';

const initialState = {
  _id: '5f44f79445e7ef000d60fb5c',
  __t: 'RecipeAdmin',
  imageUrl:
    'https://nutriology-dev.s3.eu-west-2.amazonaws.com/PlaceholderImage.png',
  tags: {
    dietType: [],
    mealType: [],
    cookingStyle: [],
    primaryIngredient: [''],
    intolerances: [],
    prepTime: [],
  },
  servingSize: 1,
  yield: 1,
  version: 'N/A',
  nutrients: {
    calories: 700,
    fat: 10,
    saturatedFat: 0,
    carbohydrates: 20,
    proteins: 12,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    transFat: 0,
    cholesterol_mg: 0,
    vitaminD_mcg: 0,
    calcium_mg: 0,
    potassium_mg: 0,
    sodium_mg: 0,
  },
  name: 'Enjoy a Meal Out!',
  placeholder: true,
  placeholderDefault: true,
  cookingInstructions: [],
  foods: [],
};

const defaultPlaceholderSlice = createSlice({
  name: 'defaultPlaceholder',
  initialState,
  extraReducers(builder) {
    builder.addCase(getDefaultPlaceholder.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const getDefaultPlaceholder = createAsyncThunk(
  'defaultPlaceholder/getDefaultPlaceholder',
  async (_, { rejectWithValue }) => {
    try {
      const recipe = await apiCall('get', `/recipe`, {
        query: { placeholderDefault: true },
      });
      return recipe[0];
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default defaultPlaceholderSlice.reducer;
