import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api/api';
import { DateTime } from 'luxon';

const initialState = {
  id: null,
  user: '',
  recipeOverrides: [],
  foodOverrides: [],
  shoppingList: [],
  shoppingListRecipes: [],
};

const defaultStartDate = DateTime.now().toFormat('yyyy-MM-dd');
const defaultEndDate = DateTime.now().plus({ weeks: 1 }).toFormat('yyyy-MM-dd');

const shoppingListSlice = createSlice({
  name: 'shoppingList',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        getShoppingLists.fulfilled,
        getFilteredShoppingLists.fulfilled,
        addFoodToShoppingList.fulfilled,
        removeFoodFromShoppingList.fulfilled,
        removeRecipeFromShoppingList.fulfilled,
        setFoodCompleted.fulfilled,
        clearCompletedFoods.fulfilled,
        adjustFoodQuantity.fulfilled,
      ),
      (state, action) => {
        return action.payload;
      },
    );
  },
});

export const getShoppingLists = createAsyncThunk(
  'shoppingList/getShoppingLists',
  async (
    { startDate = defaultStartDate, endDate = defaultEndDate },
    { rejectWithValue },
  ) => {
    try {
      const shoppingList = await apiCall('get', `/shoppingList`, {
        startDate,
        endDate,
        timezoneOffset: new Date().getTimezoneOffset(),
      });
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFilteredShoppingLists = createAsyncThunk(
  'shoppingList/getFilteredShoppingLists',
  async ({ startDate, endDate, recipeId }, { rejectWithValue }) => {
    try {
      const shoppingList = await apiCall('get', `/shoppingList/filter`, {
        startDate,
        endDate,
        recipeId,
        timezoneOffset: new Date().getTimezoneOffset(),
      });
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addFoodToShoppingList = createAsyncThunk(
  'shoppingList/addFoodToShoppingList',
  async (
    {
      shoppingListId,
      startDate = defaultStartDate,
      endDate = defaultEndDate,
      params = {},
    },
    { rejectWithValue },
  ) => {
    try {
      params.startDate = startDate;
      params.endDate = endDate;
      params.timezoneOffset = new Date().getTimezoneOffset();

      const shoppingList = await apiCall(
        'put',
        `/shoppingList/add_food/${shoppingListId}`,
        params,
      );
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const removeFoodFromShoppingList = createAsyncThunk(
  'shoppingList/removeFoodFromShoppingList',
  async (
    {
      shoppingListId,
      startDate = defaultStartDate,
      endDate = defaultEndDate,
      foodId,
    },
    { rejectWithValue },
  ) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const shoppingList = await apiCall(
        'put',
        `/shoppingList/remove_food/${shoppingListId}`,
        { startDate, endDate, foodId, timezoneOffset },
      );
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const removeRecipeFromShoppingList = createAsyncThunk(
  'shoppingList/removeRecipeFromShoppingList',
  async (
    {
      shoppingListId,
      startDate = defaultStartDate,
      endDate = defaultEndDate,
      recipeId,
    },
    { rejectWithValue },
  ) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const shoppingList = await apiCall(
        'put',
        `/shoppingList/remove_recipe/${shoppingListId}`,
        { startDate, endDate, recipeId, timezoneOffset },
      );
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setFoodCompleted = createAsyncThunk(
  'shoppingList/setFoodCompleted',
  async (
    {
      shoppingListId,
      foodId,
      completed,
      startDate = defaultStartDate,
      endDate = defaultEndDate,
    },
    { rejectWithValue },
  ) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const shoppingList = await apiCall(
        'put',
        `/shoppingList/set_complete/${shoppingListId}`,
        { foodId, completed, startDate, endDate, timezoneOffset },
      );
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clearCompletedFoods = createAsyncThunk(
  'shoppingList/clearCompletedFoods',
  async (
    { shoppingListId, startDate = defaultStartDate, endDate = defaultEndDate },
    { rejectWithValue },
  ) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const shoppingList = await apiCall(
        'put',
        `/shoppingList/clear_completed/${shoppingListId}`,
        { startDate, endDate, timezoneOffset },
      );
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const adjustFoodQuantity = createAsyncThunk(
  'shoppingList/adjustFoodQuantity',
  async (
    {
      shoppingListId,
      startDate = defaultStartDate,
      endDate = defaultEndDate,
      foodId,
      value,
    },
    { rejectWithValue },
  ) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      const shoppingList = await apiCall(
        'put',
        `/shoppingList/adjust_quantity/${shoppingListId}`,
        { startDate, endDate, timezoneOffset, foodId, value },
      );
      return shoppingList;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default shoppingListSlice.reducer;
