import { apiCall } from './api';

export function addWorkout(params) {
  if (params.image) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then((result) => {
      params.imageUrl = result.file;
      return apiCall('post', `/workout`, params);
    });
  }
  return apiCall('post', `/workout`, params);
}

export function editWorkout(id, params) {
  if (params.image) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then((result) => {
      params.imageUrl = result.file;
      return apiCall('put', `/workout/${id}`, params);
    });
  }
  return apiCall('put', `/workout/${id}`, params);
}

export function getWorkouts(params) {
  params.populate = [{ path: 'exercises.exercise' }];
  return apiCall('get', `/workout`, params);
}

export function getWorkout(id, params = {}) {
  params.populate = [{ path: 'exercises.exercise' }];
  return apiCall('get', `/workout/${id}`, params);
}

export function getUserWorkout(id, params = {}) {
  params.populate = [{ path: 'exercises.exercise' }];
  return apiCall('get', `/workoutuser/${id}`, params);
}

export function searchWorkouts(params) {
  return apiCall('get', `/workout/search`, params);
}

export function editUserWorkout(id, params) {
  return apiCall('put', `/workoutuser/${id}`, params);
}

export function updateUserWorkout(id, params) {
  params.populate = [{ path: 'exercises.exercise' }];
}

export function toggleAllComplete(id, params) {
  return apiCall('put', `/workoutuser/toggle_all_complete/${id}`, params);
}

export function getWorkoutReferences(id, params = {}) {
  params.query = { 'workouts.workout': id };
  return apiCall('get', `/fitnessplan`, params);
}

export function replaceWorkoutsOnFitnessPlan(
  fitnessPlan,
  oldWorkout,
  newWorkout,
) {
  return apiCall(
    'put',
    `/fitnessplan/${fitnessPlan}/replace_workout/${oldWorkout}/${newWorkout}`,
  );
}
