import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection || 'row'};
  flex-wrap: ${(props) => props.$wrap || 'nowrap'};
  justify-content: ${(props) => props.$justify || 'flex-start'};
  align-items: ${(props) => props.$alignItems || 'stretch'};
  align-content: ${(props) => props.$alignContent || 'stretch'};
  align-self: ${(props) => props.$alignSelf || 'auto'};
  margin: ${(props) => props.$margin || 0};
  padding: ${(props) => props.$padding || 0};
  flex-basis: ${(props) => props.$flexBasis || 'auto'};
  flex-grow: ${(props) => props.$flexGrow || null};
  flex-shrink: ${(props) => props.$flexShrink || 1};
  max-width: ${(props) => props.$maxWidth || null};
  gap: ${(props) => props.$gap || null};
`;

const FlexContainer = forwardRef(
  (
    {
      display,
      flexDirection,
      wrap,
      justify,
      alignItems,
      alignContent,
      alignSelf,
      margin,
      padding,
      flexBasis,
      flexGrow,
      flexShrink,
      maxWidth,
      gap,
      className,
      style,
      onClick,
      name,
      id,
      ...props
    },
    ref,
  ) => {
    return (
      <Flex
        ref={ref}
        $display={display}
        $flexDirection={flexDirection}
        $wrap={wrap}
        $justify={justify}
        $alignItems={alignItems}
        $alignContent={alignContent}
        $alignSelf={alignSelf}
        $margin={margin}
        $padding={padding}
        $flexBasis={flexBasis}
        $flexGrow={flexGrow}
        $flexShrink={flexShrink}
        $maxWidth={maxWidth}
        $gap={gap}
        className={className}
        style={style}
        onClick={onClick}
        name={name}
        id={id}
        data-test={props['data-test']}
        data-type={props['data-type']}
        data-name={props['data-name']}
      >
        {props.children}
      </Flex>
    );
  },
);

export default FlexContainer;
