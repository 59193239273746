import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';
import Button from '../elements/Button';
import CloseIcon from '../icons/CloseIcon';
import DialogContent from '@mui/material/DialogContent';
import FlexContainer from '../elements/FlexContainer';
import Modal from '@mui/material/Modal';
import RadioInput from '../elements/RadioInput';
import TrackPyramid from '../icons/TrackPyramid';

import { trackEvent } from '../integrations/analytics';

import { colors } from '../styleConstants';
import { switchTrack } from '../store/fitnessplan/trackSlice';
import { getTrack } from '../services/api/track';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const SwitchPlanModal = ({ open, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.currentUser.user.id);
  const currentTrack = useSelector((state) => state.track);

  const [trackLevel, setTrackLevel] = useState(currentTrack.level);
  const [defaultSelected, setDefaultSelected] = useState(true);
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    setTrackLevel(currentTrack.level);
  }, [currentTrack]);

  const handleChange = (e) => {
    setDefaultSelected(false);
    setTrackLevel(e.target.value);
    if (currentTrack.level === e.target.value) {
      setButtonText('Keep Plan');
    } else {
      setButtonText('Change Plan');
    }
  };

  // Submit track change
  // Will determine based on level selected whether to move forward or backward a track (or remain)
  const handleClick = async (e) => {
    try {
      if (currentTrack.level === trackLevel) {
        handleClose();
        return;
      }
      let newTrackId;
      const currentIndex = options.findIndex(
        (option) => currentTrack.level === option.level,
      );
      const newIndex = options.findIndex(
        (option) => trackLevel === option.level,
      );

      if (newIndex - currentIndex === 1) {
        // Track is next level
        newTrackId = currentTrack.primary
          ? currentTrack.nextTrack._id
          : currentTrack.nextLevel._id;
      } else if (newIndex - currentIndex === -1) {
        // Track is previous level
        newTrackId = currentTrack.backTrack._id;
      } else if (newIndex - currentIndex === -2 && currentIndex === 2) {
        // Until advanced plans are added we need to allow "advanced" level (which is intermediate plans)
        // to go back to beginner, so we need to go two levels back
        const backTrack = await getTrack(currentTrack.backTrack._id);
        newTrackId = backTrack.backTrack._id;
      } else {
        // Should not be possible
        console.error(
          `${trackLevel} at index ${newIndex} is more than 1 level removed from ${currentTrack.level} at ${currentIndex}`,
        );
      }

      if (newTrackId) {
        trackEvent('Switched Track');
        await dispatch(switchTrack({ userId, trackId: newTrackId }));
        setDefaultSelected(true);
        handleClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const options = [
    {
      level: 'Beginner',
      levelLabel: 'Beginner',
      labelText:
        currentTrack.level === 'Beginner'
          ? 'Your current plan level is'
          : 'Go back to',
    },
    {
      level: 'Intermediate',
      levelLabel: 'Intermediate',
      labelText:
        currentTrack.level === 'Intermediate'
          ? 'Your current plan level is'
          : currentTrack.level === 'Advanced'
            ? 'Go back to'
            : 'Move forward to',
    },
    {
      level: 'Advanced',
      levelLabel: 'Intermediate', // temporary until Advanced plans are added
      labelText:
        currentTrack.level === 'Advanced'
          ? 'Your current plan level is'
          : 'Move forward to',
    },
  ];

  const levelOptions = options
    .filter((option, i) => {
      const idx = options.findIndex((op) => currentTrack.level === op.level);
      if (
        currentTrack.level === 'Advanced' &&
        option.level === 'Intermediate'
      ) {
        // filter out Intermediate if current level is Advanced
        return false;
      } else if (
        currentTrack.level === 'Intermediate' &&
        option.level === 'Advanced'
      ) {
        // filter out Advanced if current level is Intermediate
        return false;
      } else if (idx !== -1) {
        return i === idx + 1 || i === idx || i === idx - 1 || i === idx - 2;
      }
      return true;
    })
    .map((option, i) => {
      return (
        <OptionBox
          key={i}
          alignItems="center"
          selected={trackLevel === option.level}
          $defaultSelected={defaultSelected}
          data-test="switchTrack-optionBox"
        >
          <RadioInput
            checked={trackLevel === option.level}
            value={option.level}
            onChange={handleChange}
            name="trackLevel"
            spacing="0px"
            disabled={defaultSelected && trackLevel === option.level}
            hideValue
            data-test={`switchTrack-trackLevel-${option.level}`}
          />
          <TrackPyramid
            colorScheme="teal"
            level={option.levelLabel}
            selected={trackLevel === option.level}
            disabled={defaultSelected && trackLevel === option.level}
            data-test="switchTrack-pyramid"
          />
          <FlexContainer flexDirection="column">
            <OptionLabel data-test="switchTrack-labelText">
              {option.labelText}
            </OptionLabel>
            <Level
              selected={trackLevel === option.level}
              $defaultSelected={defaultSelected}
              data-test="switchTrack-levelName"
            >
              {option.levelLabel}
            </Level>
          </FlexContainer>
        </OptionBox>
      );
    });

  return (
    <div>
      <Modal open={open} onClose={handleClose} data-test="switchTrack-modal">
        <StyledDialogContent>
          <Container justify="center" flexDirection="column">
            <NavBar justify="space-between" alignItems="center">
              <CloseRight>
                <CloseIcon
                  handleClose={handleClose}
                  data-test="switchTrack-close"
                />
              </CloseRight>
            </NavBar>
            <ModalBody
              justify="space-evenly"
              alignItems="center"
              flexDirection="column"
            >
              <StyledAvatar src={`${fileStorage}/AddExercise.png`} />
              <FlexContainer flexDirection="column" alignItems="center">
                <Header>Switch Your Fitness Plan</Header>
                <P>You can change your fitness plan level at any time.</P>
              </FlexContainer>
              <OptionsContainer
                flexDirection="column"
                alignItems="center"
                data-test="switchTrack-optionList"
              >
                {levelOptions}
              </OptionsContainer>
              <FlexContainer flexDirection="column" alignItems="center">
                {!defaultSelected && (
                  <Button
                    buttonText={buttonText}
                    buttonSize="small"
                    width="148px"
                    pink="true"
                    onClick={handleClick}
                    data-test="switchTrack-submit"
                  />
                )}
              </FlexContainer>
            </ModalBody>
          </Container>
        </StyledDialogContent>
      </Modal>
    </div>
  );
};

const StyledDialogContent = styled(DialogContent)`
  height: 650px;
  width: 480px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: white;
  border-radius: 4px;
  outline: 0;
`;

const ModalBody = styled(FlexContainer)`
  height: 100%;
  padding: 0 48px 60px 48px;
`;

const NavBar = styled(FlexContainer)`
  height: 58px;
  flex-shrink: 0;
  padding: 0 16px;
`;

const StyledAvatar = styled(Avatar)`
  width: 86px;
  height: 86px;
  background-color: ${colors.primary050};
`;

const CloseRight = styled(FlexContainer)`
  right: 12px;
  position: absolute;
`;

const Header = styled.h2`
  color: ${colors.primary800};
  text-align: center;
  margin-bottom: 8px;
`;

const P = styled.p`
  color: ${colors.primary800};
  text-align: center;
  width: 300px;
`;

const OptionsContainer = styled(FlexContainer)`
  margin-top: 24px;

  > * {
    margin-bottom: 17px;
  }
`;

const OptionBox = styled(FlexContainer)`
  height: 35px;
  width: 280px;
  padding: 20px;
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  background-color: white;
  opacity: ${(props) =>
    props.selected && props.$defaultSelected ? '0.5' : '1'};
  box-shadow: ${(props) =>
    props.selected && props.$defaultSelected
      ? 'none'
      : '0 2px 12px 0 rgba(0, 0, 0, 0.1)'};

  border: ${(props) =>
    props.selected && !props.$defaultSelected
      ? `1px solid ${colors.secondary500}`
      : `1px solid ${colors.primary200}`};

  &:hover {
    box-shadow: ${(props) =>
      !props.selected
        ? '0 2px 10px 0 rgba(0, 0, 0, 0.15)'
        : '0 2px 12px 0 rgba(0, 0, 0, 0.1)'};
    cursor: pointer;
  }
  > :first-child {
    margin-right: 24px;
  }
  > :nth-child(2) {
    margin-right: 12px;
  }
`;

const OptionLabel = styled.p`
  color: ${colors.primary700};
`;

const Level = styled.h3`
  color: ${(props) =>
    props.selected && !props.$defaultSelected
      ? `${colors.secondary600}`
      : `${colors.primary700}`};
`;

export default SwitchPlanModal;
