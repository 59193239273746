import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors, screenSize } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import PaymentOption from './PaymentOption';
import PaymentInfo from '../payment/PaymentInfo';
import { getPrices } from '../services/api/billing';
import { isMobile } from '../helpers/utils';

const PaymentScreen = () => {
  const [prices, setPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const fetchPriceData = async () => {
    const prices = await getPrices();
    setPrices(prices);

    // Get the default price and select it:
    prices.forEach((price) => {
      if (price.default) {
        setSelectedPrice(price);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.debug('loading prices information');
    fetchPriceData();
  }, []);

  const selectPrice = (price) => {
    console.debug(`Selected price ${price.id}`);
    setSelectedPrice(price);
  };

  const ref = useRef(null);
  const scrollToBilling = () => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (selectedPrice && !selectedPrice.default && isMobile()) {
      console.debug('scroll to', selectedPrice);
      scrollToBilling();
    }
  }, [selectedPrice]);

  const plans = prices.map((price) => (
    <PaymentOption
      key={price.id}
      price={price}
      data-test={price.id}
      selected={price.id === selectedPrice?.id}
      selectPrice={selectPrice}
    />
  ));

  return (
    <PageContainer>
      <InfoContainer flexDirection="column" alignItems="center">
        <Header>Unlock your body's formula to long-lasting fat loss</Header>
        <StepsContainer flexDirection="column" alignItems="flex-start">
          <FlexContainer alignItems="center" gap="16px">
            <Step>
              Next up, please enter your payment information. And begin your
              journey!
            </Step>
          </FlexContainer>
        </StepsContainer>
        <PlansContainer flexDirection="column">{plans}</PlansContainer>
        <HR />
      </InfoContainer>
      <PaymentContainer flexDirection="column" alignItems="center">
        <Slogan>Creating Healthy Habits Has Never Been Easier</Slogan>
        <PaymentBox ref={ref}>
          <PaymentInfo paymentPlan={selectedPrice} />
        </PaymentBox>
      </PaymentContainer>
    </PageContainer>
  );
};

const PageContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;

  @media (max-width: ${screenSize.laptopS}) {
    flex-direction: column;
    justify-content: initial;
    align-items: center;
    overflow-y: scroll;
  }
`;

const InfoContainer = styled(FlexContainer)`
  padding-top: 65px;
  flex-basis: 60%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${screenSize.laptopS}) {
    padding: 65px 16px 25px 16px;
    align-content: center;
    align-items: center;
    min-height: fit-content;
    overflow-y: initial;
    flex-basis: initial;
    width: calc(100% - 32px);
  }
`;

const PaymentContainer = styled(FlexContainer)`
  padding-top: 65px;
  background-color: ${colors.secondary600};
  flex-basis: 40%;
  min-width: 550px;
  height: 100%;

  @media (max-width: ${screenSize.laptopS}) {
    padding: 65px 16px 25px 16px;
    align-content: center;
    align-items: center;
    min-height: fit-content;
    overflow-y: initial;
    width: calc(100% - 32px);
    min-width: initial;
  }
`;

const PaymentBox = styled(FlexContainer)`
  border: 1px solid ${colors.primary200};
  width: 340px;
  border-radius: 8px;
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${screenSize.laptopS}) {
    width: calc(100% - 4rem);
  }
`;

const Header = styled.h1`
  color: ${colors.primary800};
  margin-bottom: 48px;
  max-width: 540px;
  font-size: 30px;

  @media (max-width: ${screenSize.laptopS}) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const Slogan = styled.h1`
  color: #fff;
  margin-bottom: 42px;
  max-width: 405px;
  font-size: 30px;

  @media (max-width: ${screenSize.laptopS}) {
    text-align: center;
  }

  @media (max-height: 750px) {
    display: none;
  }
`;

const StepsContainer = styled(FlexContainer)`
  gap: 18px;
  max-width: 540px;
`;

const Step = styled.p`
  color: #929292;
  font-size: 16px;
  font-weight: 400;
  text-align: ${() => (isMobile() ? 'center' : 'initial')};
`;

const PlansContainer = styled(FlexContainer)`
  margin-top: 60px;
  gap: 12px;

  @media (max-width: ${screenSize.laptopS}) {
    margin-top: 32px;
    width: 100%;
  }
`;

const HR = styled.hr`
  margin: 64px 0;
  color: ${colors.primary300};
  border: 0.5px solid ${colors.primary300};

  @media (max-width: ${screenSize.laptopS}) {
    margin: 24px 0;
  }
`;

export default PaymentScreen;
