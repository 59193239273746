import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';

import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../elements/TextInput';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';

import PlanList from './PlanList';
import TrackProgression from './TrackProgression';
import CheckBox from '../../elements/CheckBox';
import AlertStack from '../../elements/AlertStack';

import { getFitnessPlan } from '../../services/api/fitnessPlan';
import { levels, locationTypes, genders } from './defaults';

const TrackForm = ({
  handleChange,
  values,
  handleValueChanged,
  fitnessPlans,
  setFitnessPlans,
  backTrack,
  setBackTrack,
  remainTrack,
  setRemainTrack,
  nextTrack,
  setNextTrack,
  nextLevel,
  setNextLevel,
  status,
  setStatus,
  message,
  setMessage,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  async function addPlanToForm(params) {
    try {
      const result = await getFitnessPlan(params.fitnessPlan);
      if (result.active === false) {
        setStatus('error');
        setMessage('Please select an active plan');
        throw new Error('Please select an active plan');
      } else {
        setStatus('');
        setMessage('');
        const fitnessPlan = {
          fitnessPlan: result,
          id: fitnessPlans && fitnessPlans.length + 1,
        };
        setFitnessPlans([...fitnessPlans, fitnessPlan]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function removePlanFromForm(planId) {
    try {
      const updatedPlans = fitnessPlans.filter((fitnessPlan) => {
        if (fitnessPlan._id) {
          return fitnessPlan._id !== planId;
        }
        return fitnessPlan.id !== parseInt(planId, 10);
      });
      setFitnessPlans(updatedPlans);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <CheckBox
          checked={values.active || ''}
          label="Active"
          name="active"
          data-test="track-active"
          disabled
        />
        <TextInput
          id="trackName"
          name="name"
          onChange={handleChange}
          value={values.name || ''}
          placeholder="Track Name"
          label="Track Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Track Name')}
          data-test="track-name"
        />
        <Dropdown
          width="430px"
          options={levels}
          label="Level"
          value={{
            value: values.level,
            label: values.level,
          }}
          onChange={(value) =>
            handleValueChanged('level', value.value, 'string')
          }
          data-test="track-level"
        />
        <Dropdown
          width="430px"
          options={locationTypes}
          label="Primary Location/Setting"
          value={{
            value: values.locationType,
            label: values.locationType,
          }}
          onChange={(value) =>
            handleValueChanged('locationType', value.value, 'string')
          }
          data-test="track-locationType"
        />
        <Dropdown
          width="430px"
          options={genders}
          label="Target Gender"
          value={{
            value: values.gender,
            label: values.gender,
          }}
          onChange={(value) =>
            handleValueChanged('gender', value.value, 'string')
          }
          data-test="track-gender"
        />
        <table>
          <tbody>
            <TrackProgression
              label="Back Track"
              track={backTrack}
              trackType="backTrack"
            />
            <TrackProgression
              label="Remain Track"
              track={remainTrack}
              trackType="remainTrack"
            />
            <TrackProgression
              label="Next Track"
              track={nextTrack}
              trackType="nextTrack"
            />
            <TrackProgression
              label="Next Level"
              track={nextLevel}
              trackType="nextLevel"
            />
          </tbody>
        </table>
        <HR />
        <Heading>Fitness Plans</Heading>
        <PlanList
          addPlan={addPlanToForm}
          removePlan={removePlanFromForm}
          fitnessPlans={fitnessPlans}
          setFitnessPlans={setFitnessPlans}
          variant="admin"
        />
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Track'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="track-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="track-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="track-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="track-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

export default TrackForm;
