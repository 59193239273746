import styled from 'styled-components';
import React from 'react';
import { colors } from '../styleConstants';
import { default as MaterialButton } from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Button = ({ buttonText, buttonSize, handleClick, pink, ...props }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.secondary500,
        contrastText: '#fff',
        dark: colors.secondary400,
      },
      secondary: {
        main: colors.hlitetwo400,
        contrastText: '#fff',
        dark: colors.hlitetwo300,
      },
      default: {
        main: colors.primary050,
        contrastText: '#fff',
      },
    },
    components: {
      MuiTouchRipple: {
        styleOverrides: {
          root: {
            color: '#fff',
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {buttonSize === 'large' ? (
        <LargeButton
          variant={props?.variant ? props.variant : 'contained'}
          color={pink === 'true' ? 'secondary' : 'primary'}
          fullWidth={true}
          size="large"
          onClick={handleClick}
          disableElevation
          {...props}
        >
          {buttonText}
        </LargeButton>
      ) : (
        <SmallButton
          variant={props?.variant ? props.variant : 'contained'}
          size="small"
          onClick={handleClick}
          color={pink === 'true' ? 'secondary' : 'primary'}
          disableElevation
          {...props}
        >
          {buttonText}
        </SmallButton>
      )}
    </ThemeProvider>
  );
};

const StyledButton = styled(MaterialButton)`
  text-align: center;
  text-transform: ${(props) =>
    props.fontcase === 'titleCase'
      ? 'capitalize'
      : props.fontcase === 'lowercase'
        ? 'lowercase'
        : 'uppercase'};
  font-family: 'Work Sans';
  letter-spacing: inherit;
  box-shadow: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${(props) =>
      props.backgrounddark ? colors.hlitetwo200 : 'rgba(0, 0, 0, 0.12)'};
    color: ${(props) =>
      props.backgrounddark ? '#fff' : 'rgba(0, 0, 0, 0.26)'};
    cursor: not-allowed;
  }
`;

const LargeButton = styled(StyledButton)`
  line-height: 23px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '19px')};
  font-weight: 600;
  height: 40px;
  padding: 0 24px;
  width: ${(props) => props.width || '100%'};
`;

const SmallButton = styled(StyledButton)`
  display: inline-flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  height: 28px;
  width: ${(props) => props.width || 'auto'};
  padding: 0 16px;
`;

export default Button;
