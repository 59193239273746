import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { lowerCase } from 'lodash';

import Button from '../elements/Button';
import Avatar from '@mui/material/Avatar';

import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { isMobile } from '../helpers/utils';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const EmptyStateCard = ({ type, addWorkout, addActivity, date, ...props }) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  if (!isMobile()) {
    // Web view
    return (
      <Container alignItems="center" {...props}>
        <StyledAvatar
          src={`${fileStorage}/Exercise-Placeholder.png`}
          {...props}
        />
        <AddContainer flexDirection="column">
          <Label {...props}>Add {type}</Label>
          <Button
            buttonSize="small"
            buttonText="+ Add"
            pink="true"
            data-show-only={lowerCase(type)}
            data-date={date}
            onClick={launchModal}
            data-test={`add-${type}`}
          />
        </AddContainer>
        <ModalContainer
          open={open}
          context={context}
          handleClose={closeModal}
          initialScreen="Exercise Search"
          addWorkout={addWorkout}
          addActivity={addActivity}
        />
      </Container>
    );
  } else {
    // Mobile view
    return (
      <MobileContainer alignItems="center" justify="center" {...props}>
        <MobileAddContainer flexDirection="column">
          <MobileLabel {...props}>Add {type}</MobileLabel>
          <Button
            buttonSize="small"
            buttonText="+ Add"
            pink="true"
            data-show-only={lowerCase(type)}
            data-date={date}
            onClick={launchModal}
            data-test={`add-${type}`}
          />
        </MobileAddContainer>
        {open && (
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Exercise Search"
            addWorkout={addWorkout}
            addActivity={addActivity}
          />
        )}
      </MobileContainer>
    );
  }
};

const MobileContainer = styled(FlexContainer)`
  width: 345px;
  height: ${(props) =>
    props.medium === 'true' || props.half === 'true' ? '144px' : '208px'};
  margin-top: ${(props) =>
    isMobile()
      ? '24px'
      : props.medium === 'true' || props.half === 'true'
        ? '47px'
        : '16px'};
  background-color: ${colors.primary050};
`;

const MobileAddContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;
const MobileLabel = styled.h2`
  color: ${colors.primary500};
`;

/** Web **/

const Container = styled(FlexContainer)`
  width: ${(props) => (props.half === 'true' ? '270px' : '592px')};
  height: ${(props) =>
    props.medium === 'true' || props.half === 'true' ? '112px' : '192px'};
  padding: ${(props) =>
    props.medium === 'true' || props.half === 'true' ? '0 24.5px' : '0 32px'};
  border: 1px dashed ${colors.primary600};
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${colors.primary050};
  width: ${(props) =>
    props.medium === 'true' || props.half === 'true' ? '64px' : '144px'};
  height: ${(props) =>
    props.medium === 'true' || props.half === 'true' ? '64px' : '144px'};
`;

const AddContainer = styled(FlexContainer)`
  margin-left: 32px;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Label = styled.h2`
  color: ${colors.primary700};
  font-size: ${(props) =>
    props.half === 'true' || props.medium === 'true' ? '19px' : '24px'};
  line-height: ${(props) =>
    props.medium === 'true' || props.half === 'true' ? '23px' : '32px'};
`;

export default EmptyStateCard;
