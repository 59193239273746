import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDate } from '../store/general/selectedDateSlice';
import { getUserFitnessPlanByDate } from '../store/fitnessplan/selectedFitnessPlanSlice';
import { getUserMealPlanByDate } from '../store/mealplan/selectedMealPlanSlice';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import { IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { DateTime } from 'luxon';

const DateNavigator = ({ date, setDate, ...props }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.currentUser.user.id);

  const showPrevDay = () => {
    const dt = new Date(date);
    dt.setDate(date.getDate() - 1);
    setDate(dt);
  };

  const showNextDay = () => {
    const dt = new Date(date);
    dt.setDate(date.getDate() + 1);
    setDate(dt);
  };

  useEffect(() => {
    dispatch(setSelectedDate(date));
  }, [date]);

  useEffect(() => {
    dispatch(getUserFitnessPlanByDate({ userId, date }));
    dispatch(getUserMealPlanByDate({ userId, date }));
  }, [date, userId]);

  const luxonDate = DateTime.fromJSDate(date);
  const formattedDate = luxonDate.toFormat('MMM d');
  const showPrevButton =
    new Date().getTime() - date.getTime() < 7 * 24 * 60 * 60 * 1000;
  const showNextButton =
    date.getTime() - new Date().getTime() < 6 * 24 * 60 * 60 * 1000;

  return (
    <FlexContainer alignItems="center">
      {showPrevButton && (
        <IconButton data-test="datenavigator-prev-day" onClick={showPrevDay}>
          <NavigateBeforeIcon />
        </IconButton>
      )}
      <DateText data-test="datenavigator-text">{formattedDate}</DateText>
      {showNextButton && (
        <IconButton data-test="datenavigator-next-day" onClick={showNextDay}>
          <NavigateNextIcon />
        </IconButton>
      )}
    </FlexContainer>
  );
};

const DateText = styled(FlexContainer)`
  width: 100px;
  color: ${colors.primary800};
  text-transform: uppercase;
  border: 1px solid ${colors.primary400};
  padding: 10px;
  font-size: 17px;
  line-height: 20px;
  border-radius: 2px;
  justify-content: center;
`;

export default DateNavigator;
