import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../store/general/currentUserSlice';
import { isMobile } from '../helpers/utils';
import {
  cloneDeep,
  map,
  pickBy,
  keys,
  isEmpty,
  reduce,
  camelCase,
} from 'lodash';
import Button from '../elements/Button';
import useFormValues from '../hooks/useFormValues';
import useFormSubmit from '../hooks/useFormSubmit';
import useToggles from '../hooks/useToggles';
import DetailCard from './DetailCard';
import AlertStack from '../elements/AlertStack';
import { CircularProgress } from '@mui/material';
import { fitnessEquipments } from '../helpers/profile';

// TODO: Add these back in future when equipment and injury features are added
// https://app.clickup.com/t/2ngarke
// import Chip from '../elements/Chip';
// import AddIcon from '../icons/AddIcon';
// import { getExercises } from '../services/api/exercise';
// import { getInjuries } from '../services/api/injuries';

const FitnessPrefsForm = () => {
  const dispatch = useDispatch();
  const fitnessPlan = useSelector((state) => state.activeFitnessPlan);
  const currentUser = useSelector((state) => state.currentUser.user);
  const equipments = useMemo(
    () =>
      reduce(
        currentUser.fitnessPreferences.equipments,
        (result, value) => {
        return (result[value] = true), result; /* eslint-disable-line */
        },
        {},
      ),
    [currentUser.fitnessPreferences.equipments],
  );

  const { values, setValues, handleValueChanged } = useFormValues(
    cloneDeep(currentUser),
  );
  const { toggleValues, setToggleValues, handleToggle } = useToggles(
    cloneDeep(equipments),
  );

  // TODO: Post Beta Feature
  // https://app.clickup.com/t/2ngarke
  // const [openExerciseLikes, setOpenExerciseLikes] = useState(false);
  // const [openExerciseDislikes, setOpenExerciseDislikes] = useState(false);
  // const [openInjuries, setOpenInjuries] = useState(false);

  const [exerciseLikesValue, setExerciseLikesValue] = useState(
    cloneDeep(currentUser.fitnessPreferences.exerciseLikes),
  );
  const [exerciseDislikesValue, setExerciseDislikesValue] = useState(
    cloneDeep(currentUser.fitnessPreferences.exerciseDislikes),
  );
  // const [injuriesValue, setInjuriesValue] = useState(
  //   currentUser.fitnessPreferences.injuries,
  // );
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setToggleValues(cloneDeep(equipments));
    setValues(cloneDeep(currentUser));
  }, [currentUser, equipments]);

  useEffect(() => {
    setExerciseLikesValue(
      cloneDeep(currentUser.fitnessPreferences.exerciseLikes),
    );
    setExerciseDislikesValue(
      cloneDeep(currentUser.fitnessPreferences.exerciseDislikes),
    );
    // setInjuriesValue(cloneDeep(currentUser.fitnessPreferences.injuries));
  }, [
    currentUser.fitnessPreferences.exerciseLikes,
    currentUser.fitnessPreferences.exerciseDislikes,
  ]);

  const submitData = () => {
    setLoading(true);
    let payload = cloneDeep(values);
    payload.fitnessPreferences.exerciseLikes = map(exerciseLikesValue, 'id');
    payload.fitnessPreferences.exerciseDislikes = map(
      exerciseDislikesValue,
      'id',
    );
    // payload.fitnessPreferences.injuries = map(injuriesValue, 'id');
    payload.fitnessPreferences.equipments = keys(pickBy(toggleValues, Boolean));
    dispatch(updateUser(payload))
      .then((result) => {
        setStatus('success');
        setMessage('Your profile has been successfully updated!');
        setLoading(false);
      })
      .catch((err) => {
        setStatus('error');
        setLoading(false);
        if (err.error.message) {
          setMessage(err.error.message);
        } else {
          setMessage('There was a problem updating your profile.');
          console.error(err);
        }
      });
    setLoading(false);
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'details',
  );

  const handleToggleChange = (e, name) => {
    if (name === 'none') {
      if (!toggleValues.none) {
        for (const key in toggleValues) {
          toggleValues[key] = false;
        }
      }
    } else {
      if (toggleValues.none) {
        toggleValues.none = false;
      }
    }
    handleToggle()(e);
  };

  const fitnessEquipmentsCards = fitnessEquipments.map(({ name, label }) => (
    <DetailCard
      key={label}
      imgSrc={`${process.env.PUBLIC_URL}/icons/FitnessEquipments/${name}.png`}
      title={label}
      isSelected={toggleValues[name] || false}
      name={name}
      handleSelect={(e) => handleToggleChange(e, name)}
      allowMultiple
      data-test={`prefs-fitnessEquipment-${camelCase(name)}`}
    />
  ));
  const MobileExercisePref = () => (
    <ExercisePrefContainer>
      <SectionTitle>My Exercise Preference:</SectionTitle>
      <CardsContainer wrap="wrap">
        <DetailCard
          imgSrc={`${process.env.PUBLIC_URL}/icons/ExercisePrefs/gym.png`}
          title="At the gym"
          isSelected={values.fitnessPreferences.fitnessStyle === 'At the gym'}
          name="fitnessPreferences.fitnessStyle"
          data-test="prefs-fitnessStyle-gym"
          handleSelect={() =>
            handleValueChanged(
              'fitnessPreferences.fitnessStyle',
              'At the gym',
              'string',
            )
          }
        />
        <DetailCard
          imgSrc={`${process.env.PUBLIC_URL}/icons/ExercisePrefs/home.png`}
          title="At home"
          isSelected={values.fitnessPreferences.fitnessStyle === 'At home'}
          name="fitnessPreferences.fitnessStyle"
          data-test="prefs-fitnessStyle-home"
          handleSelect={() =>
            handleValueChanged(
              'fitnessPreferences.fitnessStyle',
              'At home',
              'string',
            )
          }
        />
      </CardsContainer>
    </ExercisePrefContainer>
  );
  const EquipmentInfo = () => (
    <>
      <SectionTitle>Equipment I have access to:</SectionTitle>
      <SectionSubtitle>You can select more than one.</SectionSubtitle>
      <CardsContainer wrap="wrap">{fitnessEquipmentsCards}</CardsContainer>
    </>
  );

  // TODO: Post Beta Feature
  // https://app.clickup.com/t/2ngarke
  // const handleOpenExerciseLikes = () => {
  //   setOpenExerciseLikes(true);
  // };

  // const handleCloseExerciseLikes = () => {
  //   setOpenExerciseLikes(false);
  // };

  // const handleOpenExerciseDislikes = () => {
  //   setOpenExerciseDislikes(true);
  // };

  // const handleCloseExerciseDislikes = () => {
  //   setOpenExerciseDislikes(false);
  // };

  // const handleOpenInjuries = () => {
  //   setOpenInjuries(true);
  // };

  // const handleCloseInjuries = () => {
  //   setOpenInjuries(false);
  // };

  // const handleDeleteExerciseLike = item => {
  //   setExerciseLikesValue(filter(exerciseLikesValue, v => v.id !== item.id));
  // };

  // const handleDeleteExerciseDislike = item => {
  //   setExerciseDislikesValue(
  //     filter(exerciseDislikesValue, v => v.id !== item.id)
  //   );
  // };

  // const handleDeleteInjury = item => {
  //   setInjuriesValue(filter(injuriesValue, v => v.id !== item.id));
  // };

  // const exerciseDislikes = exerciseDislikesValue.map((dislike, index) => (
  //   <Chip
  //     key={index}
  //     label={dislike.name}
  //     name={`dislike-${dislike.id}`}
  //     handleDelete={() => handleDeleteExerciseDislike(dislike)}
  //   />
  // ));

  // const exerciseLikes = exerciseLikesValue.map((like, index) => (
  //   <Chip
  //     key={index}
  //     label={like.name}
  //     name={`like-${like.id}`}
  //     handleDelete={() => handleDeleteExerciseLike(like)}
  //   />
  // ));

  // const injuryHistory = injuriesValue.map((injury, index) => (
  //   <Chip
  //     key={index}
  //     label={injury.name}
  //     name={injury.id}
  //     handleDelete={() => handleDeleteInjury(injury)}
  //   />
  // ));

  return (
    <>
      <SectionTitle>My Fitness Plan</SectionTitle>
      {!isEmpty(fitnessPlan) && (
        <FitnessPlanContainer data-test="prefs-fitnessPlan-name">
          {`${fitnessPlan.level} Plan - ${fitnessPlan.locationType}`}
        </FitnessPlanContainer>
      )}
      <MobileExercisePref />
      <EquipmentInfo />
      <FlexContainer
        alignSelf="center"
        flexDirection="column"
        justify="center"
        alignItems="center"
      >
        <SaveButton
          type="submit"
          buttonText="Save Changes"
          onClick={handleSubmit}
          data-test="prefs-fitness-save"
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        />
        {status === 'error' && (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'error'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="prefs-fitness-error"
          />
        )}
        {status === 'success' && (
          <AlertStack
            messages={message}
            type="success"
            variant="filled"
            open={status === 'success'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="prefs-fitness-success"
          />
        )}
      </FlexContainer>
    </>
  );
};

const FitnessPlanContainer = styled.div`
  padding: 16px;
  width: ${(props) => (isMobile() ? 'auto' : '264px')};
  background: #f7fbfb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.secondary600};
  margin-top: 11px;
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary800};
`;

const SectionSubtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary800};
  padding-top: 4px;
`;

const ExercisePrefContainer = styled.div`
  margin: 24px 0 32px 0;
`;

const CardsContainer = styled(FlexContainer)`
  margin-top: 16px;
  > * {
    margin-bottom: 16px;
  }
  > :not(:last-child) {
    margin-right: 12px;
  }
`;
const SaveButton = styled(Button)`
  width: ${(props) => (isMobile() ? '100%' : '280px')};
  height: 40px;
  background: ${colors.secondary600};
  border-radius: 2px;
  text-transform: none;
  margin-top: 24px;
`;

export default FitnessPrefsForm;
