import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';

import CheckBox from '../elements/CheckBox';
import Collapsible from 'react-collapsible';
import CollapseIconMedium from '../icons/CollapseIconMedium';
import FlexContainer from '../elements/FlexContainer';
import NumericInput from '../elements/NumericInput';
import MobileNumericInput from '../elements/MobileNumericInput';
import RemoveIcon from '../icons/RemoveIcon';
import useToggles from '../hooks/useToggles';
import useFormValues from '../hooks/useFormValues';
import { formatIngredientDisplay } from '../helpers/utils';

const FoodRow = ({
  food,
  handleComplete,
  handleRemove,
  handleQuantity,
  newItem,
  disabled,
  ...props
}) => {
  const [rowHeight, setRowHeight] = useState(60);
  const { toggleValues, handleToggle, setToggleValues } = useToggles({
    open: false,
    completed: food.completed,
  });

  useEffect(() => {
    setToggleValues({ completed: food.completed });
  }, []);

  const { quantity, measureUnit } = formatIngredientDisplay(
    food?.quantity,
    food?.measureUnit,
  );

  const rowRef = useRef(null);
  useLayoutEffect(() => {
    const height = rowRef.current.offsetHeight;
    setRowHeight(height);
  }, [rowRef]);

  useLayoutEffect(() => {
    if (rowRef.current && newItem?.foodId === food.foodId) {
      rowRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newItem]);

  const initialValues = {
    quantity: quantity,
  };

  const { values, handleChange, setValues, handleValueChanged } =
    useFormValues(initialValues);

  useEffect(() => {
    if (toggleValues.completed !== food.completed) {
      handleComplete(food.foodId, toggleValues.completed);
    }
  }, [toggleValues.completed]);

  useEffect(() => {
    if (values.quantity && values.quantity !== quantity) {
      handleQuantity(food.foodId, values.quantity);
    }
  }, [values.quantity]);

  useEffect(() => {
    if (values.quantity !== quantity) {
      setValues({ quantity: quantity });
    }
  }, [quantity]);

  const triggerStyle = {
    position: 'absolute',
    top: `${rowHeight / 2 - 20}px`, // centers the trigger vertically in the row
    right: '25px',
  };

  if (!isMobile()) {
    // Web View
    return (
      <div ref={rowRef}>
        <Row
          key={food?.foodId}
          data-test="shoppingList-foodRow"
          flexDirection="column"
          isLastRow={props.isLastRow}
          flashColor={newItem?.foodId === food.foodId}
        >
          <InfoContainer alignItems="center">
            <CheckBox
              checked={toggleValues.completed || ''}
              onChange={handleToggle()}
              name="completed"
              disabled={disabled}
              data-test="shoppingList-food-complete"
            />
            <FoodInfo>
              <span data-test="shoppingList-food-quantity">{quantity} </span>
              <span data-test="shoppingList-food-measureUnit">
                {measureUnit}{' '}
              </span>
              <span data-test="shoppingList-food-name">{food?.name}</span>
            </FoodInfo>
          </InfoContainer>
          <Collapsible
            overflowWhenOpen="visible"
            triggerStyle={triggerStyle}
            contentOuterClassName="contentOuter__no_padding"
            open={toggleValues.open}
            trigger={
              <CollapseIconMedium
                small
                onClick={handleToggle()}
                open={toggleValues.open}
                name="open"
                data-test="foodRow-trigger"
              />
            }
          >
            <FoodOptions
              alignItems="center"
              justify="space-evenly"
              data-test="food-options"
            >
              <NumericInput
                id="quantity"
                name="quantity"
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                value={values.quantity}
                minValue="0"
                label={`Quantity (${measureUnit})`}
                data-test="food-quantity"
                disabled={food.completed || disabled}
              />
              <RelatedContainer flexDirection="column">
                <NeededCaption>Needed For:</NeededCaption>
                <RelatedRecipe data-test="food-relatedRecipes">
                  {food.relatedRecipes.join(', ')}
                </RelatedRecipe>
              </RelatedContainer>
              <RemoveIcon
                toggleSelect={() => handleRemove(food.foodId)}
                orientation="row"
                disabled={disabled}
                data-test="food-remove"
              />
            </FoodOptions>
          </Collapsible>
        </Row>
      </div>
    );
  } else {
    // Mobile view
    return (
      <div ref={rowRef}>
        <Row
          key={food?.foodId}
          data-test="shoppingList-foodRow"
          flexDirection="column"
          isLastRow={props.isLastRow}
          isMobile={isMobile()}
          flashColor={newItem?.foodId === food.foodId}
        >
          <InfoContainer alignItems="center">
            <CheckBox
              checked={toggleValues.completed || ''}
              onChange={handleToggle()}
              name="completed"
              disabled={disabled}
              data-test="shoppingList-food-complete"
            />
            <FoodInfo>
              <span data-test="shoppingList-food-quantity">{quantity} </span>
              <span data-test="shoppingList-food-measureUnit">
                {measureUnit}{' '}
              </span>
              <span data-test="shoppingList-food-name">{food?.name}</span>
            </FoodInfo>
          </InfoContainer>
          <Collapsible
            overflowWhenOpen="visible"
            triggerStyle={triggerStyle}
            contentOuterClassName="contentOuter__no_padding"
            open={toggleValues.open}
            trigger={
              <CollapseIconMedium
                small
                onClick={handleToggle()}
                open={toggleValues.open}
                name="open"
                data-test="foodRow-trigger"
              />
            }
          >
            <FoodOptions
              alignItems="center"
              justify="space-between"
              data-test="food-options"
            >
              <MobileNumericInput
                id="quantity"
                name="quantity"
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                value={values.quantity}
                minValue="0"
                label={`Quantity (${measureUnit})`}
                data-test="food-quantity"
                disabled={food.completed || disabled}
              />
              <RemoveIcon
                toggleSelect={() => handleRemove(food.foodId)}
                orientation="row"
                small={true}
                disabled={disabled}
                data-test="food-remove"
              />
            </FoodOptions>
            <RelatedContainer flexDirection="column">
              <NeededCaption>Needed For:</NeededCaption>
              <RelatedRecipe data-test="food-relatedRecipes">
                {food.relatedRecipes.join(', ')}
              </RelatedRecipe>
            </RelatedContainer>
          </Collapsible>
        </Row>
      </div>
    );
  }
};

const flash = keyframes`
    from {
      background-color: ${colors.secondary300};
    }
`;

const InfoContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 16px;
  }
`;

const Row = styled(FlexContainer)`
  padding: 18px 0;
  margin: ${(props) => (props.isMobile ? `0 0 0 32px` : '0 32px')};
  border-bottom: ${(props) =>
    !props.isLastRow ? `1px solid ${colors.primary300}` : null};
  position: relative;
  animation: ${(props) =>
    props.flashColor
      ? css`
          ${flash} 2s ease 1
        `
      : null};
`;

const FoodOptions = styled(FlexContainer)`
  padding: 30px 30px 30px 40px;
`;

const RelatedContainer = styled(FlexContainer)`
  padding: 0 40px;
`;

const FoodInfo = styled.h6`
  color: ${colors.primary900};
  font-weight: 500;
  flex-basis: 75%;
  margin-right: 68px;
`;

const NeededCaption = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
`;

const RelatedRecipe = styled.p`
  color: ${colors.primary900};
  font-size: 13px;
`;

export default FoodRow;
