import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { formatDate } from '../../helpers/date';
import { apiCall } from '../../services/api/api';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

const initialState = {
  id: null,
  planRef: 'B1-1',
  active: true,
  type: 'Weight Loss',
  level: 'Beginner',
  locationType: 'Gym',
  gender: 'All',
  planLength: 7,
  activities: [],
  workouts: [],
};

const blankPlan = {
  startDate: formatDate(DateTime.now().startOf('week')),
  endDate: formatDate(DateTime.now().endOf('week')),
  planLength: 7,
  type: 'Weight Loss',
  level: 'Beginner',
  locationType: 'Home',
  gender: 'All',
  activities: [],
  workouts: [],
};

const selectedFitnessplanSlice = createSlice({
  name: 'selectedFitnessPlan',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        getUserFitnessPlanByDate.fulfilled,
        addActivityToFitnessPlan.fulfilled,
        getUserFitnessPlanById.fulfilled,
        addWorkoutToFitnessPlan.fulfilled,
        updateUserFitnessPlan.fulfilled,
        setFitnessPlanActivityProperty.fulfilled,
        setFitnessPlanWorkoutProperty.fulfilled,
      ),
      (state, action) => {
        return action.payload;
      },
    );
  },
});

export const getUserFitnessPlanByDate = createAsyncThunk(
  'fitnessPlan/getUserFitnessPlanByDate',
  async ({ userId, date = new Date() }, { rejectWithValue }) => {
    try {
      const params = {
        query: {
          user: userId,
          startDate: formatDate(DateTime.fromJSDate(date).startOf('week')),
          endDate: formatDate(DateTime.fromJSDate(date).endOf('week')),
        },
      };
      let fitnessPlan = await apiCall('get', `/fitnessplanuser`, params);
      if (!fitnessPlan[0]) {
        fitnessPlan = await apiCall('post', `/fitnessplanuser`, blankPlan);
      } else {
        fitnessPlan = fitnessPlan[0];
      }
      return fitnessPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUserFitnessPlanById = createAsyncThunk(
  'fitnessPlan/getUserFitnessPlanById',
  async ({ fitnessPlanId, params = {} }, { rejectWithValue }) => {
    try {
      const fitnessPlan = await apiCall(
        'get',
        `/fitnessplanuser/${fitnessPlanId}`,
        params,
      );
      return fitnessPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addActivityToFitnessPlan = createAsyncThunk(
  'fitnessPlan/addActivityToFitnessPlan',
  async ({ fitnessPlanId, params = {} }, { rejectWithValue }) => {
    try {
      const fitnessPlan = await apiCall(
        'put',
        `/fitnessplanuser/add_activity/${fitnessPlanId}`,
        params,
      );
      return fitnessPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addWorkoutToFitnessPlan = createAsyncThunk(
  'fitnessPlan/addWorkoutToFitnessPlan',
  async ({ fitnessPlanId, params = {} }, { rejectWithValue }) => {
    try {
      const fitnessPlan = await apiCall(
        'put',
        `/fitnessplanuser/add_workout/${fitnessPlanId}`,
        params,
      );
      return fitnessPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserFitnessPlan = createAsyncThunk(
  'fitnessPlan/updateUserFitnessPlan',
  async ({ fitnessPlanId, params = {} }, { rejectWithValue }) => {
    try {
      const fitnessPlan = await apiCall(
        'put',
        `/fitnessplanuser/${fitnessPlanId}`,
        params,
      );
      return fitnessPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setFitnessPlanActivityProperty = createAsyncThunk(
  'fitnessPlan/setFitnessPlanActivityProperty',
  async (
    { fitnessPlan, activity, property, value },
    { dispatch, rejectWithValue },
  ) => {
    try {
      console.info(
        `Setting activity property ${property} to ${value} for activity: `,
        activity,
      );

      const updatedFitnessPlan = cloneDeep(fitnessPlan);
      // Find the activity in the fitnessPlan
      updatedFitnessPlan.activities.some((currentActivity) => {
        if (currentActivity._id === activity._id) {
          switch (property) {
            case 'logged':
              currentActivity.logged = value ? new Date() : null;
              break;
            default:
              throw new Error(`Property ${property} not supported.`);
          }
          return true;
        }
        return false;
      });

      dispatch(
        updateUserFitnessPlan({
          fitnessPlanId: fitnessPlan._id,
          params: updatedFitnessPlan,
        }),
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setFitnessPlanWorkoutProperty = createAsyncThunk(
  'fitnessPlan/setFitnessPlanWorkoutProperty',
  async (
    { fitnessPlan, workout, property, value },
    { dispatch, rejectWithValue },
  ) => {
    try {
      console.info(
        `Setting workout property ${property} to ${value} for workout: `,
        workout,
      );
      const updatedFitnessPlan = cloneDeep(fitnessPlan);
      // Find the workout in the fitnessPlan
      updatedFitnessPlan.workouts.some((currentWorkout) => {
        if (currentWorkout._id === workout._id) {
          switch (property) {
            case 'allComplete':
              currentWorkout.allComplete = value ? new Date() : null;
              break;
            default:
              throw new Error(`Property ${property} not supported.`);
          }
          return true;
        }
        return false;
      });

      dispatch(
        updateUserFitnessPlan({
          fitnessPlanId: fitnessPlan._id,
          params: updatedFitnessPlan,
        }),
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default selectedFitnessplanSlice.reducer;
