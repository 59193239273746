import React from 'react';
import styled from 'styled-components';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';

import RequireAdmin from './RequireAdmin';
import SideNav from '../universal/SideNav';
import TopNav from '../universal/TopNav';
import BottomNav from '../universal/BottomNav';
import UserProfileMain from '../profile/UserProfileMain';
import UserProfilePrefs from '../profile/UserProfilePrefs';
import UserProfileNotifs from '../profile/UserProfileNotifs';
import UserProfileBilling from '../profile/UserProfileBilling';

import UserDashboard from '../dashboard/UserDashboard';
import ExploreTab from '../explore/ExploreTab';
import UserMealPlan from '../mealPlan/UserMealPlan';
import UserFitnessPlan from '../fitnessPlan/UserFitnessPlan';
import UserPlanner from '../planner/UserPlanner';
import ShoppingList from '../shoppingList/ShoppingList';

import RecipePage from '../mealPlan/recipe/RecipePage';
import FoodPage from '../mealPlan/food/FoodPage';
import ExercisePage from '../fitnessPlan/exercise/ExercisePage';
import CirclePage from '../explore/CirclePage';

import AdminRecipe from '../admin/recipe/AdminRecipe';
import AdminFood from '../admin/food/AdminFood';
import AdminMealPlan from '../admin/mealPlan/AdminMealPlan';
import AdminExercise from '../admin/exercise/AdminExercise';
import AdminActivity from '../admin/activity/AdminActivity';
import AdminWorkout from '../admin/workout/AdminWorkout';
import AdminFitnessPlan from '../admin/fitnessPlan/AdminFitnessPlan';
import AdminUser from '../admin/user/AdminUser';
import AdminTrack from '../admin/track/AdminTrack';
import AdminCampaigns from '../admin/campaigns/AdminCampaigns';
import { isMobile } from '../helpers/utils';

const AppContainer = () => {
  const location = useLocation();
  const profile = location.pathname.includes('/app/profile');
  const path = location.pathname;

  const getPageTitle = (path) => {
    // Default
    let title = 'Page Title';
    const home = isMobile() ? 'Explore' : 'Dashboard';
    const titleMap = new Map([
      ['/app/profile', 'Settings'],
      ['/app/profile/preferences', 'Settings'],
      ['/app/profile/notifications', 'Settings'],
      ['/app/profile/billing', 'Settings'],
      ['/app/mealplan', 'Meal Plan'],
      ['/app/fitnessplan', 'Fitness Plan'],
      ['/app/planner', 'Planner'],
      ['/app/shoppinglist', 'Shopping List'],
      [new RegExp(/^\/app\/mealplan\/.+\/recipe\/.+$/), 'Recipe'],
      [new RegExp(/^\/app\/mealplan\/.+\/food\/.+$/), 'Food'],
      [new RegExp(/^\/app\/fitnessplan\/.+\/exercise\/.+$/), 'Exercise'],
      [new RegExp(/^\/app\/admin\/.+$/), 'Admin Panel'],
      ['/app', home],
      ['/app/dashboard', 'Dashboard'],
      ['/app/explore', 'Explore'],
    ]);

    titleMap.forEach((value, key) => {
      if (key === path) {
        title = value;
      } else if (key instanceof RegExp && path.match(key)) {
        title = value;
      }
    });

    return title;
  };

  return (
    <>
      <NavContainer>
        {isMobile() ? (
          <BottomNav title={getPageTitle(path)} path={path} />
        ) : (
          <SideNav profile={profile} />
        )}
      </NavContainer>

      <ContentContainer>
        {!isMobile() && <TopNav title={getPageTitle(path)} />}

        <ScrollContainer data-test="scroll-container">
          <Routes>
            {isMobile() ? (
              <Route index element={<ExploreTab />} />
            ) : (
              <Route index element={<UserDashboard />} />
            )}
            {/* Add below route to intake pathway */}
            <Route path="dashboard" element={<UserDashboard />} />
            <Route path="explore" element={<ExploreTab />} />
            <Route path="profile" element={<UserProfileMain />} />
            <Route path="profile/preferences" element={<UserProfilePrefs />} />
            <Route
              path="profile/notifications"
              element={<UserProfileNotifs />}
            />
            <Route path="profile/billing" element={<UserProfileBilling />} />
            <Route path="planner" element={<UserPlanner />} />
            <Route
              path="mealplan/:mealPlanId/recipe/:recipeId"
              element={<RecipePage />}
            />
            <Route
              path="mealplan/:mealPlanId/food/:foodId"
              element={<FoodPage />}
            />
            <Route path="mealplan" element={<UserMealPlan />} />
            <Route
              path="fitnessplan/:workoutId/exercise/:exerciseId"
              element={<ExercisePage />}
            />
            <Route path="fitnessplan" element={<UserFitnessPlan />} />
            <Route path="shoppinglist" element={<ShoppingList />} />
            <Route path="nutriology-transformation" element={<CirclePage />} />
            <Route path="*" element={<Navigate to="/" />} />

            {/* Admin Routes */}
            <Route path="admin" element={<RequireAdmin />}>
              <Route path="user" element={<AdminUser />} />
              <Route path="recipe" element={<AdminRecipe />} />
              <Route path="food" element={<AdminFood />} />
              <Route path="mealPlan" element={<AdminMealPlan />} />
              <Route path="exercise" element={<AdminExercise />} />
              <Route path="activity" element={<AdminActivity />} />
              <Route path="workout" element={<AdminWorkout />} />
              <Route path="fitnessPlan" element={<AdminFitnessPlan />} />
              <Route path="track" element={<AdminTrack />} />
              <Route path="campaigns" element={<AdminCampaigns />} />
            </Route>
          </Routes>
        </ScrollContainer>
      </ContentContainer>
    </>
  );
};

const NavContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ScrollContainer = styled.div`
  height: 100%;
`;

export default AppContainer;
