import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { verifyEmail } from '../store/general/currentUserSlice';
import FlexContainer from '../elements/FlexContainer';
import CircularProgress from '@mui/material/CircularProgress';

const mapState = (state) => ({
  isVerified: state.currentUser.user.isVerified,
  isAuthenticated: state.currentUser.isAuthenticated,
  intakeComplete: state.currentUser.user.intakeComplete,
  isSubscribed: state.currentUser.user.isSubscribed,
  isAdmin: state.currentUser.user.isAdmin,
});

const CheckToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const { isVerified, intakeComplete, isAuthenticated, isSubscribed, isAdmin } =
    useSelector(mapState);

  useEffect(() => {
    async function getToken() {
      try {
        const token = params.token;
        if (token) {
          // Need to show a loading screen while processing the token
          setLoading(true);
          dispatch(verifyEmail(token));
        }
      } catch (err) {
        console.error('Error while verifying your email:', err);
        const errMsg = err.error ? err.error.message : err.message;
        navigate('/please-verify', {
          message: errMsg,
        });
      }
    }
    getToken();
  }, [params.token]);

  return isAuthenticated && isSubscribed && intakeComplete && isVerified ? (
    <Navigate to="/app" />
  ) : loading ? (
    <Loading />
  ) : !isVerified ? (
    <Outlet />
  ) : !intakeComplete && !isAdmin ? (
    <Navigate to="/intake/questionnaire" />
  ) : !isSubscribed && !isAdmin ? (
    <Navigate to="/intake/payment" />
  ) : (
    <Navigate to="/login" />
  );
};

const Loading = (props) => {
  return (
    <FlexContainer
      justify="center"
      alignItems="center"
      style={{ width: '100%', height: '100%' }}
    >
      <CircularProgress size={64} color="inherit" data-test="loading-spinner" />
    </FlexContainer>
  );
};

export default CheckToken;
