import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { capitalize, cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import BackIcon from '../../icons/BackIcon';
import FavoriteIcon from '../../icons/FavoriteIcon';
import IntensityIcon from '../../icons/IntensityIcon';
import SwapIcon from '../../icons/SwapIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import MobileDropdown from '../../elements/MobileDropdown';
import Dropdown from '../../elements/Dropdown';
import NumericInput from '../../elements/NumericInput';
import MobileNumericInput from '../../elements/MobileNumericInput';
import { trackEvent } from '../../integrations/analytics';
import {
  updateUserMealPlan,
  swapMealPlanItem,
  setMealPlanFoodProperty,
  getUserMealPlanById,
} from '../../store/mealplan/selectedMealPlanSlice';
import FoodNutritionContainer from './FoodNutritionContainer';
import ModalContainer from '../../sharedModals/ModalContainer';
import useModal from '../../hooks/useModal';
import { updateUser } from '../../store/general/currentUserSlice';
import LogIcon from '../../icons/LogIcon';
import { isMobile } from '../../helpers/utils';
import MobileTopNav from '../../universal/MobileTopNav';
import { formatCalories } from '../../helpers/nutrition';
import { formatIngredientDisplay } from '../../helpers/utils';

const FoodPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.selectedMealPlan);
  const currentUser = useSelector((state) => state.currentUser.user);
  const { mealPlanId, foodId } = useParams();
  const { open, launchModal, closeModal } = useModal(false);
  const ref = useRef(null);

  useEffect(() => {
    dispatch(getUserMealPlanById({ mealPlanId }));
  }, [currentUser.id]);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [ref]);

  // get food from url recipe ID
  const food = mealPlan.foods.find((food) => {
    return food._id === foodId;
  });

  if (!food) {
    return null;
  }

  const handleFoodPropertyChanged = (property, value) => {
    dispatch(
      setMealPlanFoodProperty({
        mealPlan,
        food,
        property,
        value,
        populate: true,
      }),
    );
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (!value || parseInt(value) < 0) {
      value = 0;
    }
    handleFoodPropertyChanged(name, value);
  };

  const toggleEaten = () => {
    trackEvent(`Logged Food (Food Page - ${isMobile() ? 'Mobile' : 'Web'})`, {
      food: food,
      eaten: !food.eaten,
    });
    dispatch(
      setMealPlanFoodProperty({
        mealPlan,
        food,
        property: 'eaten',
        value: !food.eaten,
        populate: true,
      }),
    );
  };

  const toggleFavorite = () => {
    let newFoods = [...currentUser.favoriteFoods];

    // In most cases the food ID to add to the favorite is going to be the food admin ID
    // But sometimes, there won't be any (if it's a custom food for example)
    const favoriteFood = food.food.foodAdmin
      ? food.food.foodAdmin
      : food.food._id;

    if (currentUser.favoriteFoods.includes(favoriteFood)) {
      // The food is already present, we remove it.
      trackEvent(
        `Unfavorite Food (Food Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        {
          foodId: favoriteFood,
          foodName: food.food.verboseName,
        },
      );
      newFoods.splice(newFoods.indexOf(favoriteFood), 1);
    } else {
      // The food is not present we add it to the list.
      trackEvent(
        `Favorite Food (Food Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        {
          foodId: favoriteFood,
          foodName: food.food.verboseName,
        },
      );
      newFoods.push(favoriteFood);
    }

    let userData = {};
    userData._id = currentUser._id;
    userData.favoriteFoods = newFoods;
    dispatch(updateUser(userData));
  };

  const swapItemsHandler = async (params) => {
    try {
      const newItem = params.recipe ? params.recipe : params.food;
      const quantity = params.quantity ? params.quantity : 1;

      trackEvent(
        `Swapped Food (Food Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        { oldItem: food, newItem: newItem },
      );
      await dispatch(
        swapMealPlanItem({
          mealPlanId: mealPlan._id,
          itemSourceId: newItem,
          itemDestId: foodId,
          quantity,
        }),
      );
      handleBack();
    } catch (error) {
      console.error('Error swapping: ', error);
    }
  };

  async function removeFoodFromMealPlan() {
    trackEvent(`Removed Food (Food Page - ${isMobile() ? 'Mobile' : 'Web'})`, {
      food: food,
    });
    const updatedMealPlan = cloneDeep(mealPlan);
    updatedMealPlan.foods = updatedMealPlan.foods.filter(
      (food) => food._id !== foodId,
    );
    await dispatch(
      updateUserMealPlan({ mealPlanId: mealPlan._id, params: updatedMealPlan }),
    );
    handleBack();
  }

  const handleBack = () => {
    localStorage.setItem('mealplan_goBack', 'yes');
    navigate(-1);
  };

  const { measureUnit: formattedUnits } = formatIngredientDisplay(
    food.quantity,
    food.food.measureUnit,
  );

  if (!isMobile()) {
    // Web view
    return (
      <div ref={ref}>
        <Container justify="space-between" wrap="wrap">
          <LeftColumn flexDirection="column">
            <BackIcon handleClick={handleBack} data-test="foodPage-back" />
            <FoodInfoContainer flexDirection="column">
              <FoodName
                data-test="foodPage-foodName"
                length={food.food.verboseName.length}
              >
                {food.food.verboseName}
              </FoodName>
              <IconsContainer>
                <FavoriteIcon
                  toggleSelect={toggleFavorite}
                  selected={
                    currentUser.favoriteFoods.includes(food.food._id) ||
                    currentUser.favoriteFoods.includes(food.food.foodAdmin)
                  }
                  color="purple"
                  width="40px"
                  height="40px"
                  shrinkIcon={true}
                  data-test="foodPage-favorite"
                />
                <SwapIcon
                  toggleSelect={launchModal}
                  width="40px"
                  height="40px"
                  color="teal"
                  shrinkIcon={true}
                  data-test="foodPage-swap"
                />
                <RemoveIcon
                  toggleSelect={removeFoodFromMealPlan}
                  width="40px"
                  height="40px"
                  shrinkIcon={true}
                  data-test="foodPage-remove"
                />
                <LogIcon
                  logged={food.eaten}
                  toggleSelect={toggleEaten}
                  dateLogged={DateTime.fromISO(food.eaten).toFormat('MMMM d')}
                  width="40px"
                  height="40px"
                  data-test="foodPage-logIcon"
                />
              </IconsContainer>
              <FoodCaloriesContainer>
                <InfoItem alignItems="center">
                  <IntensityIcon
                    filled={true}
                    color={colors.secondary600}
                    width="22px"
                    height="22px"
                  />
                  <InfoColumn flexDirection="column">
                    <InfoLabel>Calories: </InfoLabel>
                    <Info data-test="foodPage-calories">
                      {formatCalories(
                        food.food.nutrientsPerUnit.calories * food.quantity,
                      )}
                    </Info>
                  </InfoColumn>
                </InfoItem>
              </FoodCaloriesContainer>
              <MealActionContainer flexDirection="column" justify="center">
                <ActionContainer alignItems="center">
                  <ActionLabel>Meal:</ActionLabel>
                  {isMobile() ? (
                    <MobileDropdown
                      width="220px"
                      options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                      value={{
                        value: food.mealType,
                        label: food.mealType,
                      }}
                      onChange={(value) =>
                        handleFoodPropertyChanged('mealType', value.value)
                      }
                      data-test="foodPage-mealSelect"
                    />
                  ) : (
                    <Dropdown
                      width="220px"
                      options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                      value={{
                        value: food.mealType,
                        label: food.mealType,
                      }}
                      onChange={(value) =>
                        handleFoodPropertyChanged('mealType', value.value)
                      }
                      data-test="foodPage-mealSelect"
                    />
                  )}
                </ActionContainer>
                <ActionContainer alignItems="center">
                  <ActionLabel>Quantity:</ActionLabel>
                  <NumericInput
                    name="quantity"
                    value={food.quantity}
                    onValueChanged={handleFoodPropertyChanged}
                    onChange={handleChange}
                    minValue="0"
                    units={formattedUnits}
                    data-test="foodPage-quantity"
                  />
                </ActionContainer>
              </MealActionContainer>
            </FoodInfoContainer>
          </LeftColumn>
          <RightColumn flexDirection="column">
            <FoodNutritionContainer food={food.food} quantity={food.quantity} />
          </RightColumn>
        </Container>
        <ModalContainer
          addRecipe={swapItemsHandler}
          addFood={swapItemsHandler}
          open={open}
          context={{
            type: 'swap',
          }}
          handleClose={closeModal}
          initialScreen="Add Meal"
        />
      </div>
    );
  }
  // Mobile view
  else
    return (
      <div ref={ref}>
        <MobileTopNav arrowsDisabled />
        <FoodContainer>
          <div>
            <MobileFoodName data-test="foodPage-foodName">
              {capitalize(food.food.verboseName)}
            </MobileFoodName>
            <MobileIconContainer justify="center" alignItems="center">
              <FavoriteIcon
                toggleSelect={toggleFavorite}
                selected={
                  currentUser.favoriteFoods.includes(food.food._id) ||
                  currentUser.favoriteFoods.includes(food.food.foodAdmin)
                }
                color="purple"
                width="40px"
                height="40px"
                shrinkIcon={true}
                data-test="foodPage-favorite"
              />
              <SwapIcon
                toggleSelect={launchModal}
                width="40px"
                height="40px"
                color="teal"
                shrinkIcon={true}
                data-test="foodPage-swap"
              />
              <RemoveIcon
                toggleSelect={removeFoodFromMealPlan}
                width="40px"
                height="40px"
                shrinkIcon={true}
                data-test="foodPage-remove"
              />
              <LogIcon
                logged={food.eaten}
                toggleSelect={toggleEaten}
                dateLogged={DateTime.fromISO(food.eaten).toFormat('MMMM d')}
                width="40px"
                height="40px"
                data-test="foodPage-logIcon"
              />
            </MobileIconContainer>
            <MobileFoodInfoContainer justify="center">
              <InfoItem alignItems="center">
                <IntensityIcon
                  filled={true}
                  color={colors.secondary600}
                  width="22px"
                  height="22px"
                />
                <InfoColumn flexDirection="column">
                  <InfoLabel>Calories: </InfoLabel>
                  <Info data-test="foodPage-calories">
                    {formatCalories(
                      food.food.nutrientsPerUnit.calories * food.quantity,
                    )}
                  </Info>
                </InfoColumn>
              </InfoItem>
            </MobileFoodInfoContainer>
            <MealActionContainer flexDirection="column" justify="center">
              <ActionContainer alignItems="center">
                <ActionLabel>Meal:</ActionLabel>
                <MobileDropdown
                  width="195px"
                  options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                  value={{
                    value: food.mealType,
                    label: food.mealType,
                  }}
                  onChange={(value) =>
                    handleFoodPropertyChanged('mealType', value.value)
                  }
                  data-test="foodPage-mealSelect"
                />
              </ActionContainer>
              <ActionContainer
                alignItems="center"
                data-test="foodPage-quantityContainer"
              >
                <ActionLabel>Quantity:</ActionLabel>
                <FlexContainer alignItems="center" style={{ gap: '16px' }}>
                  <MobileNumericInput
                    name="quantity"
                    value={food.quantity}
                    onValueChanged={handleFoodPropertyChanged}
                    onChange={handleChange}
                    minValue="0"
                    data-test="foodPage-quantity"
                  />
                  <UnitsLabel data-test="foodPage-units">
                    {formattedUnits}
                  </UnitsLabel>
                </FlexContainer>
              </ActionContainer>
            </MealActionContainer>
          </div>
        </FoodContainer>
        <NutrientContainer>
          <FoodNutritionContainer food={food.food} quantity={food.quantity} />
        </NutrientContainer>
        <ModalContainer
          addRecipe={swapItemsHandler}
          addFood={swapItemsHandler}
          open={open}
          context={{
            type: 'swap',
          }}
          handleClose={closeModal}
          initialScreen="Add Meal Mobile"
        />
      </div>
    );
};

const Container = styled(FlexContainer)`
  padding: 55px;
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 40%;
  min-width: 400px;
`;

const RightColumn = styled(FlexContainer)`
  flex-basis: 50%;
`;

const ActionContainer = styled(FlexContainer)`
  gap: 32px;
`;

const ActionLabel = styled.h4`
  color: ${colors.primary600};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100px;
`;

const MealActionContainer = styled(FlexContainer)`
  width: ${(props) => (isMobile() ? 'calc(100% - 64px)' : 'calc(100% - 32px)')};
  background-color: ${colors.primary050};
  gap: 32px;
  padding: 16px;
  margin: ${(props) => (isMobile() ? '0 16px' : 'initial')};
`;

const FoodInfoContainer = styled(FlexContainer)``;

const FoodName = styled.h1`
  color: ${colors.primary800};
  margin: 16px 0 32px 0;
  font-weight: 700;
  font-size: ${(props) => (props.length > 16 ? '52px' : '64px')};
  line-height: ${(props) => (props.length > 16 ? '58px' : '72px')};
  text-transform: capitalize;
`;

const IconsContainer = styled(FlexContainer)`
  gap: 36px;
`;

/***Mobile */
const FoodContainer = styled(FlexContainer)`
  justify-content: center;
  text-align: center;
  padding-top: 48px;
`;

const MobileFoodName = styled.h1`
  margin: 24px 30px 0px 30px;
  font-weight: 700;
  color: ${colors.primary800};
  line-height: 40px;
  text-align: center;
`;

const NutrientContainer = styled.div`
  width: 100%;
  padding: 32px 0 104px 0;
`;

const MobileIconContainer = styled(FlexContainer)`
  margin-top: 24px;
  gap: 36px;
`;

const InfoColumn = styled(FlexContainer)`
  gap: 4px;
`;

const Info = styled.p`
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const InfoLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;

const InfoItem = styled(FlexContainer)`
  gap: 12px;
`;

const FoodCaloriesContainer = styled(FlexContainer)`
  margin: 28px 0;
  gap: 32px;
`;

const MobileFoodInfoContainer = styled(FlexContainer)`
  margin: 28px 0;
  gap: 32px;
  text-align: left;
`;

const UnitsLabel = styled.p`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

export default FoodPage;
