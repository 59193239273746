import React, { useState, useEffect } from 'react';
import FlexContainer from '../../elements/FlexContainer';
import IngredientCategory from './IngredientCategory';
import {
  getIngredientMaps,
  addIngredientMap,
  deleteIngredientMap,
} from '../../services/api/ingredientmap';
import AlertStack from '../../elements/AlertStack';

import { getFood } from '../../services/api/food';

const IngredientMap = () => {
  const [ingredients, setIngredients] = useState([]);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const categories = ['produce', 'grocery', 'protein', 'dairy', 'frozen'];
  const codes = {
    produce: 1,
    grocery: 2,
    protein: 3,
    dairy: 4,
    frozen: 5,
  };

  const handleRemove = (e) => {
    const ingredientId = e.target.dataset.name;
    removeIngredientFromMap(ingredientId);
  };

  async function removeIngredientFromMap(ingredientId) {
    try {
      setStatus('');
      setMessage('');
      await deleteIngredientMap(ingredientId);
      loadIngredientMap();
    } catch (err) {
      console.error(err);
      setStatus('error');
      if (err.error && err.error.message) {
        setMessage(err.error.message);
      } else if (typeof err === 'string') {
        setMessage(err);
      } else {
        setMessage('Error encountered');
      }
    }
  }

  async function addIngredientToMap(params) {
    try {
      setStatus('');
      setMessage('');
      const foodId = params.food;
      const food = await getFood(foodId);
      if (food) {
        const ingredient = {
          name: food.verboseName,
          catName: params?.category,
          catNum: codes[params?.category],
          foodAdmin: food._id,
        };
        await addIngredientMap(ingredient);
        loadIngredientMap();
      } else {
        throw new Error('Food could not be found');
      }
    } catch (err) {
      console.error(err);
      setStatus('error');
      if (err.code === 11000 && err.message.includes('duplicate key')) {
        console.log('here');
        setMessage('Foods can only be added to one category at a time.');
      } else if (err.error && err.error.message) {
        setMessage(err.error.message);
      } else if (typeof err === 'string') {
        setMessage(err);
      } else {
        setMessage('Error encountered');
      }
    }
  }

  const loadIngredientMap = () => {
    setStatus('');
    setMessage('');
    getIngredientMaps()
      .then((results) => {
        setStatus('success');
        setIngredients(results);
      })
      .catch((err) => {
        setStatus('error');
        setIngredients([]);
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    loadIngredientMap();
  }, []);

  const categoryList = categories.map((cat, i) => {
    const filteredIngredients = ingredients.filter(
      (ingredient) => ingredient.catName === cat,
    );
    return (
      <IngredientCategory
        key={i}
        catName={cat}
        ingredients={filteredIngredients}
        addIngredient={addIngredientToMap}
        handleRemove={handleRemove}
        loading={loading}
      />
    );
  });

  return (
    <FlexContainer flexDirection="column">
      {status === 'error' && (
        <AlertStack
          id="ingredientMap-error"
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="ingredientMap-message-error"
        />
      )}
      {categoryList}
    </FlexContainer>
  );
};

export default IngredientMap;
