import React, { useEffect } from 'react';
import styled from 'styled-components';
import MobileTopNav from '../universal/MobileTopNav';
import ProfileCollapsible from './ProfileCollapsible';
import FoodPrefsForm from './FoodPrefsForm';
import FitnessPrefsForm from './FitnessPrefsForm';
import useToggles from '../hooks/useToggles';
import { isMobile } from '../helpers/utils';

const UserProfilePrefs = (props) => {
  const { toggleValues, handleToggle, setToggleValues } = useToggles();

  const initialInfo = {
    foodPrefs: true,
    fitnessPrefs: isMobile() ? false : true,
  };

  useEffect(() => {
    setToggleValues(initialInfo);
  }, []);

  if (isMobile()) {
    // Mobile view
    return (
      <PrefsContainer>
        <MobileTopNav arrowsDisabled />
        <MobileContainer>
          <ProfileCollapsible
            title="My Food Preferences"
            open={toggleValues.foodPrefs}
            onClick={handleToggle()}
            name="foodPrefs"
            children={<FoodPrefsForm />}
          />
          <ProfileCollapsible
            title="My Fitness Preferences"
            open={toggleValues.fitnessPrefs}
            onClick={handleToggle()}
            name="fitnessPrefs"
            children={<FitnessPrefsForm />}
          />
        </MobileContainer>
      </PrefsContainer>
    );
  } else {
    // Web view
    return (
      <PrefsContainer>
        <CollapsibleInfoContainer>
          <ProfileCollapsible
            title="My Food Preferences"
            open={toggleValues.foodPrefs}
            onClick={handleToggle()}
            name="foodPrefs"
            children={<FoodPrefsForm />}
          />
          <ProfileCollapsible
            title="My Fitness Preferences"
            open={toggleValues.fitnessPrefs}
            onClick={handleToggle()}
            name="fitnessPrefs"
            children={<FitnessPrefsForm />}
          />
        </CollapsibleInfoContainer>
      </PrefsContainer>
    );
  }
};

const CollapsibleInfoContainer = styled.div`
  margin: 16px;

  > * {
    margin-bottom: 16px;
  }
`;

const PrefsContainer = styled.div`
  padding-bottom: 24px;
`;

const MobileContainer = styled.div`
  margin: 64px 16px;
`;

export default UserProfilePrefs;
