import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from '../../helpers/utils';
import {
  searchFoods,
  searchUserFoods,
  getFoodUsers as getAllFoodUsers,
  getFoods as getAllFoods,
} from '../../services/api/food';
import {
  searchRecipes,
  searchUserRecipes,
  getRecipeUsers as getAllRecipeUsers,
  getRecipes as getAllRecipes,
} from '../../services/api/recipe';
import { determineRecipeVersion } from '../../helpers/recipeVersion';
import useToggles from '../../hooks/useToggles';
import { recipeTags } from '../../helpers/recipeTags';
import SearchMobile from './SearchMobile';
import SearchWeb from './SearchWeb';
import { trackEvent } from '../../integrations/analytics';
import { capitalize, unionWith, isEqual } from 'lodash';
import { getUserFavoriteRecipes } from '../../services/api/recipe';
import { getUserFavoriteFoods } from '../../services/api/food';

const Search = ({
  setScreen,
  setRecipe,
  setFood,
  context = {},
  setSearchTerm,
  searchTerm,
  setSearchTypeSelected,
  searchTypeSelected,
  ...props
}) => {
  // We will sort recipes and foods first by favorites and then alphabetically within each group
  // Sorting done here so we can treat all results as one group
  const sortRecipes = (a, b) =>
    +favoriteRecipes.includes(b._id) - +favoriteRecipes.includes(a._id) ||
    a.name.localeCompare(b.name);
  const sortFoods = (a, b) =>
    +favoriteFoods.includes(b._id) - +favoriteFoods.includes(a._id) ||
    a.name.localeCompare(b.name);
  const hide = context && context.hide ? context.hide : false;
  const initialType =
    hide === 'food' ? 'recipe' : hide === 'recipe' ? 'food' : 'both';

  const pageSizeFoods = 50;
  const pageSizeRecipes = 20;
  const [searchType, setSearchType] = useState(initialType);
  const [recipes, setRecipes] = useState([]);
  const [userRecipes, setUserRecipes] = useState([]);
  const [foods, setFoods] = useState([]);
  const [userFoods, setUserFoods] = useState([]);
  const [searchQuery, setSearchQuery] = useState(searchTerm || '');
  const [hasMoreRecipes, setHasMoreRecipes] = useState(false);
  const [hasMoreCustomRecipes, setHasMoreCustomRecipes] = useState(false);
  const [hasMoreFoods, setHasMoreFoods] = useState(false);
  const [hasMoreCustomFoods, setHasMoreCustomFoods] = useState(false);

  const initialToggleValues = {},
    initialCollapseToggleValues = {};

  recipeTags.forEach((tag) => {
    initialToggleValues[tag.name] = {};
    initialCollapseToggleValues[tag.name] = false;
  });

  const {
    toggleValues: collapseToggleValues,
    handleToggle: handleCollapseToggle,
  } = useToggles(initialCollapseToggleValues);

  const { toggleValues, setToggleValues, handleToggle } =
    useToggles(initialToggleValues);

  const user = useSelector((state) => state.currentUser.user);
  const userIsAdmin = user.isAdmin;
  const favoriteRecipes = user.favoriteRecipes;
  const favoriteFoods = user.favoriteFoods;
  const recCalories = user.recommendedMealPlanCalories || 0;

  const getRecipes = async (page) => {
    // We need to display user favorites first in the search results, so first we
    // must get the favorite recipes, which are stored on the user rather than the recipe
    const userFaveRecipes = await getUserFavoriteRecipes(
      user._id,
      favoriteRecipes,
      true,
    );
    // Get admin version of recipes so all objects are of the same type and we can filter dupes
    const adminFaveRecipes = userFaveRecipes.map(
      (recipe) => recipe.recipeAdmin,
    );
    let faveRecipes = [];
    const version = determineRecipeVersion(recCalories, context?.meal);
    let searchFn;
    let params = {
      limit: pageSizeRecipes,
      skip: pageSizeRecipes * page,
      version: version,
      isAdmin: userIsAdmin,
      placeholder: props?.placeholderOnly ? true : null,
      populate: { path: 'foods.food' },
    };
    if (searchQuery === '') {
      params.query = {
        version: { $in: [version, 'N/A', null, ''] },
        placeholder: props?.placeholderOnly ? true : { $in: [false, null] },
      };
      searchFn = getAllRecipes;
      faveRecipes = adminFaveRecipes;
    } else {
      params.query = searchQuery;
      searchFn = searchRecipes;
      // Only use favorite recipes that match search term
      faveRecipes = adminFaveRecipes.filter((recipe) =>
        recipe.name.includes(searchQuery),
      );
    }

    searchFn(params)
      .then((results) => {
        if (results.length === 0 || results.length < pageSizeRecipes) {
          setHasMoreRecipes(false);
        } else {
          setHasMoreRecipes(true);
        }
        const filtered = userIsAdmin
          ? results
          : results.filter((item) => item.active && !item.placeholder);
        // Ensure we don't have duplicate recipes in results
        const IdEqual = (obj1, obj2) => obj1._id === obj2._id;
        const combinedResults = unionWith(faveRecipes, filtered, IdEqual);
        // Sort recipes first by favorites and alphabetically within each
        if (page === 0) {
          setRecipes(combinedResults.sort(sortRecipes));
        } else {
          setRecipes(recipes.concat(filtered).sort(sortRecipes));
        }
      })
      .catch((err) => {
        console.error(err);
        setRecipes([]);
        setHasMoreRecipes(false);
      });
  };

  const getUserRecipes = (page) => {
    let searchFn;
    let params = {
      limit: pageSizeRecipes,
      skip: pageSizeRecipes * page,
      isAdmin: userIsAdmin,
      customRecipeOnly: true,
      populate: { path: 'foods.food' },
    };

    if (searchQuery === '') {
      params.userId = user._id;
      searchFn = getAllRecipeUsers;
    } else {
      params.query = searchQuery;
      searchFn = searchUserRecipes;
    }

    searchFn(params)
      .then((results) => {
        if (results.length === 0 || results.length < pageSizeRecipes) {
          setHasMoreCustomRecipes(false);
        } else {
          setHasMoreCustomRecipes(true);
        }
        if (page === 0) {
          setUserRecipes(results.sort(sortRecipes));
        } else {
          setUserRecipes(userRecipes.concat(results).sort(sortRecipes));
        }
      })
      .catch((err) => {
        console.error(err);
        setUserRecipes([]);
        setHasMoreCustomRecipes(false);
      });
  };

  const getFoods = async (page) => {
    // We need to display user favorites first in the search results, so first we
    // must get the favorite foods, which are stored on the user rather than the food
    const userFaveFoods = await getUserFavoriteFoods(
      user._id,
      favoriteFoods,
      true,
    );
    // Get admin version of foods so all objects are of the same type and we can filter dupes
    const adminFaveFoods = userFaveFoods.map((food) => food.foodAdmin);
    let faveFoods = [];
    const adminFoodOnly = (context && context.adminFoodOnly) || false;
    let searchFn;
    let params = {
      limit: pageSizeFoods,
      skip: pageSizeFoods * page,
      adminFoodOnly: adminFoodOnly,
    };
    if (context?.adminFoodOnly) {
      params.query = {
        kind: 'Admin',
      };
    }
    if (searchQuery === '') {
      params.sort = 'verboseName';
      searchFn = getAllFoods;
      faveFoods = adminFaveFoods;
    } else {
      params.query = searchQuery;
      searchFn = searchFoods;
      // Only use favorite foods that match search term
      faveFoods = adminFaveFoods.filter((food) =>
        food.verboseName.includes(searchQuery),
      );
    }

    searchFn(params)
      .then((results) => {
        if (results.length === 0 || results.length < pageSizeFoods) {
          setHasMoreFoods(false);
        } else {
          setHasMoreFoods(true);
        }
        // Ensure we don't have duplicate foods in results
        const combinedResults = unionWith(faveFoods, results, isEqual);
        if (page === 0) {
          setFoods(
            combinedResults.sort((a, b) => b?.verboseName - a?.verboseName),
          );
        } else {
          setFoods(
            foods
              .concat(results)
              .sort((a, b) => b?.verboseName - a?.verboseName),
          );
        }
      })
      .catch((err) => {
        console.error(err);
        setFoods([]);
        setHasMoreFoods(false);
      });
  };

  const getUserFoods = async (page) => {
    let searchFn;
    let params = {
      limit: pageSizeFoods,
      skip: pageSizeFoods * page,
      customFoodOnly: true,
    };
    if (searchQuery === '') {
      params.userId = user._id;
      searchFn = getAllFoodUsers;
    } else {
      params.query = searchQuery;
      searchFn = searchUserFoods;
    }

    searchFn(params)
      .then((results) => {
        if (results.length === 0 || results.length < pageSizeFoods) {
          setHasMoreCustomFoods(false);
        } else {
          setHasMoreCustomFoods(true);
        }
        if (page === 0) {
          setUserFoods(results.sort(sortFoods));
        } else {
          setUserFoods(userFoods.concat(results).sort(sortFoods));
        }
      })
      .catch((err) => {
        console.error(err);
        setUserFoods([]);
        setHasMoreCustomFoods(false);
      });
  };

  const handleSearch = (inputValue) => {
    trackEvent(
      `Searched ${capitalize(searchType)} (${isMobile() ? 'Mobile' : 'Web'})`,
    );
    setSearchQuery(inputValue);
    setSearchTerm(inputValue);
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchQuery(searchTerm);
    }
    if (searchTypeSelected) {
      setSearchType(searchTypeSelected);
    }

    // Reset search results:
    setRecipe([]);
    setUserRecipes([]);
    setFoods([]);
    setUserFoods([]);

    switch (searchType) {
      case 'food':
        console.log(`Searching food for "${searchTerm}"`);
        getFoods(0);
        if (context.variant !== 'admin') {
          getUserFoods(0);
        }
        break;
      case 'recipe':
        console.log(`Searching recipe for "${searchTerm}"`);
        getRecipes(0);
        if (context.variant !== 'admin') {
          getUserRecipes(0);
        }
        break;
      default:
        console.log(`Searching food and recipe for "${searchTerm}"`);
        getFoods(0);
        getRecipes(0);
        if (context.variant !== 'admin') {
          getUserFoods(0);
          getUserRecipes(0);
        }
    }
  }, [searchType, searchQuery]);

  // Filters
  const filteredRecipes = recipes.filter((item) => {
    for (let [key, value] of Object.entries(toggleValues)) {
      for (let vKey of Object.keys(value)) {
        if (value[vKey]) {
          if (key === 'intolerances') {
            // For intolerances we should filter out items that *do* match the tag
            // In other words, if a recipe is tagged with gluten, then a user checking
            // the 'gluten' filter should filter out gluten items.
            if (item.tags[key].includes(vKey)) {
              return false;
            }
          } else {
            if (!item.tags[key].includes(vKey)) {
              return false;
            }
          }
        }
      }
    }
    return true;
  });

  const clearAllFilters = () => {
    setToggleValues(initialToggleValues);
  };

  if (isMobile()) {
    // mobile version
    return (
      <SearchMobile
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        searchType={searchType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSearchType={setSearchType}
        setSearchTypeSelected={setSearchTypeSelected}
        clearAllFilters={clearAllFilters}
        collapseToggleValues={collapseToggleValues}
        handleCollapseToggle={handleCollapseToggle}
        handleToggle={handleToggle}
        toggleValues={toggleValues}
        setScreen={setScreen}
        setRecipe={setRecipe}
        userRecipes={userRecipes}
        getUserRecipes={getUserRecipes}
        recipes={recipes}
        filteredRecipes={filteredRecipes}
        getRecipes={getRecipes}
        pageSizeRecipes={pageSizeRecipes}
        foods={foods}
        getFoods={getFoods}
        userFoods={userFoods}
        getUserFoods={getUserFoods}
        setFood={setFood}
        pageSizeFoods={pageSizeFoods}
        hide={hide}
        hasMoreFoods={hasMoreFoods}
        hasMoreRecipes={hasMoreRecipes}
        hasMoreCustomFoods={hasMoreCustomFoods}
        hasMoreCustomRecipes={hasMoreCustomRecipes}
        {...props}
      />
    );
  } else {
    // web version
    return (
      <SearchWeb
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        searchType={searchType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSearchType={setSearchType}
        setSearchTypeSelected={setSearchTypeSelected}
        clearAllFilters={clearAllFilters}
        collapseToggleValues={collapseToggleValues}
        handleCollapseToggle={handleCollapseToggle}
        handleToggle={handleToggle}
        toggleValues={toggleValues}
        setScreen={setScreen}
        setRecipe={setRecipe}
        userRecipes={userRecipes}
        getUserRecipes={getUserRecipes}
        recipes={recipes}
        filteredRecipes={filteredRecipes}
        getRecipes={getRecipes}
        pageSizeRecipes={pageSizeRecipes}
        foods={foods}
        getFoods={getFoods}
        userFoods={userFoods}
        getUserFoods={getUserFoods}
        setFood={setFood}
        pageSizeFoods={pageSizeFoods}
        hide={hide}
        hasMoreFoods={hasMoreFoods}
        hasMoreRecipes={hasMoreRecipes}
        hasMoreCustomFoods={hasMoreCustomFoods}
        hasMoreCustomRecipes={hasMoreCustomRecipes}
        {...props}
      />
    );
  }
};

export default Search;
