import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../helpers/utils';
import { trackEvent } from '../integrations/analytics';

import FlexContainer from '../elements/FlexContainer';
import MobileTopNav from '../universal/MobileTopNav';
import BannerSection from './BannerSection';
import OnDeck from './OnDeck';
import FitnessSection from './FitnessSection';
import MealsSection from './MealsSection';
import BlogFeed from './BlogFeed';
import CircleModule from './CircleModule';

import {
  addRecipeToMealPlan,
  addFoodToMealPlan,
} from '../store/mealplan/selectedMealPlanSlice';
import {
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
} from '../store/fitnessplan/selectedFitnessPlanSlice';
import { getExploreBanners } from '../services/api/campaigns';

const ExploreTab = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.currentUser.user.fullName);
  const mealPlan = useSelector((state) => state.activeMealPlan);
  const fitnessPlan = useSelector((state) => state.activeFitnessPlan);
  const subscriptionActive = useSelector(
    (state) => state.currentUser.user.isSubscriptionActive,
  );

  const [activeBanners, setActiveBanners] = useState([]);
  const loadBanners = async () => {
    const activeExploreBanners = await getExploreBanners({
      query: { active: true },
    });
    setActiveBanners(activeExploreBanners);
  };

  useEffect(() => {
    if (!isMobile()) {
      navigate('/app');
    } else {
      loadBanners();
    }
  }, []);

  const meals = [
    {
      tabIndex: 0,
      meal: 'Breakfast',
    },
    {
      tabIndex: 1,
      meal: 'Lunch',
    },
    {
      tabIndex: 2,
      meal: 'Dinner',
    },
    {
      tabIndex: 3,
      meal: 'Snack',
    },
  ];

  const setMealStorage = (params) => {
    const tabIndex = meals.findIndex((el) => el.meal === params.mealType);
    localStorage.setItem('mealplan_tabValue', tabIndex);
    localStorage.setItem('mealplan_dateValue', JSON.stringify(params.date));
  };

  const setFitnessStorage = (params) => {
    localStorage.setItem('fitnessplan_dateValue', JSON.stringify(params.date));
  };

  async function addRecipeToUserMealPlan(params) {
    trackEvent(`Add Recipe (Explore Page)`, {
      recipe: params.recipe,
    });
    dispatch(addRecipeToMealPlan({ mealPlanId: mealPlan._id, params }));
    setMealStorage(params);
    navigate('/app/mealplan');
  }

  async function addFoodToUserMealPlan(params) {
    trackEvent(`Add Food (Explore Page)`, {
      food: params.food,
    });
    dispatch(addFoodToMealPlan({ mealPlanId: mealPlan._id, params }));
    setMealStorage(params);
    navigate('/app/mealplan');
  }

  async function addWorkoutToUserFitnessPlan(params) {
    trackEvent(`Add Workout (Explore Page)`, { workout: params.workout });
    dispatch(
      addWorkoutToFitnessPlan({ fitnessPlanId: fitnessPlan._id, params }),
    );
    setFitnessStorage(params);
    navigate('/app/fitnessplan', { state: { date: params.date } });
  }

  async function addActivityToUserFitnessPlan(params) {
    trackEvent(`Add Activity (Explore Page)`, { activity: params.activity });
    dispatch(
      addActivityToFitnessPlan({ fitnessPlanId: fitnessPlan._id, params }),
    );
    setFitnessStorage(params);
    navigate('/app/fitnessplan', { state: { date: params.date } });
  }

  useEffect(() => {
    return function cleanup() {
      localStorage.setItem('from_explore', 'yes');
    };
  }, [location]);

  return (
    <div style={{ overflowX: 'hidden ' }}>
      <MobileTopNav showGreeting={true} userName={userName} arrowsDisabled />
      <PageContainer flexDirection="column" data-test="explore-tab">
        <BannerSection
          activeBanners={activeBanners}
          addRecipe={addRecipeToUserMealPlan}
          addFood={addFoodToUserMealPlan}
          addWorkout={addWorkoutToUserFitnessPlan}
          addActivity={addActivityToUserFitnessPlan}
        />
        <OnDeck
          meals={meals}
          mealPlan={mealPlan}
          fitnessPlan={fitnessPlan}
          subscriptionActive={subscriptionActive}
          setMealStorage={setMealStorage}
          setFitnessStorage={setFitnessStorage}
        />
        <CircleModule />
        <MealsSection addRecipe={addRecipeToUserMealPlan} />
        <FitnessSection addWorkout={addWorkoutToUserFitnessPlan} />
        <BlogFeed />
      </PageContainer>
    </div>
  );
};

const PageContainer = styled(FlexContainer)`
  padding: 60px 15px 120px 15px;
  min-height: 100vh;
  gap: 24px;
`;

export default ExploreTab;
