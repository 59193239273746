import { createSlice } from '@reduxjs/toolkit';

const initialState = 'Breakfast';

const selectedMealTypeSlice = createSlice({
  name: 'selectedMealType',
  initialState,
  reducers: {
    setSelectedMealType(state, action) {
      return action.payload;
    },
  },
});
export const { setSelectedMealType } = selectedMealTypeSlice.actions;
export default selectedMealTypeSlice.reducer;
