import React from 'react';
import styled from 'styled-components';
import { screenSize } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DetailCard from '../profile/DetailCard';
import { StepNumber, StepTitle } from './StepComponents';
import { camelCase } from 'lodash';
import { isMobile } from '../helpers/utils';

const StepOne = ({ values, handleValueChanged }) => {
  const goalOptions = ['Lose body fat', 'Gain muscle', 'Get fit and toned'];

  const nutriologyGoalCards = goalOptions.map((goal) => (
    <DetailCard
      key={goal}
      imgSrc={`${process.env.PUBLIC_URL}/icons/NutriologyGoals/${goal}.png`}
      title={goal}
      isSelected={values.detailPreferences.nutriologyGoal === goal}
      name="detailPreferences.nutriologyGoal"
      handleSelect={() =>
        handleValueChanged('detailPreferences.nutriologyGoal', goal, 'string')
      }
      size={!isMobile() && 'large'}
      data-test={`profile-goal-${camelCase(goal)}`}
    />
  ));

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justify="space-between"
    >
      <StyledAlert data-test="welcome-msg" icon={false} severity="success">
        <StyledAlertTitle>Welcome to Nutriology!</StyledAlertTitle>
        Please take a few moments to tell us a little more about yourself and
        your goals, so we can create your personal meal, workout, and
        transformation plan.
      </StyledAlert>
      {!isMobile() && <StepNumber>1</StepNumber>}
      <StepTitle>First off, what is your body composition goal?</StepTitle>
      <CardsContainer wrap="wrap">{nutriologyGoalCards}</CardsContainer>
    </FlexContainer>
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 24px;
  margin-bottom: ${isMobile() ? '24px' : '-24px'};
  width: 50%;

  @media (max-width: ${screenSize.mobileL}) {
    width: 80%;
  }
`;

const StyledAlertTitle = styled(AlertTitle)`
  font-weight: bold;
`;

const CardsContainer = styled(FlexContainer)`
  gap: ${(props) => (isMobile() ? '12px' : '15px')};

  @media (max-width: ${screenSize.mobileL}) {
    align-items: center;
    justify-content: center;
  }
`;

export default StepOne;
