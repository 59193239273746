import React from 'react';
import { default as MaterialAvatar } from '@mui/material/Avatar';
import styled from 'styled-components';
import { getImageURL } from '../services/api/api';

const Avatar = (props) => {
  const imgUrl = (ratio = 1) => {
    const height =
      (props.big ? '160px' : props.medium ? '78px' : '32px') * ratio;
    const width =
      (props.big ? '160px' : props.medium ? '78px' : '32px') * ratio;
    return getImageURL(
      props.src,
      `resizing_type:fill/height:${height}/width:${width}`,
    );
  };

  return <StyledAvatar src={imgUrl} {...props} />;
};

const StyledAvatar = styled(MaterialAvatar)`
  height: ${(props) => (props.big ? '160px' : props.medium ? '78px' : '32px')};
  width: ${(props) => (props.big ? '160px' : props.medium ? '78px' : '32px')};
  border: 2px solid #e8e8e8;

  & .MuiAvatar-img {
    height: ${(props) =>
      props.big ? '160px' : props.medium ? '78px' : '32px'};
    width: ${(props) => (props.big ? '160px' : props.medium ? '78px' : '32px')};
  }
`;

export default Avatar;
