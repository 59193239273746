import React, { useState } from 'react';

import { cloneDeep } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';

import ActivityForm from './ActivityForm';
import { addActivity } from '../../services/api/activity';

const AddActivity = (props) => {
  const initialValues = {
    intensity: 3,
    duration: 60,
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [imageUrl, setImageUrl] = useState();

  const submitData = () => {
    let payload = cloneDeep(values);

    setLoading(true);

    addActivity(payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Activity "${result.name}" was created successfully!`);
        setValues(initialValues);
        setImageUrl(null);
      })
      .catch((err) => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <ActivityForm
      imageUrl={imageUrl}
      setImageUrl={setImageUrl}
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      buttonText="Create Activity"
    />
  );
};

export default AddActivity;
