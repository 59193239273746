import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';

const ArrowIcon = ({ rotate, large, medium, color, ...props }) => (
  <Circle justify="center" alignItems="center" {...props}>
    <Icon
      viewBox="0 0 7 12"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      $iconRotate={rotate}
      $color={color}
      $large={large}
      $medium={medium}
      {...props}
    >
      <g
        id="Style-Guide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Style-Guide---Elements"
          transform="translate(-207.000000, -2795.000000)"
          stroke="#686868"
          strokeWidth="1.5"
        >
          <g
            id="Right-arrow"
            transform="translate(210.000000, 2801.000000) rotate(-270.000000) translate(-210.000000, -2801.000000) translate(198.000000, 2789.000000)"
          >
            <g id="Arrow" transform="translate(7.000000, 8.000000)">
              <path
                d="M0.416666667,5.58333333 L5.02719206,0.972807935"
                id="Line-2"
              ></path>
              <path
                d="M5.41666667,5.58333333 L9.60411484,1.39588516"
                id="Line-2-Copy"
                transform="translate(7.500000, 3.500000) scale(-1, 1) translate(-7.500000, -3.500000) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Circle>
);

const Circle = styled(FlexContainer)`
  width: ${(props) => (props.$large ? '40px' : '24px')};
  height: ${(props) => (props.$large ? '40px' : '24px')};
  border-radius: ${(props) => (props.$large ? '20px' : '12px')};

  &:hover {
    cursor: pointer;
    background-color: ${() => !isMobile() && colors.primary100};
  }

  &:active {
    background-color: ${() => !isMobile() && colors.primary200};
  }
`;

const Icon = styled.svg`
  width: ${(props) => (props.$large ? '10px' : props.$medium ? '9px' : '7px')};
  height: ${(props) =>
    props.$large ? '18px' : props.$medium ? '15px' : '12px'};

  transform: ${(props) => (props.$iconRotate ? 'rotate(180deg)' : 'inherit')};

  & line {
    fill: #fff;
    stroke: ${(props) => (props.$color ? props.$color : colors.primary700)};
  }

  & path {
    stroke: ${(props) => (props.$color ? props.$color : colors.primary700)};
  }

  &:disabled {
    stroke: ${colors.primary300};
  }
`;

export default ArrowIcon;
