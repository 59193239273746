import React, { useRef } from 'react';
import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../elements/TextInput';
import NumericInput from '../../elements/NumericInput';
import Dropdown from '../../elements/Dropdown';
import AddImage from '../../elements/AddImage';
import Button from '../../elements/Button';
import AlertStack from '../../elements/AlertStack';

const ActivityForm = ({
  imageUrl,
  setImageUrl,
  handleChange,
  values,
  handleValueChanged,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  // Activity Image
  const inputFile = useRef(null);

  const handleChangeImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleImage = () => {
    inputFile.current.click();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <input
          hidden
          ref={inputFile}
          type="file"
          name="image"
          onChange={handleChangeImage}
        />
        <AddImage src={imageUrl} onClick={handleImage} />
        <TextInput
          id="activityName"
          name="name"
          onChange={handleChange}
          value={values.name || ''}
          placeholder="Activity Name"
          label="Activity Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Activity Name')}
          data-test="activity-name"
        />
        <NumericInput
          id="caloriesBurned"
          name="caloriesBurned"
          onChange={handleChange}
          onValueChanged={handleValueChanged}
          value={values.caloriesBurned || ''}
          minValue="0"
          label="Estimated Calories Burned"
          units="kCal"
          data-test="activity-caloriesBurned"
        />
        <NumericInput
          id="duration"
          name="duration"
          onChange={handleChange}
          onValueChanged={handleValueChanged}
          value={values.duration || ''}
          minValue="0"
          label="Activity Duration"
          units="mins"
          data-test="activity-duration"
        />
        <Dropdown
          width="320px"
          options={[1, 2, 3, 4, 5]}
          label="Activity Intensity"
          value={{
            value: values.intensity,
            label: values.intensity,
          }}
          onChange={(value) =>
            handleValueChanged('intensity', value.value, 'string')
          }
          data-test="activity-intensity"
        />
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Activity'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="activity-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="activity-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="activity-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="activity-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

export default ActivityForm;
