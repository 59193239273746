import React from 'react';
import styled from 'styled-components';

const WaterIcon = (props) => {
  return (
    <Icon
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M29.5001 58.8531C45.7119 58.8531 58.8541 45.7111 58.8541 29.4996C58.8541 13.288 45.7119 0.145996 29.5001 0.145996C13.2883 0.145996 0.145996 13.288 0.145996 29.4996C0.145996 45.7111 13.2883 58.8531 29.5001 58.8531Z"
          fill={props.color}
        />
        <g clipPath="url(#clip0_4417_81225)">
          <path
            d="M40.1542 15.2835C39.9818 15.093 39.7386 14.9841 39.481 14.9841H19.5213C19.2655 14.9841 19.0205 15.093 18.8481 15.2835C18.6776 15.4722 18.5923 15.7263 18.6177 15.9821L21.3395 43.1994C21.3866 43.6639 21.7768 44.0159 22.2431 44.0159H36.7592C37.2255 44.0159 37.6157 43.6639 37.661 43.1994L40.3828 15.9821C40.41 15.7263 40.3265 15.4722 40.1542 15.2835ZM37.739 24.1981C35.4328 24.9438 32.4316 25.2886 30.0764 23.3562C27.1241 20.9338 23.506 21.3512 21.0528 22.0806L20.5247 16.7986H38.4776L37.739 24.1981Z"
            fill="white"
          />
        </g>
        <path
          d="M55.5679 29.5C55.5679 43.8964 43.8971 55.567 29.5005 55.567C15.1038 55.567 3.43306 43.8964 3.43306 29.5C3.43306 15.1037 15.1038 3.43306 29.5005 3.43306C43.8971 3.43306 55.5679 15.1037 55.5679 29.5Z"
          stroke="#E6E6E6"
          strokeWidth="1.24697"
        />
      </g>
      <defs>
        <clipPath id="clip0_4417_81225">
          <rect
            width="29.0322"
            height="29.0317"
            fill="white"
            transform="translate(14.9844 14.9841)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};
const Icon = styled.svg`
  width: ${(props) => (props.size === 'large' ? '53px' : '38px')};
  height: ${(props) => (props.size === 'large' ? '53px' : '38px')};

  &:hover {
    cursor: pointer;
  }
`;

export default WaterIcon;
