import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { omit, cloneDeep, keys, pickBy, isEmpty } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useModal from '../../hooks/useModal';
import useToggles from '../../hooks/useToggles';
import ModalContainer from '../../sharedModals/ModalContainer';

import MealPlanForm from './MealPlanForm';
import { addMealPlan, getMealPlan } from '../../services/api/mealplan';

const DuplicateMealPlan = (props) => {
  const initialValues = {
    type: 'Weight Loss',
    planLength: 7,
    dayNum: {
      value: 1,
      label: 'Monday',
    },
    recommendedCalories: 1800,
  };

  const initialToggleValues = {
    active: false,
    dietType: { any: true },
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);

  const [mealPlan, setMealPlan] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [foods, setFoods] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { open, launchModal, closeModal } = useModal(false);

  async function loadMealPlan(params) {
    try {
      setMessage('');
      const mealPlanId = params.mealPlan;
      const result = await getMealPlan(mealPlanId);
      if (result) {
        setMealPlan(result);
      } else {
        throw new Error('Meal Plan could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded meal plan
  useEffect(() => {
    if (!isEmpty(mealPlan)) {
      setRecipes([...mealPlan.recipes]);
      setFoods([...mealPlan.foods]);

      setValues({
        ...values,
        type: mealPlan.type,
        planLength: mealPlan.planLength,
        recommendedCalories: mealPlan.recommendedCalories,
      });
      const toggleTmp = {
        dietType: {},
        active: mealPlan.active,
      };

      const tags = mealPlan.tags;
      for (let tagType in tags) {
        tags[tagType].forEach((tagVal) => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
    }
  }, [mealPlan]);

  useEffect(() => {
    const filteredRec = recipes.filter((recipe) => {
      return recipe.dayNum === values.dayNum.value;
    });
    const filteredFood = foods.filter((food) => {
      return food.dayNum === values.dayNum.value;
    });
    setFilteredRecipes(filteredRec);
    setFilteredFoods(filteredFood);
  }, [recipes, foods, values.dayNum]);

  const getDeletedRecordNames = () => {
    let recipeString = '';
    let foodString = '';
    if (recipes.some((recipe) => recipe.recipe.isDeleted)) {
      const deletedRecipes = recipes
        .filter((recipe) => recipe.recipe.isDeleted)
        .map((recipe) => recipe.recipe.name);
      recipeString = deletedRecipes.join(', ');
    }
    if (foods.some((food) => food.food.isDeleted)) {
      const deletedFoods = foods
        .filter((food) => food.food.isDeleted)
        .map((food) => food.food.name);
      foodString = deletedFoods.join(', ');
    }
    let recordString = '';
    if (recipeString.length && foodString.length) {
      recordString = recipeString.concat(', ', foodString);
    } else if (recipeString.length) {
      recordString = recipeString;
    } else {
      recordString = foodString;
    }

    if (recordString.length) {
      return recordString;
    } else {
      return false;
    }
  };

  const submitData = () => {
    setLoading(true);
    // Prevent saving if any recipes or foods have been deleted
    const deletedRecordNames = getDeletedRecordNames();
    if (deletedRecordNames) {
      setStatus('error');
      setMessage(
        `The following items are deleted and must be removed or replaced before saving: ${deletedRecordNames}`,
      );
      setLoading(false);
      return;
    }
    let payload = cloneDeep(omit(values, ['dayNum']));
    const recRefs = recipes.map((recipe) => {
      return {
        name: recipe.recipe.name,
        mealType: recipe.mealType,
        dayNum: recipe.dayNum,
        recipe: recipe.recipe._id,
        leftover: recipe.leftover,
      };
    });
    const foodRefs = foods.map((food) => {
      return {
        name: food.food.name,
        mealType: food.mealType,
        dayNum: food.dayNum,
        quantity: food.quantity,
        food: food.food._id,
      };
    });
    payload.tags = {};
    payload.tags.dietType = keys(pickBy(toggleValues.dietType, Boolean));
    payload.recipes = recRefs;
    payload.foods = foodRefs;
    payload.active = toggleValues.active;

    addMealPlan(payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Meal Plan "${result.name}" was created successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setRecipes([]);
        setFoods([]);
        setMealPlan([]);
      })
      .catch((err) => {
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Meal Plan"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="mealPlan-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
        }}
        initialScreen="MealPlanSearch"
        handleClose={closeModal}
        addMealPlan={loadMealPlan}
        {...props}
      />
      <MealPlanForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        filteredRecipes={filteredRecipes}
        filteredFoods={filteredFoods}
        foods={foods}
        recipes={recipes}
        setRecipes={setRecipes}
        setFoods={setFoods}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        viewOnly={isEmpty(mealPlan)}
        buttonText={'Create Meal Plan'}
        formType="edit"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DuplicateMealPlan;
