import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';

import ModalContainer from '../../sharedModals/ModalContainer';
import RecipeForm from './RecipeForm';
import RelatedRecords from '../related/RelatedRecords';

import { getRecipe, editRecipe } from '../../services/api/recipe';
import { getFoods, searchFoods } from '../../services/api/food';
import { convertNutrients } from '../../helpers/units';

const DeleteRecipe = (props) => {
  const initialValues = {
    nutrients: {},
  };
  const initialToggleValues = {
    active: false,
    placeholder: false,
    dietType: {},
    intolerances: {},
    mealType: {},
    cookingStyle: {},
    primaryIngredient: {},
    prepTime: {},
  };

  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);
  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);
  const [ingredientsValue, setIngredientsValue] = useState([]);
  const [foods, setFoods] = useState([]);
  const [status, setStatus] = useState();
  const [calculatedNutrients, setCalculatedNutrients] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [imageUrl, setImageUrl] = useState();
  const instruction = { num: '', step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);
  const { open, launchModal, closeModal } = useModal(false);
  const [recipe, setRecipe] = useState({});
  const [prevRecipe, setPrevRecipe] = useState({});

  async function loadRecipe(params) {
    try {
      setMessage('');
      const recipeId = params.recipe;
      const result = await getRecipe(recipeId);
      if (result) {
        setRecipe(result);
      } else {
        throw new Error('Recipe could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  const clearNutrientValues = () => {
    setValues({ ...values, nutrients: {} });
  };

  // Populate form values from loaded recipe
  useEffect(() => {
    if (!isEmpty(recipe)) {
      setValues({
        attrLink: recipe.attrLink,
        cookTime: recipe.cookTime,
        expertTip: recipe.expertTip,
        imageUrl: recipe.imageUrl,
        name: recipe.name,
        nutrients: recipe.nutrients,
        prepTime: recipe.prepTime,
        servingUnit: recipe.servingUnit,
        servingWeight: recipe.servingWeight,
        source: recipe.source,
        servingSize: recipe.servingSize,
        yield: recipe.yield,
        version: recipe.version || 'N/A',
      });

      const toggleTmp = {
        active: recipe.active,
        placeholder: recipe.placeholder,
        dietType: {},
        intolerances: {},
        mealType: {},
        cookingStyle: {},
        primaryIngredient: {},
        prepTime: {},
      };

      const tags = recipe.tags;
      for (let tagType in tags) {
        tags[tagType].forEach((tagVal) => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setImageUrl(recipe.imageUrl);
      setInstructionsValue([...recipe.cookingInstructions]);
      const foodsCopy = [];
      recipe.foods.forEach((food) => {
        const quantity = food.quantity * recipe.yield;
        const convertedNutrients = convertNutrients(
          food.food.nutrientsPerUnit,
          food.food.gramWeightPerUnit,
          food.measureUnit,
          food.food.measureUnit,
        );

        foodsCopy.push({
          quantity: quantity,
          measureUnit: food.measureUnit,
          food: food.food.id,
          name: food.food.name,
          verboseName: food.food.verboseName,
          gramWeight: food.food.gramWeight,
          gramWeightPerUnit: food.food.gramWeightPerUnit,
          nutrientsPerUnit: convertedNutrients,
        });
      });
      setFoods(foodsCopy);
    }
  }, [recipe]);

  const deleteItem = (e) => {
    setLoading(true);
    e.preventDefault();

    const recipeId = recipe._id;
    let payload = {
      isDeleted: true,
    };
    editRecipe(recipeId, payload)
      .then((result) => {
        setPrevRecipe(recipeId);
        setStatus('success');
        setMessage(`Recipe "${result.name}" was deleted successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setInstructionsValue([]);
        setFoods([]);
        setImageUrl(null);
        setRecipe([]);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const undoDelete = (e) => {
    e.preventDefault();
    let payload = {
      isDeleted: false,
    };
    editRecipe(prevRecipe, payload)
      .then((result) => {
        setStatus('');
        setMessage('');
        loadRecipe({
          recipe: prevRecipe,
        });
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Recipe"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="recipe-find"
      />
      {recipe._id && (
        <RelatedRecords recordType="recipe" recordId={recipe._id} />
      )}
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
        }}
        initialScreen="Meal Search"
        handleClose={closeModal}
        addRecipe={loadRecipe}
        {...props}
      />
      <RecipeForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        clearNutrientValues={clearNutrientValues}
        ingredientsValue={ingredientsValue}
        setIngredientsValue={setIngredientsValue}
        instructionsValue={instructionsValue}
        setInstructionsValue={setInstructionsValue}
        getFoods={getFoods}
        foods={foods}
        searchFoods={searchFoods}
        setFoods={setFoods}
        calculatedNutrients={calculatedNutrients}
        setCalculatedNutrients={setCalculatedNutrients}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={deleteItem}
        undoDelete={undoDelete}
        viewOnly={true}
        buttonText="Delete Recipe"
        formType="delete"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DeleteRecipe;
