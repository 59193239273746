import React, { useEffect } from 'react';
import { colors } from '../styleConstants';
import * as d3 from 'd3';
// import { default as ReactMinimalPieChart } from 'react-minimal-pie-chart';

const PieChart = ({ protein, carbs, fat, totalCalories, ...props }) => {
  const drawChart = () => {
    let data = [];
    if (protein) {
      data.push({
        name: 'protein',
        value: (protein * 4) / totalCalories,
        color: colors.pie1,
      });
    }

    if (carbs) {
      data.push({
        name: 'carbs',
        value: (carbs * 4) / totalCalories,
        color: colors.pie2,
      });
    }

    if (fat) {
      data.push({
        name: 'fat',
        value: (fat * 9) / totalCalories,
        color: colors.pie3,
      });
    }

    let totalMacros = 0;
    for (let macro of data) {
      totalMacros += macro.value;
    }

    const width = 90;
    const height = 90;
    const margin = 10;

    let svg = d3.select('#graph').selectAll('svg').remove();

    svg = d3
      .select('#graph')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
    const arcs = d3.pie().value((d) => d.value)(data);

    const path = d3
      .arc()
      .innerRadius(0)
      .outerRadius(width / 2 - margin);

    svg
      .selectAll('slices')
      .data(arcs)
      .enter()
      .append('path')
      .classed('arc', true)
      .attr('fill', (d) => {
        if (d.data) {
          return d.data.color;
        }
      })
      .attr('stroke', 'none')
      .attr('d', path);

    svg
      .selectAll('slices')
      .data(arcs)
      .enter()
      .append('text')
      .attr('fill', (d) => {
        return 'black'; // will need to evaluate background color to choose whether white or black text
      })
      .text((d) => {
        return `${Math.round((d.data.value / totalMacros) * 100)}%`;
      })
      .attr('transform', (d) => 'translate(' + path.centroid(d) + ')')
      .style('text-anchor', 'middle')
      .style('font-size', '11px')
      .style('color', 'white');
  };

  useEffect(() => {
    drawChart();
  });

  return <div id="graph"></div>;

  // return (
  //   <ReactMinimalPieChart
  //     data={[
  //       {
  //         title: 'Protein',
  //         value: (protein * 4) / totalCalories,
  //         color: colors.pie1
  //       },
  //       {
  //         title: 'Carbs',
  //         value: (carbs * 4) / totalCalories,
  //         color: colors.pie2
  //       },
  //       {
  //         title: 'Fat',
  //         value: (fat * 4) / totalCalories,
  //         color: colors.pie3
  //       },
  //     ]}
  //   />
  // )
};

export default PieChart;
