const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;
export const getMealCategories = (dietType) => {
  const veg = ['Vegetarian', 'Vegan', 'Pescatarian'].includes(dietType);
  return [
    {
      name: 'Quick Breakfast',
      logic: {
        $and: [
          {
            'tags.mealType': {
              $in: ['breakfast'],
            },
          },
          {
            $or: [
              {
                'tags.prepTime': {
                  $in: ['min15'],
                },
              },
              {
                'tags.prepTime': {
                  $in: ['noCook'],
                },
              },
            ],
          },
        ],
      },
      imageUrl: veg
        ? `${fileStorage}/1-Quick+Breakfast-Veg.jpg`
        : `${fileStorage}/1-Quick-Breakfast.jpg`,
    },
    {
      name: 'Pack & Go Lunches',
      logic: {
        $and: [
          {
            'tags.mealType': {
              $in: ['lunch'],
            },
          },
          {
            'tags.cookingStyle': {
              $in: ['toGo'],
            },
          },
        ],
      },
      imageUrl: veg
        ? `${fileStorage}/2-Pack+and+Go+Lunches-Veg.jpg`
        : `${fileStorage}/3-Pack+and+Go+Lunches.jpg`,
    },
    {
      name: 'Creative Salads',
      logic: {
        'tags.cookingStyle': {
          $in: ['salad'],
        },
      },
      imageUrl: veg
        ? `${fileStorage}/3-Creative+Salads-Veg.jpg`
        : `${fileStorage}/4-Creative+Salads.jpg`,
    },
    {
      name: 'Dinner Inspiration',
      logic: {
        'tags.mealType': {
          $in: ['dinner'],
        },
      },
      imageUrl: veg
        ? `${fileStorage}/4-Dinner+Inspirations-Veg.jpg`
        : `${fileStorage}/5-Dinner+Inspiration.jpg`,
    },
    {
      name: 'Snack Rotation',
      logic: {
        'tags.mealType': {
          $in: ['snack'],
        },
      },
      imageUrl: veg
        ? `${fileStorage}/5-Snack+Rotation-Veg.jpg`
        : `${fileStorage}/6-Snack+Rotation.jpg`,
    },
    {
      name: 'No Cook Meals',
      logic: {
        'tags.prepTime': {
          $in: ['noCook'],
        },
      },
      imageUrl: veg
        ? `${fileStorage}/6-No+Cook+Meals-Veg.jpg`
        : `${fileStorage}/7-No+Cook+Meals.jpg`,
    },
    {
      name: 'Prep Ahead Meals',
      logic: {
        'tags.cookingStyle': {
          $in: ['prepAhead'],
        },
      },
      imageUrl: veg
        ? `${fileStorage}/7-Prep+Ahead+Meals-Veg.jpg`
        : `${fileStorage}/8-Prep+ahead+meals.jpg`,
    },
    {
      name: 'Stock Your Freezer',
      logic: {
        'tags.cookingStyle': {
          $in: ['prepFreeze'],
        },
      },
      imageUrl: `${fileStorage}/9-Stock-Your-Freezer.jpg`,
    },
  ];
};

export const fitnessCategories = [
  {
    name: 'At Home',
    logic: {
      'tags.location': {
        $in: ['home'],
      },
    },
    imageUrl: `${fileStorage}/7-At+Home.jpg`,
  },
  {
    name: 'No Equipment',
    logic: {
      'tags.location': {
        $in: ['noEquipment'],
      },
    },
    imageUrl: `${fileStorage}/No-Equipment.jpg`,
  },
  {
    name: 'Upper Body',
    logic: {
      'tags.specialtyFocus': {
        $in: ['upperBody'],
      },
    },
    imageUrl: `${fileStorage}/1-Upper+Body.png`,
  },
  {
    name: 'Lower Body',
    logic: {
      'tags.specialtyFocus': {
        $in: ['lowerBody'],
      },
    },
    imageUrl: `${fileStorage}/2-Lower+Body.png`,
  },
  {
    name: 'Total Body',
    logic: {
      'tags.specialtyFocus': {
        $in: ['totalBody'],
      },
    },
    imageUrl: `${fileStorage}/Total-Body.jpg`,
  },
  {
    name: 'Abs and Core',
    logic: {
      'tags.specialtyFocus': {
        $in: ['absCore'],
      },
    },
    imageUrl: `${fileStorage}/3-Abs+and+Core.png`,
  },
  {
    name: 'Cardio and HIIT',
    logic: {
      'tags.specialtyFocus': {
        $in: ['cardioHIIT'],
      },
    },
    imageUrl: `${fileStorage}/Cardio-HIIT.jpg`,
  },
  // {
  //   name: 'Total Body Mobility',
  //   logic: { 'tags.specialtyFocus': ['bodyMobility'] }, // TODO: When we add these back, update logic per above
  //   imageUrl: `${fileStorage}/5-Total-Body-Mobility.jpg`,
  // },
  // {
  //   name: 'Flexibility and Stretch Flow',
  //   logic: { 'tags.specialtyFocus': ['flexibilityStretch'] },
  //   imageUrl: `${fileStorage}/6-Flexibility+and+Stretch+Flow.png`,
  // },
  // {
  //   name: 'Yoga',
  //   logic: { 'tags.specialtyFocus': ['yoga'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
  // {
  //   name: 'Pilates',
  //   logic: { 'tags.specialtyFocus': ['pilates'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
  // {
  //   name: 'Treadmill',
  //   logic: { 'tags.specialtyFocus': ['treadmill'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
  // {
  //   name: 'Cycling and Spinning',
  //   logic: { 'tags.specialtyFocus': ['cyclingSpinning'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
  // {
  //   name: 'Form and Technique',
  //   logic: { 'tags.specialtyFocus': ['formTechnique'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
  // {
  //   name: 'Plyometrics',
  //   logic: { 'tags.specialtyFocus': ['plyometrics'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
  // {
  //   name: 'Low Impact',
  //   logic: { 'tags.specialtyFocus': ['lowImpact'] },
  //   imageUrl: `${fileStorage}/7-At+Home.jpg`,
  // },
];
