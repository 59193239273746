import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import TrashIcon from '../../icons/TrashIcon';
import IconButton from '@mui/material/IconButton';
import { SyncAlt } from '@mui/icons-material';
import Collapsible from 'react-collapsible';
import CollapseIcon from '../../icons/CollapseIcon';
import TrackProgression from './TrackProgression';
import useToggles from '../../hooks/useToggles';

const PlanCard = ({
  itemId,
  name,
  track,
  remove,
  swap,
  handleRemove,
  handleSwap,
  isDeleted,
  showProgression,
  ...props
}) => {
  const { toggleValues, handleToggle } = useToggles({ [itemId]: false });

  return (
    <CardContainer
      flexDirection="column"
      deleted={isDeleted}
      data-test="track-planCard"
    >
      <TopContainer alignItems="center" justify="space-between">
        <PlanName data-test="track-planName">{name}</PlanName>
        {remove && (
          <FlexContainer>
            <TrashIcon
              data-name={itemId || ''}
              onClick={handleRemove}
              data-test="track-remove"
            />
          </FlexContainer>
        )}
        {swap && (
          <FlexContainer>
            <IconButton
              aria-label="swap"
              onClick={handleSwap}
              data-name={itemId || ''}
              data-test="track-swap"
            >
              <SyncAlt data-name={itemId || ''} />
            </IconButton>
          </FlexContainer>
        )}
      </TopContainer>
      {showProgression && (
        <BottomContainer>
          <Collapsible
            overflowWhenOpen="visible"
            triggerStyle={triggerStyle}
            contentOuterClassName="contentOuter__no_padding"
            open={toggleValues[`${itemId}`]}
            trigger={
              <CollapseIcon
                small
                onClick={handleToggle()}
                open={toggleValues[`${itemId}`]}
                name={itemId}
                data-test={`collapsible-trigger`}
              />
            }
          >
            <Table>
              <tbody>
                <TrackProgression
                  label="Back Track"
                  track={track?.backTrack}
                  trackDirection="backTrack"
                  small
                />
                <TrackProgression
                  label="Remain Track"
                  track={track?.remainTrack}
                  trackDirection="remainTrack"
                  small
                />
                <TrackProgression
                  label="Next Track"
                  track={track?.nextTrack}
                  trackDirection="nextTrack"
                  small
                />
                <TrackProgression
                  label="Next Level"
                  track={track?.nextLevel}
                  trackDirection="nextLevel"
                  small
                />
              </tbody>
            </Table>
          </Collapsible>
        </BottomContainer>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  border: 1px solid ${(props) => (props.deleted ? 'red' : colors.primary200)};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 25px;
  width: 500px;
  padding: 30px;
  position: relative;
`;

const TopContainer = styled(FlexContainer)``;
const BottomContainer = styled(FlexContainer)``;

const PlanName = styled.h3`
  color: ${colors.primary700};
`;

const Table = styled.table`
  margin-top: 36px;
`;

const triggerStyle = {
  position: 'absolute',
  top: '80px',
  right: '50%',
};

export default PlanCard;
