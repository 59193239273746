import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { useSelector, useDispatch } from 'react-redux';
import { trackEvent } from '../../integrations/analytics';

import LogIcon from '../../icons/LogIcon';
import { setMealPlanRecipeProperty } from '../../store/mealplan/selectedMealPlanSlice';
import { useNavigate } from 'react-router-dom';
import { formatCalories } from '../../helpers/nutrition';
import { getImageURL } from '../../services/api/api';
import { isMobile } from '../../helpers/utils';

const RecipeCardLarge = ({ recipe }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.selectedMealPlan);

  async function toggleEaten(event) {
    event.preventDefault();
    event.stopPropagation();
    trackEvent(`Logged Recipe (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`, {
      recipe: recipe,
      eaten: !recipe.eaten,
    });
    dispatch(
      setMealPlanRecipeProperty({
        mealPlan,
        recipe,
        property: 'eaten',
        value: !recipe.eaten,
      }),
    );
  }

  const link = `/app/mealplan/${mealPlan._id}/recipe/${recipe._id}`;

  const handleLink = () => {
    trackEvent('Clicked Recipe or Food Name (Meal Plan - Web)', {
      recipe: recipe._id,
    });
    navigate(link);
  };

  const recipeImageURL = (ratio = 1) => {
    const width = 112 * ratio;
    return getImageURL(recipe.recipe.imageUrl, `width:${width}`);
  };

  // Necessary because leftover banner is positioned absolute and will overlap other chips otherwise
  const height = recipe?.leftover ? '102px' : '80px';

  return (
    <FlexContainer
      onClick={handleLink}
      style={{ height: height, width: '100%' }}
      alignItems="flex-end"
      data-test="meal-card"
    >
      <CardContainer
        alignItems="center"
        justify="space-between"
        leftover={recipe.leftover}
        key={recipe._id}
      >
        <LeftContainer alignItems="center" data-test="meal-card-left">
          <ImageThumb image={recipeImageURL()} image2x={recipeImageURL(2)} />
          <RecipeTitleContainer wrap="wrap">
            <RecipeTitle
              $nameLength={recipe.recipe.name.length}
              data-test="meal-card-name"
            >
              {recipe.recipe.name}
            </RecipeTitle>
          </RecipeTitleContainer>
          <RecipeNutritionContainer flexDirection="column">
            <RecipeNutrition data-test="meal-card-calories">
              {formatCalories(recipe.recipe?.nutrients?.calories)} Cal
            </RecipeNutrition>
          </RecipeNutritionContainer>
        </LeftContainer>
        <RightContainer alignItems="center">
          <LogIconContainer>
            <LogIcon
              hideLabel
              logged={recipe.eaten}
              toggleSelect={toggleEaten}
              data-test="meal-card-log"
            />
          </LogIconContainer>
        </RightContainer>
        {recipe.leftover && (
          <LabelContainer
            justify="center"
            alignItems="center"
            data-test="leftover-banner"
          >
            <LeftoverLabel>Leftovers</LeftoverLabel>
          </LabelContainer>
        )}
      </CardContainer>
    </FlexContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  border: ${(props) =>
    props.leftover
      ? `1px solid ${colors.secondary600}`
      : `1px solid ${colors.primary200}`};
  border-radius: ${(props) => (props.leftover ? '4x 0 4px 4px' : '4px')};
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 80px;
  width: 550px;
  position: relative;
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
  width: 85%;
`;
const RightContainer = styled(FlexContainer)`
  height: 100%;
`;

const LogIconContainer = styled(FlexContainer)`
  padding: 0 24px 0 16px;
`;

const ImageThumb = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-image: ${(props) =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${(props) =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  height: 100%;
  width: 112px; // Update image dimensions accordingly.
  min-width: 112px;
  background-size: cover;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const RecipeTitleContainer = styled(FlexContainer)`
  margin-left: 32px;
  width: 50%;
`;

const RecipeTitle = styled.h3`
  color: ${colors.primary700};
  font-size: ${(props) => (props.$nameLength > 35 ? '16px' : '19px')};
  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const RecipeNutritionContainer = styled(FlexContainer)`
  margin-left: 16px;
`;

const RecipeNutrition = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary500};
`;

const LabelContainer = styled(FlexContainer)`
  position: absolute;
  top: -22px;
  right: -1px;
  border-radius: 4px 4px 0px 0px;
  width: 88px;
  height: 22px;
  background-color: ${colors.secondary600};
`;

const LeftoverLabel = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: #fff;
`;

export default RecipeCardLarge;
