import styled from 'styled-components';
import { colors, screenSize } from '../styleConstants';

export const StepNumber = styled.h3`
  color: ${colors.primary300};
  border: 2px solid ${colors.primary300};
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 81px 0 23px 0;
  text-align: center;
  line-height: 30px;

  @media (max-width: ${screenSize.mobileL}) {
    align-self: center;
    margin: 40px 0 20px 0;
  }
`;

export const StepTitle = styled.h2`
  color: ${colors.primary800};
  margin-bottom: 40px;
  max-width: 600px;
  text-align: center;

  @media (max-width: ${screenSize.mobileL}) {
    text-align: center;
    width: 90%;
  }
`;

export const StepSVG = styled.div`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  height: 96px;
  width: 128px;
  margin-bottom: 20px;

  @media (max-width: ${screenSize.mobileL}) {
    margin-bottom: 0px;
  }
`;
