import React from 'react';
import styled from 'styled-components';
import { isMobile } from '../helpers/utils';
import { camelCase } from 'lodash';
import { colors, screenSize } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import DetailCard from '../profile/DetailCard';
import { StepNumber, StepTitle } from './StepComponents';

const StepFive = ({ values, handleValueChanged }) => {
  const activityLevel = {
    Novice: 'I never or rarely workout.',
    Competent:
      'I workout on a regular basis and train with weights using easy simple movements.',
    Proficient:
      'I workout and train with weights on a regular basis and feel comfortable with a variety of movements.',
    Expert:
      'I workout and train with weights on a frequent basis with a variety of movements.',
  };

  const activityLevelCards = Object.entries(activityLevel).map(
    ([activeness, label]) => (
      <DetailCard
        key={activeness}
        imgSrc={`${process.env.PUBLIC_URL}/icons/ActivityLevel/${activeness}.png`}
        title={activeness}
        isSelected={values.detailPreferences.activityLevel === activeness}
        name="detailPreferences.activityLevel"
        handleSelect={() =>
          handleValueChanged(
            'detailPreferences.activityLevel',
            activeness,
            'string',
          )
        }
        extraInfo={label}
        size={!isMobile() && 'large'}
        data-test={`questionnaire-activityLevel-${camelCase(activeness)}`}
      />
    ),
  );

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justify="space-between"
    >
      {!isMobile() && <StepNumber>5</StepNumber>}
      <StepTitle>What is your workout experience?</StepTitle>
      <SubTitle>
        (Please select the option that best fits your typical exercise habits
        and lifestyle.)
      </SubTitle>
      <Container wrap="wrap">{activityLevelCards}</Container>
    </FlexContainer>
  );
};

const SubTitle = styled.span`
  font-size: 15px;
  color: ${colors.primary600};
  margin-bottom: 40px;
  margin-top: -20px;

  @media (max-width: ${screenSize.mobileL}) {
    text-align: center;
    width: 80%;
  }
`;

const Container = styled(FlexContainer)`
  width: 700px;
  gap: 15px;

  @media (max-width: ${screenSize.mobileL}) {
    width: unset;
    align-items: center;
    justify-content: center;
  }
`;

export default StepFive;
