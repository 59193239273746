import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import LinearProgress from '@mui/material/LinearProgress';
import { isMobile } from '../helpers/utils';
import { colors } from '../styleConstants';

const FitnessStats = ({ caloriesBurned, workoutGoal, steps, stepsGoal }) => {
  if (!isMobile()) {
    return (
      <>
        <TopContainer flexDirection="column">
          <Header>Total Calories Burned</Header>
          <StatsContainer alignItems="center" justify="space-between">
            <Stat data-test="fitnessStats-caloriesBurned">
              {caloriesBurned || 0} calories
            </Stat>
            <Progress
              value={(caloriesBurned / workoutGoal) * 100}
              $over={caloriesBurned / workoutGoal >= 1}
              variant="determinate"
              data-test="fitnessStats-progressBar"
            />
          </StatsContainer>
          <Goal data-test="fitnessStats-workoutGoal">
            Workout Goal: {workoutGoal.toLocaleString() || 0} calories
          </Goal>
        </TopContainer>
        {/* TODO: Post-Beta feature
        https://app.clickup.com/t/8ethkc
      <BottomContainer flexDirection="column">
        <Header>Total Daily Steps</Header>
        <StatsContainer alignItems="center" justify="space-between">
          <Stat>{steps.toLocaleString() || 0} steps</Stat>
          <ProgressBar
            value={steps / stepsGoal}
            max="1"
            over={steps / stepsGoal >= 1}
          />
        </StatsContainer>
        <Goal>Daily Steps Goal: {stepsGoal.toLocaleString()}</Goal>
      </BottomContainer> */}
      </>
    );
  } else {
    return (
      <MobileStateContainter justify="center" alignItems="center">
        <Label>Daily Fitness Total:</Label>
        <MobleStat data-test="mobile-fitnessStats-caloriesBurned">
          {caloriesBurned || 0} calories
        </MobleStat>
      </MobileStateContainter>
    );
  }
};

const MobileStateContainter = styled(FlexContainer)`
  margin-top: 24px;
  padding-bottom: 104px;
`;
const MobleStat = styled.h6`
  color: ${colors.secondary500};
  font-weight: bold;
  margin-left: 5px;
`;
const Label = styled.h6`
  color: ${colors.primary700};
`;

const TopContainer = styled(FlexContainer)`
  /* TODO: Post-Beta feature
  border-bottom: 0.5px solid ${colors.primary400}; 
  padding-bottom: 32px; */
`;

// TODO: Post-Beta feature
// const BottomContainer = styled(FlexContainer)`
//   padding-top: 24px;
// `;

const Header = styled.h6`
  font-weight: 400;
  font-size: 13px;
  color: ${colors.hliteone500};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const StatsContainer = styled(FlexContainer)`
  margin-bottom: 8px;
  max-width: 240px;
`;

const Stat = styled.h2`
  color: ${colors.primary700};
`;
const Goal = styled.p`
  color: ${colors.primary500};
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
`;

const Progress = styled(LinearProgress)`
  width: 64px;
  height: 6px;
  border: none;
  border-radius: 4px;
  background-color: ${colors.primary100};

  & .MuiLinearProgress-bar {
    background-color: ${(props) =>
      props.$over ? 'green' : colors.hliteone400};
    border-radius: 4px;
  }
`;

export default FitnessStats;
