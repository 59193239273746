import React, { useRef } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

import ExerciseCard from './ExerciseCard';
import ActivityCard from './ActivityCard';
import WorkoutCard from './WorkoutCard';
import FitnessPlanCard from './FitnessPlanCard';
import TrackCard from './TrackCard';

const CardsRow = ({
  title,
  type,
  items,
  wrap,
  setScreen,
  setItem,
  slider,
  isMobile,
  hasMore,
  ...props
}) => {
  const sliderRef = useRef(null);
  if (!items.length) {
    return null;
  }

  const handleClick = (e) => {
    setItem(items[e.target.dataset.idx]);
    setScreen(`${type} Page`);
  };

  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };

  const sliderNext = () => {
    sliderRef.current.slickNext();
  };

  const itemCards = items.map((item, i) => (
    <CardContainer key={i} wrap={wrap} isMobile={isMobile}>
      {type === 'Exercise' ? (
        <ExerciseCard
          setExercise={setItem}
          setScreen={setScreen}
          exercise={item}
          onClick={handleClick}
          isMobile={isMobile}
          {...props}
        />
      ) : type === 'Activity' ? (
        <ActivityCard activity={item} isMobile={isMobile} {...props} />
      ) : type === 'Workout' ? (
        <WorkoutCard
          workout={item}
          setWorkout={setItem}
          setScreen={setScreen}
          onClick={handleClick}
          isMobile={isMobile}
          {...props}
        />
      ) : type === 'Fitness Plan' ? (
        <FitnessPlanCard
          setFitnessPlan={setItem}
          setScreen={setScreen}
          fitnessPlan={item}
          onClick={handleClick}
          isMobile={isMobile}
          {...props}
        />
      ) : type === 'Track' ? (
        <TrackCard track={item} {...props} />
      ) : null}
    </CardContainer>
  ));

  const loader = (
    <Loader key={0} justify="center">
      <CircularProgress size={24} color="inherit" />
    </Loader>
  );

  const sliderSettings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipe: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Container flexDirection="column" wrap={wrap} flexShrink="0">
      {title && <SectionTitle>{title}</SectionTitle>}
      {!slider && props.loadMoreItems && (
        <InfiniteScroll
          loadMore={props.loadMoreItems}
          hasMore={hasMore}
          loader={loader}
          useWindow={false}
          getScrollParent={() => props.scrollParent.current}
          initialLoad={false}
        >
          <RowContainer
            alignItems={!isMobile && 'center'}
            wrap={wrap || 'no-wrap'}
            alignContent={!isMobile && 'space-between'}
            flexDirection={isMobile && 'column'}
            isMobile={isMobile}
            data-test={`fitnessModal-${title}-cardRow`}
          >
            {itemCards}
          </RowContainer>
        </InfiniteScroll>
      )}
      {!slider && !props.loadMoreItems && (
        <RowContainer
          alignItems={!isMobile && 'center'}
          wrap={wrap || 'no-wrap'}
          alignContent={!isMobile && 'space-between'}
          flexDirection={isMobile && 'column'}
          isMobile={isMobile}
          data-test={`fitnessModal-${title}-cardRow`}
        >
          {itemCards}
        </RowContainer>
      )}
      {slider && (
        <SliderContainer
          flexDirection="row"
          alignItems="center"
          data-test={`fitnessModal-${title}-cardRow`}
        >
          <LeftButtonContainer>
            <IconButton onClick={sliderPrev}>
              <NavigateBeforeIcon />
            </IconButton>
          </LeftButtonContainer>
          <StyledSlider ref={sliderRef} {...sliderSettings}>
            {itemCards}
          </StyledSlider>
          <RightButtonContainer>
            <IconButton onClick={sliderNext}>
              <NavigateNextIcon />
            </IconButton>
          </RightButtonContainer>
        </SliderContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  > :first-child {
    margin-bottom: 24px;
  }
`;

const Loader = styled(FlexContainer)`
  color: ${colors.secondary500};
`;

const RowContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-right: ${(props) => !props.isMobile && '16px'};
  }

  overflow-x: auto;
`;

const SliderContainer = styled(FlexContainer)`
  position: relative;
  justify-content: flex-start;
`;

const LeftButtonContainer = styled(FlexContainer)`
  position: absolute;
  left: -50px;
`;

const RightButtonContainer = styled(FlexContainer)`
  position: absolute;
  right: 50px;
`;

const CardContainer = styled(FlexContainer)`
  margin-bottom: ${(props) =>
    props.isMobile ? '15px' : props.wrap ? '24px' : '0'};
  display: flex !important; /* necessary due to slider css */
`;

const SectionTitle = styled.h6`
  font-weight: 400;
  color: ${colors.secondary500};
`;

const StyledSlider = styled(Slider)`
  width: 90%;
`;

export default CardsRow;
