import { apiCall } from './api';
import { DateTime } from 'luxon';

const defaultStartDate = DateTime.now().startOf('week').toFormat('yyyy-MM-dd');

const defaultEndDate = DateTime.now()
  .plus({ weeks: 1 })
  .endOf('week')
  .toFormat('yyyy-MM-dd');

export function getShoppingLists(params = {}) {
  params.startDate = params.startDate
    ? params.startDate.toFormat('yyyy-MM-dd')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.toFormat('yyyy-MM-dd')
    : defaultEndDate;
  return apiCall('get', `/shoppingList`, params);
}

export function addShoppingList(params = {}) {
  return apiCall('post', `/shoppingList`, params);
}
