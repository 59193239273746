import React from 'react';
import styled from 'styled-components';
import { colors, screenSize } from '../styleConstants';
import { camelCase } from 'lodash';
import FlexContainer from '../elements/FlexContainer';
import CustomSlider from '../elements/Slider';
import DateInput from '../elements/DateInput';
import MobileDateInput from '../elements/MobileDateInput';
import DetailCard from '../profile/DetailCard';
import { isMobile } from '../helpers/utils';
import { StepNumber, StepTitle } from './StepComponents';

const StepTwo = ({ values, handleChange, handleValueChanged }) => {
  const genderOptions = ['Male', 'Female', 'Other'];
  const defaultDate = new Date('1980-01-02');

  const genderCards = genderOptions.map((gender) => (
    <DetailCard
      key={gender}
      imgSrc={`${process.env.PUBLIC_URL}/icons/Gender/${gender}.png`}
      title={gender}
      isSelected={values.gender === gender}
      name="gender"
      extraInfo={
        gender === 'Other'
          ? "Other nonbinary gender identity that doesn't fit into existing gender schemas or constructs."
          : ''
      }
      handleSelect={() => handleValueChanged('gender', gender, 'string')}
      data-test={`questionnaire-gender-${camelCase(gender)}`}
    />
  ));

  return (
    <InputsContainer
      flexDirection="column"
      alignItems="center"
      justify="space-between"
    >
      {!isMobile() && <StepNumber>2</StepNumber>}
      <StepTitle>
        Tell us a little about yourself so we can develop the right plan for you
      </StepTitle>
      <FormContainer
        flexDirection="column"
        alignItems="center"
        alignContent="center"
        justify="space-between"
      >
        <RowContainer wrap="wrap">
          <Container>
            <Title>Select Your Gender</Title>
            <CardsContainer wrap="wrap">{genderCards}</CardsContainer>
          </Container>

          <Container>
            <Title>Date of Birth</Title>
            {isMobile() ? (
              <MobileDateInput
                name="birthDate"
                handleValueChanged={handleValueChanged}
                value={values.birthDate || defaultDate}
                data-test="questionnaire-birthDate"
              />
            ) : (
              <DateInput
                name="birthDate"
                width="200px"
                value={values.birthDate || defaultDate}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                showMonthYearDropdown
                data-test="questionnaire-birthDate"
              />
            )}
          </Container>
        </RowContainer>
        <RowContainer wrap="wrap">
          <BoxContainer flexDirection="column">
            <Title>Select your current weight</Title>
            {/* <PillContainer> // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Kg
            </Pill>

            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              lbs
            </Pill>
          </PillContainer> */}
            <CustomSlider
              unit="lbs"
              name="weight"
              min={30}
              max={400}
              value={values.weight}
              handleValueChanged={handleValueChanged}
              data-test="questionnaire-currentWeight"
            />
          </BoxContainer>
          <BoxContainer flexDirection="column">
            <Title>What is your height?</Title>
            {/* <PillContainer> // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Meters
            </Pill>

            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              Feet
            </Pill>
          </PillContainer> */}
            <CustomSlider
              name="height.feet"
              unit="ft"
              unitColor="grey"
              value={values.height.feet}
              min={3}
              max={8}
              handleValueChanged={handleValueChanged}
              data-test="questionnaire-heightFeet"
            />
            <CustomSlider
              name="height.inches"
              unit="in"
              unitColor="grey"
              value={values.height.inches}
              min={0}
              max={11}
              handleValueChanged={handleValueChanged}
              data-test="questionnaire-heightInches"
            />
          </BoxContainer>
        </RowContainer>
      </FormContainer>
    </InputsContainer>
  );
};

const InputsContainer = styled(FlexContainer)`
  @media (max-width: ${screenSize.mobileL}) {
    padding: 0 20px;
  }
`;

const FormContainer = styled(FlexContainer)`
  gap: 24px;
  @media (max-width: ${screenSize.mobileL}) {
    align-items: flex-start;
  }
`;

const RowContainer = styled(FlexContainer)`
  width: ${(props) => (isMobile() ? '100%' : '750px')};
  gap: 24px;
  flex-direction: ${(props) => (isMobile() ? 'column' : 'row')};
`;

const Title = styled.h4`
  color: ${colors.primary800};
  margin-bottom: 16px;
  font-weight: 700;
`;

const Container = styled(FlexContainer)`
  flex-direction: column;

  @media (max-width: ${screenSize.mobileL}) {
    width: unset;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CardsContainer = styled(FlexContainer)`
  gap: 12px;

  @media (max-width: ${screenSize.mobileL}) {
    width: unset;
    align-items: center;
    justify-content: center;
  }
`;

const BoxContainer = styled(FlexContainer)`
  padding: 16px;
  background: ${colors.primary050};
  border-radius: 6px;
  width: ${(props) => !isMobile() && '316px'};
`;

export default StepTwo;
