import React, { useState } from 'react';

import { cloneDeep, keys, pickBy } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';
import { createFood } from '../../services/api/food';

import FoodForm from './FoodForm';

const AddFoodAdmin = (props) => {
  const initialToggleValues = {
    categories: {},
  };

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);

  const initialValues = {
    name: '',
    verboseName: '',
    type: '',
    variety: '',
    amount: 1,
    measureUnit: '',
    gramWeight: 0,
    calories: 0,
    proteins: 0,
    carbohydrates: 0,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    fat: 0,
    saturatedFat: 0,
    transFat: 0,
    cholesterol_mg: 0,
    vitaminD_mcg: 0,
    calcium_mg: 0,
    potassium_mg: 0,
    sodium_mg: 0,
    kind: 'Admin',
  };

  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const submitData = () => {
    let payload = cloneDeep(values);
    payload.categories = keys(pickBy(toggleValues.categories, Boolean));

    setLoading(true);

    createFood(payload)
      .then((result) => {
        setStatus('success');
        setMessage(
          `Custom Food "${result.verboseName}" was created successfully!`,
        );
        setValues(initialValues);
        setToggleValues(initialToggleValues);
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <FoodForm
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      toggleValues={toggleValues}
      handleToggle={handleToggle}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      viewOnly={false}
      buttonText="Create Food"
      formType="create"
    />
  );
};

export default AddFoodAdmin;
