import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import Chip from './Chip';
import AddIcon from '../icons/AddIcon';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getFormattedDate } from '../helpers/date';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const Workout = ({
  date,
  workouts,
  activities,
  addWorkout,
  addActivity,
  editable,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const chips = workouts
    .concat(activities)
    .sort((a, b) => a.index - b.index)
    .map((item) => {
      const type = item.workout ? 'workout' : 'activity';
      const subItem = item.workout ? item.workout : item.activity;
      const logged = item.workout ? item.allComplete : item.logged;
      const avatar = item.workout
        ? subItem.imageUrl || `${fileStorage}/WorkoutIcon.png`
        : subItem.imageUrl || 'a';
      return (
        <Draggable
          key={item._id}
          draggableId={`${type}|${item._id}|${item.date}`}
          index={item.index}
          isDragDisabled={!editable}
        >
          {(provided, snapshot) => (
            <ChipContainer
              onClick={() => {
                if (editable) {
                  props.openModal(item);
                }
              }}
              ref={provided.innerRef}
              data-test={
                editable ? 'editable-fitness-chip' : 'disabled-fitness-chip'
              }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Chip
                key={item._id}
                logged={logged}
                label={subItem.name}
                avatar={avatar}
                testId={`chip-fitnessplan-${getFormattedDate(date)}`}
              />
            </ChipContainer>
          )}
        </Draggable>
      );
    });

  return (
    <WorkoutContainer
      flexDirection="column"
      height={props.workoutHeight}
      data-test="planner-fitness"
    >
      <FlexContainer alignItems="center">
        <WorkoutTitle>Workout</WorkoutTitle>
        <IconContainer justify="flex-end">
          <AddIcon
            small="true"
            pink="true"
            hideLabel
            data-date={date}
            onClick={(e) => {
              if (editable) {
                launchModal(e);
              }
            }}
            data-test="planner-add-fitness"
          />
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Add Exercise"
            addWorkout={addWorkout}
            addActivity={addActivity}
          />
        </IconContainer>
      </FlexContainer>
      <Droppable droppableId={date} type="FITNESS">
        {(provided, snapshot) => (
          <ChipsContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            data-test="workout-chip-droppable"
          >
            {chips}
            {provided.placeholder}
          </ChipsContainer>
        )}
      </Droppable>
    </WorkoutContainer>
  );
};

const WorkoutContainer = styled(FlexContainer)`
  padding-bottom: 17px;
  min-height: ${(props) => props.height}px;
`;

const IconContainer = styled(FlexContainer)`
  flex-grow: 1;
  margin-right: 5px;
`;

const WorkoutTitle = styled.p`
  color: ${colors.hlitetwo400};
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 3px;
  margin-left: 7px;
  text-align: left;
`;

const ChipsContainer = styled.div`
  min-height: 5px;
  margin: 0 5px;
  > * {
    margin: 4px 0;
  }
`;

const ChipContainer = styled.div`
  background-color: white;
  border-radius: 200px;
`;

export default Workout;
