import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Dropdown from '../elements/Dropdown';
import Button from '../elements/Button';
import FitnessList from '../admin/fitnessPlan/FitnessList';

import useFormValues from '../hooks/useFormValues';

const FitnessPlanModalView = ({
  fitnessPlan,
  context,
  handleClose,
  addFitnessPlan,
  ...props
}) => {
  const { values, handleDateChanged } = useFormValues({
    dayNum: {
      value: 1,
      label: 'Monday',
    },
  });
  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  const dayOptions = [];
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  for (let i = 0; i < fitnessPlan.planLength; i++) {
    let dayNum = i + 1;
    dayOptions.push({ value: dayNum, label: days[dayNum - 1] });
  }

  useEffect(() => {
    const filteredWorkout = fitnessPlan.workouts.filter((workout) => {
      return workout.dayNum === values.dayNum.value;
    });
    const filteredActivity = fitnessPlan.activities.filter((activity) => {
      return activity.dayNum === values.dayNum.value;
    });
    setFilteredWorkouts(filteredWorkout);
    setFilteredActivities(filteredActivity);
  }, [fitnessPlan.workouts, fitnessPlan.activities, values.dayNum]);

  const handleSubmit = () => {
    const fitnessPlanId = fitnessPlan._id;

    const params = {
      fitnessPlan: fitnessPlanId,
    };

    addFitnessPlan(params);
    handleClose();
  };

  return (
    <Container justify="space-between">
      <LeftColumn flexDirection="column">
        <FitnessPlanName data-test="fitnessPlanModal-name">
          {fitnessPlan.name || 'Fitness Plan Name'}
        </FitnessPlanName>
        <FitnessListContainer flexDirection="column">
          <Dropdown
            width="220px"
            options={dayOptions}
            label="Fitness Plan Day:"
            value={{
              value: values.dayNum.value,
              label: values.dayNum.label,
            }}
            onChange={(value) =>
              handleDateChanged('dayNum', value.value, value.label)
            }
          />
          <FitnessList
            workouts={filteredWorkouts}
            activities={filteredActivities}
            date={values.dayNum.value}
            variant="viewOnly"
          />
        </FitnessListContainer>
      </LeftColumn>
      <RightColumn flexDirection="column" alignItems="flex-end">
        <FitnessPlanInfoContainer flexDirection="column">
          <Info>Plan Length: {fitnessPlan.planLength} days</Info>
          <Info>Level: {fitnessPlan.level}</Info>
          <Info>Type: {fitnessPlan.type}</Info>
          <Info>Primary Location: {fitnessPlan.locationType}</Info>
          <Info>Gender: {fitnessPlan.gender}</Info>
          <Info>Date Created: {fitnessPlan.dateCreated || 'N/A'}</Info>
          <Info>Last Modified: {fitnessPlan.dateModified || 'N/A'}</Info>
        </FitnessPlanInfoContainer>
      </RightColumn>
      <AddButtonContainer justify="center" alignItems="center">
        <Button
          buttonSize="large"
          width="300px"
          buttonText="Add Fitness Plan"
          pink="true"
          onClick={handleSubmit}
          data-test="fitnessPlanModal-submit"
        />
      </AddButtonContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 130%;
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 75%;
`;

const RightColumn = styled(FlexContainer)`
  padding-top: 15%;
  flex-basis: 25%;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const FitnessListContainer = styled(FlexContainer)`
  max-width: 90%;

  > * {
    margin-bottom: 10px;
  }
`;

const FitnessPlanInfoContainer = styled(FlexContainer)`
  margin: 16px 0;
`;

const FitnessPlanName = styled.h1`
  color: ${colors.primary800};
  margin: 16px 0 32px 0;
`;

const Info = styled.p`
  margin-bottom: 6px;
  color: ${colors.secondary600};
`;

export default FitnessPlanModalView;
