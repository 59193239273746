import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import CardsRow from '../../mealModals/CardsRow';
import StaticSearchBar from '../../elements/StaticSearchBar';
import {
  searchExploreBanners,
  getExploreBanners as getAllExploreBanners,
} from '../../services/api/campaigns';

const CampaignSearch = ({
  setScreen,
  setExploreBanner,
  context,
  setSearchTerm,
  searchTerm,
  ...props
}) => {
  const [exploreBanners, setExploreBanners] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasMore, setHasMore] = useState(false);

  const pageSize = 10;

  const getExploreBanners = (page) => {
    let searchFn;
    let params = {
      limit: pageSize,
      skip: pageSize * page,
    };
    if (searchQuery === '') {
      searchFn = getAllExploreBanners;
    } else {
      params.query = searchQuery;
      searchFn = searchExploreBanners;
    }
    searchFn(params)
      .then((results) => {
        if (results.length === 0 || results.length < pageSize) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        if (page === 0) {
          setExploreBanners(results);
        } else {
          setExploreBanners(exploreBanners.concat(results));
        }
      })
      .catch((err) => {
        console.error(err);
        setExploreBanners([]);
      });
  };

  const handleSearch = (inputValue) => {
    setSearchQuery(inputValue);
    setSearchTerm(inputValue);
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchQuery(searchTerm);
    }

    // Reset search results:
    setExploreBanners([]);
    // Run search
    getExploreBanners(0);
  }, [searchQuery]);

  return (
    <Container flexDirection="column">
      <SearchArea flexDirection="column">
        <Title>Search Campaigns</Title>
        <StaticSearchBar
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data-test="campaign-search"
        />
      </SearchArea>
      {exploreBanners.length > 0 && (
        <RecommendedContainer flexDirection="column">
          <Header>Results</Header>
          <RowsContainer wrap="wrap">
            <CampaignRows flexDirection="column">
              <CardsRow
                title="Explore Banners"
                items={exploreBanners}
                setScreen={setScreen}
                setItem={setExploreBanner}
                type="Explore Banner"
                wrap="wrap"
                pageSize={pageSize}
                loadMoreItems={getExploreBanners}
                scrollParent={props.scrollParent}
                context={context}
                hasMore={hasMore}
                {...props}
              />
            </CampaignRows>
          </RowsContainer>
        </RecommendedContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)``;

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 16px;
`;

const SearchArea = styled(FlexContainer)`
  width: fit-content;
  margin-bottom: 64px;
`;

const RecommendedContainer = styled(FlexContainer)``;
const RowsContainer = styled(FlexContainer)`
  margin-top: 24px;
`;

const CampaignRows = styled(FlexContainer)`
  margin-right: 64px;
  > * {
    margin-bottom: 24px;
  }
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

export default CampaignSearch;
