import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';
import ModalNav from '../mealModals/ModalNav';
import MobileDialogHeader from './mobile/MobileDialogHeader';

// Meal (Food and Recipe) Content
import AddMeal from '../mealModals/AddMeal';
import { default as MealSearch } from '../mealModals/Search';
import MealPlanSearch from '../mealModals/MealPlanSearch';
import { default as MealDuplicate } from '../mealModals/Duplicate';
import CustomFood from '../mealModals/CustomFood';
import CustomRecipe from '../mealModals/CustomRecipe';
import { default as MyRecipeFavorites } from '../mealModals/MyFavorites';
import RecipeModalView from '../mealModals/RecipeModalView';
import FoodModalView from '../mealModals/FoodModalView';
import MealPlanModalView from '../mealModals/MealPlanModalView';
import GenericAddModal from './web/GenericAddModal';
import CampaignSearch from './campaigns/CampaignSearch';
import ExploreModal from '../explore/ExploreModal';

// Fitness Content
import AddExercise from '../fitnessModals/AddExercise';
import ExerciseModalView from '../fitnessModals/ExerciseModalView';
import WorkoutModalView from '../fitnessModals/WorkoutModalView';
import FitnessPlanModalView from '../fitnessModals/FitnessPlanModalView';
import { default as ExerciseSearch } from '../fitnessModals/Search';
// import { default as ExerciseDuplicate } from '../fitnessModals/Duplicate';
// import { default as MyExerciseFavorites } from '../fitnessModals/MyFavorites';
import { useSelector } from 'react-redux';

const ModalContent = ({
  handleClose,
  data,
  handleBackBtnClick,
  screen,
  setScreen,
  ...props
}) => {
  const [recipeToReturn, setRecipeToReturn] = useState(
    props?.context?.initialRecipe ?? {},
  );
  const [foodToReturn, setFoodToReturn] = useState(
    props?.context?.initialFood ?? {},
  );
  const [mealPlanToReturn, setMealPlanToReturn] = useState({});
  const [exerciseToReturn, setExerciseToReturn] = useState({});
  const [workoutToReturn, setWorkoutToReturn] = useState(
    props?.context?.initialWorkout ?? {},
  );
  const [fitnessPlanToReturn, setFitnessPlanToReturn] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTypeSelected, setSearchTypeSelected] = useState('');

  const scrollContainerRef = useRef(null);

  let linksType = 'hide';
  let screenContent;

  const defaultPlaceholder = useSelector((state) => state.defaultPlaceholder);

  const handleBack = () => {
    if (screen === 'Recipe Page' || screen === 'Food Page') {
      setScreen('Meal Search');
    } else if (
      screen === 'Exercise Page' ||
      screen === 'Fitness Plan Page' ||
      screen === 'Workout Page'
    ) {
      setScreen('Exercise Search');
    } else if (screen === 'Meal Plan Page') {
      setScreen('MealPlanSearch');
    } else if (screen === 'Explore Banner Page') {
      setScreen('Campaign Search');
    }
  };

  const handleBackMobile = () => {
    if (
      props.initialScreen === 'Explore Modal' &&
      (screen === 'Workout Page' || screen === 'Recipe Page')
    ) {
      setScreen('Explore Modal');
    } else if (screen === 'Recipe Page' || screen === 'Food Page') {
      setScreen('Meal Search');
    } else if (
      screen === 'Exercise Page' ||
      screen === 'Fitness Plan Page' ||
      screen === 'Workout Page'
    ) {
      setScreen('Exercise Search');
    } else if (
      screen === 'Meal Search' ||
      screen === 'Meal Duplicate' ||
      screen === 'Custom Food' ||
      screen === 'Custom Recipe' ||
      screen === 'My Recipe Favorites'
    ) {
      setScreen('Add Meal Mobile');
    } else if (screen === 'Exercise Search') {
      setScreen('Add Fitness');
    }
  };

  let modalHeaderTitle = '';

  switch (screen) {
    case 'Generic Modal':
      screenContent = <GenericAddModal setScreen={setScreen} />;
      linksType = 'hide';
      break;
    case 'Add Meal':
      screenContent = <AddMeal setScreen={setScreen} />;
      linksType = 'hide';
      break;
    case 'Add Exercise':
      screenContent = <AddExercise setScreen={setScreen} />;
      linksType = 'hide';
      break;
    case 'Meal Search':
      screenContent = (
        <MealSearch
          scrollParent={scrollContainerRef}
          setScreen={setScreen}
          setRecipe={setRecipeToReturn}
          setFood={setFoodToReturn}
          handleClose={handleClose}
          setSearchTerm={setSearchTerm}
          setSearchTypeSelected={setSearchTypeSelected}
          searchTerm={searchTerm}
          searchTypeSelected={searchTypeSelected}
          {...props}
        />
      );
      linksType = 'meal';
      modalHeaderTitle = 'Search';
      break;
    case 'Exercise Search':
      screenContent = (
        <ExerciseSearch
          scrollParent={scrollContainerRef}
          setScreen={setScreen}
          setExercise={setExerciseToReturn}
          setWorkout={setWorkoutToReturn}
          setFitnessPlan={setFitnessPlanToReturn}
          handleClose={handleClose}
          setSearchTerm={setSearchTerm}
          setSearchTypeSelected={setSearchTypeSelected}
          searchTerm={searchTerm}
          searchTypeSelected={searchTypeSelected}
          {...props}
        />
      );
      linksType = 'exercise';
      modalHeaderTitle = 'Search';
      break;
    case 'Meal Duplicate':
      screenContent = (
        <MealDuplicate
          setScreen={setScreen}
          setRecipe={setRecipeToReturn}
          handleClose={handleClose}
          searchTypeSelected={searchTypeSelected}
          setSearchTypeSelected={setSearchTypeSelected}
          {...props}
        />
      );
      linksType = 'meal';
      modalHeaderTitle = 'Duplicate';
      break;
    // case 'Exercise Duplicate':
    //   screenContent = (
    //     <ExerciseDuplicate
    //       setScreen={setScreen}
    //       setExercise={setExerciseToReturn}
    //       handleClose={handleClose}
    //     />
    //   );
    //   linksType = 'exercise';
    //   modalHeaderTitle = 'Duplicate';
    //   break;
    case 'Custom Food':
      screenContent = (
        <CustomFood
          setScreen={setScreen}
          handleClose={handleClose}
          setFood={setFoodToReturn}
          {...props}
        />
      );
      linksType = 'meal';
      modalHeaderTitle = 'Custom Food';
      break;
    case 'Custom Recipe':
      screenContent = screenContent = (
        <CustomRecipe
          setScreen={setScreen}
          setRecipe={setRecipeToReturn}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = 'meal';
      modalHeaderTitle = 'Custom Recipe';
      break;
    case 'My Recipe Favorites':
      screenContent = (
        <MyRecipeFavorites
          scrollParent={scrollContainerRef}
          setScreen={setScreen}
          setRecipe={setRecipeToReturn}
          setFood={setFoodToReturn}
          handleClose={handleClose}
          searchTypeSelected={searchTypeSelected}
          setSearchTypeSelected={setSearchTypeSelected}
          {...props}
        />
      );
      linksType = 'meal';
      modalHeaderTitle = 'My Favorites';
      break;
    case 'Placeholder':
      screenContent = (
        <RecipeModalView
          recipe={defaultPlaceholder}
          setScreen={setScreen}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = 'meal';
      modalHeaderTitle = 'My Favorites';
      break;
    // case 'My Exercise Favorites':
    //   screenContent = (
    //     <MyExerciseFavorites
    //       setScreen={setScreen}
    //       setExercise={setExerciseToReturn}
    //       handleClose={handleClose}
    //     />
    //   );
    //   linksType = 'exercise';
    //   modalHeaderTitle = 'My Favorites';
    //   break;
    case 'Recipe Page':
      screenContent = (
        <RecipeModalView
          recipe={recipeToReturn}
          setScreen={setScreen}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = isMobile() ? 'mobileBack' : 'back';
      modalHeaderTitle = 'Recipe';
      break;
    case 'Food Page':
      screenContent = (
        <FoodModalView
          food={foodToReturn}
          setScreen={setScreen}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = isMobile() ? 'mobileBack' : 'back';
      modalHeaderTitle = 'Food';
      break;
    case 'Meal Plan Page':
      screenContent = (
        <MealPlanModalView
          mealPlan={mealPlanToReturn}
          setScreen={setScreen}
          handleClose={handleClose}
          setSearchTerm={setSearchTerm}
          setSearchTypeSelected={setSearchTypeSelected}
          searchTerm={searchTerm}
          searchTypeSelected={searchTypeSelected}
          {...props}
        />
      );
      linksType = 'back';
      break;
    case 'Fitness Plan Page':
      screenContent = (
        <FitnessPlanModalView
          fitnessPlan={fitnessPlanToReturn}
          setScreen={setScreen}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = 'back';
      break;
    case 'Workout Page':
      screenContent = (
        <WorkoutModalView
          workout={workoutToReturn}
          setScreen={setScreen}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = 'back';
      modalHeaderTitle = 'Workout';
      break;
    case 'Exercise Page':
      screenContent = (
        <ExerciseModalView
          exercise={exerciseToReturn}
          setScreen={setScreen}
          handleClose={handleClose}
          {...props}
        />
      );
      linksType = 'back';
      modalHeaderTitle = 'Exercise';
      break;
    case 'MealPlanSearch':
      screenContent = (
        <MealPlanSearch
          scrollParent={scrollContainerRef}
          setScreen={setScreen}
          setMealPlan={setMealPlanToReturn}
          handleClose={handleClose}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          {...props}
        />
      );
      linksType = 'hide';
      break;
    case 'Campaign Search':
      screenContent = (
        <CampaignSearch
          scrollParent={scrollContainerRef}
          setScreen={setScreen}
          handleClose={handleClose}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          {...props}
        />
      );
      linksType = 'hide';
      break;
    case 'Explore Modal':
      screenContent = (
        <ExploreModal
          open={props.open}
          handleClose={handleClose}
          category={props?.category}
          setScreen={setScreen}
          setRecipe={setRecipeToReturn}
          setWorkout={setWorkoutToReturn}
          scrollParent={scrollContainerRef}
          {...props}
        />
      );
      linksType = 'hide';
      break;
    default:
      screenContent = <AddMeal setScreen={setScreen} />;
      linksType = 'hide';
  }
  if (isMobile()) {
    // mobile version of modal content
    return (
      <BodyContainer ref={scrollContainerRef} isMobile>
        {screen !== 'Explore Modal' &&
          props?.context?.variant !== 'explore' && (
            <MobileDialogHeader
              title={modalHeaderTitle}
              handleBackBtnClick={handleBackMobile}
              context={props.context}
              screen={screen}
            />
          )}
        {screenContent}
      </BodyContainer>
    );
  } else {
    // web version of modal content
    return (
      <Container justify="center" alignItems="center">
        <ModalBody flexDirection="column">
          <ModalNav
            handleClose={handleClose}
            linksType={linksType}
            activePage={screen}
            setScreen={setScreen}
            handleBack={handleBack}
            {...props}
          />
          <BodyContainer ref={scrollContainerRef}>
            {screenContent}
          </BodyContainer>
        </ModalBody>
      </Container>
    );
  }
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
`;
const ModalBody = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: white;
  outline: 0;
`;

const BodyContainer = styled(FlexContainer)`
  margin-bottom: ${(props) => (props.isMobile ? 0 : '65px')};
  padding: ${(props) => (props.isMobile ? 0 : '64px 80px')};
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: ${(props) => (!props.isMobile ? 'calc(85% - 120px)' : 'initial')};
  display: ${(props) => (props.isMobile ? 'flex' : undefined)};
  flex-direction: ${(props) => (props.isMobile ? 'column' : undefined)};
`;

export default ModalContent;
