import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

import MenuCard from '../sharedModals/web/ModalMenuCard';

const AddMeal = ({ setScreen, ...props }) => {
  const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

  const handleClick = (e) => {
    setScreen(e.currentTarget.attributes.name.value);
  };

  return (
    <Container flexDirection="column" justify="center" alignItems="center">
      <Title>Add Meal</Title>
      <Grid wrap="wrap" justify="center" data-test="modal-list">
        <MenuCard
          title="Search"
          name="Meal Search"
          icon={`${fileStorage}/Search.svg`}
          handleClick={handleClick}
        />
        <MenuCard
          title="Duplicate"
          name="Meal Duplicate"
          icon={`${fileStorage}/Duplicate.svg`}
          handleClick={handleClick}
        />
        <MenuCard
          title="Custom Food"
          name="Custom Food"
          icon={`${fileStorage}/Custom-Food.svg`}
          handleClick={handleClick}
        />
        <MenuCard
          title="Custom Recipe"
          name="Custom Recipe"
          icon={`${fileStorage}/New-Recipe.svg`}
          handleClick={handleClick}
        />
        <MenuCard
          title="My Favorites"
          name="My Recipe Favorites"
          icon={`${fileStorage}/My-Favorites.svg`}
          handleClick={handleClick}
        />
        <MenuCard
          title="Placeholder"
          name="Placeholder"
          icon={`${fileStorage}/shootingstar.svg`}
          handleClick={handleClick}
          placeholder={true}
        />
      </Grid>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;

  padding-top: 24px;

  > * {
    margin-bottom: 24px;
  }
`;

const Grid = styled(FlexContainer)`
  max-width: 700px;

  & :nth-child(3n-1) {
    margin: 0 16px;
  }

  > * {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  color: ${colors.primary800};
`;

export default AddMeal;
