import React, {
  useState,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useRef,
} from 'react';
import styled from 'styled-components';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Dialog, Slide } from '@mui/material';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { Outlet, Link, useLocation, useSearchParams } from 'react-router-dom';
import Button from '../elements/Button';
import ArrowIcon from '../icons/ArrowIcon';
import {
  getFormTitle,
  getButtonText,
  getCTADescription,
  getLinkText,
  getLinkTo,
  getDataTest,
  getCTAText,
} from './helpers';

// Temporary until admin feature built
const sliderItems = [
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/onboarding/onboarding1.png`,
    heading: `Unlock your body's formula to long-lasting fat loss`,
    subHeading: `Now you can lose weight and keep it off. <b>With Nutriology Transformation.<b> The all-in-one program that
      promotes lifelong wellbeing.`,
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/onboarding/hero2.png`,
    heading: '30-Day Fat Loss Kickstarter Program',
    subHeading:
      'Focusing on nutrition, movement, and wellness so you can not only lose the fat, but keep it off.',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/onboarding/hero3.png`,
    heading: 'Personalized Nutrition Plan ',
    subHeading:
      'Get weekly meal plans with tasty dietitian-approved recipes, ready in under 30 minutes.',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/onboarding/hero7.png`,
    heading: 'Workout Plans that WORK',
    subHeading:
      'With a progressive mix of strength, cardio, and fun activities designed to maximize your results.',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/onboarding/hero5.png`,
    heading: 'Accountable Coaching',
    subHeading:
      'Stay supported with group and 1-on-1 coaching to keep you on track and achieving your long-term weightloss goals.',
  },
];

export const AuthPageMobile = ({
  values,
  setValues,
  handleChange,
  toggleValues,
  handleToggle,
  ...props
}) => {
  const { pathname, state } = useLocation();
  const resetToken = state?.resetToken;
  const [open, setOpen] = useState(pathname === '/signup' ? false : true);
  const ref = useRef(null);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams(); 

  useEffect(() => {
    // remove params from URL
    setSearchParams(
      (searchParams) => {
        // remove the "referrer" from the queryString
        searchParams.delete('referrer');

        // return the next queryString
        return searchParams;
      },
      { replace: true },
    ); // <-- redirect action
  }, []);

  // If arriving at page from reset email, start with dialog open
  useLayoutEffect(() => {
    if (resetToken) {
      setOpen(true);
    }
  }, [resetToken]);

  const scrollToTop = () => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useLayoutEffect(() => {
    if (open) {
      scrollToTop();
    }
  }, [open]);

  return (
    <Container $isModalOpen={open}>
      <MobileHeadingContainer
        justify="center"
        alignItems="center"
        $isModalOpen={open}
        ref={ref}
      >
        <BackArrowContainer>
          <ArrowIcon color="#fff" rotate="true" medium />
        </BackArrowContainer>
        <NutriologyNameLogo color={open ? '#fff' : colors.secondary600}>
          Nutriology
        </NutriologyNameLogo>
      </MobileHeadingContainer>

      <SliderContainer open={open}>
        <Slider {...sliderSettings}>
          {sliderItems.map((item, i) => {
            let bold = '';
            let subHeading = item.subHeading;
            let subHeading1 = '';
            let subHeading2 = '';
            if (subHeading.includes('<b>')) {
              bold = subHeading.split('<b>')[1];
              subHeading1 = subHeading.split('<b>')[0];
              subHeading2 = subHeading.split('<b>')[2];
            }
            return (
              <TestContainer key={i} style={{ position: 'relative' }}>
                <Image src={item.imgSrc} />
                <TextContainer>
                  <ImageHeading>{item.heading}</ImageHeading>
                  {subHeading.includes('<b>') ? (
                    <>
                      <ImageSubheading>
                        {subHeading1}
                        <span style={{ fontWeight: 700 }}>{bold}</span>
                        {subHeading2}
                      </ImageSubheading>
                    </>
                  ) : (
                    <ImageSubheading>{subHeading}</ImageSubheading>
                  )}
                </TextContainer>
              </TestContainer>
            );
          })}
        </Slider>
      </SliderContainer>
      <MobileCTAContainer>
        <MobileCTASubheading>
          Unlock your body's formula to long-lasting fat loss
        </MobileCTASubheading>
        <StyledButton
          buttonText={getButtonText(pathname, resetToken)}
          buttonSize="large"
          pink="true"
          handleClick={() => {
            setOpen(!open);
          }}
          data-test="auth-launch-modal"
        />
        <MobileLoginCTADescription>
          {getCTADescription(pathname)}

          <StyledLink
            to={getLinkTo(pathname)}
            onClick={() => {
              setOpen(!open);
            }}
            data-test={getDataTest(pathname)}
          >
            {getLinkText(pathname)}
          </StyledLink>
        </MobileLoginCTADescription>
      </MobileCTAContainer>
      <CustomDialog
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        TransitionComponent={Transition}
        data-test="mobile-auth-dialog"
      >
        <FormContainer>
          <Title data-test="auth-form-title">
            {getFormTitle(pathname, resetToken)}
          </Title>
          <Subtitle>{getCTAText(pathname)}</Subtitle>
          <Outlet
            context={[
              values,
              setValues,
              handleChange,
              handleToggle,
              toggleValues,
              resetToken,
            ]}
          />
        </FormContainer>
      </CustomDialog>
    </Container>
  );
};

const TestContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: ${(props) =>
    props.$isModalOpen
      ? colors.secondary600
      : `linear-gradient(180deg, #fbfbfb 0%, #f4ffef 100%)`};
  &:active {
    background: ${(props) =>
      props.$isModalOpen
        ? colors.secondary600
        : `linear-gradient(180deg, #fbfbfb 0%, #f4ffef 100%)`};
  }
  .slick-slide {
    background: ${(props) =>
      props.$isModalOpen
        ? colors.secondary600
        : `linear-gradient(180deg, #fbfbfb 0%, #f4ffef 100%), #16979a`};
  }
  .slick-prev {
    width: auto;
    height: auto;
    z-index: 2;
    position: absolute;
    top: 307px;
    left: -5%;

    &:before {
      display: none;
    }

    @media (max-width: 400px) {
      left: -6%;
    }
  }
  .slick-next {
    width: auto;
    height: auto;
    position: absolute;
    top: 307px;
    right: -5%;
    &:before {
      display: none;
    }
    @media (max-width: 400px) {
      right: -6%;
    }
  }
  .slick-dots {
    bottom: 50px;
    left: 30px;
    text-align: left;
  }
  .slick-dots ::before {
    display: none;
  }
  .slick-dots li {
    background: #fff;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 2px;
  }
  li.slick-active {
    background: ${colors.pie3};
    width: 25px;
  }
`;

const FormContainer = styled.div`
  margin: 35px 16px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const MobileHeadingContainer = styled(FlexContainer)`
  height: 10vh;
  position: relative;
`;

const NutriologyNameLogo = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.color || colors.secondary600};
  font-weight: 700;
  font-size: 26px;
  transition: color 0.8s;
`;

const BackArrowContainer = styled.div`
  position: absolute;
  left: 16px;
`;

const SliderContainer = styled.div`
  margin: 0px 22px;
  visibility: ${(props) => (props.open ? 'hidden' : 'visible')};
  transition: visibility 0.3s ease-in-out 0.1s;
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 10%;
  left: 5%;
  text-align: left;
  margin: 24px 15px;
  padding: 15px;
  border-radius: 12px;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ImageHeading = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  margin-right: 58px;
  margin-bottom: 8px;
  color: ${colors.primary050};
  width: 100%;
`;

const ImageSubheading = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.primary050};
`;

const MobileCTAContainer = styled.div`
  margin: 0 22px;
`;

const MobileCTASubheading = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding: 38px 0 18px 0;
  color: ${colors.primary900};
`;

const StyledButton = styled(Button)`
  height: 51px;
  text-transform: inherit;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
`;

const MobileLoginCTADescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 0;
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  color: ${colors.secondary600};
  margin-left: 8px;
`;

const Title = styled.div`
  font-size: 36px;
  text-align: center;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${colors.primary800};
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${colors.primary600};
  margin-bottom: 40px;
`;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    height: ${(props) => props.height || '90%'};
    max-height: ${(props) => props.maxheight || undefined};
    border: 1px solid ${colors.primary200};
    border-radius: 25px 25px 0 0;
    background: linear-gradient(180deg, #fbfbfb 0%, #f4ffef 100%);
    margin-bottom: ${(props) => (props.small ? '60px' : 0)};
  }

  .MuiBackdrop-root {
    background: none;
  }
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPrevArrowContainer = styled.img`
  transform: rotateY(180deg);
`;

const LeftArrowContainer = styled.span`
  position: absolute;
  top: 165px;
  left: 30%;
  color: #000;
`;

const RightArrowContainer = styled.span`
  position: absolute;
  top: 165px;
  right: 30%;
  color: #000;
`;

const PrevArrow = (
  <div>
    <StyledPrevArrowContainer
      src={`${`${process.env.PUBLIC_URL}/assets/onboarding/onboardingImgArrowBg.png`}`}
      alt="arrow"
    />
    <LeftArrowContainer>
      <ArrowLeftIcon />
    </LeftArrowContainer>
  </div>
);

const NextArrow = (
  <div>
    <img
      src={`${process.env.PUBLIC_URL}/assets/onboarding/onboardingImgArrowBg.png`}
      alt="arrow"
    />
    <RightArrowContainer>
      <ArrowRightIcon />
    </RightArrowContainer>
  </div>
);

const sliderSettings = {
  mobileFirst: true,
  dots: true,
  prevArrow: PrevArrow,
  nextArrow: NextArrow,
};

export default AuthPageMobile;
