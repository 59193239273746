import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { formatDate } from '../helpers/date';
import { useNavigate } from 'react-router-dom';
import useModal from '../hooks/useModal';

import Button from '../elements/Button';
import TextLink from '../elements/TextLink';
import ModalContainer from '../sharedModals/ModalContainer';

import FitnessRow from './FitnessRow';

const TodaysWorkout = ({
  activities,
  workouts,
  fitnessPlan,
  addWorkout,
  addActivity,
  removeWorkout,
  removeActivity,
  totalCaloriesBurned,
  ...props
}) => {
  const today = new Date();
  const navigate = useNavigate();
  const { open, launchModal, closeModal, context } = useModal(false);

  const workoutList = workouts.map((workout) => (
    <FitnessRow
      key={workout._id}
      item={workout.workout}
      userItem={workout}
      removeItem={removeWorkout}
      fitnessPlan={fitnessPlan}
    />
  ));

  const activityList = activities.map((activity) => (
    <FitnessRow
      key={activity._id}
      item={activity.activity}
      userItem={activity}
      removeItem={removeActivity}
      fitnessPlan={fitnessPlan}
    />
  ));

  return (
    <Container flexDirection="column" justify="space-between">
      <TopContainer flexDirection="column">
        <TopRow justify="space-between">
          <WorkoutLabel>Today's Workout</WorkoutLabel>
          <TotalCalories data-test="dashboard-totalCaloriesBurned">
            Total Workout Calories:{' '}
            <span style={{ fontWeight: 500 }}>{totalCaloriesBurned}</span>
          </TotalCalories>
        </TopRow>
        <ListContainer flexDirection="column">
          <ScrollContainer
            flexDirection="column"
            data-test="dashboard-fitnessList"
          >
            {workoutList}
            {activityList}
          </ScrollContainer>
        </ListContainer>
      </TopContainer>
      <BottomContainer alignItems="center" justify="space-between">
        <TextLink
          linkText="View Fitness Plan"
          handleClick={() => navigate('/app/fitnessPlan')}
          data-test="dashboard-view-fitnessPlan"
        />
        <AddButton
          pink="true"
          buttonText="+ Add"
          data-date={formatDate(today)}
          handleClick={launchModal}
          data-test="dashboard-fitness-add"
        />
      </BottomContainer>
      <ModalContainer
        open={open}
        handleClose={closeModal}
        context={context}
        initialScreen="Add Exercise"
        addWorkout={addWorkout}
        addActivity={addActivity}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const TopContainer = styled(FlexContainer)``;
const ListContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${colors.primary200};
`;

// padding/margin hack in order to ensure trash icon isn't clipped
const ScrollContainer = styled(FlexContainer)`
  overflow-y: scroll;
  max-height: 420px;
  width: 100%;
  position: relative;
  padding-right: 40px;
  margin-right: -40px;
`;

const BottomContainer = styled(FlexContainer)`
  width: 95%;
  padding-top: 18px;
`;

const TopRow = styled(FlexContainer)`
  padding-bottom: 22px;
`;

const AddButton = styled(Button)`
  width: 120px;
`;

const TotalCalories = styled.h6`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary500};
  font-weight: 400;
`;

const WorkoutLabel = styled.h6`
  color: ${colors.secondary500};
  text-transform: uppercase;
  font-weight: 400;
  ${'' /* position: absolute; */}
  top: 0px;
  left: 0px;
`;

export default TodaysWorkout;
