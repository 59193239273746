import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AlertStack from '../elements/AlertStack';
import Button from '../elements/Button';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  resendVerificationEmail,
  logout,
} from '../store/general/currentUserSlice';

const VerificationPage = (props) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.currentUser.user.email);

  const handleBack = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (location?.state?.message) {
      setMessage(location.state.message);
    }
  }, []);

  const resendEmail = async () => {
    if (!userEmail) {
      setMessageType('error');
      setMessage(`Please enter your email`);
      setOpen(true);
      return;
    }
    try {
      setLoading(true);
      await dispatch(resendVerificationEmail(userEmail));
      setMessageType('success');
      setMessage('Email has been re-sent successfully.');
      setOpen(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessageType('error');
      setOpen(true);
      if (error.error && error.error.message) {
        setMessage(error.error.message);
      } else {
        setMessage(
          'Something went wrong. Please contact support@nutriology.io',
        );
      }
    }
  };

  return (
    <>
      <Container justify="center" alignItems="center">
        <VerifyBox
          flexDirection="column"
          justify="center"
          alignItems="center"
          data-test="please-verify"
        >
          <OuterCircle>
            <InnerCircle />
          </OuterCircle>
          <TextContainer
            flexDirection="column"
            justify="center"
            alignItems="center"
          >
            <Header>Verify your email address</Header>
            <Text>
              We've sent you a verification email. Please verify your email
              address in order to complete the registration. The link will
              expire in <Span>30 minutes</Span>.
            </Text>
            <Text>
              If you are having difficulty receiving the email or verifying your
              account, please contact us at support@nutriology.io
            </Text>
          </TextContainer>
          <StyledButton
            type="submit"
            buttonText="Click here to resend"
            onClick={resendEmail}
            startIcon={
              loading && <CircularProgress size={24} color="inherit" />
            }
            data-test="resend-verification-email"
          />
          <LinkContainer>
            <StyledLink onClick={handleBack} data-test="back-to-login">
              Back To Login
            </StyledLink>
          </LinkContainer>
        </VerifyBox>
        {message.length > 0 && (
          <AlertStack
            messages={message}
            type={messageType}
            variant="outlined"
            open={open}
            handleClose={() => setOpen(false)}
            data-test={
              messageType === 'error' ? 'error-message' : 'auth-submit-success'
            }
          />
        )}
      </Container>
    </>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100vh;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${process.env.PUBLIC_URL}/SignUpImage.jpg);
    background-size: cover;
    background-position: top;
    filter: blur(5.5px);
    z-index: -1;
  }
`;

const VerifyBox = styled(FlexContainer)`
  width: ${() => (isMobile() ? 'calc(90% - 48px)' : 'calc(560px - 210px)')};
  height: ${() => (isMobile() ? '450px' : '420px')};
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: relative;
  padding: ${() => (isMobile() ? '0 24px' : '0 105px')};
`;

const TextContainer = styled(FlexContainer)`
  gap: 24px;
  padding-bottom: 12px;
`;

const Header = styled.h2`
  font-weight: ${() => (isMobile() ? '700' : '800')};
  font-size: ${() => (isMobile() ? '20px' : '24px')};
  line-height: 32px;
  color: ${colors.primary700};
  text-align: center;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: ${colors.primary600};
  text-align: center;
`;

const Span = styled.span`
  color: ${colors.secondary500};
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  width: ${(props) => (isMobile() ? '90%' : '200px')};
  height: 40px;
  background: ${colors.secondary500};
  border-radius: 4px;
  text-transform: none;
  position: absolute;
  bottom: ${(props) => (isMobile() ? '44px' : '56px')};
  left: ${(props) => (isMobile() ? '5%' : 'calc(50% - 100px)')};
  font-size: 13px;
`;

const OuterCircle = styled(FlexContainer)`
  width: ${(props) => (isMobile() ? '124px' : '140px')};
  height: ${(props) => (isMobile() ? '124px' : '140px')};
  border-radius: 70px;
  background-color: ${colors.secondary100};
  position: absolute;
  top: ${(props) => (isMobile() ? '-62px' : '-70px')};
  left: ${(props) => (isMobile() ? 'calc(50% - 62px)' : '210px')};
`;

const InnerCircle = styled(FlexContainer)`
  width: 104px;
  height: 104px;
  border-radius: 52px;
  background-color: ${colors.secondary500};
  position: absolute;
  top: ${(props) => (isMobile() ? '11px' : '18px')};
  left: ${(props) => (isMobile() ? '11px' : '18px')};
  &:after {
    content: url('/icons/EnvelopeIcon.svg');
    position: absolute;
    top: 6px;
    left: -10px;
  }
`;

const LinkContainer = styled(FlexContainer)`
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: ${(props) => (isMobile() ? '12px' : '18px')};
`;

const StyledLink = styled.p`
  color: ${colors.secondary500};
  font-weight: 600;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export default VerificationPage;
