import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { formatDate } from '../../helpers/date';
import { apiCall } from '../../services/api/api';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { getShoppingLists } from '../shoppinglist/shoppingListSlice';

const initialState = {
  id: null,
  type: 'Weight Loss',
  planLength: 7,
  recommendedCalories: 1800,
  active: true,
  recipes: [],
  foods: [],
  water: [],
};

const blankPlan = {
  startDate: formatDate(DateTime.now().startOf('week')),
  endDate: formatDate(DateTime.now().endOf('week')),
  planLength: 7,
  type: 'Weight Loss',
  recommendedCalories: 2000,
};

const selectedMealplanSlice = createSlice({
  name: 'selectedMealPlan',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        getUserMealPlanByDate.fulfilled,
        getUserMealPlanById.fulfilled,
        addRecipeToMealPlan.fulfilled,
        addFoodToMealPlan.fulfilled,
        addMultipleRecipesToMealPlan.fulfilled,
        updateUserMealPlan.fulfilled,
        swapMealPlanItem.fulfilled,
      ),
      (state, action) => {
        return action.payload;
      },
    );
  },
});

export const getUserMealPlanByDate = createAsyncThunk(
  'mealPlan/getUserMealPlanByDate',
  async ({ userId, date = new Date() }, { rejectWithValue }) => {
    try {
      const params = {
        query: {
          user: userId,
          startDate: formatDate(DateTime.fromJSDate(date).startOf('week')),
          endDate: formatDate(DateTime.fromJSDate(date).endOf('week')),
        },
      };
      let mealPlan = await apiCall('get', `/mealplanuser`, params);
      if (!mealPlan[0]) {
        mealPlan = await apiCall('post', `/mealplanuser`, blankPlan);
      } else {
        mealPlan = mealPlan[0];
      }
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUserMealPlanById = createAsyncThunk(
  'mealPlan/getUserMealPlanById',
  async ({ mealPlanId, params = {} }, { rejectWithValue }) => {
    try {
      const mealPlan = await apiCall(
        'get',
        `/mealplanuser/${mealPlanId}`,
        params,
      );
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addRecipeToMealPlan = createAsyncThunk(
  'mealPlan/addRecipeToMealPlan',
  async ({ mealPlanId, params = {} }, { dispatch, rejectWithValue }) => {
    try {
      const mealPlan = await apiCall(
        'put',
        `/mealplanuser/add_recipe/${mealPlanId}`,
        params,
      );
      dispatch(getShoppingLists({}));
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addMultipleRecipesToMealPlan = createAsyncThunk(
  'mealPlan/addMultipleRecipesToMealPlan',
  async ({ mealPlanId, params = {} }, { dispatch, rejectWithValue }) => {
    try {
      const mealPlan = await apiCall(
        'put',
        `/mealplanuser/add_recipes/${mealPlanId}`,
        {
          recipes: params,
        },
      );
      dispatch(getShoppingLists({}));
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addFoodToMealPlan = createAsyncThunk(
  'mealPlan/addFoodToMealPlan',
  async ({ mealPlanId, params = {} }, { dispatch, rejectWithValue }) => {
    try {
      const mealPlan = await apiCall(
        'put',
        `/mealplanuser/add_food/${mealPlanId}`,
        params,
      );
      dispatch(getShoppingLists({}));
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserMealPlan = createAsyncThunk(
  'mealPlan/updateUserMealPlan',
  async ({ mealPlanId, params = {} }, { dispatch, rejectWithValue }) => {
    try {
      const mealPlan = await apiCall(
        'put',
        `/mealplanuser/${mealPlanId}`,
        params,
      );
      dispatch(getShoppingLists({}));
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const swapMealPlanItem = createAsyncThunk(
  'mealPlan/swapMealPlanItem',
  async (
    { mealPlanId, itemSourceId, itemDestId, quantity, params = {} },
    { dispatch, rejectWithValue },
  ) => {
    try {
      console.info(
        `Swapping mealPlan item ${itemSourceId} for item ${itemDestId}`,
      );
      params.itemSource = itemSourceId;
      params.itemDestination = itemDestId;
      params.quantity = quantity;
      const mealPlan = await apiCall(
        'put',
        `/mealplanuser/swap_item/${mealPlanId}`,
        params,
      );
      dispatch(getShoppingLists({}));
      return mealPlan;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setMealPlanRecipeProperty = createAsyncThunk(
  'mealPlan/setMealPlanRecipeProperty',
  async (
    { mealPlan, recipe, property, value },
    { dispatch, rejectWithValue },
  ) => {
    try {
      console.info(
        `Setting recipe property ${property} to ${value} for recipe: `,
        recipe,
      );

      const updatedMealPlan = cloneDeep(mealPlan);
      // Find the recipe in the mealPlan
      updatedMealPlan.recipes.some((currentRecipe) => {
        if (currentRecipe._id === recipe._id) {
          switch (property) {
            case 'mealType':
              currentRecipe.mealType = value;
              break;
            case 'eaten':
              currentRecipe.eaten = value ? new Date() : null;
              break;
            case 'yield':
              currentRecipe.yield = value;
              break;
            default:
              throw new Error(`Property ${property} not supported.`);
          }
          return true;
        }
        return false;
      });

      dispatch(
        updateUserMealPlan({
          mealPlanId: mealPlan._id,
          params: updatedMealPlan,
        }),
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setMealPlanFoodProperty = createAsyncThunk(
  'mealPlan/setMealPlanFoodProperty',
  async (
    { mealPlan, food, property, value },
    { dispatch, rejectWithValue },
  ) => {
    try {
      console.info(
        `Setting food property ${property} to ${value} for food: `,
        food,
      );
      const updatedMealPlan = cloneDeep(mealPlan);
      // Find the food in the mealPlan
      updatedMealPlan.foods.some((currentFood) => {
        if (currentFood._id === food._id) {
          switch (property) {
            case 'quantity':
              currentFood.quantity = value;
              break;
            case 'mealType':
              currentFood.mealType = value;
              break;
            case 'eaten':
              currentFood.eaten = value ? new Date() : null;
              break;
            default:
              throw new Error(`Property ${property} not supported.`);
          }
          return true;
        }
        return false;
      });

      dispatch(
        updateUserMealPlan({
          mealPlanId: mealPlan._id,
          params: updatedMealPlan,
        }),
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setUserMealPlanAllEaten = createAsyncThunk(
  'mealPlan/setUserMealPlanAllEaten',
  async ({ mealPlan, dateValue }, { dispatch, rejectWithValue }) => {
    try {
      const dateSelected =
        DateTime.fromJSDate(dateValue).toFormat('yyyy-MM-dd');

      const updatedMealPlan = cloneDeep(mealPlan);
      updatedMealPlan.recipes.forEach((recipe) => {
        const recipeDate = DateTime.fromISO(recipe.date).toFormat('yyyy-MM-dd');
        if (dateSelected === recipeDate && !recipe.eaten) {
          recipe.eaten = new Date();
        }
      });
      updatedMealPlan.foods.forEach((food) => {
        const foodDate = DateTime.fromISO(food.date).toFormat('yyyy-MM-dd');
        if (dateSelected === foodDate && !food.eaten) {
          food.eaten = new Date();
        }
      });

      dispatch(
        updateUserMealPlan({
          mealPlanId: mealPlan._id,
          params: updatedMealPlan,
        }),
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePlaceholderRecipe = createAsyncThunk(
  'mealPlan/updatePlaceholderRecipe',
  async (
    { mealPlanId, recipe, updates, userId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (!recipe.recipe.placeholder || recipe.recipe.user !== userId) {
        throw new Error(
          `Only the user's own placeholder recipes can be edited.`,
        );
      }
      const updatedRecipe = cloneDeep(recipe.recipe);
      for (const [key, value] of Object.entries(updates)) {
        console.debug(`${key}: ${value}`);
        if (key === 'name' && value) {
          updatedRecipe[key] = value;
        } else if (value) {
          updatedRecipe.nutrients[key] = value;
        }
      }
      // handle image
      if (updates.image && !updates.imageUrl) {
        const recipeImage = new FormData();
        recipeImage.append('image', updates.image);
        const result = await apiCall('post', '/users/upload', recipeImage);
        updatedRecipe.imageUrl = result.file;
      }

      // update placeholder
      await apiCall('put', `/recipeuser/${recipe.recipe._id}`, updatedRecipe);
      // get meal plan
      dispatch(getUserMealPlanById({ mealPlanId }));
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default selectedMealplanSlice.reducer;
