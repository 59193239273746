import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useOutletContext } from 'react-router-dom';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import Button from '../../elements/Button';
import TextInput from '../../elements/TextInput';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import CheckBox from '../../elements/CheckBox';
import { TermsAndConditions, PrivacyPolicy } from '../Docs';
import useFormSubmit from '../../hooks/useFormSubmit';
import PasswordStrengthMeter from '../../elements/PasswordStrengthMeter';
import { authUser } from '../../store/general/currentUserSlice';
import { useDispatch } from 'react-redux';
import { isMobile } from '../../helpers/utils';

const SignupForm = () => {
  const [values, setValues, handleChange, handleToggle, toggleValues] =
    useOutletContext();
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [tcError, setTcError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const submitData = async () => {
    if (!toggleValues.accepted) {
      setTcError(true);
      return;
    }
    setLoading(true);
    setTcError(false);
    await dispatch(authUser({ type: 'register', userData: values }));
    //   .catch((err) => {
    setLoading(false);
    // setErrorOpen(true);
    // });
  };

  let { handleSubmit, errors } = useFormSubmit(
    submitData,
    values,
    setValues,
    'register',
  );

  const handleIconClick = () => setShowPassword(!showPassword);

  return (
    <Container flexDirection="column">
      <NameContainer>
        <TextInput
          id="firstName"
          name="firstName"
          autoComplete="given-name"
          onChange={handleChange}
          value={values.firstName || ''}
          placeholder="First Name"
          label="First Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'First Name')}
          error={errors.firstName}
          disabled={loading}
          flat="true"
          theme={isMobile() ? 'light' : 'dark'}
          data-test="signup-firstName"
        />
        <TextInput
          id="lastName"
          name="lastName"
          autoComplete="family-name"
          onChange={handleChange}
          value={values.lastName || ''}
          placeholder="Last Name"
          label="Last Name"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Last Name')}
          error={errors.lastName}
          disabled={loading}
          flat="true"
          theme={isMobile() ? 'light' : 'dark'}
          data-test="signup-lastName"
        />
      </NameContainer>
      <TextInput
        id="email"
        name="email"
        autoComplete="username"
        type="email"
        onChange={handleChange}
        value={values.email || ''}
        placeholder="Email"
        label="Email"
        onFocus={(e) => (e.target.placeholder = '')}
        onBlur={(e) => (e.target.placeholder = 'Email')}
        error={errors.email}
        disabled={loading}
        flat="true"
        theme={isMobile() ? 'light' : 'dark'}
        data-test="signup-email"
      />
      <FlexContainer style={{ position: 'relative' }}>
        <PasswordStrengthMeter
          password={values.password || ''}
          small="true"
          style={{
            position: 'absolute',
            top: '5px',
            right: 0,
          }}
        />
        <TextInput
          id="password"
          name="password"
          autoComplete="new-password"
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          value={values.password || ''}
          placeholder="Password"
          label="Password"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Password')}
          error={errors.password}
          disabled={loading}
          flat="true"
          theme={isMobile() ? 'light' : 'dark'}
          data-test="signup-password"
        />
        {showPassword ? (
          <StyledVisibilityOff onClick={handleIconClick} />
        ) : (
          <StyledVisibility onClick={handleIconClick} />
        )}
      </FlexContainer>
      <FlexContainer style={{ position: 'relative' }}>
        <TextInput
          id="password2"
          name="password2"
          autoComplete="new-password"
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          value={values.password2 || ''}
          placeholder="Confirm Password"
          label="Confirm Password"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Confirm Password')}
          error={errors.password}
          disabled={loading}
          flat="true"
          theme={isMobile() ? 'light' : 'dark'}
          data-test="signup-password2"
        />
        {showPassword ? (
          <StyledVisibilityOff onClick={handleIconClick} />
        ) : (
          <StyledVisibility onClick={handleIconClick} />
        )}
      </FlexContainer>
      <FlexContainer style={{ margin: '8px 0' }}>
        <CheckBox
          checked={toggleValues.accepted || ''}
          label=""
          theme={isMobile() ? 'light' : 'dark'}
          onChange={handleToggle()}
          error={tcError}
          name="accepted"
          data-test="accept-tandc"
        />
        <Message $left>
          By creating an account, you agree to the{' '}
          <DocsLink
            onClick={() => {
              setShowTerms(true);
            }}
          >
            Terms and Conditions
          </DocsLink>{' '}
          and{' '}
          <DocsLink onClick={() => setShowPolicy(true)}>
            Privacy Policy
          </DocsLink>
        </Message>
        <Dialog
          open={showTerms}
          maxWidth="lg"
          onClose={() => setShowTerms(false)}
          data-test="terms-and-conditions"
        >
          <DialogTitleContainer justify="space-between">
            <StyledDialogTitle>
              <b>Terms and Conditions</b>
            </StyledDialogTitle>
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={() => setShowTerms(false)}
                data-test="terms-and-conditions-close"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </DialogTitleContainer>
          <DialogContent>
            <TermsAndConditions />
          </DialogContent>
        </Dialog>

        <Dialog
          open={showPolicy}
          maxWidth="lg"
          onClose={() => setShowPolicy(false)}
          data-test="privacy-policy"
        >
          <DialogTitleContainer justify="space-between">
            <StyledDialogTitle>
              <b>Privacy Policy</b>
            </StyledDialogTitle>
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={() => setShowPolicy(false)}
                data-test="privacy-policy-close"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </DialogTitleContainer>
          <DialogContent>
            <PrivacyPolicy />
          </DialogContent>
        </Dialog>
      </FlexContainer>
      {tcError && (
        <TCError>
          Please read and accept the Terms and Conditions before submititng
        </TCError>
      )}
      <StyledButton
        type="submit"
        buttonText="Begin Your Journey"
        buttonSize="large"
        pink="true"
        backgrounddark="true"
        $termsAccepted={toggleValues.accepted}
        disabled={loading}
        onClick={handleSubmit}
        startIcon={
          loading && (
            <CircularProgress
              size={24}
              color="inherit"
              data-test="loading-spinner"
            />
          )
        }
        data-test="auth-submit"
      />
      <FlexContainer style={{ gap: 4 }} justify="center">
        <LinkText>Already have an account?</LinkText>
        <StyledLink to="/login" data-test="link-to-login">
          Log in now
        </StyledLink>
      </FlexContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  gap: 14px;
  width: 100%;
`;

const LinkText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${() => (isMobile() ? colors.primary800 : colors.primary050)};
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-decoration: underline;
  color: ${() => (isMobile() ? colors.secondary600 : colors.primary050)};
`;

const StyledButton = styled(Button)`
  height: ${() => (isMobile() ? '51px' : null)};
  text-transform: ${() => (isMobile() ? 'inherit' : null)};
  font-weight: ${() => (isMobile() ? '700' : null)};
  font-size: ${() => (isMobile() ? '17x' : null)};
  line-height: ${() => (isMobile() ? '20px' : null)};
  ${(props) =>
    !props.$termsAccepted
      ? `background-color: ${colors.hlitetwo200};
    color: #fff;
    cursor: not-allowed;`
      : ``}
`;

const DocsLink = styled.span`
  cursor: pointer;
  font-weight: 700;
  color: ${() => (isMobile() ? colors.secondary600 : colors.primary050)};
`;

const DialogTitleContainer = styled(FlexContainer)`
  border-bottom: 1px solid black;
`;
const StyledDialogTitle = styled(DialogTitle)`
  text-align: center;
  font-weight: bold;
`;

const Message = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${() => (isMobile() ? colors.primary800 : colors.primary050)};
  text-align: ${(props) =>
    props.$left ? 'left' : props.$right ? 'right' : 'center'};

  margin-left: 12px;
`;

const NameContainer = styled(FlexContainer)`
  gap: 16px;
`;

const StyledVisibility = styled(Visibility)`
  position: absolute;
  right: 12px;
  top: 34px;
  color: grey;
`;

const StyledVisibilityOff = styled(VisibilityOff)`
  position: absolute;
  top: 34px;
  right: 12px;
  color: grey;
`;

const TCError = styled.p`
  color: ${colors.hlitetwo300};
  letter-spacing: 0.03em;
  margin-top: -8px;
  padding: 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

export default SignupForm;
