import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import WorkoutForm from './WorkoutForm';
import { getWorkout } from '../../services/api/workout';

const ViewWorkout = (props) => {
  const initialValues = {
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
  };
  const initialWorkoutInfo = {
    intensity: 3,
    duration: 30,
    caloriesBurned: 100,
    week: 1,
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const initialToggleValues = {
    active: false,
    level: {},
    location: {},
    specialtyFocus: {},
  };

  const { toggleValues, handleToggle, setToggleValues } =
    useToggles(initialToggleValues);
  const { open, launchModal, closeModal } = useModal(false);

  const [exercises, setExercises] = useState([]);
  const [workout, setWorkout] = useState({});
  const [workoutInfo, setWorkoutInfo] = useState([{ ...initialWorkoutInfo }]);
  const [imageUrl, setImageUrl] = useState();

  async function loadWorkout(params) {
    try {
      const workoutId = params.workout;
      const result = await getWorkout(workoutId);
      if (result) {
        setWorkout(result);
      } else {
        throw new Error('Workout could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded workout
  useEffect(() => {
    if (!isEmpty(workout)) {
      setValues({
        name: workout.name,
        locationType: workout.locationType,
        gender: workout.gender,
        restBetweenSets: workout.restBetweenSets,
        numberOfWeeks: workout.numberOfWeeks,
        source: workout.source,
        attrLink: workout.attrLink,
        imageUrl: workout.imageUrl,
      });
      const toggleTmp = {
        active: workout.active,
        level: {},
        location: {},
        specialtyFocus: {},
      };

      const tags = workout.tags;
      for (let tagType in tags) {
        tags[tagType].forEach((tagVal) => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setWorkoutInfo(workout.workoutInfo);
      setImageUrl(workout.imageUrl);
      setExercises([...workout.exercises]);
    }
  }, [workout]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Workout"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="workout-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'workout',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addWorkout={loadWorkout}
        {...props}
      />
      <WorkoutForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        exercises={exercises}
        setExercises={setExercises}
        workoutInfo={workoutInfo}
        setWorkoutInfo={setWorkoutInfo}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default ViewWorkout;
