import React from 'react';
import styled from 'styled-components';
import { isMobile } from '../helpers/utils';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import WorkoutSimplified from './workout/WorkoutSimplified';
import { formatDate } from '../helpers/date';

const WorkoutModalView = ({
  workout,
  context,
  handleClose,
  addWorkout,
  ...props
}) => {
  const handleSubmit = () => {
    const params = {
      workout: workout._id,
      date: context?.date ?? formatDate(new Date()),
      week: 1, // In the future, we may allow users to select which week of the workout,
      // but for now we will default to week 1
    };
    addWorkout(params);
    handleClose();
  };
  if (isMobile()) {
    // mobile version
    return (
      <MobileContainer>
        <WorkoutNameMobile data-test="workoutModal-name">
          {workout?.name}
        </WorkoutNameMobile>
        <WorkoutSimplified
          exercises={workout.exercises}
          viewOnly={true}
          variant="admin"
        />
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="300px"
            buttonText="Add Workout"
            pink="true"
            onClick={handleSubmit}
            data-test="workoutModal-submit"
          />
        </AddButtonContainer>
      </MobileContainer>
    );
  } else {
    // web version
    return (
      <Container justify="space-between">
        <LeftColumn flexDirection="column">
          <WorkoutName data-test="workoutModal-name">
            {workout.name || 'Workout Name'}
          </WorkoutName>
          <WorkoutSimplified
            exercises={workout.exercises}
            viewOnly={true}
            variant="admin"
          />
        </LeftColumn>
        <RightColumn flexDirection="column" alignItems="flex-end">
          {context.variant === 'admin' && (
            <WorkoutInfoContainer flexDirection="column">
              <Info data-test="workoutModal-sidebar-locationType">
                Primary Location: {workout.locationType}
              </Info>
              <Info data-test="workoutModal-sidebar-gender">
                Gender: {workout.gender}
              </Info>
              <Info data-test="workoutModal-sidebar-dateCreated">
                Date Created: {workout.dateCreated || 'N/A'}
              </Info>
              <Info data-test="workoutModal-sidebar-dateModified">
                Last Modified: {workout.dateModified || 'N/A'}
              </Info>
            </WorkoutInfoContainer>
          )}
        </RightColumn>
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="300px"
            buttonText="Add Workout"
            pink="true"
            onClick={handleSubmit}
            data-test="workoutModal-submit"
          />
        </AddButtonContainer>
      </Container>
    );
  }
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 130%;
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 75%;
`;

const RightColumn = styled(FlexContainer)`
  padding-top: 15%;
  flex-basis: 25%;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: ${isMobile() ? '64px' : '0%'};
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const WorkoutName = styled.h1`
  color: ${colors.primary800};
  margin: 16px 0 32px 0;
`;

const WorkoutInfoContainer = styled(FlexContainer)`
  margin: 16px 0;
`;

const Info = styled.p`
  margin-bottom: 6px;
  color: ${colors.secondary600};
`;

const MobileContainer = styled.div`
  margin: 20px;
`;
const WorkoutNameMobile = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
`;

export default WorkoutModalView;
