import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';

import ModalContainer from '../../sharedModals/ModalContainer';

import ActivityForm from './ActivityForm';
import { getActivity } from '../../services/api/activity';

const ViewActivity = (props) => {
  const initialValues = {
    intensity: 0,
    duration: 0,
  };
  const { values, setValues, handleChange, handleValueChanged } =
    useFormValues(initialValues);

  const [imageUrl, setImageUrl] = useState();
  const { open, launchModal, closeModal } = useModal(false);
  const [activity, setActivity] = useState({});

  async function loadActivity(params) {
    try {
      const activityId = params.activity;
      const result = await getActivity(activityId);
      if (result) {
        setActivity(result);
      } else {
        throw new Error('Activity could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded activity
  useEffect(() => {
    if (!isEmpty(activity)) {
      setValues({
        name: activity.name,
        intensity: activity.intensity,
        imageUrl: activity.imageUrl,
        duration: activity.duration,
        caloriesBurned: activity.caloriesBurned,
      });

      setImageUrl(activity.imageUrl);
    }
  }, [activity]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Activity"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'exercise',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addActivity={loadActivity}
        {...props}
      />
      <ActivityForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default ViewActivity;
