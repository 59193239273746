import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import BackIcon from '../../icons/BackIcon';
import MobileDialogHeaderText from './MobileDialogHeaderText';
import FlexContainer from '../../elements/FlexContainer';

const MobileDialogHeader = ({
  title,
  rightBtn,
  handleBackBtnClick,
  context,
  screen,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, [title]);

  return (
    <DialogHeader ref={ref} alignItems="center" justify="space-between">
      <FlexContainer justify="flex-start" alignItems="center">
        {(!context?.type ||
          context?.type !== 'shoppinglist' ||
          (context?.type === 'shoppinglist' && screen !== 'Meal Search')) && (
          <StyledBackIcon
            color={colors.primary800}
            handleClick={handleBackBtnClick}
            hideCircle
            data-test="mobileModal-back"
          />
        )}

        <HeaderTextContainer>
          <MobileDialogHeaderText data-test="mobile-modal-title">
            {title}
          </MobileDialogHeaderText>
        </HeaderTextContainer>
      </FlexContainer>
      {rightBtn}
    </DialogHeader>
  );
};

const DialogHeader = styled(FlexContainer)`
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  height: 70px;
  padding: 0 17px;
`;
const HeaderTextContainer = styled.div`
  padding: 19px 0;
`;
const StyledBackIcon = styled(BackIcon)`
  height: 16px;
  width: 16px;
`;
export default MobileDialogHeader;
