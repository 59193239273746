import React, { useState } from 'react';

import { cloneDeep, isEmpty, omit } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import ExploreBannerForm from './ExploreBannerForm';

import { addExploreBanner } from '../../services/api/campaigns';

const AddExploreBanner = (props) => {
  const initialValues = {
    color: 'Grey',
    actionType: {
      value: 'link',
      label: 'External Link',
    },
  };
  const {
    values,
    setValues,
    handleChange,
    handleValueChanged,
    handleDateChanged,
  } = useFormValues(initialValues);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [imageUrl, setImageUrl] = useState();
  const [modalImageUrl, setModalImageUrl] = useState();
  const [itemToAdd, setItemToAdd] = useState({});
  const [itemsToShow, setItemsToShow] = useState([]);

  const getIdAndType = (item) => {
    let itemType = '';
    let itemId = '';
    if (item.hasOwnProperty('recipe')) {
      itemType = 'recipe';
      itemId = item.recipe;
    } else if (item.hasOwnProperty('food')) {
      itemType = 'food';
      itemId = item.food;
    } else if (item.hasOwnProperty('workout')) {
      itemType = 'workout';
      itemId = item.workout;
    } else if (item.hasOwnProperty('activity')) {
      itemType = 'activity';
      itemId = item.activity;
    }
    return { itemId, itemType };
  };

  const handleAddItem = async (item) => {
    const { itemId, itemType } = getIdAndType(item);
    setItemToAdd({ itemId, itemType });
  };

  const handleAddItemsToShow = (item) => {
    const { itemId, itemType } = getIdAndType(item);
    setItemsToShow([...itemsToShow, { itemId, itemType }]);
  };

  const clearAddItem = () => {
    setItemToAdd({});
  };

  const clearItemsToShow = () => {
    setItemsToShow([]);
  };

  const submitData = () => {
    let payload = cloneDeep(omit(values, ['actionType']));
    payload.actionType = values.actionType.value;
    if (!isEmpty(itemToAdd)) {
      payload.itemToAdd = itemToAdd;
    }
    if (!isEmpty(itemsToShow)) {
      payload.itemsToShow = itemsToShow;
    }
    setLoading(true);

    addExploreBanner(payload)
      .then((result) => {
        setStatus('success');
        setMessage(`Explore Banner "${result.name}" was created successfully!`);
        setValues(initialValues);
        setImageUrl(null);
        setModalImageUrl(null);
        setItemToAdd({});
        setItemsToShow([]);
      })
      .catch((err) => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        if (payload.modalImageUrl) {
          values.modalImageUrl = payload.modalImageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin',
  );

  return (
    <ExploreBannerForm
      imageUrl={imageUrl}
      setImageUrl={setImageUrl}
      modalImageUrl={modalImageUrl}
      setModalImageUrl={setModalImageUrl}
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      handleDateChanged={handleDateChanged}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      handleAddItem={handleAddItem}
      handleAddItemsToShow={handleAddItemsToShow}
      itemToAdd={itemToAdd}
      itemsToShow={itemsToShow}
      clearAddItem={clearAddItem}
      clearItemsToShow={clearItemsToShow}
      buttonText="Create Explore Banner"
    />
  );
};

export default AddExploreBanner;
