import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors, screenSize } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import useFormValues from '../hooks/useFormValues';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import Nav from './Nav';
import QuestionnaireProgress from './QuestionnaireProgress';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import FinalStep from './FinalStep';
import { updateUser } from '../store/general/currentUserSlice';
import { debounce, cloneDeep } from 'lodash';

const Questionnaire = (props) => {
  const user = useSelector((state) => state.currentUser.user);
  const dispatch = useDispatch();
  const debouncedDispatch = debounce(dispatch);
  const [step, setStep] = useState(1);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    cloneDeep(user),
  );

  const handleBack = async () => {
    try {
      setError(null);
      if (step > 1) {
        setStep(step - 1);
      }
    } catch (err) {
      console.error(err);
      setError(
        err.message || 'Error while saving your information, please try again.',
      );
    }
  };

  const handleContinue = async () => {
    try {
      if (step === 5) {
        setLoading(true);
        setError(null);
        await debouncedDispatch(updateUser(values));
        setStep(step + 1);
        setLoading(false);
      } else {
        await debouncedDispatch(updateUser(values));
        if (step < 6) {
          setStep(step + 1);
        }
        setError(null);
      }
    } catch (err) {
      values.intakeComplete = false;
      // await props.updateUser(values);
      console.error(err);
      setError(
        err.message || 'Error while saving your information, please try again.',
      );
    }
  };

  useEffect(() => {
    setValues(cloneDeep(user));
  }, [user]);

  let component;
  const stepProps = {
    values,
    handleValueChanged,
    handleChange,
  };
  switch (step) {
    case 1:
      component = <StepOne {...stepProps} />;
      break;
    case 2:
      component = <StepTwo {...stepProps} />;
      break;
    case 3:
      component = <StepThree {...stepProps} />;
      break;
    case 4:
      component = <StepFour {...stepProps} />;
      break;
    case 5:
      component = <StepFive {...stepProps} />;
      break;
    case 6:
      component = (
        <FinalStep
          loading={loading}
          setLoading={setLoading}
          error={error}
          {...stepProps}
        />
      );
      break;
    default:
      break;
  }

  return (
    <FlexContainer flexDirection="column" flexGrow="1">
      <Nav step={step} />
      <ScrollContainer>
        {isMobile() && (
          <ProgressContainer justify="center" alignContent="center">
            <QuestionnaireProgress step={step} />
          </ProgressContainer>
        )}
        {component}

        {step < 6 && (
          <ButtonContainer
            alignItems="center"
            justify="center"
            flexDirection="column"
          >
            {error && <Error>{error}</Error>}
            <RowContainer>
              {step > 1 && (
                <Button
                  buttonSize="large"
                  buttonText="Go Back"
                  pink="true"
                  variant="outlined"
                  onClick={handleBack}
                  width={isMobile() ? '100%' : '138px'}
                />
              )}
              <Button
                buttonSize="large"
                buttonText="Continue"
                pink="true"
                onClick={handleContinue}
                width={isMobile() ? '100%' : '205px'}
              />
            </RowContainer>
          </ButtonContainer>
        )}
      </ScrollContainer>
    </FlexContainer>
  );
};

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  position: relative;
`;

const Error = styled.p`
  color: ${colors.error};
  margin-bottom: 16px;
`;

const RowContainer = styled(FlexContainer)`
  gap: 8px;
  width: 100%;
  flex-direction: ${(props) => (isMobile() ? 'column-reverse' : 'row')};
  justify-content: ${(props) => (isMobile() ? 'flex-start' : 'center')};
`;

const ButtonContainer = styled(FlexContainer)`
  margin: 60px auto;
  @media (max-width: ${screenSize.mobileL}) {
    margin: 40px auto;
    padding: 0 16px;
  }
`;

const ProgressContainer = styled(FlexContainer)`
  margin: 24px 0;
`;

export default Questionnaire;
