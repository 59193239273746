import React, { useEffect, useState, useMemo } from 'react';
import {
  capitalize,
  cloneDeep,
  map,
  isEmpty,
  without,
  pickBy,
  keys,
  reduce,
  camelCase,
  startCase,
} from 'lodash';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import RadioInput from '../elements/RadioInput';
import Chip from '../elements/Chip';
import Button from '../elements/Button';
import AddIcon from '../icons/AddIcon';
import { colors } from '../styleConstants';
import useFormValues from '../hooks/useFormValues';
import useFormSubmit from '../hooks/useFormSubmit';
import useToggles from '../hooks/useToggles';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../store/general/currentUserSlice';
import { searchFoods } from '../services/api/food';
import SearchModalReturnField from '../universal/SearchModalReturnField';
import { isMobile } from '../helpers/utils';
import DetailCard from './DetailCard';
import FoodDislikeIcon from '../icons/FoodDislikeIcon';
import FoodLikeIcon from '../icons/FoodLikeIcon';
import AddIconSmall from '../icons/AddIconSmall';
import { CircularProgress } from '@mui/material';
import {
  eatingPrefsMap,
  foodAvoidances,
  alcoholOptions,
} from '../helpers/profile';
import AlertStack from '../elements/AlertStack';

const FoodPrefsForm = () => {
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.activeMealPlan);
  const currentUser = useSelector((state) => state.currentUser.user);
  const userFoodAvoidances = useMemo(
    () =>
      reduce(
        currentUser.foodPreferences.foodAvoidances,
        (result, value) => {
      return (result[value] = true), result; /* eslint-disable-line */
        },
        {},
      ),
    [currentUser.foodPreferences.foodAvoidances],
  );

  // Need values to match enum in db but display user-friendly text
  const prefMap = {
    'I like everything': 'Any',
    'Flexitarian (Minimal meat consumption)': 'Flexitarian',
    'Pescatarian (Fish but no meat)': 'Pescatarian',
    Vegetarian: 'Vegetarian',
    Vegan: 'Vegan',
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    cloneDeep(currentUser),
  );

  const { toggleValues, setToggleValues, handleToggle } = useToggles(
    cloneDeep(userFoodAvoidances),
  );

  const [openLikes, setOpenLikes] = useState(false);
  const [openDislikes, setOpenDislikes] = useState(false);
  const [foodLikesValue, setFoodLikesValue] = useState(
    currentUser.foodPreferences.likes,
  );
  const [foodDislikesValue, setFoodDislikesValue] = useState(
    currentUser.foodPreferences.dislikes,
  );
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setToggleValues(cloneDeep(userFoodAvoidances));
    setValues(cloneDeep(currentUser));
  }, [currentUser, userFoodAvoidances, setToggleValues]);

  useEffect(() => {
    setFoodLikesValue(cloneDeep(currentUser.foodPreferences.likes));
    setFoodDislikesValue(cloneDeep(currentUser.foodPreferences.dislikes));
  }, [currentUser.foodPreferences.likes, currentUser.foodPreferences.dislikes]);

  const submitData = () => {
    setLoading(true);

    let payload = cloneDeep(values);
    payload.foodPreferences.eatingPreference =
      prefMap[payload.foodPreferences.eatingPreference];
    payload.foodPreferences.foodAvoidances = keys(
      pickBy(toggleValues, Boolean),
    );
    payload.foodPreferences.likes = foodLikesValue;
    payload.foodPreferences.dislikes = foodDislikesValue;
    payload.fitnessPreferences.exerciseLikes = map(
      payload.fitnessPreferences.exerciseLikes,
      'id',
    );
    payload.fitnessPreferences.exerciseDislikes = map(
      payload.fitnessPreferences.exerciseDislikes,
      'id',
    );
    // payload.fitnessPreferences.injuries = map(
    //   payload.fitnessPreferences.injuries,
    //   'id',
    // );
    dispatch(updateUser(payload))
      .then((result) => {
        setStatus('success');
        setMessage('Your profile has been successfully updated!');
        setLoading(false);
      })
      .catch((err) => {
        setStatus('error');
        setLoading(false);
        if (err.error.message) {
          setMessage(err.error.message);
        } else {
          setMessage('There was a problem updating your profile.');
          console.error(err);
        }
      });
    setLoading(false);
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'details',
  );

  const handleOpenLikes = () => {
    setOpenLikes(true);
  };

  const handleCloseLikes = () => {
    setOpenLikes(false);
  };

  const handleOpenDislikes = () => {
    setOpenDislikes(true);
  };

  const handleCloseDislikes = () => {
    setOpenDislikes(false);
  };

  const handleDeleteLike = (item) => {
    setFoodLikesValue(without(foodLikesValue, item));
  };

  const handleDeleteDislike = (item) => {
    setFoodDislikesValue(without(foodDislikesValue, item));
  };

  const foodDislikes = foodDislikesValue.map((dislike, index) => (
    <ChipItem
      key={index}
      label={dislike}
      name={dislike}
      handleDelete={() => handleDeleteDislike(dislike)}
      bgcolor={colors.hlitetwo400}
      iconcolor="#FFFBFB"
      iconhovercolor="#FFFBFB"
      hovercolor={colors.hlitetwo400}
      data-test="foodChip-dislike"
      selected
    />
  ));

  const foodLikes = foodLikesValue.map((like, index) => (
    <ChipItem
      key={index}
      label={like}
      name={like}
      handleDelete={() => handleDeleteLike(like)}
      bgcolor={colors.secondary600}
      iconcolor="#FFFBFB"
      iconhovercolor="#FFFBFB"
      hovercolor={colors.secondary600}
      data-test="foodChip-like"
      selected
    />
  ));

  const eatingPrefsCards = eatingPrefsMap.map(
    ({ label, value, iconSrc, extraInfo }) => (
      <DetailCard
        key={label}
        imgSrc={iconSrc}
        title={label}
        isSelected={values.foodPreferences.eatingPreference === value}
        name="foodPreferences.eatingPreference"
        handleSelect={() =>
          handleValueChanged(
            'foodPreferences.eatingPreference',
            value,
            'string',
          )
        }
        extraInfo={extraInfo}
        data-test={`prefs-eatingPreference-${camelCase(label)}`}
      />
    ),
  );
  const foodAvoidancesCards = foodAvoidances.map(({ label, name }) => (
    <DetailCard
      key={label}
      imgSrc={`${process.env.PUBLIC_URL}/icons/FoodAvoidances/${startCase(
        label,
      ).replace(/\s/g, '')}.png`}
      title={label}
      isSelected={toggleValues[name] || false}
      name={name}
      handleSelect={handleToggle()}
      allowMultiple
      data-test={`prefs-foodAvoidance-${camelCase(label)}`}
    />
  ));

  const alcoholOptionsCards = alcoholOptions.map((option) => (
    <DetailCard
      key={option}
      imgSrc={`${process.env.PUBLIC_URL}/icons/AlcoholPrefs/${camelCase(
        option,
      )}.png`}
      title={option}
      isSelected={values.foodPreferences.alcoholPreference === option}
      name={option}
      handleSelect={() =>
        handleValueChanged(
          'foodPreferences.alcoholPreference',
          option,
          'string',
        )
      }
      data-test={`prefs-alcoholPreference-${camelCase(option)}`}
    />
  ));

  const EatingStylePref = () => (
    <EatingStyleContainer>
      <SectionTitle>My Eating Style:</SectionTitle>
      <BR />
      <RadioInput
        checked={values.foodPreferences.eatingStyle === 'AllHome'}
        value="AllHome"
        label="I prepare all of my meals at home."
        labelMargin="20px"
        onChange={handleChange}
        name="foodPreferences.eatingStyle"
        data-test="eatingStyle-AllHome"
      />
      <BR />

      <RadioInput
        checked={values.foodPreferences.eatingStyle === 'MainlyHome'}
        value="MainlyHome"
        label="I eat mainly home-made meals but eat out a few times during the week."
        labelMargin="20px"
        onChange={handleChange}
        name="foodPreferences.eatingStyle"
        data-test="eatingStyle-MainlyHome"
      />
      <BR />

      <RadioInput
        checked={values.foodPreferences.eatingStyle === '50/50'}
        value="50/50"
        label="I eat some meals at home and some out - approximately a 50/50 balance."
        labelMargin="20px"
        onChange={handleChange}
        name="foodPreferences.eatingStyle"
        data-test="eatingStyle-5050"
      />
      <BR />

      <RadioInput
        checked={values.foodPreferences.eatingStyle === 'MostlyOut'}
        value="MostlyOut"
        label="I eat most of my meals out."
        labelMargin="20px"
        onChange={handleChange}
        name="foodPreferences.eatingStyle"
        data-test="eatingStyle-MostlyOut"
      />
    </EatingStyleContainer>
  );

  const EatingPref = () => (
    <EatingPreferenceContainer>
      <SectionTitle>My Eating Preference:</SectionTitle>
      <CardsContainer wrap="wrap">{eatingPrefsCards}</CardsContainer>
    </EatingPreferenceContainer>
  );
  const FoodAvoidance = () => (
    <>
      <SectionTitle>My Food Avoidances</SectionTitle>
      <SectionSubtitle>You can select more than one.</SectionSubtitle>
      <CardsContainer wrap="wrap">{foodAvoidancesCards}</CardsContainer>
    </>
  );

  const FoodDislike = () => (
    <FoodLikeDisLikeContainer>
      <FlexContainer alignItems="center">
        <FoodDislikeIcon />
        <FoodLikeDislikeTitle>My Food Dislikes:</FoodLikeDislikeTitle>
      </FlexContainer>
      <ChipContainer wrap="wrap" alignItems="center">
        {foodDislikes}
        {foodDislikesValue?.length > 0 ? (
          <AddIcon
            hideLabel
            onClick={handleOpenDislikes}
            pink="true"
            data-test="add-food-dislike"
          />
        ) : (
          <StyledButton
            onClick={handleOpenDislikes}
            alignItems="center"
            justify="center"
            $pink={true}
            data-test="add-food-dislike"
          >
            <AddIconSmall color="pink" />
            <ButtonLabel $pink={true}>Add a Food Dislike</ButtonLabel>
          </StyledButton>
        )}
        <SearchModalReturnField
          heading="Food Dislikes"
          chipBgColor="#ff6868"
          hoverColor={colors.hlitetwo200}
          open={openDislikes}
          handleClose={handleCloseDislikes}
          value={foodDislikesValue}
          setValue={setFoodDislikesValue}
          fetchService={searchFoods}
          fetchQueryFieldName="verboseName"
          getOptionLabel={(option) => capitalize(option)}
          placeholder="Try tomato, etc."
        />
      </ChipContainer>
    </FoodLikeDisLikeContainer>
  );

  const FoodLike = () => (
    <FoodLikeDisLikeContainer>
      <FlexContainer alignItems="center">
        <FoodLikeIcon />
        <FoodLikeDislikeTitle>My Food Likes:</FoodLikeDislikeTitle>
      </FlexContainer>
      <ChipContainer wrap="wrap" alignItems="center">
        {foodLikes}
        {foodLikesValue?.length > 0 ? (
          <AddIcon
            hideLabel
            onClick={handleOpenLikes}
            green="true"
            data-test="add-food-like"
          />
        ) : (
          <StyledButton
            onClick={handleOpenLikes}
            alignItems="center"
            justify="center"
            data-test="add-food-like"
          >
            <AddIconSmall />
            <ButtonLabel>Add a Food Like</ButtonLabel>
          </StyledButton>
        )}
        <SearchModalReturnField
          heading="Food Likes"
          chipBgColor={colors.secondary600}
          hoverColor={colors.secondary400}
          open={openLikes}
          handleClose={handleCloseLikes}
          value={foodLikesValue}
          setValue={setFoodLikesValue}
          fetchService={searchFoods}
          fetchQueryFieldName="verboseName"
          getOptionLabel={(option) => capitalize(option)}
          placeholder="Try tomato, etc."
        />
      </ChipContainer>
    </FoodLikeDisLikeContainer>
  );

  const AlcoholPref = () => (
    <>
      <SectionTitle>Alcohol Preference:</SectionTitle>
      <CardsContainer wrap="wrap">{alcoholOptionsCards}</CardsContainer>
    </>
  );

  return (
    <>
      {!isEmpty(mealPlan) && (
        <RecommendedCalories alignItems="center">
          <img
            src={`${process.env.PUBLIC_URL}/icons/Meal.png`}
            alt=""
            height="40"
            width="40"
          />

          <CalorieTitle data-test="prefs-mealPlan-name">
            <CalorieValue>{mealPlan.recommendedCalories}</CalorieValue> Calorie
            Plan
          </CalorieTitle>
        </RecommendedCalories>
      )}
      <EatingStylePref />
      <EatingPref />
      <FoodAvoidance />
      {isMobile() ? (
        <>
          <FoodLike />
          <FoodDislike />
        </>
      ) : (
        <WebLikesContainer>
          <FoodLike />
          <FoodDislike />
        </WebLikesContainer>
      )}
      <AlcoholPref />
      <FlexContainer
        alignSelf="center"
        flexDirection="column"
        justify="center"
        alignItems="center"
      >
        <SaveButton
          type="submit"
          buttonText="Save My Food Preferences"
          onClick={handleSubmit}
          data-test="prefs-food-save"
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        />
        {status === 'error' && (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'error'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="prefs-food-error"
          />
        )}
        {status === 'success' && (
          <AlertStack
            messages={message}
            type="success"
            variant="filled"
            open={status === 'success'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="prefs-food-success"
          />
        )}
      </FlexContainer>
    </>
  );
};

const ChipItem = styled(Chip)`
  text-transform: capitalize;
`;

const WebLikesContainer = styled(FlexContainer)`
  width: 90%;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 1075px) {
    flex-wrap: wrap;
  }
`;

const RecommendedCalories = styled(FlexContainer)`
  padding: 16px;
  background: #f7fbfb;
  border-radius: 8px;
  width: ${() => (isMobile() ? 'auto' : '264px')};
`;

const CalorieTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.secondary600};
  margin-left: 20px;
`;

const CalorieValue = styled.span`
  font-weight: 700;
`;

const EatingStyleContainer = styled.div`
  margin-top: 16px;
  padding: 16px;
  background: ${colors.primary050};
  border-radius: 6px;
  max-width: ${() => (isMobile() ? 'auto' : '750px')};
`;

const SectionTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary800};
`;

const BR = styled.div`
  margin: 12px 0;
  border-bottom: 1px solid ${colors.primary100};
`;

const EatingPreferenceContainer = styled.div`
  margin: 32px 0;
`;

const CardsContainer = styled(FlexContainer)`
  margin: 16px 0;
  > * {
    margin-bottom: 16px;
  }
  > :not(:last-child) {
    margin-right: 12px;
  }
`;

const SectionSubtitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary800};
  padding-top: 4px;
`;

const FoodLikeDisLikeContainer = styled.div`
  width: 100%;
`;
const FoodLikeDislikeTitle = styled(SectionTitle)`
  margin-left: 8px;
`;
const ChipContainer = styled(FlexContainer)`
  padding: 16px 0;
  > * {
    margin-right: 8px;
    margin-bottom: 16px;
  }
`;
const StyledButton = styled(FlexContainer)`
  width: ${() => (isMobile() ? '100%' : '348px')};
  height: 40px;
  background: #ffffff;
  border: 1px solid
    ${(props) => (props.$pink ? colors.hlitetwo400 : colors.secondary600)};
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => (props.$pink ? '#ffb7b73d' : '#bdf9ea69')};
  }

  &:active {
    background-color: ${(props) =>
      props.$pink ? colors.hlitetwo200 : colors.secondary200};
  }
`;

const ButtonLabel = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.$pink ? colors.hlitetwo400 : colors.secondary600)};
  margin-left: 12px;
`;

const SaveButton = styled(Button)`
  width: ${() => (isMobile() ? '100%' : '280px')};
  height: 40px;
  background: ${colors.secondary600};
  border-radius: 2px;
  text-transform: none;
`;

export default FoodPrefsForm;
