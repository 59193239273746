import React from 'react';
import styled from 'styled-components';
import { useLocation, Outlet } from 'react-router-dom';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import useWindowSize from '../hooks/useWindowSize';
import { getFormTitle, getCTAText } from './helpers';

const AuthPageWeb = ({
  values,
  setValues,
  handleChange,
  toggleValues,
  handleToggle,
  ...props
}) => {
  const windowSize = useWindowSize();
  const { pathname, state } = useLocation();
  const resetToken = state?.resetToken;

  // TODO: This will be replaced in a future PR when we allow admins to create their own campaigns
  const sampleBullets = [
    `30-Day Fat Loss Kickstarter Program - focusing on nutrition, movement, and wellness so you can not only lose
    the fat, but keep it off.`,
    `Personalized Nutrition Plan - get weekly meal plans with tasty dietitian-approved recipes, ready in under 30
    minutes.`,
    `Workout Plans that WORK - with a progressive mix of strength, cardio, and fun activities designed to maximize
    your results.`,
    `Accountable Coaching - stay supported with group and 1-on-1 coaching to keep you on track and achieving your
    long-term weight loss goals.`,
  ];

  const bubbles = sampleBullets.map((bullet, index) => {
    return (
      <PromoBubble key={index} alignItems="center">
        <img
          alt="Green check mark"
          src={`${process.env.PUBLIC_URL}/icons/GreenCheck.svg`}
          width="24px"
          height="24px"
        />
        <BubbleText>{bullet}</BubbleText>
      </PromoBubble>
    );
  });

  return (
    <Container>
      <LeftContainer>
        <HomeLogo href={'https://nutriology.io/'}> Nutriology </HomeLogo>
        <PromoContainer flexDirection="column">
          <Subhead>Unlock your body's formula to long-lasting fat loss</Subhead>
          <Description>
            Now you can lose weight and keep it off.{' '}
            <b>With Nutriology Transformation.</b> The all-in-one program that
            promotes lifelong wellbeing.
          </Description>
          <BubblesContainer wrap="wrap" style={{ gap: 12 }}>
            {bubbles}
          </BubblesContainer>
        </PromoContainer>
      </LeftContainer>
      <RightBackground flexDirection="column" justify="center">
        <RightContainer>
          <FormContainer flexDirection="column" alignItems="flex-start">
            <FormInfo flexDirection="column">
              <Header>
                Unlock your body's formula to long-lasting fat loss
              </Header>
              <HR />
              {windowSize.width > 1240 && windowSize.height > 750 && (
                <FormTitle data-test="auth-form-title">
                  {getFormTitle(pathname, resetToken)}
                </FormTitle>
              )}
              <FormTagLine>{getCTAText(pathname)}</FormTagLine>
            </FormInfo>
            <Form flexDirection="column">
              {(windowSize.width <= 1240 || windowSize.height <= 750) && (
                <FormTitle data-test="auth-form-title">
                  {getFormTitle(pathname, resetToken)}
                </FormTitle>
              )}
              <Outlet
                context={[
                  values,
                  setValues,
                  handleChange,
                  handleToggle,
                  toggleValues,
                  resetToken,
                ]}
              />
            </Form>
          </FormContainer>
        </RightContainer>
      </RightBackground>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  height: 100vh;
  background-image: linear-gradient(
      360deg,
      #ffffff 24.95%,
      rgba(255, 255, 255, 0) 50.95%
    ),
    url(${process.env.PUBLIC_URL}/SignUpImage.jpg);
  background-position: left;
  background-size: 100%;
  background-repeat: no-repeat;
  overflow-x: clip;

  @media screen and (max-width: 1450px) {
    background-image: linear-gradient(
        54.69deg,
        #ffffff 16.27%,
        rgba(255, 255, 255, 0.87) 24.53%,
        rgba(255, 255, 255, 0) 46.49%
      ),
      url(${process.env.PUBLIC_URL}/SignUpImage.jpg);
  }
  @media screen and (max-width: 1240px), (max-height: 750px) {
    background-image: url(${process.env.PUBLIC_URL}/SignUpImageTablet.png);
    flex-direction: column;
    width: 100%;
    height: auto;
    background-position: top;
  }
`;

const LeftContainer = styled(FlexContainer)`
  width: 80%;
  padding: 56px 4px 55px 55px;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1240px), (max-height: 750px) {
    height: calc(100vh - 110px);
    max-height: 768px;
    width: 100%;
  }
`;

const PromoContainer = styled(FlexContainer)`
  gap: 24px;

  @media screen and (max-width: 1375px) {
    max-width: 75%;
  }
  @media screen and (max-width: 940px) {
    max-width: 95%;
  }
`;

const RightBackground = styled(FlexContainer)`
  background-image: url(${process.env.PUBLIC_URL}/SignUpFormBknd-Large.svg);
  width: 800px;
  min-width: 700px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;

  @media screen and (max-height: 850px) {
    background-image: url(${process.env.PUBLIC_URL}/SignUpFormBknd-Medium.svg);
    min-width: 640px;
  }

  @media screen and (max-height: 775px) {
    align-items: flex-end;
  }

  @media screen and (max-height: 700px) {
    background-image: url(${process.env.PUBLIC_URL}/SignUpFormBknd-Small.svg);
    min-width: 520px;
    padding-left: 50px;
  }

  @media screen and (max-width: 1240px), (max-height: 750px) {
    background-image: url(${process.env.PUBLIC_URL}/SignUpFormBknd-Tablet.svg);
    background-size: cover;
    width: 100%;
  }
`;

const RightContainer = styled(FlexContainer)`
  width: 480px;
  padding-left: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-height: 760px) {
    padding-left: 0;
  }

  @media screen and (max-height: 720px) {
    background-size: cover;
    width: 451px;
  }

  @media screen and (max-width: 1240px), (max-height: 750px) {
    width: calc(100% - 160px);
    padding: 80px;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 360px;
  @media screen and (max-height: 720px) {
    width: 300px;
  }

  @media screen and (max-width: 1240px), (max-height: 750px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const Header = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${colors.hlitetwo050};
`;

const HR = styled.hr`
  width: 100%;
  background-color: #39aaac;
  border: none;
  height: 1px;
  margin: 32px 0;

  @media screen and (max-width: 1240px), (max-height: 750px) {
    display: none;
  }
`;

const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.hlitetwo050};
`;

const FormTagLine = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 18px 0 30px 0;
  color: ${colors.hlitetwo050};
`;

const Form = styled(FlexContainer)`
  gap: 14px;
  width: 100%;

  @media screen and (max-width: 1240px), (max-height: 750px) {
    max-width: 50%;
  }
`;

const FormInfo = styled(FlexContainer)`
  @media screen and (max-width: 1240px), (max-height: 750px) {
    max-width: 35%;
  }
`;

const BubblesContainer = styled(FlexContainer)`
  gap: 12px;

  @media screen and (max-width: 1240px), (max-height: 750px) {
    > :nth-child(odd) {
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 940px) {
    > :nth-child(odd) {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 1375px) {
    width: 90%;
  }
`;

const PromoBubble = styled(FlexContainer)`
  width: 308px;
  height: 60px;
  padding: 12px;
  background: rgba(22, 151, 154, 0.2);
  border-radius: 15px;
  gap: 8px;
`;

const BubbleText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000;
`;

const Subhead = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  max-width: 75%;
  color: ${colors.primary900};

  @media screen and (max-width: 1240px), (max-height: 750px) {
    max-width: 40%;
  }
  @media screen and (max-width: 1440px) {
    max-width: 50%;
  }
  @media screen and (max-width: 940px) {
    max-width: 60%;
  }
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;

  @media screen and (max-width: 1240px), (max-height: 750px) {
    max-width: 40%;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 60%;
  }
  @media screen and (max-width: 940px) {
    max-width: 60%;
  }
`;

const HomeLogo = styled.h1`
  color: ${colors.secondary600};
  text-decoration: none;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.73px;
  line-height: 32px;
  text-transform: uppercase;
`;

export default AuthPageWeb;
